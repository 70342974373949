<template>
  <div>
    <DragnDropArea hoverText="Datei hier ablegen" @files="openAddDocumentModal($event)">
      <InsuranceOptionMenu 
        :headerActions="headerActions"
        :insuranceId="$route.params.id"
        @action-NEW-ACTIVITY="newAppointment()"
        @action-OLD-POLICE-NUMBERS="showOldPoliceNumbers()"
        @action-SCHADEN-MELDEN="createSchaden()"
        @action-EMAIL-KUNDE="emailStart(false)"
        @action-EMAIL-GESELLSCHAFT="emailStart(true)"
        @action-BRIEF-ERSTELLEN="briefStart()"
        @action-KUENDIGUNG-VERSICHERUNGEN="emailCancel()"
        @action-AUFGABE-HINZUFUEGEN="handleAddActivity()"
        @action-VERSICHERUNG-KOPIEREN="versicherungKopierenBestaetigung()"
        @action-DOKUMENTE-HOCHLADEN="dateiHochladen()"
        @action-EXTERNEN-ZUGANG-AUFRUFEN="externenZugangOeffnen()"
        @action-VERS-ADMIN-A="versAdminn('A')"
        @action-VERS-ADMIN-M="versAdminn('M')"
      >
        <template #title>
          <a @click="navigateToGesellschaft()">{{ gesellschaftName }}</a>
        </template>
        <template #subtitle>
          <template v-if="bezeichnung">
            <span>{{ bezeichnung }}</span>
            <span v-if="nummer || statusAktuell || showKennzeichen"> | </span>
          </template>
          <template v-if="nummer">
            <router-link :to="overviewLink($route.params.id)">{{ nummer }}</router-link>
            <span v-if="statusAktuell || showKennzeichen"> | </span>
          </template>
          <template v-if="statusAktuell">
            <span><Pill :label="statusAktuell" :type="statusToPillType[statusAktuell]" /></span>
            <span v-if="showKennzeichen"> | </span>
          </template>
          <template v-if="showKennzeichen">
            <span>{{ kennzeichen }}</span>
          </template>
        </template>
      </InsuranceOptionMenu>

      <div class="box__container">
        <BaseCollapsable>
          <template v-slot:title>
            <span class="box__title">
              Hinweise und Fehler
            </span>
          </template>
          <template v-slot:content>
            <div class="antrag-sidetext__container mt-8px" v-if="!hasHinweis">
              <div class="row">
                <label class="col-12">
                  Keine Hinweise vorhanden.
                </label>
              </div>
            </div>
            <div v-else>
              <div class="font-bold mt-2">Hinweise:</div>
              <div class="row" v-for="(hinweis, index) of hinweise" :key="index">
                <div class="col-12">
                  <span class="font-bold" v-if="hinweis && hinweis.label">{{ hinweis.label + ' : '}}</span> {{ hinweis.text }}
                </div>  
              </div>
            </div>
            <div class="antrag-sidetext__container mt-8px" v-if="errorsAndWarnings && errorsAndWarnings.length">
              <div class="row">
                <div class="col-12">
                  <span class="font-bold">Fehler:</span>
                </div>
              </div>
              <ul>
                <div class="row" v-for="(warning, index) of errorsAndWarnings" :key="index">
                  <div class="col-12 color-danger" @click="navigateToStepWithWarning()">
                    <li>
                      <span>{{warning}}</span>
                      <span><PhPencilLine :size="16"/></span>
                    </li>
                  </div>
                </div>
              </ul>
            </div>
          </template>
        </BaseCollapsable>
      </div>

      <StepperForm
        ref="stepperForm"
        stepType="tab"
        stepperName="insurance-detail"
        :stepperMediator="tabsStepperMediator"
        :hasFertig="false"
        :selectedStepKey="selectedStepKey"
        :selectedSubstepKey="selectedSubstepKey"
        @set-step="setStepByKey"
        @set-substep="setSubstepByKey"
        @handleOtherActions="handleOtherActions"
        :additionalActionsForMobile="baseModalActions"        
      >
        <template #contentTemplate>
          <div v-if="selectedStepKey === 'allgemeine'">
            <div class="box__container" >
              <AllgemeineVertragsdaten ref="allgemeine" :canChangeVertragKundennr="canChangeVertragKundennr" @changeStep="setStepByKey($event)" />
            </div>

            <div class="box__container" v-if="versicherungsSummen.length">
              <VersicherungenSummen/>
            </div> 
          </div>

          <div v-else-if="selectedStepKey === 'weitere-vertragsdetails'">
            <div class="box__container" >
              <WeitereVertragsdetails/>
            </div>
  
          
          </div>

          <div class="box__container" v-else-if="selectedStepKey === 'versichertes-objekt'">
            <VersichertesObjekt/>
          </div>

          <div class="box__container" v-else-if="selectedStepKey === 'vertrags-personen'">
            <VertragsPersonen/>
          </div>


          <div v-else-if="selectedStepKey === 'sparteUbersicht'">
            <SparteUbersicht/>
          </div>

          <div v-else-if="selectedStepKey === 'fahrzeugDaten'">
            <FahrzeugDatenEdit :versicherungId="$route.params.id"/>
          </div>
          <div v-else-if="selectedStepKey === 'aenderungslog'">
           <Aenderungslog :typ ="'vers'"/>
          </div>
          <div class="box__container" v-else-if="selectedStepKey === 'sparten'">
            <AuswahlSparten 
              :spartenSteps="versicherungenDetails && versicherungenDetails.steps" 
              :spartenValues="getSpartenData" :comboboxValues="getSpartenComboboxValues" 
              @changeStepVisibility="changeStepVisibility($event)"/>
          </div>
          
          <div class="box__container" v-else-if="selectedStepKey === 'activities'">
            <PostfachOverviewTable
                title="E-Mails &amp; Aufgaben"
                isInsurance
                :records="records"
                :loading="loadingActivities"
                :parentId="$route.params.id"
                :disabledColumns="['name', 'contact']"
            />
          </div>

          <div v-else-if="selectedStepKey === 'dokumente'">
            <DocumentsFilter />
            <div class="box__container">
              <DocumentsTable 
                title="Dokumente" 
                :nodeId="nodeId" 
                :versicherungId="$route.params.id" 
                :rowsPerPage="rowsPerPage"
                :unregisterEmailEventsBeforeDestroy="false"
                @sendEmail="emailStart($event.toCompany, $event.attachments)"
              />
            </div>
          </div>

          <div class="box__container" v-else-if="selectedStepKey === 'anmerkungen'">
            <Anmerkungen/>
          </div>

          <div class="box__container" v-else-if="selectedStepKey === 'schadensmeldungen'">
            <div v-if="detailsData">

              <InsuranceDetailSchadensmeldungenTable 
                :schadenInfos="detailsData.schadenInfos" 
                :isCalledFromMrMoneyPdfbox="isCalledFromMrMoneyPdfbox" 
                :mrMoneyId="mrMoneyId"
                :vSNR="vSNR"
                :gesellName="versicherungenDetails.insurance.gesellschaft.zweigstelleName"
              />

            </div>
          </div>

          <div class="box__container" v-else-if="selectedStepKey === 'wertpapiere'">
            <div v-if="hasDepotPositions">
              <InsuranceDetailDepotPositionsTable :depotPositions="depotPositions" />
            </div>
          </div>

          <div v-else-if="selectedSubstepKey === 'abrechnungDetails'">
            <InsuranceAbrechnungDetails :editable="detailsData.insurance.allowNewBuchung"/>
          </div>

          <div class="box__container" v-else-if="selectedSubstepKey === 'zubringercourtage'">
            <InsuranceZubringercourtage :editable="detailsData.insurance.allowEditCourtage"/>
          </div>

          <div v-else-if="isSpartenStepSelected">
            <SparteSubstep 
              :spartenComponents="spartenComponents" 
              :hasHauptdaten="hasHauptdaten" 
              :spartenValues="getSpartenData" 
              :comboboxValues="getSpartenComboboxValues" 
              :sparteId="selectedStepKey"
              :stepConfig="getSparteStepConfig"
              :layoutModus="versicherung.layoutModus"
            />
          </div>

        </template>
      </StepperForm>

      <div v-if="false" class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="box__container" v-if="detailsData && detailsData.insurance">
            <div class="box__title">Sparten</div>
            <div v-for="sp in detailsData.insurance.sparten" :key="sp.id">
              <BaseCollapsable :showSection="true">
                <template v-slot:title>{{sp.typ.label}}</template>
                <template v-slot:content>
                  <template v-if="sp.parameters && sp.parameters.length">
                    <div class="row" v-for="pa in sp.parameters" v-bind:key="pa.id">
                      <div class="col-6">{{pa.label}}</div>
                      <div class="col-6">{{pa.parameter}}</div>
                    </div>
                  </template>
                  <div v-else>Keine daten</div>
                </template>
              </BaseCollapsable>
            </div>
          </div>
        </div>
      </div>
    </DragnDropArea>
    
    <EmailVersand/>

    <AufgabeBearbeitenModal 
    :bereichId="$route.params.id" 
    :gesellschaftName="gesellschaftName" 
    :nummer="nummer" 
    :bezeichnung="bezeichnung" 
    :bereichText="bereichText"
    ref="modalAdd"></AufgabeBearbeitenModal>

    <AddDocument ref="addDocumentModal" :nodeId="nodeId" :versicherungId="$route.params.id" @close="goToDownloadArea($route.params.id)" />

    <CopyInsuranceConfirmationModal ref="copyInsuranceConfirmationModal" @open-item="openItemSelectedDetail"></CopyInsuranceConfirmationModal>
    <ShowOldPoliceNumbersModal ref="showOldPoliceNumbersModal" @open-item="openItemSelectedDetail"></ShowOldPoliceNumbersModal> 
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VERSICHERUNG_TYPES from "@/store/versicherungen/types";
import BaseButton from "@/components/core/BaseButton.vue";
import BaseCollapsable from "@/components/core/BaseCollapsable.vue";
import InsuranceDetailSchadensmeldungenTable from '@/components/versicherungen/InsuranceDetailSchadensmeldungenTable.vue';
import InsuranceDetailDepotPositionsTable from '@/components/versicherungen/InsuranceDetailDepotPositionsTable.vue';
import AddDocument from './AddDocument.vue';
import DragnDropArea from '@/components/fileUpload/DragnDropArea.vue';
import DocumentsTable from '@/components/documents/DocumentsTable.vue';
import DocumentsFilter from '@/components/documents/DocumentsFilter.vue';
import Pill from '@/components/core/Pill.vue';
import MAILCOMPOSER_TYPES from '@/store/mailcomposer/types';
import CORE_TYPES from '@/store/core/types'
import BoxContainer from '@/components/core/BoxContainer.vue';
import InsuranceDetailMixin from '@/components/versicherungen/InsuranceDetail-mixin.js'
import StepperForm from '@/components/stepper/StepperForm.vue';
import { StepperMediator } from '@/components/stepper/utils';
import AllgemeineVertragsdaten from '@/components/versicherungen/AllgemeineVertragsdaten.vue'
import WeitereVertragsdetails from '@/components/versicherungen/WeitereVertragsdetails.vue'
import VersicherungenSummen from '@/components/versicherungen/VersicherungenSummen.vue'
import AuswahlSparten from '@/components/versicherungen/AuswahlSparten.vue'
import SparteSubstep from '@/components/versicherungen/SparteSubstep.vue'
import { trackBreadcrumbToCurrentRouteIfNeeded } from '@/router/breadcrumb/index'
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import { PhPencilLine } from 'phosphor-vue'
import BaseModal from '@/components/core/BaseModal.vue';
import AppointmentEditCreate from '@/components/calendar/appointmentFiles/AppointmentEditCreate.vue';
import Anmerkungen from '@/components/versicherungen/Anmerkungen.vue';
import PostfachOverviewTable from '@/views/communication/PostfachOverviewTable.vue';
import COMMUNICATION_TYPES from '@/store/communication/types';
import InsuranceAbrechnungDetails from '@/components/versicherungen/InsuranceAbrechnungDetails.vue'
import InsuranceZubringercourtage from '@/components/versicherungen/InsuranceZubringercourtage.vue'
import { ROLES, VIEW_ROLES } from '@/router/roles.js'
import { addParamToURL } from '@/helpers/utils-helper';
import SparteUbersicht from '@/components/versicherungen/SparteUbersicht.vue'
import VersichertesObjekt from "@/components/versicherungen/VersichertesObjekt.vue";
import InsuranceOptionMenu from './insurance-group/InsuranceOptionMenu.vue';
import VertragsPersonen from "@/components/versicherungen/VertragsPersonen.vue";
import FahrzeugDatenEdit from '@/views/versicherungen/FahrzeugDatenEdit.vue';
import CUSTOMER_SEARCH_TYPES from '@/store/customerSearch/types';
import EmailVersand from "@/components/core/EmailVersand.vue";
import AufgabeBearbeitenModal from '@/views/versicherungen/AufgabeBearbeitenModal.vue'
import CopyInsuranceConfirmationModal from '@/views/versicherungen/CopyInsuranceConfirmationModal.vue'
import ShowOldPoliceNumbersModal from '@/views/versicherungen/ShowOldPoliceNumbersModal.vue'
import INSURANCE_BUCHUNG_TYPES from "@/store/insuranceBuchung/types";

import Aenderungslog from '@/views/versicherungen/Aenderungslog.vue'

import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import { BaseModalSimpleAction } from '@/components/core/base-modal-actions/base-modal-actions-utils';

const VERSICHERUNGEN_GESELLSCHAFT_ART = 'VERSICHERUNGEN';
const VORSORGE_KATEGORIE = 'Vorsorge';


export default {
  mixins: [InsuranceDetailMixin, InteractiveHelpCommonsMixin],
  components: {
    BaseButton,
    BaseCollapsable,
    InsuranceDetailSchadensmeldungenTable,
    InsuranceDetailDepotPositionsTable,
    AddDocument,
    DragnDropArea,
    DocumentsTable,
    DocumentsFilter,
    Pill,
    BoxContainer,
    StepperForm,
    AllgemeineVertragsdaten,
    WeitereVertragsdetails,
    VersicherungenSummen,
    AuswahlSparten,
    SparteSubstep,
    PhPencilLine,
    AppointmentEditCreate,
    BaseModal,
    Anmerkungen,
    PostfachOverviewTable,
    InsuranceAbrechnungDetails,
    InsuranceZubringercourtage,
    SparteUbersicht,
    VersichertesObjekt,
    InsuranceOptionMenu,
    VertragsPersonen,
    FahrzeugDatenEdit,
    EmailVersand,
    AufgabeBearbeitenModal,
    Aenderungslog,
    CopyInsuranceConfirmationModal,
    ShowOldPoliceNumbersModal
},
  data() {
    return {
      vertragsdaten: {},
      selectedSubstepKey: '',
      newVertrag: false,
      loadingActivities: false,
      mailUsers: [],
      selectedRowsValues: [],
      isCancelable: false,
    };
  },
  watch:{
    deletedSchadensmeldungId(){
      this.retrievetAllData();
    },
    '$route.query.newVertrag': {
      handler(newVertrag) {
        this.newVertrag = newVertrag === 'true'
        this.tabsStepperMediator.setStepHidden('schadensmeldungen', this.newVertrag)
      },
      immediate: true
    },
    '$route.params.id'() {
      this.retrievetAllData().then(() => this.openAddDocumentModalTempFiles());
    },
    versicherungenDetails(value) {
      this.addDefaultBreadcrumbIfNotExists(value?.insurance);
      this.isCancelable = this.isCancelableInsurance();
    },
    isOriginalUserBroker: {
      handler() {
        this.tabsStepperMediator.setStepHidden('weitere-vertragsdetails', !this.isOriginalUserBroker && !this.isOriginalUserIntern)
      },
      immediate: true
    },
    initMailData() {
      this.mailUsers = this.initMailData?.participants;
    },
  },
  computed: {
    ...mapGetters({
      versicherungenDetails: VERSICHERUNG_TYPES.GETTERS.INSURANCE_DETAILS,
      versicherungDetailsEdited : VERSICHERUNG_TYPES.GETTERS.RETRIEVE_VERSICHERUNG_DETAILS_EDITED,
      filteredCategory: VERSICHERUNG_TYPES.GETTERS.FILTERED_CATEGORY,
      editingDetails: VERSICHERUNG_TYPES.GETTERS.EDITING_DETAILS,
      depotPositions: VERSICHERUNG_TYPES.GETTERS.DEPOT_POSITIONS,
      tempFiles: VERSICHERUNG_TYPES.GETTERS.TEMP_FILES,
      isCustomer: CORE_TYPES.GETTERS.IS_CUSTOMER,
      isOriginalUserBroker: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_BROKER,
      isOriginalUserIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
      deletedSchadensmeldungId: VERSICHERUNG_TYPES.GETTERS.LAST_DELETED_SCHADENSMELDUNG,
      insuranceList: VERSICHERUNG_TYPES.GETTERS.INSURANCES_FILTERED,
      backToPreviousData: CORE_TYPES.GETTERS.GET_SAVE_BACK_TO_PREVIOUS_PAGE,
      isFK: CORE_TYPES.GETTERS.IS_FK,
      isFA: CORE_TYPES.GETTERS.IS_FA,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
      records: COMMUNICATION_TYPES.GETTERS.COMMUNICATION_INSURANCE_RECORDS,
      initMailData: MAILCOMPOSER_TYPES.GETTERS.GET_INIT,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      versicherungsSummen: VERSICHERUNG_TYPES.GETTERS.VERSICHERUNGSSUMMEN,
    }),
    baseModalActions() {
      const actions =  [];

      if (this.selectedStepKey === 'dokumente') {
        actions.push(BaseModalSimpleAction('DOKUMENTE-HOCHLADEN', 'Dokumente hochladen'));
         
      }

      return actions;
    },    
    isSpartenStepSelected() {
      return this.dynamicSteps.some(step => step.stepKey === this.selectedStepKey);
    },    
    hasDepotPositions() {
      return this.depotPositions?.records?.length;
    },
    showRueckkaufsWert() {
      return this.detailsData?.insurance?.vtgGruppe === VORSORGE_KATEGORIE;
    },
    tabsStepperMediator() {
      return new StepperMediator(JSON.parse(JSON.stringify(this.steps)));
    },
    errorsAndWarnings() {
      return this.versicherung.fehler;
    },
    hasHinweis() {
      return this.hinweise?.length
    },
    hinweise() {
      return this.getHinweise(this.selectedStepKey); 
    },
    spartenComponents() {
      if (this.selectedSubstepKey && this.versicherungenDetails?.components) {
        return this.versicherungenDetails?.components[this.selectedSubstepKey] || [];
      }
      return [];
    },
    hasHauptdaten() {
      const step = this.tabsStepperMediator.getCurrentStep(this.selectedStepKey);
      return !step?.substeps?.length || step?.substeps[0]?.substepKey === this.selectedSubstepKey;
    },
    getSpartenData() {
      return this.versicherungenDetails?.insurance?.sparten
    },
    getSpartenComboboxValues() {
        return this.versicherungenDetails?.comboboxValues || {};
    },
    initSpartenSteps() {
      if (this.versicherungenDetails?.steps?.length) {
        let position = 3;
        this.versicherungenDetails?.steps.forEach(step => {
          const hidden = !this.versicherungenDetails?.insurance?.sparten?.some(sp => sp.sparteId === step.stepKey);
          this.tabsStepperMediator.addStep({ stepKey: step.stepKey, label: step.label, hidden: hidden } , position++);
          if (step.substeps?.length) {
            step.substeps.forEach(sub => this.tabsStepperMediator.addSubStep(step.stepKey, { substepKey: sub.substepKey, title: sub.label, hidden: hidden }));
          }
        });
        this.$refs.stepperForm?.updateStepper();
      }
    },
    hatZugriffsrecht() {
      return this.detailsData()?.hatZugriffsrecht || false;
    },
    canChangeVertragKundennr() {
      return this.hasRoles([ROLES.SONDERRECHT_KUNDENNR, [ROLES.FK, VIEW_ROLES.VIEW_CUSTOMER_AS_BROKER]]);
    },
    shouldHideAenderungslog(){
      return (this.hasRoles([[ ROLES.IS_FPP_PLUS_MASTER],[ ROLES.IS_FPP_PLUS], [ ROLES.IS_BAVARIA ], [ ROLES.IS_SVM ], [VIEW_ROLES.VIEW_CUSTOMER_AS_INTERN]])); 
    },
    getSparteStepConfig() {
      return this.steps?.find(step => this.selectedStepKey === step.stepKey)?.config;
    },
    versicherungId() {
      return this.$route.params.id;
    },
    isCalledFromMrMoneyPdfbox() {
      return this.$route?.query?.isCalledFromMrMoneyPdfbox === 'true';
    },
    mrMoneyId()  {
      return this.$route?.query?.mrMoneyId;
    },
    vSNR() {
      return this.$route?.query?.VSNR;
    },
  },
  methods: {
    handleOtherActions(actionData) {
      if (actionData?.key == 'DOKUMENTE-HOCHLADEN') {
        this.dateiHochladen();
      } 
      
    },    
    async setSubstepByKey(data) {
      if (data?.stepKey && data?.substepKey && this.selectedSubstepKey !== data.substepKey) {
        await this.saveBeforeLeave()
          .then((canChangeStep) => {
            if(canChangeStep) {
              this.selectedStepKey = data.stepKey;
              this.selectedSubstepKey = data.substepKey;
            }
          });
      }

      if (this.selectedSubstepKey === 'abrechnungDetails') {
        this.$store.commit(INSURANCE_BUCHUNG_TYPES.MUTATIONS.RESET_INSURANCE_BUCHUNG_VERTEILUNG_STRUKTUR);
      }
    },
    async retrievetAllData() {
      return await Promise.all([
        this.retrieveInsuranceDetails(),
        this.retrieveDepotPositions(),
      ]);
    },
    retrieveInsuranceDetails() {
      return this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.RETRIEVE_INSURANCE_DETAILS, {
        versVertrag: this.$route.params.id,
      });
    },
    retrieveDepotPositions() {
      return this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.GET_DEPOT_POSITIONS, {
        vertragsNr: this.$route.params.id,
      });
    },
    openAddDocumentModalTempFiles() {
      if(this.tempFiles?.length) {
        this.openAddDocumentModal(this.tempFiles);
      }
      this.$store.commit(VERSICHERUNG_TYPES.MUTATIONS.CLEAR_TEMP_FILES);
    },
    addDefaultBreadcrumbIfNotExists(insurance) {
      if (insurance && Object.keys(insurance).length > 0 && !this.backToPreviousData?.length) {
        this.$addBreadcrumb({
          label: 'zurück zu Versicherungen',
          fullPath: '/home/versicherungen/insurance-group',
          breadcrumb: `${insurance?.kategorieName} | ${insurance?.bezeichnung}`,
        });
      }
    },
    navigateTo(event) {
      this.$router.push({ path: `${event}` });
    },
    navigateToStepWithWarning(){
      this.setStepByKey('allgemeine')
    },
    changeStepVisibility(event) {
      if (event?.sparteId) {
        this.$refs.stepperForm.setStepHidden(event.sparteId, !event.value);
        const step = this.tabsStepperMediator.getCurrentStep(event.sparteId);
        if (step?.substeps?.length) {
          step.substeps.forEach(sub => this.$refs.stepperForm.setSubstepHidden(event.sparteId, sub.substepKey, !event.value));
        }
      }
    },
    resetAppointment() {
      try {
        this.loadingActivities = true;
        this.$store.dispatch(COMMUNICATION_TYPES.ACTIONS.LOAD_COMMUNICATION_INSURANCE_LISTS, this.$route.params.id);
      } finally {
        this.loadingActivities = false;
      }
    },
    selectedRows(rows) {
      this.selectedRowsValues = rows;
    },
    isCancelableInsurance() {
      if (this.versicherungenDetails?.insurance && this.versicherungenDetails.insurance.kuendiegungsMailPojo) {
        return this.versicherungenDetails.insurance.kuendiegungsMailPojo?.canMail && !this.versicherungenDetails.insurance.kuendiegungsMailPojo?.kuendiegungLeuft;
      } else {
        return false
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    
  },
  async beforeRouteLeave(to, from, next) {
    let url = from.fullPath;
    if(from.fullPath.includes('stepKey')){
      url = from.fullPath.replaceAll(/stepKey\=.*(\&)/gi, `stepKey=${this.selectedStepKey}$1`)
    } else {
      url = addParamToURL(from.fullPath, `stepKey=${this.selectedStepKey}`)
    }
    this.$addBreadcrumb({
      label: 'zurück zu Details', 
      fullPath: url,
      breadcrumb: to?.meta?.breadcrumb?.label,
    });

    await this.saveBeforeLeave();
    this.resetExternalUrl();

    this.$store.commit(VERSICHERUNG_TYPES.MUTATIONS.NODE_ID, {nodeId: this.nodeId});
    this.$store.commit(VERSICHERUNG_TYPES.MUTATIONS.EDITING_DETAILS_STOP);
    this.$store.commit(VERSICHERUNG_TYPES.MUTATIONS.CLEAR_DEPOT_POSITIONS);
    this.$store.commit(VERSICHERUNG_TYPES.MUTATIONS.CLEAR_TEMP_FILES);
    this.$store.commit(VERSICHERUNG_TYPES.MUTATIONS.CLEAR_VERSICHERUNG_FORM_VALIDATION);
    this.$store.commit(VERSICHERUNG_TYPES.MUTATIONS.CLEAR_INSURANCE_VERTRAGSDATEN_FIELDS_DEFINITION);
    this.$store.commit(VERSICHERUNG_TYPES.MUTATIONS.GET_FAHRZEUGDATEN_LIST_SUCCESS, [])
    this.$store.commit(CUSTOMER_SEARCH_TYPES.MUTATIONS.SET_SELECTED_CUSTOMERS, []);

    next()
  },
  beforeCreate(){
    this.$store.commit(VERSICHERUNG_TYPES.MUTATIONS.CLEAR_VERSICHERUNG_FORM_VALIDATION);
  },
  async mounted() {
    this.selectedStepKey =  this.getSteps[0].stepKey;
    await this.retrievetAllData().then(() => this.openAddDocumentModalTempFiles());
    if (this.$route.query.stepKey) {
      this.setStepByKey(this.$route.query.stepKey);
    }
    this.isCancelable = this.isCancelableInsurance();
    await this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.RETRIEVE_VERSICHERUNGSSUMMEN, {vertragId: this.$route.params.id});
 
  },
  
};
</script>
