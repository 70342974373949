var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { title: _vm.pageTitle, actions: _vm.headerActions },
        on: {
          "action-CREATE": function($event) {
            return _vm.submit()
          }
        }
      }),
      _vm.settings
        ? _c(
            "div",
            {
              staticClass: "box__container",
              attrs: { tid: "813cee9d-2008-4830-8181-c1072c7e8436" }
            },
            [
              _c("p", [
                _vm._v(
                  "Bitte wählen Sie die Zusammenstellung Ihrer Vermögensübersicht:"
                )
              ]),
              _c("InputRadioBoxGroup", {
                staticStyle: { width: "100%" },
                attrs: {
                  values: [
                    { value: "aktuell", label: "Aktuelle Vermögensübersicht" },
                    {
                      value: "historisch",
                      label: "Historische Vermögensübersicht"
                    }
                  ],
                  title: "Stichtag"
                },
                on: {
                  input: function($event) {
                    return _vm.onChange({
                      value: $event,
                      field: "valueDatumTyp"
                    })
                  }
                },
                model: {
                  value: _vm.form["valueDatumTyp"],
                  callback: function($$v) {
                    _vm.$set(_vm.form, "valueDatumTyp", $$v)
                  },
                  expression: "form['valueDatumTyp']"
                }
              }),
              _vm.form["valueDatumTyp"] == "historisch"
                ? _c("DatePickerField", {
                    attrs: {
                      isComponentHalfSize: "",
                      isValueAsString: "",
                      label: "Datum Vermögensübersicht"
                    },
                    on: {
                      input: function($event) {
                        return _vm.onChange({
                          value: $event,
                          field: "datumVermogensubersicht"
                        })
                      }
                    },
                    model: {
                      value: _vm.form["datumVermogensubersicht"],
                      callback: function($$v) {
                        _vm.$set(_vm.form, "datumVermogensubersicht", $$v)
                      },
                      expression: "form['datumVermogensubersicht']"
                    }
                  })
                : _vm._e(),
              _c("InputRadioBoxGroup", {
                staticStyle: { width: "100%" },
                attrs: {
                  values: [
                    {
                      value: "userSettings",
                      label:
                        "Aufteilung der Depots gem. Einstellung beim Kunden"
                    },
                    {
                      value: "allInOne",
                      label: "Alle Depotpositionen in einer Vermögensübersicht"
                    },
                    {
                      value: "separate",
                      label:
                        "Depotpositionen auf Depotauszüge der angelegten Depots aufteilen"
                    },
                    {
                      value: "selected",
                      label: "Nur das aktuell ausgewählte Depot"
                    }
                  ],
                  title: "Aufteilung der Depotpositionen"
                },
                on: {
                  input: function($event) {
                    return _vm.onChange({
                      value: $event,
                      field: "depotDistribution"
                    })
                  }
                },
                model: {
                  value: _vm.form["depotDistribution"],
                  callback: function($$v) {
                    _vm.$set(_vm.form, "depotDistribution", $$v)
                  },
                  expression: "form['depotDistribution']"
                }
              }),
              _vm.form["depotDistribution"] == "selected"
                ? _c("ComboBox", {
                    attrs: { values: _vm.depots },
                    on: {
                      input: function($event) {
                        return _vm.onChange({ value: $event, field: "depot" })
                      }
                    },
                    model: {
                      value: _vm.form["depot"],
                      callback: function($$v) {
                        _vm.$set(_vm.form, "depot", $$v)
                      },
                      expression: "form['depot']"
                    }
                  })
                : _vm._e(),
              _c("InputToggleSwitch", {
                attrs: {
                  label: "Transaktionen berücksichtigen",
                  value: _vm.form["inclTransactions"]
                },
                on: {
                  input: function($event) {
                    return _vm.onChange({
                      value: $event,
                      field: "inclTransactions"
                    })
                  }
                }
              }),
              _vm.form["inclTransactions"]
                ? _c("DatePickerField", {
                    attrs: {
                      isComponentHalfSize: "",
                      isValueAsString: "",
                      label: "Datum ab"
                    },
                    on: {
                      input: function($event) {
                        return _vm.onChange({
                          value: $event,
                          field: "transactionsFromDate"
                        })
                      }
                    },
                    model: {
                      value: _vm.form["transactionsFromDate"],
                      callback: function($$v) {
                        _vm.$set(_vm.form, "transactionsFromDate", $$v)
                      },
                      expression: "form['transactionsFromDate']"
                    }
                  })
                : _vm._e(),
              _vm.form["inclTransactions"]
                ? _c("DatePickerField", {
                    attrs: {
                      isComponentHalfSize: "",
                      isValueAsString: "",
                      label: "bis",
                      disabled: _vm.form["valueDatumTyp"] !== "historisch"
                    },
                    on: {
                      input: function($event) {
                        return _vm.onChange({
                          value: $event,
                          field: "transactionsToDate"
                        })
                      }
                    },
                    model: {
                      value: _vm.form["transactionsToDate"],
                      callback: function($$v) {
                        _vm.$set(_vm.form, "transactionsToDate", $$v)
                      },
                      expression: "form['transactionsToDate']"
                    }
                  })
                : _vm._e(),
              _c("InputToggleSwitch", {
                attrs: {
                  label: "Vermögensübersicht in Kurzform",
                  value: _vm.form["isShortForm"]
                },
                on: { input: _vm.onInputToggleSwitch }
              }),
              !_vm.isShortForm
                ? _c(
                    "div",
                    [
                      _c("h4", [_vm._v("Grafischer Verlauf:")]),
                      _c("InputToggleSwitch", {
                        attrs: {
                          label: "Grafischen Verlauf anzeigen",
                          value: _vm.form["inclGraphic"]
                        },
                        on: {
                          input: function($event) {
                            return _vm.onChange({
                              value: $event,
                              field: "inclGraphic"
                            })
                          }
                        }
                      }),
                      _c("InputRadioBoxGroup", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          values: [
                            {
                              value: "ENTWICKLUNG_MONATE",
                              label: "Entwicklung der letzten Monate",
                              default: true
                            },
                            {
                              value: "ENTWICKLUNG_AB_DATUM",
                              label: "Entwicklung ab Datum"
                            },
                            {
                              value: "ENTWICKLUNG_SEIT_BEGINN",
                              label: "Entwicklung seit Beginn"
                            }
                          ]
                        },
                        on: {
                          input: function($event) {
                            return _vm.onChange({
                              value: $event,
                              field: "gvPeriod"
                            })
                          }
                        },
                        model: {
                          value: _vm.form["gvPeriod"],
                          callback: function($$v) {
                            _vm.$set(_vm.form, "gvPeriod", $$v)
                          },
                          expression: "form['gvPeriod']"
                        }
                      }),
                      _vm.form["gvPeriod"] == "ENTWICKLUNG_MONATE"
                        ? _c("InputField", {
                            attrs: {
                              label: "Entwicklung der letzten Monate",
                              isComponentHalfSize: ""
                            },
                            on: {
                              input: function($event) {
                                return _vm.onChange({
                                  value: $event,
                                  field: "monthsCount"
                                })
                              }
                            },
                            model: {
                              value: _vm.form["monthsCount"],
                              callback: function($$v) {
                                _vm.$set(_vm.form, "monthsCount", $$v)
                              },
                              expression: "form['monthsCount']"
                            }
                          })
                        : _vm._e(),
                      _vm.form["gvPeriod"] == "ENTWICKLUNG_AB_DATUM"
                        ? _c("DatePickerField", {
                            attrs: {
                              isComponentHalfSize: "",
                              isValueAsString: "",
                              label: "Entwicklung ab Datum"
                            },
                            on: {
                              input: function($event) {
                                return _vm.onChange({
                                  value: $event,
                                  field: "graphFromDate"
                                })
                              }
                            },
                            model: {
                              value: _vm.form["graphFromDate"],
                              callback: function($$v) {
                                _vm.$set(_vm.form, "graphFromDate", $$v)
                              },
                              expression: "form['graphFromDate']"
                            }
                          })
                        : _vm._e(),
                      _c("InputToggleSwitch", {
                        attrs: {
                          label:
                            "Inkl. Wertentwicklung einzelner Depotpositionen",
                          value: _vm.form["inclGraphDetails"]
                        },
                        on: {
                          input: function($event) {
                            return _vm.onChange({
                              value: $event,
                              field: "inclGraphDetails"
                            })
                          }
                        }
                      }),
                      _vm.form["showDeckBlattForm"]
                        ? _c("InputToggleSwitch", {
                            attrs: {
                              label: "Inkl. Deckblatt",
                              value: _vm.form["deckblatt"]
                            },
                            on: {
                              input: function($event) {
                                return _vm.onChange({
                                  value: $event,
                                  field: "deckblatt"
                                })
                              }
                            }
                          })
                        : _vm._e(),
                      _vm.form["showDeckBlattForm"]
                        ? _c("InputTextArea", {
                            attrs: {
                              label: "Nachricht für Kunden auf dem Deckblatt"
                            },
                            on: {
                              input: function($event) {
                                return _vm.onChange({
                                  value: $event,
                                  field: "message"
                                })
                              }
                            },
                            model: {
                              value: _vm.form["message"],
                              callback: function($$v) {
                                _vm.$set(_vm.form, "message", $$v)
                              },
                              expression: "form['message']"
                            }
                          })
                        : _vm._e(),
                      _c("InputCheckBoxGroup", {
                        attrs: {
                          values: [
                            { label: "Inkl. Factsheet", path: "inclFactSheet" },
                            {
                              label: "Inkl. Factsheet KAG / Verkaufsprospekt",
                              path: "inclKag"
                            },
                            {
                              label:
                                "Inkl. PRIIP-BIB / Produktinformationsblatt",
                              path: "inclKid"
                            }
                          ],
                          value: _vm.form
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "BaseModal",
                {
                  ref: "generatingPDFModal",
                  attrs: {
                    labelButtonCancel: "Abbrechen",
                    showCancelButton: true,
                    showConfirmButton: false,
                    size: "lg"
                  },
                  on: {
                    onCancelButton: function($event) {
                      return _vm.cancelPDFGeneration()
                    },
                    onCloseButton: function($event) {
                      return _vm.cancelPDFGeneration()
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "modalTitle",
                        fn: function() {
                          return [_vm._v(" Vermögensübersicht wird erstellt ")]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    3047805846
                  )
                },
                [
                  _c("div", { staticClass: "mb-1" }, [
                    _vm._v(
                      "Daten werden geladen... Es kann einige Minuten dauern."
                    )
                  ])
                ]
              )
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "box__container" },
            [
              _c(
                "GhostLoading",
                { attrs: { repeat: 2 } },
                [
                  _c("Block", { attrs: { type: "title" } }),
                  _c("p", [_c("Block")], 1),
                  _c(
                    "p",
                    [
                      _c("Block"),
                      _c("Block", { attrs: { width: "80%" } }),
                      _c("Block", { attrs: { width: "80%" } })
                    ],
                    1
                  ),
                  _c(
                    "p",
                    [
                      _c("Block"),
                      _c("Block", { attrs: { width: "80%" } }),
                      _c("Block", { attrs: { width: "80%" } }),
                      _c("Block", { attrs: { width: "80%" } }),
                      _c("Block", { attrs: { width: "80%" } }),
                      _c("Block", { attrs: { width: "80%" } })
                    ],
                    1
                  ),
                  _c(
                    "p",
                    [_c("Block"), _c("Block", { attrs: { width: "80%" } })],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }