var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cards__container" },
    [
      _vm.positionsSorted && _vm.positionsSorted.length
        ? _c(
            "div",
            { staticClass: "cards__items" },
            _vm._l(_vm.positionsSorted, function(pos, index) {
              return _c(
                "div",
                {
                  key: pos.isin + index,
                  ref: "cards",
                  refInFor: true,
                  staticClass: "box__container cards__item",
                  class: _vm.getPositionClasses(pos)
                },
                [
                  _c(
                    "PageHeaderTitleNavigation",
                    _vm._g(
                      {
                        attrs: {
                          id: pos.isin + index,
                          actions: _vm.headerActions(pos),
                          noPrimaryAction: true,
                          showBackButton: false
                        },
                        on: {
                          "action-REMOVE": function($event) {
                            return _vm.openModalDeleteFond(pos)
                          },
                          "action-AA": function($event) {
                            return _vm.setAAForAll(pos.inputAA)
                          }
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function() {
                                return [
                                  _vm._t("title", function() {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "cards__item--header " },
                                        [
                                          _c(
                                            "a",
                                            {
                                              on: {
                                                click: function($event) {
                                                  return _vm.gotoFondsinfo(pos)
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getWertpapiernameStr(
                                                    pos,
                                                    false
                                                  )
                                                )
                                              )
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "text-divider" },
                                            [_vm._v("|")]
                                          ),
                                          _c(
                                            "span",
                                            { class: _vm.getColorClass(pos) },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.getOrderTyp(pos)) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  })
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "subtitle",
                              fn: function() {
                                return [
                                  _vm._t("subtitle", function() {
                                    return [
                                      _c("div", { staticClass: "font-bold" }, [
                                        _c("span", [
                                          _vm._v("ISIN: " + _vm._s(pos.isin))
                                        ]),
                                        _c(
                                          "span",
                                          { staticClass: "text-divider" },
                                          [_vm._v("|")]
                                        ),
                                        _c("span", [
                                          _vm._v("WKN: " + _vm._s(pos.wkn))
                                        ]),
                                        _c(
                                          "span",
                                          { staticClass: "text-divider" },
                                          [_vm._v("|")]
                                        ),
                                        _c("span", [
                                          _vm._v("SRI " + _vm._s(pos.sri))
                                        ]),
                                        _vm.isNurAnteile(pos)
                                          ? _c("span", [
                                              _vm._v(
                                                " | " + _vm._s(pos.produktArt)
                                              )
                                            ])
                                          : _vm._e()
                                      ])
                                    ]
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          true
                        )
                      },
                      _vm.$listeners
                    )
                  ),
                  _c(
                    "div",
                    { staticClass: "cards__item--inputs" },
                    [
                      pos.lagerstelle === "CAPITALBANK" &&
                      (pos.positionTyp == "ALT_KAUF" ||
                        pos.positionTyp == "NEU_KAUF")
                        ? _c("InputToggleSwitch", {
                            attrs: {
                              isEmbedded: "",
                              label: "Ca. Auftrag",
                              value: pos.isCaAuftrag,
                              id: "isCaAuftrag" + pos.index,
                              disabled:
                                _vm.readOnly ||
                                pos.hellobankOrderart == "Limit" ||
                                pos.hellobankOrderart == "Stop"
                            },
                            on: {
                              input: function($event) {
                                return _vm.assureOnlyOneCheckboxIsChecked(
                                  $event,
                                  pos,
                                  "isCaAuftrag"
                                )
                              }
                            }
                          })
                        : _vm._e(),
                      _c("InputField", {
                        attrs: {
                          isEmbedded: "",
                          type: "number",
                          label: "Auftragshöhe",
                          value: pos.betrag === 0 ? "" : pos.betrag,
                          precision: _vm.getPrecision(pos),
                          id: "betrag" + pos.index,
                          disabled:
                            _vm.readOnly ||
                            pos.betragModus ===
                              "VALUE_BETRAG_MODUS_ALLE_ANTEILE" ||
                            pos.auftragArt === "LOESCHEN" ||
                            !!pos.comdirectBoOrderId
                        },
                        on: {
                          inputRawValue: function($event) {
                            return _vm.onChangeBetrag($event, pos, "betrag")
                          },
                          change: function($event) {
                            return _vm.onChangeBetrag($event, pos, "betrag")
                          }
                        }
                      }),
                      _c("ComboBox", {
                        staticClass: "cards__item--input-no-label",
                        attrs: {
                          isEmbedded: "",
                          value: pos.betragModus,
                          id: "betragModus" + pos.index,
                          disabled:
                            _vm.readOnly ||
                            pos.istEbaseTauschRegelmaessig == "true" ||
                            pos.istEbaseTauschRegelmaessig == true ||
                            pos.auftragArt === "LOESCHEN" ||
                            !!pos.comdirectBoOrderId,
                          values: _vm.betragModusValues(pos)
                        },
                        on: {
                          change: function($event) {
                            return _vm.modusChanged(pos, $event)
                          }
                        }
                      }),
                      _vm.hasInputAA(pos) &&
                      (_vm.inputAAType(pos) === "INPUT_AA" ||
                        _vm.inputAAType(pos) === "INPUT_BONUS")
                        ? _c("InputField", {
                            attrs: {
                              isEmbedded: "",
                              type: "percent",
                              precision:
                                _vm.inputAAType(pos) === "INPUT_AA" ? 5 : 2,
                              label: pos.inputAAComponents.label,
                              value: pos.inputAA,
                              id: "inputAA" + pos.index,
                              disabled:
                                _vm.readOnly ||
                                !!pos.comdirectBoOrderId ||
                                pos.comdirectBoModus == "K"
                            },
                            on: {
                              inputRawValue: function($event) {
                                return _vm.onChangeInputField(
                                  !$event ? 0 : $event,
                                  pos,
                                  "inputAA"
                                )
                              },
                              change: function($event) {
                                return _vm.onChangeInputField(
                                  !$event ? 0 : $event,
                                  pos,
                                  "inputAA"
                                )
                              }
                            }
                          })
                        : _vm.hasInputAA(pos) &&
                          (_vm.inputAAType(pos) === "COMBO_BONUS" ||
                            _vm.inputAAType(pos) === "COMBO_RABATT")
                        ? _c(
                            "div",
                            { staticClass: "cards__item--input-240" },
                            [
                              _c("ComboBox", {
                                attrs: {
                                  label: pos.inputAAComponents.label,
                                  disabled: _vm.readOnly,
                                  value: pos.inputAA,
                                  id: "inputAA" + pos.index,
                                  values: pos.inputAAComboboxValues || [],
                                  sortComboboxValues: false
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.onChangeInputField(
                                      $event,
                                      pos,
                                      "inputAA"
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.getAuftragArtValues(pos)
                        ? _c(
                            "div",
                            {
                              class: {
                                "cards__item--input-240":
                                  pos.lagerstelle === "FODB"
                              }
                            },
                            [
                              _c("ComboBox", {
                                attrs: {
                                  label: "Verwendung",
                                  disabled: _vm.readOnly,
                                  value: pos.auftragArt,
                                  id: "auftragArt" + pos.index,
                                  values: _vm.getAuftragArtValues(pos) || [],
                                  sortComboboxValues: false
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.onChangeAuftragArt($event, pos)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      pos && pos.hasPlannummer
                        ? _c(
                            "div",
                            {
                              class: {
                                "cards__item--input-295":
                                  pos.lagerstelle === "FODB"
                              }
                            },
                            [
                              _c("ComboBox", {
                                attrs: {
                                  isEmbedded: "",
                                  label:
                                    pos.lagerstelle === "FODB"
                                      ? "Bestehender Plan"
                                      : "Plannummer",
                                  disabled:
                                    _vm.readOnly || pos.auftragArt === "NEU",
                                  value: pos.plannummer,
                                  id: "plannummer" + pos.index,
                                  values: pos.plannummerComboboxValues || []
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.onChangeInputField(
                                      $event,
                                      pos,
                                      "plannummer"
                                    )
                                  }
                                }
                              }),
                              _c("div", { staticClass: "plannummer-info" }, [
                                _vm._v(
                                  _vm._s(
                                    " nur für Änderung und Löschung relevant"
                                  )
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.hasBoerseData(pos) &&
                      (pos.lagerstelle === "HELLOBANK" ||
                        pos.lagerstelle === "CAPITALBANK")
                        ? _c("ComboBox", {
                            attrs: {
                              isEmbedded: "",
                              label: "Orderart",
                              value: pos.hellobankOrderart,
                              id: "hellobankOrderart" + pos.index,
                              disabled: _vm.readOnly,
                              values: _vm.orderartValues(pos)
                            },
                            on: {
                              change: function($event) {
                                return _vm.updateBoersenOrder(
                                  $event,
                                  pos,
                                  "hellobankOrderart"
                                )
                              }
                            }
                          })
                        : _vm._e(),
                      _vm.hasBoerseData(pos) && pos.lagerstelle === "COMDIRECT"
                        ? _c("ComboBox", {
                            attrs: {
                              isEmbedded: "",
                              label: "Orderart",
                              value: pos.comdirectBoModus,
                              id: "comdirectBoModus" + pos.index,
                              disabled: _vm.readOnly,
                              values: _vm.orderartValuesComdirect(pos)
                            },
                            on: {
                              change: function($event) {
                                return _vm.updateBoersenOrder(
                                  $event,
                                  pos,
                                  "comdirectBoModus"
                                )
                              }
                            }
                          })
                        : _vm._e(),
                      pos.positionTyp == "ALT_TAUSCH" &&
                      (pos.lagerstelle === "EBASE" ||
                        pos.lagerstelle == "EBASE_AAB")
                        ? _c("ComboBox", {
                            attrs: {
                              isEmbedded: "",
                              label: "Art",
                              value: "false",
                              id: "istEbaseTauschRegelmaessig" + pos.index,
                              values: [{ label: "einmalig", value: "false" }],
                              disabled: _vm.readOnly
                            },
                            on: {
                              change: function($event) {
                                return _vm.updateEbaseUmschichtungArt(
                                  $event,
                                  pos,
                                  "istEbaseTauschRegelmaessig"
                                )
                              }
                            }
                          })
                        : _vm._e(),
                      pos.positionTyp == "ALT_TAUSCH" &&
                      pos.lagerstelle === "FODB"
                        ? _c(
                            "div",
                            { staticClass: "cards__item--input-295" },
                            [
                              _c("ComboBox", {
                                attrs: {
                                  isEmbedded: "",
                                  label: "Zieldepot",
                                  value: pos.tauschInDepot,
                                  id: "tauschInDepot" + pos.index,
                                  disabled: _vm.readOnly,
                                  values: _vm.tauschInDepotValues
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.onChangeInputField(
                                      $event,
                                      pos,
                                      "tauschInDepot"
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      pos.tauschInDepot === "freie Eingabe:" &&
                      pos.positionTyp == "ALT_TAUSCH" &&
                      pos.lagerstelle === "FODB"
                        ? _c("InputField", {
                            staticClass: "comboalign",
                            attrs: {
                              isEmbedded: "",
                              id: "tauschInDepotText" + pos.index,
                              value: pos.tauschInDepotText || "",
                              disabled: _vm.readOnly
                            },
                            on: {
                              change: function($event) {
                                return _vm.onChangeInputField(
                                  $event || "",
                                  pos,
                                  "tauschInDepotText"
                                )
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _vm.hasInputAA(pos)
                        ? [
                            _vm.inputAAType(pos) === "INPUT_AA"
                              ? _c("div", { staticClass: "font-bold" }, [
                                  _vm._v(
                                    " Ausgabeaufschlag: normal " +
                                      _vm._s(_vm.displayAANormal(pos)) +
                                      "% "
                                  ),
                                  _vm.displayAANormal(pos) > 0
                                    ? _c("span", [
                                        _vm._v(
                                          ", entspricht " +
                                            _vm._s(_vm.displayAARabatt(pos)) +
                                            "% Rabatt"
                                        )
                                      ])
                                    : _vm._e()
                                ])
                              : _c("div", { staticClass: "font-bold" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(pos.inputAAComponents.comment) +
                                      " "
                                  )
                                ])
                          ]
                        : _vm._e(),
                      [
                        _c("WertpapierorderAnteileInfo", {
                          attrs: { position: pos }
                        })
                      ],
                      _vm.getWarningText(pos)
                        ? _c("div", { staticClass: "color-danger" }, [
                            _vm._v(_vm._s(_vm.getWarningText(pos)))
                          ])
                        : _vm._e()
                    ],
                    2
                  ),
                  pos.hellobankOrderart &&
                  (pos.lagerstelle === "HELLOBANK" ||
                    pos.lagerstelle === "CAPITALBANK")
                    ? [
                        _c("div", [
                          _c("div", { staticClass: "font-bold mt-4" }, [
                            _vm._v(
                              " Achtung: die folgenden Angaben werden nur berücksichtigt, wenn das Wertpapier über eine Börse gehandelt wird. "
                            )
                          ]),
                          _c(
                            "div",
                            { staticClass: "cards__item--inputs" },
                            [
                              _c("InputField", {
                                attrs: {
                                  label: "Börse",
                                  id: "hellobankBoerse" + pos.index,
                                  value: pos.hellobankBoerse,
                                  disabled: _vm.readOnly
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.onChangeInputField(
                                      $event,
                                      pos,
                                      "hellobankBoerse"
                                    )
                                  }
                                }
                              }),
                              _c("InputField", {
                                class: {
                                  "cards__item--input-240":
                                    pos.lagerstelle === "HELLOBANK"
                                },
                                attrs: {
                                  type: "currency",
                                  id: "hellobankKurs" + pos.index,
                                  label:
                                    pos.lagerstelle === "HELLOBANK"
                                      ? "Kurs, falls Limit- oder Stoporder"
                                      : "Kurs",
                                  value: pos.hellobankKurs,
                                  validateUntouched: true,
                                  disabled:
                                    _vm.readOnly ||
                                    pos.hellobankOrderart === "Bestens"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.onChangeInputField(
                                      $event,
                                      pos,
                                      "hellobankKurs"
                                    )
                                  }
                                }
                              }),
                              pos.lagerstelle === "CAPITALBANK"
                                ? _c("ComboBox", {
                                    attrs: {
                                      label: "Währung",
                                      id: "boerseWaehrung" + pos.index,
                                      value: pos.boerseWaehrung,
                                      values: _vm.waehrungen,
                                      disabled: _vm.readOnly
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.onChangeInputField(
                                          $event,
                                          pos,
                                          "boerseWaehrung"
                                        )
                                      }
                                    }
                                  })
                                : _vm._e(),
                              _c("DatePickerField", {
                                attrs: {
                                  label: "Gültigkeit bis",
                                  id: "hellobankGueltigkeit" + pos.index,
                                  value: pos.hellobankGueltigkeit,
                                  isValueAsString: "",
                                  validateUntouched: true,
                                  showPreset: false,
                                  disabled: _vm.readOnly
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.onChangeInputField(
                                      $event,
                                      pos,
                                      "hellobankGueltigkeit"
                                    )
                                  }
                                }
                              }),
                              pos.lagerstelle === "HELLOBANK" &&
                              pos.positionTyp !== "ALT_VERKAUF"
                                ? _c("ComboBox", {
                                    attrs: {
                                      label: "Art des Kaufs",
                                      id: "hellobankIstZeichnung" + pos.index,
                                      value: pos.hellobankIstZeichnung,
                                      values: [
                                        { label: "Kauf", value: "KF" },
                                        { label: "Zeichnung", value: "ZE" }
                                      ],
                                      disabled: _vm.readOnly
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.onChangeInputField(
                                          $event,
                                          pos,
                                          "hellobankIstZeichnung"
                                        )
                                      }
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ])
                      ]
                    : _vm._e(),
                  pos.comdirectBoModus &&
                  pos.comdirectBoModus !== "NICHT" &&
                  pos.comdirectBoModus !== "LAST"
                    ? [
                        _c(
                          "div",
                          { staticClass: "cards__item--inputs" },
                          [
                            _c("DatePickerField", {
                              attrs: {
                                label: "Gültigkeit bis",
                                id: "comdirectBoGueltigkeit" + pos.index,
                                value: pos.comdirectBoGueltigkeit,
                                isValueAsString: "",
                                validateUntouched: true,
                                showPreset: false,
                                disabled:
                                  _vm.readOnly ||
                                  (!!pos.comdirectBoOrderId &&
                                    pos.comdirectBoModus == "ST") ||
                                  !_vm.supportsComdirectGueltigkeit(pos)
                              },
                              on: {
                                change: function($event) {
                                  return _vm.onChangeInputField(
                                    $event,
                                    pos,
                                    "comdirectBoGueltigkeit"
                                  )
                                }
                              }
                            }),
                            _c("InputField", {
                              attrs: {
                                label: "Limit",
                                id: "comdirectBoLimit" + pos.index,
                                value: pos.comdirectBoLimit,
                                validateUntouched: true,
                                disabled:
                                  _vm.readOnly ||
                                  (!!pos.comdirectBoOrderId &&
                                    pos.comdirectBoModus == "ST") ||
                                  !_vm.supportsComdirectLimitOrder(pos)
                              },
                              on: {
                                change: function($event) {
                                  return _vm.onChangeInputField(
                                    $event,
                                    pos,
                                    "comdirectBoLimit"
                                  )
                                }
                              }
                            }),
                            _c("ComboBox", {
                              attrs: {
                                label: "Orderzusatz",
                                id: "comdirectBoOrderzusatz" + pos.index,
                                value: pos.comdirectBoOrderzusatz,
                                values: _vm.getOrderzusatzValues(pos),
                                validateUntouched: true,
                                disabled:
                                  _vm.readOnly ||
                                  !!pos.comdirectBoOrderId ||
                                  _vm.getOrderzusatzValues(pos).length == 0
                              },
                              on: {
                                change: function($event) {
                                  return _vm.onChangeInputField(
                                    $event,
                                    pos,
                                    "comdirectBoOrderzusatz"
                                  )
                                }
                              }
                            }),
                            !_vm.venuesAvailable(pos)
                              ? _c("InputField", {
                                  attrs: {
                                    label: "Ausführungsplatz",
                                    id:
                                      "comdirectBoAusfuehrungsplatz" +
                                      pos.index,
                                    value: pos.comdirectBoAusfuehrungsplatz,
                                    validateUntouched: true,
                                    disabled:
                                      _vm.readOnly || !!pos.comdirectBoOrderId
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.onChangeComdirectAusfuehrungsplatz(
                                        $event,
                                        pos,
                                        "comdirectBoAusfuehrungsplatz"
                                      )
                                    }
                                  }
                                })
                              : _vm._e(),
                            _vm.venuesAvailable(pos)
                              ? _c("ComboBox", {
                                  attrs: {
                                    label: "Ausführungsplatz",
                                    id:
                                      "comdirectBoAusfuehrungsplatz" +
                                      pos.index,
                                    value: pos.comdirectBoAusfuehrungsplatz,
                                    values: _vm.getVenues(pos),
                                    validateUntouched: true,
                                    disabled:
                                      _vm.readOnly || !!pos.comdirectBoOrderId
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.onChangeComdirectAusfuehrungsplatz(
                                        $event,
                                        pos,
                                        "comdirectBoAusfuehrungsplatz"
                                      )
                                    }
                                  }
                                })
                              : _vm._e(),
                            !pos.comdirectWebserviceActive
                              ? _c("InputField", {
                                  attrs: {
                                    label: "Hinweise",
                                    id: "comdirectBoHinweise" + pos.index,
                                    value: pos.comdirectBoHinweise,
                                    validateUntouched: true,
                                    disabled:
                                      _vm.readOnly || !!pos.comdirectBoOrderId
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.onChangeInputField(
                                        $event,
                                        pos,
                                        "comdirectBoHinweise"
                                      )
                                    }
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    : _vm._e(),
                  _vm.getTauschStr(pos)
                    ? _c("div", [
                        _vm._v(" " + _vm._s(_vm.getTauschStr(pos)) + " ")
                      ])
                    : _vm._e(),
                  _vm.isNurAnteile(pos)
                    ? _c("div", [
                        _vm._v(" Im Regelfall nur in ganzen Anteilen möglich. ")
                      ])
                    : _vm._e()
                ],
                2
              )
            }),
            0
          )
        : _vm.isWertpapierorderLoading
        ? _c(
            "GhostLoading",
            { staticClass: "mb-4", attrs: { useBoxContainer: "" } },
            [
              _c("Block", { staticClass: "mb-4", attrs: { type: "title" } }),
              _c("Block", { attrs: { height: "120px" } })
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("NoData", {
                attrs: { noIcon: "", content: "Keine Positionen" }
              })
            ],
            1
          ),
      _c("div", { staticClass: "row my-0" }, [
        _c("div", { staticClass: "col col-12 col-sm-6 mb-4 mb-sm-0" }, [
          _c("div", { staticClass: "box__container m-0" }, [
            _c(
              "div",
              [
                _c("CurrencyLabel", {
                  attrs: {
                    label: "Einmalanlagen:",
                    value: _vm.einmalSum,
                    showInlineLabel: ""
                  }
                }),
                _c("CurrencyLabel", {
                  attrs: {
                    label: "Verkäufe:",
                    value: _vm.verkaufSum,
                    showInlineLabel: ""
                  }
                }),
                _c("hr"),
                _c("CurrencyLabel", {
                  attrs: {
                    label: "Gesamt:",
                    value: _vm.einmalGesamtSum,
                    showInlineLabel: ""
                  }
                })
              ],
              1
            )
          ])
        ]),
        _c("div", { staticClass: "col col-12 col-sm-6" }, [
          _c("div", { staticClass: "box__container m-0" }, [
            _c(
              "div",
              [
                _c("CurrencyLabel", {
                  attrs: {
                    label: "monatliche Anlage:",
                    value: _vm.sparplanSum,
                    showInlineLabel: ""
                  }
                }),
                _c("CurrencyLabel", {
                  attrs: {
                    label: "monatliche Entnahme:",
                    value: _vm.entnahmeSum,
                    showInlineLabel: ""
                  }
                }),
                _c("hr"),
                _c("CurrencyLabel", {
                  attrs: {
                    label: "Gesamt:",
                    value: _vm.monatGesamtSum,
                    showInlineLabel: ""
                  }
                })
              ],
              1
            )
          ])
        ])
      ]),
      _c("DeletePositionModal", {
        ref: "deleteModal",
        attrs: {
          position: _vm.positionToDelete,
          positionLabel: _vm.getOrderTyp(_vm.positionToDelete)
        },
        on: {
          delete: function($event) {
            return _vm.removePosition()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }