import WEBRTC_TYPES from "./types";
import CORE_TYPES from "../core/types";
import LOG_TYPES from "@/store/log/types";
import COMMUNICATION_TYPES from "@/store/communication/types";
import LOADING_TYPES from "@/store/loading/types";
import HTTP_REQ_TYPES from "@/store/http-requests/types";
import axios from "axios";
import {
  MemoryTransport,
  TClosePhraseProtocol,
} from "./utils/thrift-non-generated";
import { WebRtcCallStatus } from "./utils/webrtc_types.js";
import {
  WebSocketEndPoint,
  WebSocketCloseReasonPhrase,
  SignalingConnectionRole,
  SignalingMessageId,
} from "./utils/signalingService_types.js";
import ROLES, { VIEW_ROLES } from "../../router/roles";
import { nextIncomingCallData } from "./getters";
import { buildMessage, buildMessageWith } from "@/helpers/log-message-helper";
import { v4 as uuidv4 } from "uuid";
import BRIDGE_TYPES from "@/store/bridge/types";
import { STOP_CALL_CAUSE } from "../bridge/index";
import { MESSAGE_CANCEL_REPEATED_REQUESTS } from "@/store/http-requests/actions";
import { makeQueryParam } from "@/helpers/utils-helper";
import router from "@/router";

import { createMessageToSignaler, writeThrift, createCallInfo } from '@/store/webrtc/sockets/helpers';
import { setupPushSocket } from '@/store/webrtc/sockets/push-socket';
import { setupSignalingSocket } from '@/store/webrtc/sockets/signaler';
import { createMediaStreams, getAudioMediaStream } from '@/store/webrtc/sockets/media-device-management';
import { setupPeerConnection } from '@/store/webrtc/sockets/peer-connection';

const uuidPrefix = "@";
const spinnerConfig = {
  defaultSpinner: true,
};

const disableDefaultLogConfig = {
  ...spinnerConfig,
  disableDefaultLog: true,
};

function sendChatMessage(rootState, commit, chatInfo, message, isInternalRemark) {
  return axios.post(
    rootState.core.apiAddress + "/../mrschat",
    {
      messageText: message,
      chatId: chatInfo.chatId,
      channelId: chatInfo.channelId,
      isInternalRemark: isInternalRemark,
    },
    {
      headers: {
        "Content-Type": "application/json; charset=utf-8;",
      },
      defaultSpinner: true,
    }
  );
}

function ignoreStartPushSocket({ getters }) {
  // MSC-25335 Temporarily enable the push socket on ios
  if (getters[BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT_IOS]) {
    return false;
  }

  // ignore connect on mobile native context and if it is not a browser simulation
  return (
    getters[BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT] &&
    !getters[BRIDGE_TYPES.GETTERS.IS_BROWSER_SIMULATION]
  );
}

/**
 * This function checks whether the microphone is allowed.
 *
 * Webview Android doesn't implement permission.query.
 * Instead, the microphone is requested and the microphone_blocked action is trigged from the catch error.
 * @param {*} dispatch
 * @param {*} state
 */
function checkMicrophonePermission(dispatch, state) {
  if (!navigator?.permissions?.query) {
    return;
  }

  navigator.permissions
    .query({ name: "microphone" })
    .then((permissionStatus) => {
      // granted, denied, prompt
      if (permissionStatus.state !== "granted") {
        dispatch(WEBRTC_TYPES.ACTIONS.MICROPHONE_BLOCKED);

        permissionStatus.onchange = function() {
          if (this.state === "granted") {
            state.audioCtx?.resume?.();
            dispatch(WEBRTC_TYPES.ACTIONS.MICROPHONE_ALLOWED);
          }
        };
      }
    })
    .catch(() => {
      dispatch(WEBRTC_TYPES.ACTIONS.MICROPHONE_BLOCKED);
    });
}

export default {
  async [WEBRTC_TYPES.ACTIONS.START_PUSH_SOCKET]({
    commit,
    dispatch,
    state,
    rootState,
    getters,
  }) {
    if (state.pushSocket || ignoreStartPushSocket({ getters })) return;

    const hasRoles = getters[CORE_TYPES.GETTERS.HAS_ROLES];
    if (hasRoles([ROLES.IS_BYPASS_SLASH, VIEW_ROLES.VIEW_BROKER_AS_BYPASS])) {
      return;
    }

    try {
      const response = await axios.post(
        `${
          getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]
        }/notificationregistration`,
        {},
        disableDefaultLogConfig
      );
      const socketUrl = response.data.pushSocketUrl;

      if (/NON_WEBRTC_DEV_ENVIRONMENT/.test(socketUrl)) {
        const msg = `WebRTC Calls push socket: the backend returned the following url: '${socketUrl}'. environment not properly configured`;
        dispatch(LOG_TYPES.ACTIONS.WARN, msg);
        return;
      }

      // all went well, the socket can be created
      setupPushSocket({
        socketUrl,
        commit,
        dispatch,
        state,
        rootState,
        getters,
        regData: response.data,
      });

      // now check if we got a push notification before the login, and if yes, dispatch it:
      console.log("handleChatPushFromBeforeLogin");
      const payloadOfPushBeforeLogin = getters[WEBRTC_TYPES.GETTERS.SAVED_PUSH_PAYLOAD];
      const isMobileContextIOS = getters[BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT_IOS];
      if (payloadOfPushBeforeLogin && isMobileContextIOS) {
        console.log("handleChatPushFromBeforeLogin --> paylod exists");
        // hand it to the bridge:
        this.$store.dispatch(BRIDGE_TYPES.HANDLERS.PUSH_NOTIFICATION_RECEIVED, payloadOfPushBeforeLogin);
        // clear it:
        this.$store.commit(WEBRTC_TYPES.MUTATIONS.SAVE_PUSH_PAYLOAD, null);
      };

    } catch (error) {
      const timeWaitingForReconnect = 60 + Math.random() * 540;
      dispatch(LOG_TYPES.ACTIONS.ERROR, {
        message: `notificationregistration returned an error`,
        error,
      });
      if (error.response.status != 401) {
        dispatch(
          LOG_TYPES.ACTIONS.WARN,
          `pushSocket failed, waiting ${timeWaitingForReconnect} seconds to reconnect`
        );
        setTimeout(() => {
          dispatch(WEBRTC_TYPES.ACTIONS.START_PUSH_SOCKET);
        }, timeWaitingForReconnect * 1000);
      }
    }
  },
  async [WEBRTC_TYPES.ACTIONS.STOP_PUSH_SOCKET](
    { commit, state },
    { message }
  ) {
    if (state.pushSocket) {
      state.pushSocket.close(1000, message || "");
      commit(WEBRTC_TYPES.MUTATIONS.SET_PUSH_SOCKET, null);
    }
  },
  [WEBRTC_TYPES.ACTIONS.UPDATE_AVAILABILITY](
    { commit, dispatch, getters },
    { calleeChatBeteiligterId, available }
  ) {
    if (getters[CORE_TYPES.GETTERS.ORIGINAL_USER_IS_BROKER])
      commit(WEBRTC_TYPES.MUTATIONS.UPDATE_AVAILABILITY, {
        calleeChatBeteiligterId,
        available,
      });
    else {
      if (!available)
        commit(WEBRTC_TYPES.MUTATIONS.SET_CALLEE_CHAT_BETEILIGTER_ID, null);
      else
        commit(
          WEBRTC_TYPES.MUTATIONS.SET_CALLEE_CHAT_BETEILIGTER_ID,
          calleeChatBeteiligterId
        );
    }

    if (calleeChatBeteiligterId) {
      dispatch(
        WEBRTC_TYPES.ACTIONS.LOAD_BETEILIGTER_INFO,
        calleeChatBeteiligterId
      );
    }
  },
  [WEBRTC_TYPES.ACTIONS.OPEN_CALL_MODAL](
    { commit, dispatch, state },
    calleeChatBeteiligterId
  ) {
    if (!calleeChatBeteiligterId) {
      calleeChatBeteiligterId = state.calleeChatBeteiligterId;
    }
    dispatch(
      LOG_TYPES.ACTIONS.INFO,
      `WEBRTC_TYPES.ACTIONS.OPEN_CALL_MODAL: calleeChatBeteiligter: ${calleeChatBeteiligterId}`
    );
    if (state.callModalForceOpenId == calleeChatBeteiligterId) {
      // clicked on the "open call modal" button twice -> close it again
      commit(WEBRTC_TYPES.MUTATIONS.TOGGLE_CALL_MODAL);
      return;
    }
    if (calleeChatBeteiligterId) {
      if (!state.beteiligterInfos[calleeChatBeteiligterId])
        dispatch(
          WEBRTC_TYPES.ACTIONS.LOAD_BETEILIGTER_INFO,
          calleeChatBeteiligterId
        );
      commit(WEBRTC_TYPES.MUTATIONS.TOGGLE_CALL_MODAL, calleeChatBeteiligterId);
      if (
        !state.chatInfos.find(
          (chatInfo) => chatInfo.beteiligterId == calleeChatBeteiligterId
        )
      )
        dispatch(
          WEBRTC_TYPES.ACTIONS.LOAD_CHAT_INFO_FOR_WEBRTC,
          calleeChatBeteiligterId
        );
    }
  },
  [WEBRTC_TYPES.ACTIONS.LOAD_BETEILIGTER_INFO](
    { commit, dispatch, getters },
    calleeChatBeteiligterId
  ) {
    if (!getters[CORE_TYPES.GETTERS.IS_LOGGED_IN]) {
      dispatch(LOG_TYPES.ACTIONS.WARN, {
        message:
          "The user is not logged in. Cannot load beteiligter info using endpoint.",
      });
      return;
    }

    axios
      .get(
        `${
          getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]
        }/mrsbeteiligterinfo?beteiligter_id=${calleeChatBeteiligterId}`
      )
      .then((response) => {
        if (response.data.beteiligterInfo) {
          commit(WEBRTC_TYPES.MUTATIONS.UPDATE_BETEILIGTER_INFO, {
            ...response.data.beteiligterInfo,
            calleeChatBeteiligterId,
          });
        }
      })
      .catch((error) => {
        dispatch(LOG_TYPES.ACTIONS.ERROR, {
          message: "WEBRTC_TYPES.ACTIONS.LOAD_BETEILIGTER_INFO ",
          error,
        });
      });
  },

  async [WEBRTC_TYPES.ACTIONS.START_CALL_IOS](
    { commit, dispatch },
    { calleeChatBeteiligterId }
  ) {
    commit(WEBRTC_TYPES.MUTATIONS.UPDATE_CALLING_ID, calleeChatBeteiligterId);
    // ownUUID aka callerSignalingUUID without uuidPrefix
    // The 'trigger-outgoing-call' doesn't need this prefix
    const ownUUID = uuidv4();
    const otherUUID = uuidv4();

    await dispatch(BRIDGE_TYPES.ACTIONS.TRIGGER_OUTGOING_CALL, {
      calleeId: calleeChatBeteiligterId,
      calleeIdType: "CHAT_BETEILIGTER_ID",
      callerSignalingUUID: ownUUID,
      calleeSignalingUUID: otherUUID,
      video: false,
    });

    commit(WEBRTC_TYPES.MUTATIONS.UPDATE_CALLING_ID, null);
  },

  async [WEBRTC_TYPES.ACTIONS.START_CALL_WEB](
    ctx,
    { calleeChatBeteiligterId }
  ) {
    const { commit, dispatch, getters } = ctx;
    commit(WEBRTC_TYPES.MUTATIONS.UPDATE_CALLING_ID, calleeChatBeteiligterId);
    const ownUUID = uuidPrefix + uuidv4();
    const otherUUID = uuidv4();

    try {
      const params = {
        toUser: {
          chatBeteiligterId: calleeChatBeteiligterId,
        },
        fromSignalingUUID: ownUUID,
        toSignalingUUID: otherUUID,
        videoEnabled: false,
      };

      const config = {
        headers: {
          "Content-Type": "application/json; charset=utf-8;",
          UID: uuidv4(),
        },
        defaultSpinner: true,
      };

      const prepareCallUrl = `${
        getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]
      }/mrswebrtcpreparecall`;
      commit(WEBRTC_TYPES.MUTATIONS.WEBRTC_PREPARE_CALL_REQUEST_UUID, {
        UID: config.headers.UID,
      });

      const response = await axios
        .post(prepareCallUrl, params, config)
        .finally(() => {
          commit(WEBRTC_TYPES.MUTATIONS.WEBRTC_PREPARE_CALL_REQUEST_UUID, {
            UID: null,
          });
        });

      if (response?.data?.busy) {
        dispatch(
          LOG_TYPES.ACTIONS.ADD_MESSAGE,
          buildMessage(
            "Leider ist Ihr Gesprächspartner gerade nicht erreichbar. Bitte versuchen Sie es später nochmal.",
            "danger"
          )
        );
        commit(WEBRTC_TYPES.MUTATIONS.UPDATE_CALLING_ID, null);
      } else if (response?.data) {
        try {

          const { stream, audioStream } = await createMediaStreams({ 
            dispatch, 
            commit 
          });
          if (!audioStream) {
            if (stream?.getTracks) {
              stream.getTracks().forEach(track => track.stop());
            }
            dispatch(WEBRTC_TYPES.ACTIONS.STOP_CALL_ON_NO_AUDIO_STREAM_AVAILABLE);
            return;
          }

          const callInfo = createCallInfo(
            ctx,
            response.data.callId,
            SignalingConnectionRole.CALLER_WEBRTC_CALL_RECEIVER,
            ownUUID,
            otherUUID,
            calleeChatBeteiligterId,
            response.data.webrtcConfig.signalingServerUrl,
            response.data.webrtcConfig.turnServerUrl,
            response.data.webrtcConfig.turnServerUser,
            response.data.webrtcConfig.turnServerPassword
          );

          commit(WEBRTC_TYPES.MUTATIONS.UPDATE_CALLING_ID, null);
          await dispatch(WEBRTC_TYPES.ACTIONS.CREATE_CALL_INFO, { callInfo });
          await dispatch(WEBRTC_TYPES.ACTIONS.SETUP_WEBRTC_CALL, {
            callInfo,
            stream,
            audioStream,
          });
        } catch (error) {
          dispatch(LOG_TYPES.ACTIONS.ERROR, {
            message: "failed to receive audio/video input",
            error,
          });
        }
      } else {
        commit(WEBRTC_TYPES.MUTATIONS.UPDATE_CALLING_ID, null);
        throw new Error(
          "The response of /mrswebrtcpreparecall has missing information."
        );
      }
    } catch (error) {
      commit(WEBRTC_TYPES.MUTATIONS.UPDATE_CALLING_ID, null);
      dispatch(LOG_TYPES.ACTIONS.ERROR, {
        message: "WEBRTC_TYPES.ACTIONS.START_CALL",
        error,
      });

      if (error.name !== "CanceledError") {
        const msg =
          "Es ist ein unerwarteter Fehler beim Versuch den Anruf aufzubauen aufgetreten. Ihr Anruf wurde beendet.";
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(msg, "danger"));
        dispatch(BRIDGE_TYPES.ACTIONS.STOP_CALL, {
          stopCallCause: STOP_CALL_CAUSE.ERROR,
        });
      } else {
        dispatch(BRIDGE_TYPES.ACTIONS.STOP_CALL, {
          stopCallCause: STOP_CALL_CAUSE.HANG_UP_LOCAL,
        });
      }
    }
  },
  async [WEBRTC_TYPES.ACTIONS.START_CALL](
    { dispatch, getters },
    calleeChatBeteiligterId
  ) {
    if (getters[BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT_ANDROID]) {
      const allowedToProceed = await dispatch(
        BRIDGE_TYPES.ACTIONS.START_OUTGOING_CALL,
        { calleeChatBeteiligterId }
      );

      if (allowedToProceed) {
        await dispatch(WEBRTC_TYPES.ACTIONS.START_CALL_WEB, {
          calleeChatBeteiligterId,
        });
      }
    } else if (getters[BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT_IOS]) {
      // MSC-25335 Temporarily disable the call on iOS native stack and using CALL_WEB instead.
      // Might be reverted back in future
      // await dispatch(WEBRTC_TYPES.ACTIONS.START_CALL_IOS, { calleeChatBeteiligterId })
      await dispatch(WEBRTC_TYPES.ACTIONS.START_CALL_WEB, {
        calleeChatBeteiligterId,
      });
    } else {
      await dispatch(WEBRTC_TYPES.ACTIONS.START_CALL_WEB, {
        calleeChatBeteiligterId,
      });
    }
  },
  async [WEBRTC_TYPES.ACTIONS.CREATE_CALL_INFO]({ commit }, { callInfo }) {
    commit(WEBRTC_TYPES.MUTATIONS.CREATE_CALL_INFO, callInfo);
  },
  async [WEBRTC_TYPES.ACTIONS.SETUP_WEBRTC_CALL](
    { commit, dispatch, state, getters },
    { callInfo, stream, audioStream }
  ) {
    dispatch(LOG_TYPES.ACTIONS.INFO, {
      message: "[WEBRTC_TYPES.ACTIONS.SETUP_WEBRTC_CALL] called",
      params: { callInfo, stream, audioStream },
    });

    if (!callInfo || !audioStream) {
      dispatch(WEBRTC_TYPES.ACTIONS.STOP_CALL_ON_NO_AUDIO_STREAM_AVAILABLE, {
        callId: callInfo?.callId,
      });
      return;
    }

    await setupSignalingSocket({ callInfo, commit, dispatch, state, getters });
    await setupPeerConnection({ callInfo, commit, state, dispatch });

    commit(WEBRTC_TYPES.MUTATIONS.CREATE_AUDIO_CONTEXT);
    commit(WEBRTC_TYPES.MUTATIONS.SETUP_INITIAL_STREAM, {
      callId: callInfo.callId,
      stream,
    });
    commit(WEBRTC_TYPES.MUTATIONS.AUDIO_INPUT, audioStream);

    dispatch(WEBRTC_TYPES.ACTIONS.UPDATE_RINGING_SYMBOL, callInfo.callId);
  },
  [WEBRTC_TYPES.ACTIONS.STOP_CALL_ON_ERROR](
    { dispatch, state },
    { callId, event }
  ) {
    let callInfo = state.callInfos.find((data) => data.callId == callId);
    dispatch(WEBRTC_TYPES.ACTIONS.END_CALL, {
      callId: callInfo.callId,
      status: WebRtcCallStatus.DROPOUT,
      alertMsg:
        "Beim Versuch einen Anruf aufzubauen, ist ein Fehler aufgetreten.",
      stopCallCause: STOP_CALL_CAUSE.CANCELED,
    });
    dispatch(LOG_TYPES.ACTIONS.ERROR, {
      message: "Browser caused a DROPOUT",
      userAgent: navigator.userAgent,
      iceConnectionState: callInfo.peerConnection.iceConnectionState,
      event: event,
    });
  },
  [WEBRTC_TYPES.ACTIONS.STOP_CALL_ON_NO_AUDIO_STREAM_AVAILABLE](
    { dispatch },
    { callId } = {}
  ) {
    const alertMsg =
      "Beim Versuch einen Anruf aufzubauen, ist ein Fehler aufgetreten. Es konnte kein Zugriff auf das Mikrofon gewährt werden.";

    if (callId) {
      dispatch(WEBRTC_TYPES.ACTIONS.END_CALL, {
        callId,
        status: WebRtcCallStatus.REJECTED,
        alertMsg,
        stopCallCause: STOP_CALL_CAUSE.REJECTED,
      });
    } else {
      dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(alertMsg, "danger"));
    }
  },
  [WEBRTC_TYPES.ACTIONS.UPDATE_RINGING_SYMBOL](
    { commit, dispatch, state },
    callId
  ) {
    const callInfo = nextIncomingCallData(state);
    if (callInfo && callInfo.callId == callId) {
      commit(WEBRTC_TYPES.MUTATIONS.UPDATE_RINGING_SYMBOL);
      setTimeout(
        () => dispatch(WEBRTC_TYPES.ACTIONS.UPDATE_RINGING_SYMBOL, callId),
        500
      );
    }
  },
  [WEBRTC_TYPES.ACTIONS.END_CALL](
    { commit, state, dispatch, getters },
    { callId, status, alertMsg, stopCallCause }
  ) {
    const callInfo = state.callInfos.find((data) => data.callId == callId);
    const transport = new MemoryTransport("");
    const protocol = new TClosePhraseProtocol(transport);
    new WebSocketCloseReasonPhrase({
      dbPrefix: callInfo.dbPrefix,
      webRtcCallId: callInfo.callId,
      connectionRole: callInfo.connectionRole,
      status: status,
      closingEndpoint: WebSocketEndPoint.CLIENT,
      correspondentSignalingUUID: callInfo.otherUUID,
    }).write(protocol);
    let data = transport.readAll();
    if (callInfo.socketBacklog) {
      // the socket isn't open yet. by setting closeSocketData the socket will be closed in its onopen event
      commit(WEBRTC_TYPES.MUTATIONS.CLOSED_TOO_EARLY, {
        callId,
        closeSocketData: data,
        alertMsg,
      });
    } else {
      commit(WEBRTC_TYPES.MUTATIONS.END_CALL, {
        callId,
        closeSocketData: data,
        alertMsg,
      });
    }

    if (stopCallCause) {
      dispatch(BRIDGE_TYPES.ACTIONS.STOP_CALL, { stopCallCause });
    }
    // Return the beteiligter to the proper Berater after the call is ended
    if (getters[CORE_TYPES.GETTERS.HAS_ROLES](VIEW_ROLES.VIEW_CUSTOMER_ONLY)) {
      const {
        calleeChatBeteiligterId,
        calleeChatBeteiligterAvailable,
      } = getters[CORE_TYPES.GETTERS.GET_LOGIN_DATA];
      dispatch(WEBRTC_TYPES.ACTIONS.UPDATE_AVAILABILITY, {
        calleeChatBeteiligterId,
        available: calleeChatBeteiligterAvailable,
      });
    }
  },
  async [WEBRTC_TYPES.ACTIONS.ACCEPT_CALL]({
    commit,
    dispatch,
    state,
    getters,
  }) {
    const { stream, audioStream } = await createMediaStreams({
      dispatch,
      commit,
    });

    const callInfo = nextIncomingCallData(state);
    await dispatch(WEBRTC_TYPES.ACTIONS.SETUP_WEBRTC_CALL, {
      callInfo,
      stream,
      audioStream,
    });

    if (callInfo) {
      commit(WEBRTC_TYPES.MUTATIONS.ACCEPT_CALL, callInfo.callId);
      if (
        callInfo.peerConnection &&
        (callInfo.peerConnection.iceConnectionState === "connected" ||
          callInfo.peerConnection.iceConnectionState === "completed")
      ) {
        let tmsg = createMessageToSignaler(
          callInfo,
          SignalingMessageId.ACCEPTED_BY_CALLEE
        );
        commit(WEBRTC_TYPES.MUTATIONS.SEND_SIGNAL_MESSAGE, {
          callId: callInfo.callId,
          message: writeThrift(tmsg),
        });
        await dispatch(WEBRTC_TYPES.ACTIONS.CALL_ESTABLISHED, callInfo.callId);
      }
      if (
        getters[CORE_TYPES.GETTERS.ORIGINAL_USER_IS_BROKER] &&
        !state.calleeChatBeteiligterId
      ) {
        dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, {
          customerId:
            state.beteiligterInfos[callInfo.calleeChatBeteiligterId].kundennr,
        });
      }
    }
  },
  [WEBRTC_TYPES.ACTIONS.CALL_ESTABLISHED]({ commit, dispatch, state }, callId) {
    commit(WEBRTC_TYPES.MUTATIONS.CALL_ESTABLISHED, callId);
    commit(WEBRTC_TYPES.MUTATIONS.SET_MUTE_STATE, false);

    // Commented out because the current implementation doesn't allow the call 
    // to be started if there is no microphone permission.
    // Also the permission API is not fully implemented in Firefox
    // checkMicrophonePermission(dispatch, state)

    state.callInfos.forEach((callInfo) => {
      // reject all other incoming or outgoing calls (if any)
      if (callInfo.callId != callId && callInfo.alertMsg != null) {
        dispatch(WEBRTC_TYPES.ACTIONS.END_CALL, {
          callId: callInfo.callId,
          status: WebRtcCallStatus.REJECTED,
          stopCallCause: STOP_CALL_CAUSE.REJECTED,
        });
      }
    });

    const callInfo = state.callInfos.find(
      (data) => data.callId == state.callId
    );

    if (!state.isDummyStream) {
      const tmsg = createMessageToSignaler(
        callInfo,
        SignalingMessageId.REMOTE_VIDEO_ON
      );
      commit(WEBRTC_TYPES.MUTATIONS.SEND_SIGNAL_MESSAGE, {
        callId: callInfo.callId,
        message: writeThrift(tmsg),
      });
    }
  },
  [WEBRTC_TYPES.ACTIONS.REJECT_CALL]({ dispatch, state }, payload) {
    const callInfo = nextIncomingCallData(state);
    if (callInfo) {
      dispatch(WEBRTC_TYPES.ACTIONS.END_CALL, {
        callId: callInfo.callId,
        status: WebRtcCallStatus.REJECTED,
        stopCallCause: !payload?.triggeredByNativeStack
          ? STOP_CALL_CAUSE.REJECTED
          : null,
      });
    }
  },
  [WEBRTC_TYPES.ACTIONS.HANG_UP]({ dispatch, state, commit }, payload) {
    if (state.prepareCallRequestUID) {
      dispatch(HTTP_REQ_TYPES.ACTIONS.KILL_SESSION, {
        requestId: state.prepareCallRequestUID,
      });
      commit(WEBRTC_TYPES.MUTATIONS.WEBRTC_PREPARE_CALL_REQUEST_UUID, {
        UID: null,
      });
    }

    let callInfo = state.callInfos.find(
      (data) =>
        data.callId == state.callId ||
        data.acceptedButNotEstablished ||
        data.isCaller
    );

    if (!callInfo) {
      callInfo = nextIncomingCallData(state);
    }

    if (callInfo) {
      dispatch(WEBRTC_TYPES.ACTIONS.END_CALL, {
        callId: callInfo.callId,
        status:
          callInfo.callId == state.callId
            ? WebRtcCallStatus.FINISHED
            : WebRtcCallStatus.CANCELED,
        alertMsg: "",
        stopCallCause: !payload?.triggeredByNativeStack
          ? STOP_CALL_CAUSE.HANG_UP_LOCAL
          : null,
      });
    }
  },
  [WEBRTC_TYPES.ACTIONS.STOP_CALL_ON_LOGOUT]({ dispatch, state }) {
    const callInfoIncomingCall = nextIncomingCallData(state);
    const callInfo = state.callInfos.find(
      (data) =>
        data.callId == state.callId ||
        data.acceptedButNotEstablished ||
        data.isCaller
    );

    let callId = null;
    let stopCallCause = STOP_CALL_CAUSE.CANCELED;
    let status = WebRtcCallStatus.CANCELED;

    if (callInfoIncomingCall?.isCallee) {
      callId = callInfoIncomingCall?.callId
      stopCallCause = STOP_CALL_CAUSE.REJECTED
      status = WebRtcCallStatus.REJECTED

    } else if (callInfoIncomingCall?.isCaller) {
      callId = callInfoIncomingCall?.callId
      stopCallCause = STOP_CALL_CAUSE.CANCELED
      status = WebRtcCallStatus.CANCELED

    } else if (callInfo?.established) {
      callId = callInfo?.callId;
      stopCallCause = STOP_CALL_CAUSE.HANG_UP_LOCAL;
      status = WebRtcCallStatus.FINISHED;
    } else {
      callId = callInfo?.callId;
    }

    if (callId) {
      dispatch(WEBRTC_TYPES.ACTIONS.END_CALL, {
        callId,
        status,
        stopCallCause,
      });
    }
  },
  [WEBRTC_TYPES.ACTIONS.MUTE]({ commit, state }) {
    const callInfo = state.callInfos.find(
      (data) => data.callId == state.callId
    );
    commit(WEBRTC_TYPES.MUTATIONS.SET_MUTE_STATE, true);
    const tmsg = createMessageToSignaler(
      callInfo,
      SignalingMessageId.REMOTE_AUDIO_OFF
    );
    commit(WEBRTC_TYPES.MUTATIONS.SEND_SIGNAL_MESSAGE, {
      callId: callInfo.callId,
      message: writeThrift(tmsg),
    });
  },
  [WEBRTC_TYPES.ACTIONS.UNMUTE]({ commit, dispatch, state }) {
    const callInfo = state.callInfos.find(
      (data) => data.callId == state.callId
    );
    if (!state.audioInput || !state.audioInput.mediaStream.active) {
      dispatch(WEBRTC_TYPES.ACTIONS.REQUEST_AUDIO_INPUT, callInfo.callId);
    } else {
      const tmsg = createMessageToSignaler(
        callInfo,
        SignalingMessageId.REMOTE_AUDIO_ON
      );
      commit(WEBRTC_TYPES.MUTATIONS.SEND_SIGNAL_MESSAGE, {
        callId: callInfo.callId,
        message: writeThrift(tmsg),
      });
      commit(WEBRTC_TYPES.MUTATIONS.SET_MUTE_STATE, false);
    }
  },
  [WEBRTC_TYPES.ACTIONS.MICROPHONE_BLOCKED]({ commit, state }) {
    const callInfo = state.callInfos.find(
      (data) => data.callId == state.callId
    );
    const tmsg = createMessageToSignaler(
      callInfo,
      SignalingMessageId.REMOTE_AUDIO_OFF
    );
    commit(WEBRTC_TYPES.MUTATIONS.SEND_SIGNAL_MESSAGE, {
      callId: callInfo.callId,
      message: writeThrift(tmsg),
    });
  },
  [WEBRTC_TYPES.ACTIONS.MICROPHONE_ALLOWED]({ commit, state }) {
    const callInfo = state.callInfos.find(
      (data) => data.callId == state.callId
    );
    const tmsg = createMessageToSignaler(
      callInfo,
      SignalingMessageId.REMOTE_AUDIO_ON
    );
    commit(WEBRTC_TYPES.MUTATIONS.SEND_SIGNAL_MESSAGE, {
      callId: callInfo.callId,
      message: writeThrift(tmsg),
    });
  },
  async [WEBRTC_TYPES.ACTIONS.REQUEST_AUDIO_INPUT]({ dispatch }, callId) {
    const [stream] = await getAudioMediaStream(dispatch);

    if (stream) {
      dispatch(WEBRTC_TYPES.ACTIONS.MICROPHONE_ALLOWED);
      dispatch(WEBRTC_TYPES.ACTIONS.TRANSMIT_AUDIO, { callId, stream });
    } else {
      dispatch(WEBRTC_TYPES.ACTIONS.MICROPHONE_BLOCKED);
    }
  },
  [WEBRTC_TYPES.ACTIONS.TRANSMIT_AUDIO](
    { commit, dispatch, state, getters },
    { callId, stream }
  ) {
    dispatch(LOG_TYPES.ACTIONS.INFO, "WEBRTC_TYPES.ACTIONS.TRANSMIT_AUDIO");
    const callInfo = state.callInfos.find((data) => data.callId == callId);
    if (!callInfo || callInfo.alertMsg != null) {
      dispatch(
        LOG_TYPES.ACTIONS.WARN,
        "received audio input after call already ended"
      );
      stream.getTracks().forEach((track) => {
        track.stop();
      });
      return;
    }
    if (
      state.callId == callId &&
      getters[WEBRTC_TYPES.GETTERS.MUTED] &&
      !state.muted
    ) {
      // the user didn't choose to be muted, there was just no stream available
      dispatch(
        LOG_TYPES.ACTIONS.INFO,
        "user allowed audio input, sending REMOTE_AUDIO_ON"
      );
      const tmsg = createMessageToSignaler(
        callInfo,
        SignalingMessageId.REMOTE_AUDIO_ON
      );
      commit(WEBRTC_TYPES.MUTATIONS.SEND_SIGNAL_MESSAGE, {
        callId: callId,
        message: writeThrift(tmsg),
      });
    }
    commit(WEBRTC_TYPES.MUTATIONS.AUDIO_INPUT, stream);
    stream.getAudioTracks()[0].onended = () => {
      if (state.callId == callId && !state.muted) {
        dispatch(
          LOG_TYPES.ACTIONS.INFO,
          "user closed audio input, sending REMOTE_AUDIO_OFF"
        );
        const tmsg = createMessageToSignaler(
          callInfo,
          SignalingMessageId.REMOTE_AUDIO_OFF
        );
        commit(WEBRTC_TYPES.MUTATIONS.SEND_SIGNAL_MESSAGE, {
          callId: callId,
          message: writeThrift(tmsg),
        });
      }
    };
  },
  [WEBRTC_TYPES.ACTIONS.TRANSMIT_VIDEO](
    { commit, dispatch, state },
    { stream, isCamera }
  ) {
    dispatch(
      LOG_TYPES.ACTIONS.INFO,
      "WEBRTC_TYPES.ACTIONS.TRANSMIT_VIDEO " + isCamera
    );

    const callInfo = state.callInfos.find(
      (data) => data.callId === state.callId
    );
    const wasAlreadyTransmitting = callInfo.videoSender.track.enabled;

    if (stream) {
      if (isCamera) {
        commit(WEBRTC_TYPES.MUTATIONS.CAMERA_VIDEO, stream);
        commit(WEBRTC_TYPES.MUTATIONS.ENUMERATE_DEVICES);
      }
      stream.getVideoTracks()[0].onended = () => {
        if (state.cameraVideo == stream) {
          commit(WEBRTC_TYPES.MUTATIONS.CAMERA_VIDEO, null);
        }
        if (state.transmittedVideo == stream) {
          commit(WEBRTC_TYPES.MUTATIONS.TRANSMIT_VIDEO, null);
          if (!state.transmittedVideo) {
            const tmsg = createMessageToSignaler(
              callInfo,
              SignalingMessageId.REMOTE_VIDEO_OFF
            );
            commit(WEBRTC_TYPES.MUTATIONS.SEND_SIGNAL_MESSAGE, {
              callId: callInfo.callId,
              message: writeThrift(tmsg),
            });
          }
        }
      };
    }

    commit(WEBRTC_TYPES.MUTATIONS.TRANSMIT_VIDEO, stream);

    if (state.transmittedVideo && !wasAlreadyTransmitting) {
      dispatch(
        LOG_TYPES.ACTIONS.INFO,
        "user allowed video input, sending " +
          (callInfo.requestedVideo
            ? "REMOTE_VIDEO_ON"
            : "REQUEST_VIDEO_UPGRADE")
      );
      const tmsg = createMessageToSignaler(
        callInfo,
        callInfo.requestedVideo
          ? SignalingMessageId.REMOTE_VIDEO_ON
          : SignalingMessageId.REQUEST_VIDEO_UPGRADE
      );
      commit(WEBRTC_TYPES.MUTATIONS.SEND_SIGNAL_MESSAGE, {
        callId: callInfo.callId,
        message: writeThrift(tmsg),
      });
      commit(WEBRTC_TYPES.MUTATIONS.UPDATE_REQUESTED_VIDEO);
    }

    if (!state.transmittedVideo && wasAlreadyTransmitting) {
      dispatch(
        LOG_TYPES.ACTIONS.INFO,
        "user ended video input, sending REMOTE_VIDEO_OFF"
      );
      const tmsg = createMessageToSignaler(
        callInfo,
        SignalingMessageId.REMOTE_VIDEO_OFF
      );
      commit(WEBRTC_TYPES.MUTATIONS.SEND_SIGNAL_MESSAGE, {
        callId: callInfo.callId,
        message: writeThrift(tmsg),
      });
    }
  },
  [WEBRTC_TYPES.ACTIONS.LOAD_CHAT_INFO_FOR_WEBRTC](
    { dispatch, rootState, getters },
    beteiligterId
  ) {
    if (!getters[CORE_TYPES.GETTERS.IS_LOGGED_IN]) {
      dispatch(LOG_TYPES.ACTIONS.WARN, {
        message: "The user is not logged in. Cannot load chat info endpoint.",
      });
      return;
    }

    dispatch(LOG_TYPES.ACTIONS.INFO, "LOAD_CHAT_INFO_FOR_WEBRTC");
    axios
      .get(
        rootState.core.apiAddress +
          "/../mrschat?beteiligter=" +
          beteiligterId +
          "&lastChat=true"
      )
      .then((response) => {
        if (response.data.chatId && response.data.channelId) {
          this.channelId = response.data.channelId;
          this.chatId = response.data.chatId;
          dispatch(WEBRTC_TYPES.ACTIONS.LOAD_CHAT_COMMUNICATION, {
            beteiligterId,
            chatId: response.data.chatId,
            channelId: response.data.channelId,
          });
        } else {
          dispatch(WEBRTC_TYPES.ACTIONS.LOAD_CHAT_COMMUNICATION, {
            beteiligterId,
            chatId: "0",
            channelId: "0",
          });
        }
      })
      .catch((error) => {
        dispatch(LOG_TYPES.ACTIONS.ERROR, {
          message: "LOAD_CHAT_INFO_FOR_WEBRTC ran into an error",
          error,
        });
        dispatch(WEBRTC_TYPES.ACTIONS.LOAD_CHAT_COMMUNICATION, {
          beteiligterId,
          chatId: "0",
          channelId: "0",
        });
      });
  },
  [WEBRTC_TYPES.ACTIONS.LOAD_CHAT_COMMUNICATION](
    { commit, dispatch, getters },
    { beteiligterId, chatId, channelId, emailId }
  ) {
    if (!getters[CORE_TYPES.GETTERS.IS_LOGGED_IN]) {
      dispatch(LOG_TYPES.ACTIONS.WARN, {
        message: "The user is not logged in. Cannot load chat info endpoint.",
      });
      return;
    }

    dispatch(LOG_TYPES.ACTIONS.INFO, "LOAD_CHAT_COMMUNICATION");
    let promise = null;
    let loadData = null;
    if (chatId == "0" && channelId == "0") {
      if (emailId) {
        loadData = { emailId, chatId, channelId };
        promise = axios.get(
          `${
            getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]
          }/mrschat?chatInfo=true&newChat=true&emailId=${emailId}`
        );
      } else {
        const queryParam = makeQueryParam({
          chatInfo: "true",
          newChat: "true",
          beteiligter: beteiligterId,
        });
        promise = axios.get(
          `${
            getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]
          }/mrschat?${queryParam}`
        );
      }
    } else {
      loadData = { chatId, channelId };
      promise = axios.get(
        `${
          getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]
        }/mrschat?chatId=${chatId}&channelId=${channelId}&chatInfo=true`
      );
    }
    return promise.then((response) => {
      if (response?.message === MESSAGE_CANCEL_REPEATED_REQUESTS) {
        return;
      }
      if (
        !response ||
        !response.data ||
        response.data.error ||
        !response.data.receiverId
      ) {
        dispatch(
          LOG_TYPES.ACTIONS.ADD_MESSAGE,
          buildMessage("Der Chatverlauf konnte nicht geladen werden.", "danger")
        );
        dispatch(LOG_TYPES.ACTIONS.ERROR, {
          message: "got unexpected answer in LOAD_CHAT_COMMUNICATION:",
          response,
        });
        return;
      }

      if (chatId == "0" || channelId == "0") {
        chatId = response.data?.chatId || chatId;
        channelId = response.data?.channelId || channelId;
      }

      commit(WEBRTC_TYPES.MUTATIONS.ADD_CHAT_INFO, {
        beteiligterId,
        chatId: chatId,
        channelId: channelId,
        emailId,
        thema: response.data.thema,
        receiverName: response.data.receiverName,
        receiverNames: response.data.receiverNames,
        senderName: response.data.senderName,
        readonly: response.data.readonly,
        receiverId: response.data.receiverId,
        receiverIds: response.data.receiverIds,
        messageList: null,
      });

      if (!loadData && response.data?.channelId && response.data?.chatId) {
        loadData = {
          channelId: response.data.channelId,
          chatId: response.data.chatId,
        };
      }

      if (loadData) {
        const { chatId, channelId, emailId } = loadData;
        if (emailId || chatId || channelId) {
          dispatch(WEBRTC_TYPES.ACTIONS.UPDATE_CHAT_MESSAGES, loadData);
        }
      }
      return;
    });
  },
  [WEBRTC_TYPES.ACTIONS.SEND_CHAT_MESSAGE]({ commit, dispatch, state, rootState }, {beteiligterId, chatId, channelId, emailId, message, thema, fmaBeschwerde, isInternalRemark, bezugId, important}) {
    return new Promise((resolve, reject) => {
      let chatInfo = state.chatInfos.find(
        (callInfo) =>
          callInfo.chatId == chatId && callInfo.channelId == channelId
      );
      if (!chatInfo && beteiligterId)
        chatInfo = state.chatInfos.find(
          (callInfo) => callInfo.beteiligterId == beteiligterId
        );
      if (!chatInfo) {
        reject();
        throw "attempted to send chat message to non-existant " + beteiligterId;
      }

      if (chatInfo.chatId == "0" && chatInfo.channelId == "0") {
        if (chatInfo.unsent) {
          commit(WEBRTC_TYPES.MUTATIONS.UNSENT_CHAT, {
            beteiligterId,
            emailId,
            message,
          });
          reject(); // chatId and channelId aren't known yet and will be supplied by call to this action already
        } else {
          // prepare state in case other messages are sent before the axios post has returned chatId and channelId
          commit(WEBRTC_TYPES.MUTATIONS.UNSENT_CHAT, {
            beteiligterId,
            emailId,
          });

          let receiverIds = [beteiligterId || chatInfo.receiverId];
          let promises = [];
          if (chatInfo.recieverEnabled && chatInfo.recieverEnabled.length > 0) receiverIds = chatInfo.recieverEnabled;

          //Loop in case of multiple receiverIds
          receiverIds.forEach((receiverId) => {
            promises.push(
              new Promise((res, rej) => {
                axios
                  .post(
                    rootState.core.apiAddress + "/../mrschat",
                    {
                      messageText: message,
                      receiverId: receiverId,
                      emailId: chatInfo.emailId,
                      thema: thema && thema.trim(),
                      fmaBeschwerde: fmaBeschwerde,
                      isInternalRemark: isInternalRemark,
                      bezugId,
                      important: important
                    },
                    {
                      headers: {
                        "Content-Type": "application/json; charset=utf-8;",
                      },
                      defaultSpinner: true,
                      cancelToken: null,
                    }
                  )
                  .then((response) => {
                    const unsent = chatInfo.unsent;

                    if (!response.data) return rej(response);

                    if (chatId === 0 && channelId === 0) {
                      commit(WEBRTC_TYPES.MUTATIONS.ADD_CHAT_INFO, {
                        ...chatInfo,
                        beteiligterId,
                        chatId: response.data.chatId,
                        channelId: response.data.channelId,
                        emailId,
                      });
                    } else {
                      commit(WEBRTC_TYPES.MUTATIONS.INITIALIZE_CHAT_INFO, {
                        beteiligterId,
                        chatId: response.data.chatId,
                        channelId: response.data.channelId,
                        emailId,
                      });
                    }

                    if (unsent) {
                      unsent.forEach((message) =>
                        sendChatMessage(rootState, commit, chatInfo, message, false)
                      );
                    }

                    dispatch(WEBRTC_TYPES.ACTIONS.UPDATE_CHAT_MESSAGES, {
                      chatId: response.data.chatId,
                      channelId: response.data.channelId,
                      emailId: response.data.emailId,
                    });

                    res(response.data);
                  });
              })
            );
          });

          //Await all resolveIds
          Promise.all(promises).then((data) => {
            resolve({
              chatId: data[0].chatId,
              channelId: data[0].channelId,
            });
          });
        }
      } else {
        sendChatMessage(rootState, commit, chatInfo, message, isInternalRemark).then(() => {
          dispatch(WEBRTC_TYPES.ACTIONS.UPDATE_CHAT_MESSAGES, {
            chatId: chatId,
            channelId: channelId,
          });
          resolve({
            chatId: chatId,
            channelId: channelId,
          });
        });
      }
    });
  },
  [WEBRTC_TYPES.ACTIONS.UPDATE_CHAT_MESSAGES](
    { commit, dispatch, state, rootState, getters },
    { chatId, channelId, emailId }
  ) {
    let url = "/../mrschat?";
    if (emailId) url += "newChat=true&emailId=" + emailId;
    else url += "chatId=" + chatId + "&channelId=" + channelId;

    const loadingId = `CHAT_MESSAGE-${chatId}-${channelId}`;
    dispatch(LOADING_TYPES.ACTIONS.START_LOADING, loadingId);

    // if the chat is already loading cancel the old request
    const requestList = getters[HTTP_REQ_TYPES.GETTERS.HTTP_REQUEST_URL_LIST];
    if (requestList && requestList.length) {
      let oldRequest = requestList.find((req) => req.url?.includes(url));
      if (oldRequest && oldRequest.requestId) {
        dispatch(HTTP_REQ_TYPES.ACTIONS.KILL_SESSION, {
          requestId: oldRequest.requestId,
        });
      }
    }

    axios
      .get(rootState.core.apiAddress + url, spinnerConfig)
      .then((response) => {
        if (response && response.data) {
          const chatInfo = (state.chatInfos || []).find(
            (chatInfo) =>
              chatInfo.chatId == chatId && chatInfo.channelId == channelId
          );
          if (chatInfo && chatInfo.messageList) {
            const previousMessages = chatInfo.messageList;
            const lastMessageId =
              previousMessages.length > 0
                ? previousMessages[previousMessages.length - 1].messageId
                : -1;
            dispatch(LOG_TYPES.ACTIONS.INFO, { message: "chatInfo", chatInfo });
            response.data.messageList.forEach((message) => {
              if (
                message.direction == "RECEIVING" &&
                !message.isGelesen &&
                message.messageId > lastMessageId
              ) {
                if (message.imgAttachments && message.imgAttachments.length > 0)
                  dispatch(
                    LOG_TYPES.ACTIONS.ADD_MESSAGE,
                    buildMessage(
                      chatInfo.receiverName + " hat ein Bild geschickt",
                      "primary"
                    )
                  );
                else if (
                  message.externalAttachments &&
                  message.externalAttachments.length > 0
                )
                  dispatch(
                    LOG_TYPES.ACTIONS.ADD_MESSAGE,
                    buildMessage(
                      chatInfo.receiverName + " hat eine Datei geschickt",
                      "primary"
                    )
                  );
                else
                  dispatch(
                    LOG_TYPES.ACTIONS.ADD_MESSAGE,
                    buildMessage(
                      "Nachricht von " +
                        chatInfo.receiverName +
                        ': "' +
                        message.messageText +
                        '"',
                      "primary"
                    )
                  );
              }
            });
          }

          if (!response?.data?.messageList) {
            return;
          }

          commit(WEBRTC_TYPES.MUTATIONS.UPDATE_CHAT_MESSAGES, {
            chatId,
            channelId,
            emailId,
            messageList: response.data.messageList,
          });
        }
      })
      .finally(() => dispatch(LOADING_TYPES.ACTIONS.STOP_LOADING, loadingId));
  },
  async [WEBRTC_TYPES.ACTIONS.NOTIFY_NEW_CHAT_MESSAGE](
    { dispatch },
    { chatId }
  ) {
    if (!chatId) {
      return;
    }

    const { currentRoute } = router;
    const isChatAlreadyOpened =
      currentRoute.name === "ticket" && currentRoute.params.chatId == chatId;
    if (isChatAlreadyOpened) {
      return;
    }

    const chat = await dispatch(
      COMMUNICATION_TYPES.ACTIONS.FIND_CHAT_WITH_LAST_MESSAGE,
      { chatId }
    );
    if (!chat?.chatId || !chat?.channelId) {
      return;
    }

    const safeLabelWithSeparator = (label) => (label ? ` | ${label}` : "");
    const source = `${chat.nameBeteiligter ||
      safeLabelWithSeparator(chat.kundennr)}`;

    const message = `
      <div class="chat-notification__container">
        <p>Nachricht von ${source}</p>
        <p>Thema: ${chat.thema || ""}</p>
        <p>${chat.lastMessageText || ""}</p>
      </div>
    `;
    dispatch(
      LOG_TYPES.ACTIONS.ADD_MESSAGE,
      buildMessageWith({
        message,
        type: "primary",
        actions: [
          {
            label: "zur Nachricht",
            isPrimary: true,
            handler: () =>
              router.push({
                name: "ticket",
                query: { _: new Date().getTime() }, // it is to force open the page if the user is in the same route
                params: { chatId: chat.chatId, channelId: chat.channelId },
              }),
          },
        ],
      })
    );
  },
  [WEBRTC_TYPES.ACTIONS.LOAD_ATTACHMENT](
    { commit, dispatch, rootState },
    attId
  ) {
    // prevent duplicate calls to this action (assuming a null-check for data)
    commit(WEBRTC_TYPES.MUTATIONS.LOAD_ATTACHMENT, {
      attId,
      data: "",
    });
    axios
      .post(
        rootState.core.apiAddress +
          "/../mrschatattachmentviewer?attId=" +
          attId,
        { attId },
        { defaultSpinner: true, responseType: "blob" }
      )
      .then((response) => {
        if (response.data && response.data.size > 0)
          commit(WEBRTC_TYPES.MUTATIONS.LOAD_ATTACHMENT, {
            attId,
            data: URL.createObjectURL(response.data),
          });
        else
          dispatch(LOG_TYPES.ACTIONS.ERROR, {
            message: "WEBRTC_TYPES.ACTIONS.LOAD_ATTACHMENT",
            response,
          });
      })
      .catch((error) => {
        dispatch(LOG_TYPES.ACTIONS.ERROR, {
          message: "WEBRTC_TYPES.ACTIONS.LOAD_ATTACHMENT",
          error,
        });
      });
  },
  [WEBRTC_TYPES.ACTIONS.OPEN_FILE](
    { dispatch, rootState },
    { attId, filename }
  ) {
    if (!filename) filename = "Dokument.pdf";
    axios
      .post(
        rootState.core.apiAddress + "/../mrschatattachmentviewer",
        { attId },
        { defaultSpinner: true, responseType: "blob" }
      )
      .then((response) => {
        dispatch(LOG_TYPES.ACTIONS.INFO, { message: "OPEN_FILE", response });
        if (response.data && response.data.size > 0) {
          const windowRef = window.open(
            rootState.core.apiAddress + "/../../images/etc/ksc3/warten.html",
            "_blank"
          );
          if (!windowRef || !windowRef.location) {
            dispatch(
              LOG_TYPES.ACTIONS.ADD_MESSAGE,
              buildMessage(
                "Bitte Popups erlauben: Die Datei wird in einem neuen Tab geöffnet. \
            Bitte vergewissern Sie sich, dass kein Addblocker diesen Zugriff verbietet. \
            Bitte erlauben Sie das Öffnen in einem neuen Tab",
                "warning"
              )
            );
            throw new Error("Can not access Tab to write content to.");
          } else {
            if (navigator.userAgent.toLocaleLowerCase().indexOf("edge") > -1) {
              windowRef.close();
              navigator.msSaveOrOpenBlob(response.data, filename);
            } else {
              const file = new File([response.data], filename, {
                type: response.data.type,
              });
              const a = document.createElement("a");
              a.href = URL.createObjectURL(file);
              a.setAttribute("download", filename);
              a.click();
            }
          }
        }
      })
      .catch((error) => {
        dispatch(LOG_TYPES.ACTIONS.ERROR, {
          message: "WEBRTC_TYPES.ACTIONS.OPEN_FILE",
          error,
        });
      });
  },
  [WEBRTC_TYPES.ACTIONS.DELETE_MESSAGE](
    { dispatch, rootState },
    { chatId, channelId, messageId }
  ) {
    axios
      .get(
        rootState.core.apiAddress +
          "/../mrschat?messageId=" +
          messageId +
          "&delete=true",
        spinnerConfig
      )
      .then(() => {
        dispatch(WEBRTC_TYPES.ACTIONS.UPDATE_CHAT_MESSAGES, {
          chatId,
          channelId,
        });
      });
  },
  [WEBRTC_TYPES.ACTIONS.UPLOAD_CHAT](
    { commit, dispatch, rootState },
    { channelId, file }
  ) {
    let formData = new FormData();
    formData.append("channelId", channelId);
    formData.append("file", file);
    return axios
      .post(
        rootState.core.apiAddress + "/../mrschatupload",
        formData,
        spinnerConfig
      )
      .then((response) => {
        setTimeout(() => {
          if (channelId == "0") {
            commit(WEBRTC_TYPES.MUTATIONS.INITIALIZE_CHAT_INFO, {
              chatId: response.data.chatId,
              channelId: response.data.channelId,
            });
          }
          dispatch(WEBRTC_TYPES.ACTIONS.UPDATE_CHAT_MESSAGES, {
            chatId: response.data.chatId,
            channelId: response.data.channelId,
          });
        });
        return response.data;
      });
  },
  [WEBRTC_TYPES.ACTIONS.ADD_FILE_CHAT]({ commit, dispatch, rootState }, {channelId, id}) {
    let formData = new FormData();
    formData.append('channelId', channelId);
    formData.append('fileId', id);
    return axios.post(rootState.core.apiAddress + '/../mrschataddfile', formData, spinnerConfig)
    .then(response => {
      setTimeout(() => {
        if (channelId == '0') {
          commit(WEBRTC_TYPES.MUTATIONS.INITIALIZE_CHAT_INFO, {
            chatId: response.data.chatId,
            channelId: response.data.channelId,
          });
        }
        dispatch(WEBRTC_TYPES.ACTIONS.UPDATE_CHAT_MESSAGES, {chatId: response.data.chatId, channelId: response.data.channelId});
      });
      return response.data
    }).catch((error) => {
      dispatch(LOG_TYPES.ACTIONS.ERROR, {
        message: "Das Versenden der Datei hat nicht funktioniert.",
        error,
      });
      return error
    })
  },
  [WEBRTC_TYPES.ACTIONS.START_RECORDING]({ commit, state, rootState }, callId) {
    axios
      .post(
        rootState.core.apiAddress + "/../startrecordingcall",
        { callId },
        spinnerConfig
      )
      .then((response) => {
        if (state.callId == callId) {
          if (response.data.success)
            commit(WEBRTC_TYPES.MUTATIONS.START_RECORDING);
        }
      });
  },
  [WEBRTC_TYPES.ACTIONS.HANDLE_ACTION_SUGGESTION](
    { dispatch, getters },
    payload
  ) {
    axios
      .get(
        `${
          getters[CORE_TYPES.GETTERS.API_ADDRESS]
        }/finia/actionsuggestion/suggestionInfo?id=${payload.actionId}`
      )
      .then((response) => {
        if (
          getters[CORE_TYPES.GETTERS.IS_BYPASS] &&
          getters[CORE_TYPES.GETTERS.GET_USER_ID] ==
            response.data.parameters["kundennr"]
        ) {
          // access stored values instead of if else later
          let page = null;
          if (response.data.uri == "CHECK_CREDIT") {
            page = "kredit";
          } else if (response.data.uri == "CHECK_INSURANCES") {
            page = "versicherungen";
          }

          const text = "Aktionsvorschlag: " + response.data.message;
          dispatch(
            LOG_TYPES.ACTIONS.ADD_MESSAGE,
            buildMessage(text, "primary", true, 0, page)
          );
          // acknowledge message (to mark as received)
          axios
            .post(
              `${
                getters[CORE_TYPES.GETTERS.API_ADDRESS]
              }/finia/actionsuggestion/acknowledgeInfo?id=${payload.actionId}`
            )
            .then(() => {});
        }
      });
  },
};
