var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showOptionen
        ? [
            _c("PageHeaderTitleNavigation", {
              attrs: { title: "2FA Intervall ändern" }
            })
          ]
        : _vm._e(),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("div", { staticClass: "box__title" }, [
            _vm._v("Intervall für Zwei-Faktor-Authentifizierung festlegen")
          ]),
          _c(
            "p",
            [
              _vm.loading
                ? _c(
                    "GhostLoading",
                    { attrs: { type: "custom" } },
                    [_c("Block")],
                    1
                  )
                : [
                    _vm._v(
                      " Momentan können Sie sich nach einem Login mit Zwei-Faktor-Authentifizierung " +
                        _vm._s(_vm.currentInterval) +
                        " " +
                        _vm._s(_vm.currentInterval <= 1 ? "Tag" : "Tage") +
                        " anmelden, bis Sie wieder Ihren zweiten Faktor verwenden müssen. "
                    )
                  ]
            ],
            2
          ),
          _vm.error
            ? _c("p", { staticClass: "color-danger" }, [
                _vm._v(_vm._s(_vm.error))
              ])
            : _vm._e(),
          _c("ComboBox", {
            attrs: {
              isComponentHalfSize: "",
              values: _vm.options,
              sortComboboxValues: false,
              label: _vm.currentIntervalLabel,
              disabled: _vm.loading
            },
            on: { change: _vm.changeIntervals },
            model: {
              value: _vm.currentInterval,
              callback: function($$v) {
                _vm.currentInterval = $$v
              },
              expression: "currentInterval"
            }
          }),
          _vm.isEmployeeLimitConfigVisible || _vm.isCustomerLimitConfigVisible
            ? _c("hr")
            : _vm._e(),
          _vm.isEmployeeLimitConfigVisible
            ? _c("ComboBox", {
                attrs: {
                  isComponentHalfSize: "",
                  values: _vm.brokerEmployeeLimitOptions,
                  sortComboboxValues: false,
                  label:
                    "Obergrenze für Mitarbeiter <strong>(in Tagen)</strong>",
                  disabled: _vm.loading
                },
                on: { change: _vm.changeIntervals },
                model: {
                  value: _vm.brokerEmployeeLimit,
                  callback: function($$v) {
                    _vm.brokerEmployeeLimit = $$v
                  },
                  expression: "brokerEmployeeLimit"
                }
              })
            : _vm._e(),
          _vm.isCustomerLimitConfigVisible
            ? _c("ComboBox", {
                attrs: {
                  isComponentHalfSize: "",
                  values: _vm.customerLimitOptions,
                  sortComboboxValues: false,
                  label: "Obergrenze für Kunden <strong>(in Tagen)</strong>",
                  disabled: _vm.loading
                },
                on: { change: _vm.changeIntervals },
                model: {
                  value: _vm.customerLimit,
                  callback: function($$v) {
                    _vm.customerLimit = $$v
                  },
                  expression: "customerLimit"
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }