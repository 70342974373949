import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store';
import { 
  isUserLoggedIn, 
  isUserHasPendingAction, 
  isUserHasRequiredRole, 
  loadBrokerInfo, 
  reloadLoggedInUser, 
  clearLocalStorageIfLoginToken, 
  setKundenzugangStorageIfNeeded, 
  setMaklerzugangStorageIfNeeded,
  checkMobileNativeContextParameter,
  checkColorThemeParameter,
  configureMenu,
  isOptionMenuPermissionVisibleAsync,
  trackRecentMenuOpened,
  isRolesIncludes,
  logRoutesChanges,
  disableNativeBackButtonIfNeeded,
} from './guards'
import { VIEW_ROLES, ROLES, EMPLOYEE_ROLES, BROKER_LEVEL_ROLES } from '@/router/roles'
import { registerMenuGuards } from './menu-guards';
import communicationRoutes from './features-routes/communication'
import beratungRoutes from './features-routes/beratung'
import serviceRoutes from './features-routes/service'
import customerRoutes from './features-routes/customer'
import internRoutes from './features-routes/intern'
import homeRoutes from './features-routes/home'
import cmsRoutes from './features-routes/cms'
import cmsRoutesRoot from './features-routes/cmsRoot'
import docsRoutes from './features-routes/docs'
import persoenlicheDaten from './features-routes/persoenlicheDaten'
import dbm from './features-routes/dbm'
import loginRoutes from './features-routes/login'
import sharedRoutes from '@/router/features-routes/shared';
import { breadcrumbRouterResolver, keepBreadcrumbNavigationIntegrity, } from '@/router/breadcrumb';
import { PhDatabase } from 'phosphor-vue'
import MENU_TYPES from '@/store/menu/types';
import { PageMenuCfg } from '@/menu/menu-utils';

Vue.use(VueRouter)

const rootRoutes = [
  {
    path: '/home',
    // name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/core/base-router-view/BaseHomeView.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [...homeRoutes]
  }, 
  {
    path: '/customer',
    component: () => import(/* webpackChunkName: "base-customer-view" */'../views/core/base-router-view/BaseCustomerView.vue'),
    meta: {
      requiresAuth: true,
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_BROKER, EMPLOYEE_ROLES.ZUGRIFF_KUNDEN, BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN],
          [VIEW_ROLES.VIEW_INTERN]
        ],
      }
    },
    children: [...customerRoutes]
  },
  {
    path: '/dbm',
    component: () => import( '../views/core/base-router-view/BaseDbmView.vue'),
    meta: {
      menu: { label: 'Dbm', component:  PhDatabase },
      requiresAuth: true,
      breadcrumb: 'KommunDbmikation',
      roles: {
        allowed:[ ROLES.DBM]
      }
    },
    children: [...dbm]
  },
  {
    path: '/communication',
    component: () => import(/* webpackChunkName: "base-communication-view" */ '../views/core/base-router-view/BaseCommunicationView.vue'),
    meta: {
      requiresAuth: true,
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_CUSTOMER],
          [VIEW_ROLES.VIEW_BROKER],
          [VIEW_ROLES.VIEW_INTERN], 
        ]
      }
    },
    children: [...communicationRoutes]
  },
  {
    path: '/persoenlichedaten',
    component: () => import(/* webpackChunkName: "persoenlichedaten-view" */ '@/views/BaseRouterView.vue'),
    meta: {
      requiresAuth: true,
      roles: {
        allowed: [VIEW_ROLES.VIEW_CUSTOMER],
      }
    },
    children: [...persoenlicheDaten]
  },
  {
    path: '/beratung',
    component: () => import(/* webpackChunkName: "base-communication-view" */ '@/views/core/base-router-view/BaseBeratungView.vue'),
    meta: {
      requiresAuth: true,
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_CUSTOMER],
          [VIEW_ROLES.VIEW_BROKER]
        ]
      },
    },
    children: [...beratungRoutes]
  },
  {
    path: '/service',
    component: () => import(/* webpackChunkName: "base-communication-view" */ '@/views/core/base-router-view/BaseServiceView.vue'),
    meta: {
      requiresAuth: true,
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS],
        ],
      },
    },
    children: [...serviceRoutes],
  },
  {
    path: '/intern',
    component: () => import(/* webpackChunkName: "base-intern-view" */'../views/core/base-router-view/BaseInternView.vue'),
    meta: {
      requiresAuth: true,
      roles: {
        allowed: [VIEW_ROLES.VIEW_BROKER, VIEW_ROLES.VIEW_INTERN],
      }
    },
    children: [...internRoutes],
  },
  {
    path: '/shared',
    component: () => import(/* webpackChunkName: "base-shared-view" */ '@/views/core/base-router-view/BaseSharedView.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [...sharedRoutes],
  },
  {
    path: '/role-forbidden',
    component: () => import(/* webpackChunkName: "role-forbidden" */ '../views/core/BaseRoleForbidden.vue'),
  },
  {
    path: '/login',
    beforeEnter: loadBrokerInfo,
    component: () => import(/* webpackChunkName: "base-login" */ '@/views/core/BaseLogin.vue'),
    children: [...loginRoutes],
    meta: {
      canBypassPendingActions: true,
      bypassContainerSize: true
    }
  },
  {
    path: '/next-url-broker-customer',
    component: () => import(/* webpackChunkName: "next-url-broker-customer"*/ '@/views/core/next-url/NextUrlBrokerCustomer.vue'),
    meta: {
      canBypassPendingActions: true,
      simplePage: true,
      doNotLogRouteChange: true,
    },
  },
  {
    path: '/next-url-broker-customer-test',
    component: () => import(/* webpackChunkName: "next-url-broker-customer"*/ '@/views/core/next-url/NextUrlBrokerCustomerTest.vue'),
    meta: {
      canBypassPendingActions: true,
      simplePage: true,
      doNotLogRouteChange: true,
    },
  },  
  {
    path: '/token-login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/core/TokenLogin.vue'),
    meta: {
      canBypassPendingActions: true,
      bypassContainerSize: true,
      doNotLogRouteChange: true,
    }
  },
  {
    path: '/kundenzugang-login',
    component: () => import(/* webpackChunkName: "kundenzugang-login" */ '@/views/core/KundenzugangLogin.vue'),
    meta: {
      canBypassPendingActions: true,
      bypassContainerSize: true,
      doNotLogRouteChange: true,
    }
  },
  {
    path: '/maklerzugang-login',
    component: () => import(/* webpackChunkName: "maklerzugang-login" */ '@/views/core/MaklerzugangLogin.vue'),
    meta: {
      canBypassPendingActions: true,
      bypassContainerSize: true,
      doNotLogRouteChange: true,
    }
  },
  {
    path: '/waiting',
    component: () => import(/* webpackChunkName: "login" */ '@/views/core/TokenLogin.vue'),
    meta: {
      canBypassPendingActions: true,
      bypassContainerSize: true,
      doNotLogRouteChange: true,
    }
  },
  {
    path: '/logged-out',
    component: () => import(/* webpackChunkName: "login" */ '@/views/core/LoggedOut.vue'),
    meta: {
      canBypassPendingActions: true,
      bypassContainerSize: true
    }
  },
  {
    path: '/privacy-terms',
    component: () => import(/* webpackChunkName: "BaseRouterView" */ '@/views/BaseRouterView.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        redirect: 'main',
      },
      {
        path: 'main',
        component: () => import(/* webpackChunkName: "privacy" */ '@/views/core/privacy/PrivacyTerms.vue'),
      },
      {
        path: 'terms-content',
        component: () => import(/* webpackChunkName: "privacy-notice" */ '@/views/core/privacy/TermsContent.vue'),
      },
    ]
  },
  {
    path: '/conflict-of-interest',
    component: () => import(/* webpackChunkName: "conflict-of-interest" */ '@/views/core/conflict-of-interest/ConflictOfInterest.vue'),
    meta: {
      requiresAuth: true,
      canBypassPendingActions: true,
    }
  },
  {
    path: '/conflict-of-interests/principles',
    component: () => import(/* webpackChunkName: "conflict-of-interest-principles" */ '@/views/core/conflict-of-interest/ConflictOfInterestPrinciples.vue'),
    meta: {
      requiresAuth: true,
      canBypassPendingActions: true,
    }
  },
  {
    path: '/change-password',
    component: () => import(/* webpackChunkName: "change-password" */ '../views/core/ChangePassword.vue'),
    meta: {
      requiresAuth: true,
      bypassContainerSize: true,
    },
  },
  {
    path: '/negativerklaerung',
    component: () => import(/* webpackChunkName: "negativerklaerung" */ '../views/core/Negativerklaerung.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/kundenzugang-und-kundenapp',
    component: () => import(/* webpackChunkName: "kundenzugang-und-kundenapp" */ '@/views/core/kundenzugang-und-kundenapp/KundenzugangUndKundenApp.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/re-balancing-ffb-fondsshop',
    component: () => import(/* webpackChunkName: "re-balancing-ffb-fondsshop" */ '@/views/core/re-balancing-ffb-fondsshop/ReBalancingFFBFondsshop.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/calculator',
    component: () => import(/* webpackChunkName: "calculator" */ '../views/core/Calculator.vue'),
  },
  {
    path: '/router-viewer',
    component: () => import(/* webpackChunkName: "router-viewer"*/ '@/views/core/RouterViewer.vue'),
    meta: {
      roles: {
        allowed: [VIEW_ROLES.VIEW_INTERN],
      }
    }
  },
  {
    path: "/accountloeschung",
    beforeEnter: loadBrokerInfo,
    component: () => import(/* webpackChunkName: "DataDeletionRequest" */ "@/views/core/DataDeletionRequest.vue"),
  },
  {
    path: '/confirmed-2fa',
    beforeEnter: loadBrokerInfo,
    component: () => import(/* webpackChunkName: "confirmed-2fa" */ '@/views/core/Confirmed2FaMessage.vue'),
  },
  {
    path: '/open-link',
    component: () => import(/* webpackChunkName: "link-resolvers/open-link"*/ '@/link-resolvers/views/OpenLink.vue'),
    meta: {
      canBypassPendingActions: true,
      simplePage: true,
    },
  },
  {
    path: '/m/:groupId', // custom options menu generic route
    component: () => import(/* webpackChunkName: "custom-group-menu"*/ '@/views/BaseRouterView.vue'),
  },
  {
    path: '/noop', // used on router navigation to force reopen a route
    meta: {
      doNotLogRouteChange: true,
    },
    component: () => import(/* webpackChunkName: "noop" */ '@/components/core/Noop.vue'),
  },
  {
    path: '/page/:broker_id(\\d+)?',
    beforeEnter: loadBrokerInfo,
    component: () => import(/* webpackChunkName: "cms" */ '@/views/core/base-router-view/BaseCmsView.vue'),
    meta: {
      isCmsPage: true,
      ...PageMenuCfg().withOptionsMenu(false).withWorkspaceHeader(false)
    },
    children: [
      ...cmsRoutes
    ],
  },
  {
    path: '',
    beforeEnter: loadBrokerInfo,
    component: () => import(/* webpackChunkName: "cms" */ '@/views/core/base-router-view/BaseCmsView.vue'),
    meta: {
      isCmsPage: true,
    },
    children: [
      ...cmsRoutesRoot
    ],
  },
  {
    // will match everything
    path: '*',
    redirect: '/home'
  },
]

if (process.env.VUE_APP_PRODUCTION === 'false') {
    // show internal documentation if not in production
    rootRoutes.unshift({
        path: '/docs',
        component: () => import(/* webpackChunkName: "docs" */ '../views/core/base-router-view/BaseDocsView.vue'),
        meta: {
            requiresAuth: false,
        },
        children: docsRoutes,
    });
}

/**
 * Creates a new router instance
 * 
 * @param {*} contextRoles - context roles are used by menu global configuration, it is to inject roles from user context as Makler, Makler -> Kunde, Kunde
 * @returns 
 */
export function _createRouter(contextRoles) {
  const __CONTEXT_ROLES__ = contextRoles ? [ ...contextRoles || [], ] : undefined;

  const mapRoute = (route) => {
    return {
      ...route,
      meta: {
        ...route?.meta || {},
        __CONTEXT_ROLES__,
      },
      children: route?.children?.map(mapRoute),
    };
  };

  const allRoutes = rootRoutes.map(mapRoute);

  return new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [ ...allRoutes, ],
    __CONTEXT_ROLES__,
    scrollBehavior(to, from, savedPosition) {
      const toMatchedRoute = [ ...to?.matched, ].pop();
      const fromMatchedRoute = [ ...from?.matched, ].pop();
      const isNavigatingToSameRoute = toMatchedRoute?.path === fromMatchedRoute?.path;
  
      if(isNavigatingToSameRoute && to?.meta?.keepScroll) {
        return savedPosition;
      }
  
      return { x: 0, y: 0 };
    },
  });
}

const router = _createRouter();

/**
 * Define rules to be checked before each navigation
 * 
 * some rule examples are:
 * isUserLoggedIn: check if the user is logged in
 * isUserHasPendingAction: check if the user has pending actions, like privacy check
 */

// beforeEach config
router.beforeEach((to, from, next) => requestAnimationFrame(next));
router.beforeEach(setKundenzugangStorageIfNeeded);
router.beforeEach(setMaklerzugangStorageIfNeeded);
router.beforeEach(clearLocalStorageIfLoginToken);
router.beforeEach(reloadLoggedInUser);
router.beforeEach(isUserLoggedIn);
router.beforeEach(isUserHasRequiredRole);
router.beforeEach(isUserHasPendingAction);
router.beforeEach(checkMobileNativeContextParameter);
router.beforeEach(checkColorThemeParameter);
router.beforeEach(configureMenu);
router.beforeEach(isOptionMenuPermissionVisibleAsync);

// afterEach config
registerMenuGuards(router);
router.afterEach(logRoutesChanges);
router.afterEach(breadcrumbRouterResolver);
router.afterEach(keepBreadcrumbNavigationIntegrity);
router.afterEach(trackRecentMenuOpened);
router.afterEach(disableNativeBackButtonIfNeeded);

export function getRouteChildren(routePath) {
  if(!routePath) {
    return [];
  }

  const findChildren = (routes, path, result) => {
    return routes.reduce((acc, route) => {
      if(route?.redirect) return acc;

      const exactPath = new RegExp(`^${path}${route.path}(|/)$`, 'gi');
      const containsPath = new RegExp(`^${path}${route.path}/+`, 'gi');
      if(routePath.search(exactPath) >= 0) {
        const children = [...route.children].map(r => ({
          ...r,
          _completePath: `${path}${route.path}/${r.path}`,
        }));
        acc = acc.concat(children);
      } else if(routePath.search(containsPath) >= 0) {
        return findChildren(route.children, `${path}${route.path}/`, acc);
      }
      return acc;
    }, result)
  }

  return findChildren(rootRoutes, '', []);
}

export function redirectToFirstAllowedChild(route, validPaths = []) {
  const __CONTEXT_ROLES__ = route?.meta?.__CONTEXT_ROLES__;

  const routes = getRouteChildren(route.path)
    .filter(child => (child?.path || '') !== '' && !child?.path?.includes(':')) // ignore empty path route and route with params

  const children = validPaths?.length 
    ? validPaths.map(path => routes.find(route => route.path === path)).filter(r => r != null) // only get the valid paths, following configuration order
    : routes;

  const allowedChildren = children
    .filter(child => {
      const isAllowed = !child.meta?.roles?.allowed?.length || isRolesIncludes(child.meta?.roles?.allowed || [], __CONTEXT_ROLES__);
      const isDenied = child.meta?.roles?.denied?.length && isRolesIncludes(child.meta?.roles?.denied || [], __CONTEXT_ROLES__);
      const isOptionMenuPermissionVisible = store.getters[MENU_TYPES.GETTERS.IS_OPTION_MENU_PATH_VISIBLE](child._completePath);
      return isAllowed && !isDenied && isOptionMenuPermissionVisible;
    });

  if(allowedChildren?.length > 0) { // check if there is at least one allowed child route
    return allowedChildren[0].path;
  } else { // if there is no allowed child route, return the first one, it will force a redirect to '/role-forbidden'
    return children[0].path;
  }
}

export function isMenuRouteActive(path) {
  if(!path) return false;

  const currentRoute = router?.app?.$route;
  if(!currentRoute) return;

  const resolvedRoute = router.resolve(path)?.route;

  return currentRoute?.path === path || currentRoute?.path === resolvedRoute?.path || currentRoute?.fullPath === path || currentRoute.matched.some(route => {
    const resolvedPath = Object.keys(currentRoute?.params || {})
      .reduce((result, key) => result.replace(`:${key}`, currentRoute?.params?.[key] || `:${key}`), route.path);

    return route?.path === path || resolvedPath === path;
  });
}

export default router
