import { PageCfg, SmartMSCAppLabel } from '@/helpers/app-helper';

export const LOGIN_URL = '/login';

export function isLoginRoute(path) {
  return path.search(/^\/login/gi) >= 0;
}

export function isRegisterRoute(path) {
  return path.search(/^\/login\/register/gi) >= 0;
}

export function isRegisterPrivacyRoute(path) {
  return path.search(/^\/login\/register\/privacy/gi) >= 0;
}

export function getRegistrationQuery(route) {
  const maklernr = route?.query?.maklernr;
  if(maklernr) {
    return {
      maklernr,
    };
  }
  return {};
}

export function findUserId(route) {
  return Object.entries(route?.query ?? {})
    .reduce((acc, [key, value]) => ['userid', 'name'].includes(key.toLowerCase()) ? value : acc, null);
}

export function findLoginNextUrl(route) {
  const query = route?.query ?? {};

  // email
  const { emailid } = query;
  if(emailid) {
    return `/communication/postfach/emails/email/${emailid}`;
  }

  // chat
  const { chatid } = query;
  if (chatid?.includes('_')) {
    return `/communication/postfach/chats/chat/${chatid.split('_').join('/')}`;
  }

  // confirm anlegerprofil
  if (query.confirmAp) {
    return '/beratung/confirmAnlegerprofil';
  }

  return query.nextUrl;
}

function checkNoCredentialsLogin(to, from, next) {
  const { token, SitzungID } = to.query;

  if (token || (SitzungID && SitzungID.indexOf('MFA') < 0)) {
    next({ path: '/login/nc', query: to.query })
    return;
  }
  next();
}

function checkMfaCredentialsLogin(to, from, next) {
  const { SitzungID } = to.query;

  if (SitzungID && SitzungID.indexOf('MFA') >= 0) {
    next({ path: '/login', query: to.query })
    return;
  }
  next();
}


export default [
  {
    path: '',
    name: 'login',
    beforeEnter: checkNoCredentialsLogin,
    component: () => import(/* webpackChunkName: "login" */ '@/views/core/Login.vue'),
  },
  {
    path: 'nc',
    name: 'no-credentials-login',
    beforeEnter: checkMfaCredentialsLogin,
    component: () => import(/* webpackChunkName: "register" */ '@/views/core/login/NoCredentialsLogin.vue'),
  },
  {
    path: 'register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '@/views/core/register/Register.vue'),
  },
  {
    path: 'register/privacy',
    name: 'register-privacy',
    component: () => import(/* webpackChunkName: "register-privacy" */ '@/views/core/register/RegisterPrivacy.vue'),
    meta: {
      breadcrumb: {
        label: 'Datenschutz',
      },
    },
  },
  {
    path: 'register/privacy/terms-content',
    name: 'register-terms-content',
    component: () => import(/* webpackChunkName: "register-terms-content" */ '@/views/core/register/RegisterTermsContent.vue'),
  },
  {
    path: 'aktivieren',
    name: 'register-activation',
    component: () => import(/* webpackChunkName: "register-aktivierung" */ '@/views/core/register/RegistrationActivation.vue'),
  },
  {
    path: 'forgotpassword',
    name: 'forgotpassword',
    component: () => import(/* webpackChunkName: "forgotpassword" */ '@/views/core/ForgotPassword.vue'),
    meta: {
      disableNativeBackButton: true,
    },
  },
  {
    path: 'sso-login',
    name: 'sso-login',
    component: () => import(/* webpackChunkName: "sso" */ '@/views/core/sso/SsoLogin.vue'),
    meta: {
      ...PageCfg(SmartMSCAppLabel.APP_NAME),
    },
  },
  {
    path: 'sso-registration',
    name: 'sso-registration',
    component: () => import(/* webpackChunkName: "sso" */ '@/views/core/sso/SsoRegistration.vue'),
    meta: {
      ...PageCfg(SmartMSCAppLabel.APP_NAME),
    },
  },
];
