<template>
  <div>
    <PageHeaderTitleNavigation title="Versandprotokolle" />

    <BaseFilter
      v-if="!loadingSetup && defaultOptions"
      title="Filter"
      filterId="Versandprotokolle"
      :configFilter="filterConfig"
      :metadata="metadata"
      :defaultOptions="defaultOptions"
      showSaveButton
      hasSmartSearch
      :showAlwaysPrimaryselection="false"
      :immidiateSearch="false"
      
      @onFilter="onFilter"
      ref="refFilter"
    >
    </BaseFilter>
    <GhostLoading v-else-if="loadingSetup" type="block" :config="{ block: { height: 120, }, }" useBoxContainer />

    <div class="box__container" v-if="requestedData">
      <VersandProtokolleTable
        :title="TABLE_TITLE"
        :records="records"
        :loading="loading"
        :selectedPage="selectedPage"
        :maxRowsPerPage="maxRowsPerPage"
        :searchSort="searchSort"
        :exportConfig="exportConfig"
        @page="selectedPage = $event"
        @sort="onSearchSort"
        @onScroll="onScroll"
        @localSort="searchSort = $event"
        @requestPage="loadPage"
        @rowsPerPage="onPageSizeChange"
      />
    </div>
    <GhostLoading v-else-if="loading" type="table" useBoxContainer :title="TABLE_TITLE" />
    
  </div>
</template>

<script>
import CORE_TYPES from "@/store/core/types";

import V_P_TYPES from "@/store/versandProtokolle/types";
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import { PhPencilLine } from "phosphor-vue";
import BaseFilter from "@/components/core/BaseFilter.vue";
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import { DatePickerUtils } from "@/components/core/forms/DatePicker/date-picker-utils";
import VersandProtokolleTable from "@/views/communication/versandProtokolle/VersandProtokolleTable.vue";
import BaseButton from "@/components/core/BaseButton.vue";
import BaseModal from "@/components/core/BaseModal.vue";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';

const TABLE_TITLE = 'Versandprotokolle';

export default {
  components: {
    PhPencilLine,
    BaseFilter,
    OptionMenu,
    PageHeaderTitleNavigation,
    VersandProtokolleTable,
    BaseButton,
    BaseModal,
    GhostLoading,
  },
  computed: {
    ...mapGetters({
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
      isBroker: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_BROKER,
      records: V_P_TYPES.GETTERS.FILTER,
      setup: V_P_TYPES.GETTERS.SETUP,
      reload:V_P_TYPES.GETTERS.RELOAD,
      isTestCustomer: CORE_TYPES.GETTERS.IS_TEST_CUSTOMER,
      lastFilterParams: V_P_TYPES.GETTERS.GET_FILTER_PARAMS,
    }),
    metadata() {
      let result = [
        {
          type: "group",
          key: "allgemein",
          label: "",
          menuItems: [
            {
              type: "text",
              label: "Kundennummer",
              key: "kundennr",
              dataType: 'number',
              emptyDenied: true,
              inputmode: 'numeric',
            },
            {
              type: "text",
              label: "Vermittlernummer",
              key: "maklernr",
              dataType: 'number',
              inputmode: 'numeric',
            },
            {
              type: "text",
              label: "Name",
              key: "name",
              emptyDenied: true,
            },
            {
              type: "text",
              label: "Vorname",
              key: "vorname",
              emptyDenied: true,
            },
            {
              type: "text",
              label: "Betreff",
              key: "subject",
              emptyDenied: true,
            },
            {
              type: "text",
              label: "Dokumentstyp",
              key: "docType",
              emptyDenied: true,
            },

            {
              type: "text",
              label: "Barcode",
              key: "barcode",
              emptyDenied: true,
            },
            {
              type: "combobox",
              label: "Bereich",
              key: "bereich",
              comboOptions: this.setup && this.setup.berichCombo,
            },
            {
              type: "combobox",
              label: "Gesellschaft",
              key: "gesellschaft",
              comboOptions: this.setup && this.setup.allCombo,
            },

            {
              type: "dateRange",
              label: "Zeitraum",
              key: "zeitraum",
              emptyDenied: true,
            },

           
          ],
        },
      ];
      if (this.setup && this.setup.modus == "VERWALTUNG") {
        result[0].menuItems.push({
          type: "default",
          label: "nur fehlerhafte / nicht bearbeitete Dokumente anzeigen",
          key: "errorOnly",
        });
      } 
       if (!this.isIntern) {
        result[0].menuItems.push( {
              type: "default",
              label: "Dokumente der Unterstruktur auch anzeigen",
              key: "structure",
            },);
      } 

      return result;
    },
    maxRowsPerPage() {
      if (this.tablePageSize > 0) {
        return this.tablePageSize;
      }
      return 25;
    },
    exportConfig() {
      return {
        pdf: true, 
        xls: true, 
        name: 'Versandprotokolle' + ' ' + dayjs(new Date()).format('DD MMM YYYY HH mm'), 
        title: 'Versandprotokolle',
        dispatch: this.exportAsFile,
    }},
  },
  data() {
    return {
      TABLE_TITLE,
      loadingSetup: false,
      defaultOptions: null,
      filterConfig: {
        placeholderForDefSearchInput: "Kundennummer",
        defaultSearchInputKeys: ['kundennr'],
        filterZurucksetzen: null, // () => {},
        filterType: 'versandProtokolle',
      },
      requestedData: false,
      loading: false,
      filterParams: {},
      selectedPage: 0,
      searchSort: {},
      onScrollEnd: () => {},
      tablePageSize: 0,
      lastParams: null,
    };
  },
  watch: {
    setup(next, oldValue) {
      let setVar = this.$store.getters[V_P_TYPES.GETTERS.SETUP];

      const startDate = new Date();

      if (setVar && setVar.modus == "VERWALTUNG") {
        startDate.setDate(startDate.getDate() - 3);
      } else {
        startDate.setDate(startDate.getDate() - 28);
      }

      this.defaultOptions = {
        zeitraum: {
          value: [
            {
              key: "min",
              value: startDate,
            },
            {
              key: "max",
              value: new Date(),
            },
          ],
        },
        errorOnly: {
          value: setVar && setVar.modus == "VERWALTUNG",
        },
      };
      this.lastParams = this.defaultOptions;
    },
     reload(next, oldValue){
        if(next==true && oldValue==false)   {
            const bf = this.getBaseFilter();
            bf.emitFilterEvent();
        }
        
    }
  },
  created() {
    this.setupRun();
  },
  methods: {
    async setupRun() {
      if (this.$route.query?.backAction && this.lastFilterParams?.lastParams) {
        this.lastParams = this.lastFilterParams.lastParams;
        this.defaultOptions = this.lastParams;
        this.selectedPage = this.lastFilterParams.selectedPage;
        this.tablePageSize = this.lastFilterParams.tablePageSize;
        this.filterParams = this.lastFilterParams.filterParams;
        if (this.records?.[this.selectedPage]) {
          this.requestedData = true;
        }
        
        this.$store.commit(V_P_TYPES.MUTATIONS.SET_FILTER_PARAMS, {});
        return;
      }
      try {
        this.loadingSetup = true;
        await this.$store.dispatch(V_P_TYPES.ACTIONS.SETUP);
      } finally {
        this.loadingSetup = false;
      }
    },
    loadPage(event){
      this.selectedPage = event;
      this.doSearch()
    },
    onScroll(onScrollEnd) {
      this.onScrollEnd = onScrollEnd
      this.loadPage(this.selectedPage + 1)
    },
    onSearchSort(searchSort) {
      this.searchSort = searchSort || {}
      this.loadPage(0);
    },
    async onFilter(filterConfig) {
      this.$store.commit(V_P_TYPES.MUTATIONS.RELOAD,false);
      let params = {};
      this.lastParams = {};

      filterConfig.forEach((fc) => {
        let value = fc.value;
        this.lastParams[fc.key] = {value};
        if (fc.key == "zeitraum") {
          params.dateFrom = DatePickerUtils.toDateStr(
            value.find((v) => v.key == "min").value
          );
          params.dateTo = DatePickerUtils.toDateStr(
            value.find((v) => v.key == "max").value
          );
        } else {
          params[fc.key] = value;
        }
      });

      this.filterParams = params
      this.loadPage(0);
    },
    getBaseFilter() {
      return this.$refs.refFilter;
    },
    doSearch(urlParams = this.filterParams, page = this.selectedPage) {
      this.loading = true;
      const params = { limit: this.maxRowsPerPage, sortKey: this.searchSort.key, sortAscending: this.searchSort.sortDirection === 'asc', page }

      this.$store.dispatch(V_P_TYPES.ACTIONS.FILTER, {...params, ...urlParams})
      .finally(() => {
        this.requestedData = true;
        this.loading = false
        this.onScrollEnd()
      })
    },
    onPageSizeChange(pageSize) {
      this.tablePageSize = pageSize;
      this.loadPage(0);
    },
    exportAsFile(fileType) {
      const params = { 
        limit: 20000, 
        sortKey: this.searchSort.key, 
        sortAscending: this.searchSort.sortDirection === 'asc', 
        page: (fileType == 'pdf' ? 0 : 1),
        ...this.filterParams
      }

      return this.$store.dispatch(V_P_TYPES.ACTIONS.FILTER_EXPORT, params);
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.path.startsWith('/communication/versandprtokolle/') ) { // child menu
      this.$addBreadcrumb({
        label: `zurück zu den Versandprotokolle`,
        to,
        from,
      });
      this.$store.commit(V_P_TYPES.MUTATIONS.SET_FILTER_PARAMS, {
        lastParams: this.lastParams, 
        selectedPage: this.selectedPage,
        tablePageSize: this.tablePageSize,
        filterParams: this.filterParams
      });
    }

    next();
  },
};
</script>

<style scoped>
.subject {
  display: inline-block;
  max-width: 700px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>