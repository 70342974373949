var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box__container-wrap" }, [
    _c(
      "div",
      { staticClass: "box__container-rows" },
      [
        _vm.rows.length
          ? [
              !_vm.$isSmallScreen
                ? _c("OverviewTable", {
                    attrs: { records: _vm.rows },
                    on: { subject: _vm.clickBetreff },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function(row) {
                            return [
                              _c(
                                "div",
                                { staticClass: "ellipsis bold" },
                                [
                                  row.isTitleLink
                                    ? _c(
                                        "a",
                                        {
                                          staticStyle: { color: "inherit" },
                                          on: {
                                            click: function($event) {
                                              return _vm.openCustomerNewTab(row)
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(row.title))]
                                      )
                                    : _c("span", [_vm._v(_vm._s(row.title))]),
                                  row.status !== "success" || row.important
                                    ? [
                                        _c("span", { staticClass: "mx-2" }, [
                                          _vm._v(" | ")
                                        ]),
                                        row.important
                                          ? _c("ph-warning-circle", {
                                              staticClass: "mr-1 color-warning",
                                              attrs: { size: 16 }
                                            })
                                          : _vm._e(),
                                        row.status !== "success"
                                          ? _c("ph-warning", {
                                              class: "color-" + row.status,
                                              attrs: { size: 16 }
                                            })
                                          : _vm._e()
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1281064739
                    )
                  })
                : _c("Table", {
                    attrs: {
                      headers: _vm.headers,
                      rows: _vm.rows,
                      rowId: "commId",
                      mobileConfig: {
                        title: "title",
                        headers: ["subject", "date"]
                      }
                    },
                    on: { clickRow: _vm.clickBetreff },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function(row) {
                            return [
                              _c(
                                "div",
                                { staticClass: "ellipsis" },
                                [
                                  row.hasAttachment
                                    ? _c("ph-paperclip", {
                                        staticClass: "mr-1",
                                        staticStyle: {
                                          "vertical-align": "bottom"
                                        },
                                        attrs: { size: 16 }
                                      })
                                    : _vm._e(),
                                  _c("span", [_vm._v(_vm._s(row.title))]),
                                  row.status !== "success" || row.important
                                    ? [
                                        _c("span", { staticClass: "mx-2" }, [
                                          _vm._v(" | ")
                                        ]),
                                        row.important
                                          ? _c("ph-warning-circle", {
                                              staticClass: "mr-1 color-warning",
                                              attrs: { size: 16 }
                                            })
                                          : _vm._e(),
                                        row.status !== "success"
                                          ? _c("ph-warning", {
                                              class: "color-" + row.status,
                                              attrs: { size: 16 }
                                            })
                                          : _vm._e()
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2962599915
                    )
                  })
            ]
          : _vm.loading
          ? _c("GhostLoading", {
              attrs: { type: "table", config: { table: { rows: 5 } } }
            })
          : _c("NoData", { attrs: { noIcon: _vm.$isSmallScreen } })
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "bottom-row text-right mt-3" },
      [
        _c(
          "router-link",
          { attrs: { to: "/communication/postfach/" + _vm.routeStep } },
          [_vm._v(" weitere... ")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }