<template>
  <div>
    <PageHeaderTitleNavigation
      title="E-Mail Postfach"
      :actions="headerActions"
      :defaultMenu="customOptionMenu"
      @action-NEW_MAIL="handleNewEmail()"
    />

    <BaseFilter
      title="E-Mail Filter"
      filterId="PostfachMailFilter"
      :configFilter="filterConfig"
      :defaultOptions="defaultOptions"
      :metadata="metadata"
      showSaveButton
      hasSmartSearch
      immidiateSearch
      @onFilter="onFilter"
    />

    <div class="box__container">
      <ScrollLoading
        v-if="rows.length"
        :hasScroll="rows.length !== maxRowCount && !reachedEnd"
        @scroll="onScroll"
      >
        <Table
          v-if="$isSmallScreen"
          :title="isInbox ? 'Posteingang' : 'Gesendet'"
          rowId="commId"
          v-model="selectedRows"
          :headers="headers"
          :rows="rows"
          :headerActions="titleActions"
          :exportConfig="{ roottext: 'Postfach' }"
          :mobileConfig="mobileConfig"
          :selectionAsBlacklist="invertSelection"
          @clickRow="clickBetreff"
          @selectionAsBlacklist="invertSelection = $event"
          @headerAction-BULK_DELETE="deleteSelected"
        >
          <template #title="row">
            <div class="ellipsis d-flex" style="align-items: center">
              <template v-if="row.hasAttachment">
                <ph-paperclip style="flex-shrink: 0" :size="20" />
              </template>

              <div class="ellipsis">{{ row.title }}</div>
            </div>
          </template>
        </Table>

        <OverviewTable
          v-else
          v-model="selectedRows"
          :records="rows"
          :title="isInbox ? 'Posteingang' : 'Gesendet'"
          :titleActions="titleActions"
          :selectionAsBlacklist="invertSelection"
          @selectionAsBlacklist="invertSelection = $event"
          @subject="clickBetreff"
          @action-DELETE="deleteRow"
          @action-BULK_DELETE="deleteSelected"
          @action-WEITERLEITEN="handleActionWeiterleiten"
          @action-WEITERLEITEN_USER="handleActionWeiterleitenUser"
          @action-WEITERLEITEN_COMPANY="handleActionWeiterleitenCompany"
        >
          <template #title="row">
            <div class="ellipsis bold">
              <a
                style="color: inherit"
                v-if="row.isTitleLink"
                @click="openCustomerNewTab(row.nummer)"
                >{{ row.title }}</a
              >
              <span v-else>{{ row.title }}</span>

              <template v-if="row.status !== 'success' || row.important">
                <span class="mx-2"> | </span>
                <ph-warning-circle v-if="row.important" class="mr-1 color-warning" :size="16" />
                <ph-warning v-if="row.status !== 'success'" :size="16" :class="`color-${row.status}`" />
              </template>
            </div>
          </template>
        </OverviewTable>
      </ScrollLoading>
      <GhostLoading v-else-if="loading" type="table" :config="{ table: { rows: 10 } }"/>
      <NoData v-else/>
    </div>

    <BaseModal
      ref="mailContacts"
      modalTitle="Kontakte"
      size="lg"
      :showCancelButton="false"
      labelButtonConfirm="Auswahl übernehmen"
      @onConfirmButton="confirmContactsToSend"
    >
      <InsuranceCompanyMailContacts
        :records="mailUsers"
        @selectedRows="selectRowsContacts"
      />
    </BaseModal>
  </div>
</template>

<script>
import CORE_TYPES from "@/store/core/types";
import COMMUNICATION_TYPES from "@/store/communication/types";
import MAILCOMPOSER_TYPES from "@/store/mailcomposer/types";

import ScrollLoading from "@/views/communication/ScrollLoading.vue";
import OverviewTable from "@/views/communication/OverviewTable.vue";
import BaseModal from "@/components/core/BaseModal.vue";
import InsuranceCompanyMailContacts from "@/views/versicherungen/InsuranceCompanyMailContacts.vue";
import Table from "@/components/table2/Table.vue";
import axios from "axios";
import dayjs from "dayjs";
import postfachTableMixin from '@/views/communication/postfach/tables/postfach-table-mixin.js';
import { mapGetters } from "vuex";
import {
  SimpleAction,
  ConfirmedAction,
  TextColumn,
  DateColumn,
  IconColumn,
  Icon,
  SlotColumn,
} from "@/components/table2/table_util.js";
import { makeQueryParam } from "@/helpers/utils-helper";
import { DatePickerUtils } from "@/components/core/forms/DatePicker/date-picker-utils";
import {
  PageHeaderSimpleAction,
  PageHeaderConfirmedAction,
} from "@/components/core/header-title-navigation/page-header-utils";
import { PhWarningCircle, PhWarning, PhPaperclip, } from "phosphor-vue";
import { TabMenu } from '@/menu/menu-utils';

export default {
  mixins: [postfachTableMixin],
  components: {
    BaseModal,
    InsuranceCompanyMailContacts,
    Table,
    OverviewTable,
    ScrollLoading,
    PhWarning,
    PhPaperclip,
    PhWarningCircle
  },
  data() {
    return {
      onScrollEnd: () => {},
      invertSelection: false,
      reachedEnd: false,
      inboxRowCount: 0,
      outboxRowCount: 0,
      inboxRecords: [],
      outboxRecords: [],
      mailUsers: [],
      selectedRowsContacts: [],
      rowSelected: {},
      insuranceId: "",
      filterConfig: {
        placeholderForDefSearchInput: "Betreff",
        defaultSearchInputKeys: ["subject"],
        hideFirstColumn: true,
      },
      mobileConfig: {
        title: "title",
        headers: ["subject", "date", "statusIcon", "importantIcon"],
      },
      metadata: [
        {
          type: "group",
          key: "allgemein",
          label: "Allgemein",
          menuItems: [
            {
              type: "text",
              label: "Betreff",
              key: "subject",
              emptyDenied: true,
            },
            {
              type: "text",
              label: "Name",
              key: "name",
              emptyDenied: true,
            },
            {
              type: "dateRange",
              label: "Zeitraum",
              key: "zeitraum",
              emptyDenied: true,
            },
            {
              type: "datepicker",
              label: "Datum",
              key: "datum",
              emptyDenied: true,
            },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      initMailData: MAILCOMPOSER_TYPES.GETTERS.GET_INIT,
      isBrokerOrBypass: CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS,
    }),
    customOptionMenu() {
      return [
        TabMenu('/communication/postfach/emails/inbox', 'Posteingang'),
        TabMenu('/communication/postfach/emails/outbox', 'Postausgang'),
      ];
    },
    headers() {
      return {
        lockedLeft: [
          SlotColumn("title", "Titel", undefined, 1),
          TextColumn("subject", "Betreff", undefined, 1).addCellProps({
            lineClamp: 4,
          }),
          DateColumn("date", "Datum", undefined, 1),
          IconColumn("statusIcon", "Status").setColorFn(
            (row) => `color-${row.status}`
          ),
          IconColumn("importantIcon", "Wichtig").setColorFn('color-danger'),
        ],
        center: [],
        lockedRight: [],
      };
    },
    rows() {
      const records = this.isInbox
        ? this.inboxRecords || []
        : this.outboxRecords || [];
      return records.map((record) => ({
        ...record,
        title: record.name
          ? record.name
          : record.contact
          ? "<" + record.contact + ">"
          : "[Unbekannt]",
        subject: record.subject || "[kein Betreff]",
        id: record.commId,
        isTitleLink: this.canOpenCustomer(record),
        isSubjectLink: !!record.commId,
        statusIcon: Icon("PhWarning"),
        importantIcon: Icon("PhWarningCircle"),
        actions: this.makeActions(record),
      }));
    },
    isInbox() {
      return this.$route.params.box === "inbox";
    },
    maxRowCount() {
      return this.isInbox ? this.inboxRowCount : this.outboxRowCount;
    },
    titleActions() {
      return [
        PageHeaderConfirmedAction(
          "BULK_DELETE",
          "Markierte löschen",
          "Sollen die markierten Einträge wirklich gelöscht werden?",
          "Markierte löschen",
          "Löschen"
        ).withDisabled(() =>
          this.invertSelection
            ? this.selectedRows.length >= this.maxRowCount
            : !this.selectedRows.some((item) => item.deletable)
        ),
      ];
    },
    headerActions() {
      return [
        PageHeaderSimpleAction("NEW_MAIL", "Neue E-Mail").withVisible(
          () => this.isBrokerOrBypass
        ),
      ];
    },
  },
  watch: {
    initMailData() {
      this.mailUsers = this.initMailData?.participants;
    },
    '$route.params.box'() {
      this.selectedRows = [];
      this.invertSelection = false;

      if (
        (!this.outboxRecords.length && !this.isInbox) ||
        (!this.inboxRecords.length && this.isInbox)
      ) {
        this.doSearch();
      }
    },
  },
  mounted() {
    this.insuranceId = this.$route.params.id;
  },
  methods: {
    onScroll(onScrollEnd) {
      this.onScrollEnd = onScrollEnd;
      this.doSearch();
    },
    makeActions(row) {
      const actions = [];

      if (
        row.subject &&
        (row.subject.toLowerCase().includes("pol.nr.") ||
          row.subject.toLowerCase().includes("pol. nr.") ||
          row.subject.toLowerCase().includes("[[fkv") ||
          row.subject.toLowerCase().includes("{{fkv"))
      ) {
        actions.push(
          SimpleAction(
            "WEITERLEITEN_COMPANY",
            "PhShare",
            "Weiterleiten an Gesellschaft"
          )
        );
        actions.push(
          SimpleAction("WEITERLEITEN_USER", "PhShare", "Weiterleiten an Kunde")
        );
      } else {
        actions.push(SimpleAction("WEITERLEITEN", "PhShare", "Weiterleiten"));
      }

      actions.push(
        ConfirmedAction(
          "DELETE",
          "PhTrash",
          "Löschen",
          `Soll die E-Mail "${
            row.subject || "[kein Betreff]"
          }" wirklich gelöscht werden?`,
          "E-Mail löschen",
          "Löschen"
        )
      );

      return actions;
    },
    clickBetreff(row) {
      this.$router.push(
        `/communication/postfach/emails/${this.isInbox ? 'inbox' : 'outbox'}/email/${row.commId}`
      );
    },
    handleActionWeiterleiten(row, url = "mailcomposer-postfach") {
      this.$store
        .dispatch(COMMUNICATION_TYPES.ACTIONS.LOAD_EMAIL, row.commId)
        .then((responseData) => {
          this.$router.push({
            name: url,
            params: {
              subject: "Fwd: " + row.subject,
              copiedFiles: responseData.attachments,
              content:
                "<br><br>-------- Weitergeleitete Nachricht --------<br><i>" +
                "<u>Betreff:</u> " +
                row.subject +
                "<br>" +
                "<u>Datum:</u> " +
                responseData.dateSend +
                "<br>" +
                "<u>Von:</u> " +
                responseData.fromAddress +
                "<br>" +
                "<u>An:</u> " +
                responseData.toAddress +
                "</i><br>" +
                (responseData.ccAddress
                  ? `<u>CC:</u> ${responseData.ccAddress}<br>`
                  : "") +
                responseData.text,
            },
          });
        });
    },
    handleActionWeiterleitenUser(row) {
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAILCOMPOSER_CONFIG, {
        type: "mailcomposer-insurance",
        action: "clear",
      });
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, {
        type: "mailcomposer-insurance",
        action: "clear",
      });
      this.$store
        .dispatch(COMMUNICATION_TYPES.ACTIONS.LOAD_EMAIL, row.commId)
        .then((responseData) => {
          this.$store.commit(
            MAILCOMPOSER_TYPES.MUTATIONS.SET_MAIL_LINKED_OBJECT,
            {
              data: [responseData.insurance],
              toCompany: false,
              participants: undefined,
            }
          );
          this.$router.push({
            name: "mailcomposer-insurance",
            params: {
              subject: "Fwd: " + row.subject,
              copiedFiles: responseData.attachments,
              content:
                "<br><br>-------- Weitergeleitete Nachricht --------<br><i>" +
                "<u>Betreff:</u> " +
                row.subject +
                "<br>" +
                "<u>Datum:</u> " +
                responseData.dateSend +
                "<br>" +
                "<u>Von:</u> " +
                responseData.fromAddress +
                "<br>" +
                "<u>An:</u> " +
                responseData.toAddress +
                "</i><br>" +
                (responseData.ccAddress
                  ? `<u>CC:</u> ${responseData.ccAddress}<br>`
                  : "") +
                responseData.text,
            },
          });
        });
    },
    handleActionWeiterleitenCompany(row) {
      this.rowSelected = row;
      axios
        .post(
          process.env.VUE_APP_API + "/../mrsmailviewer",
          { commId: row.commId, commType: "email" },
          { defaultSpinner: true }
        )
        .then((response) => {
          if (response.data.insurance) {
            this.startCompanySelector(response.data.insurance);
          } else {
            this.sendMailWeiterleitenCompany();
          }
        });
    },
    startCompanySelector(insurance) {
      this.insuranceId = insurance;
      this.$store
        .dispatch(MAILCOMPOSER_TYPES.ACTIONS.GET_INIT, {
          insurance,
          toCompany: true,
        })
        .then((response) => {
          if (
            response?.data?.participants &&
            response?.data?.participants?.length
          ) {
            this.$refs.mailContacts.open();
          } else {
            this.handleActionWeiterleiten(
              response?.data,
              "mailcomposer-insurance"
            );
          }
        });
    },
    confirmContactsToSend() {
      this.$refs.mailContacts.close();
      this.sendMailWeiterleitenCompany();
    },
    selectRowsContacts(rows) {
      this.selectedRowsContacts = rows;
    },
    sendMailWeiterleitenCompany() {
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, {
        type: "mailcomposer-insurance",
        action: "clear",
      });
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAILCOMPOSER_CONFIG, {
        type: "mailcomposer-insurance",
        action: "clear",
      });
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAIL_LINKED_OBJECT, {
        data: [this.insuranceId],
        toCompany: true,
        participants: this.selectedRowsContacts || [],
      });
      this.$store
        .dispatch(
          COMMUNICATION_TYPES.ACTIONS.LOAD_EMAIL,
          this.rowSelected.commId
        )
        .then((responseData) => {
          this.$router.push({
            name: "mailcomposer-insurance",
            params: {
              subject: "Fwd: " + this.rowSelected.subject,
              copiedFiles: responseData.attachments,
              content:
                "<br><br>-------- Weitergeleitete Nachricht --------<br><i>" +
                "<u>Betreff:</u> " +
                this.rowSelected.subject +
                "<br>" +
                "<u>Datum:</u> " +
                responseData.dateSend +
                "<br>" +
                "<u>Von:</u> " +
                responseData.fromAddress +
                "<br>" +
                "<u>An:</u> " +
                responseData.toAddress +
                "</i><br>" +
                (responseData.ccAddress
                  ? `<u>CC:</u> ${responseData.ccAddress}<br>`
                  : "") +
                responseData.text,
            },
          });
        });
    },
    handleNewEmail() {
      this.$router.push({ path: `/communication/mailcomposer-postfach` });
    },
    deleteRow(row) {
      const records = this.isInbox
        ? this.inboxRecords || []
        : this.outboxRecords || [];

      axios
        .delete(
          `${process.env.VUE_APP_API}/postfach/emails/delete?ids=${row.commId}`,
          { defaultSpinner: true }
        )
        .then((response) => {
          if (response && response.data && response.data.length) {
            response.data.forEach((id) => {
              const index = records.findIndex((record) => record.commId == id);
              if (index >= 0) records.splice(index, 1);
            });
          }
        });
    },
    deleteSelected() {
      const records = this.isInbox
        ? this.inboxRecords || []
        : this.outboxRecords || [];
      const ids = this.selectedRows
        .filter((row) => row.deletable)
        .map((v) => v.commId);

      const filterParams = this.prepareFilterParams(0, 0);
      const paramString = makeQueryParam({
        invertSelection: this.invertSelection,
        ...filterParams,
        ids,
      });

      axios
        .delete(
          `${process.env.VUE_APP_API}/postfach/emails/delete?${paramString}`,
          { defaultSpinner: true }
        )
        .then((response) => {
          if (response && response.data && response.data.length) {
            response.data.forEach((id) => {
              const index = records.findIndex((record) => record.commId == id);
              if (index >= 0) records.splice(index, 1);
            });
          }

          this.selectedRows = [];
          this.invertSelection = false;
        });
    },
    onFilter(filterConfig) {
      let params = {};

      filterConfig.forEach((fc) => {
        let value = fc.value;
        if (fc.key == "zeitraum") {
          params.fromDate = DatePickerUtils.toDateStr(
            value.find((v) => v.key == "min").value
          );
          params.toDate = DatePickerUtils.toDateStr(
            value.find((v) => v.key == "max").value
          );
        } else if (fc.key == "datum") {
          params.fromDate = DatePickerUtils.toDateStr(value);
          params.toDate = DatePickerUtils.toDateStr(value);
        } else {
          params[fc.key] = value;
        }
      });

      this.filterParams = params;
      this.inboxRecords = [];
      this.outboxRecords = [];
      this.reachedEnd = false
      this.doSearch();
    },
    doSearch(offset = this.rows.length, limit = 20) {
      if(this.reachedEnd) return;

      this.loading = true;
      const isInbox = this.isInbox;

      const paramString = makeQueryParam(
        this.prepareFilterParams(offset, limit)
      );

      axios
        .get(
          `${process.env.VUE_APP_API}/postfach/list/emails?${paramString}`,
          { defaultSpinner: true }
        )
        .then((response) => {
          this.reachedEnd = response.data.records.length < limit

          if (isInbox) {
            this.inboxRecords.push(...response.data.records);
            // this.inboxRecords.splice.apply(this.inboxRecords, [offset, limit].concat(response.data.records));
            this.inboxRowCount = response.data.rowCount;
          } else {
            this.outboxRecords.push(...response.data.records);
            this.outboxRowCount = response.data.rowCount;
          }
        })
        .finally(() => {
          this.onScrollEnd();
          this.loading = false;
          this.selectedRows = [];
        });
    },
    prepareFilterParams(offset, limit) {
      const urlParams = this.filterParams;

      return {
        offset,
        limit,
        isInbox: this.isInbox,
        isOutbox: !this.isInbox,
        fromDate: urlParams.fromDate || "01.01.2000",
        toDate: urlParams.toDate || dayjs(new Date()).format("DD.MM.YYYY"),
        subject: urlParams.subject || "",
        name: urlParams.name || "",
      };
    },
  },
};
</script>