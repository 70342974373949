import V_P from './types';

import axios from 'axios';
import CORE_TYPES from "@/store/core/types";
import LOG_TYPES from '@/store/log/types';
import { doUpload,isLegi } from '@/helpers/versandProtokolle';
import COMMUNICATION_TYPES from "@/store/communication/types";
import router from '../../router';
export default {

  [V_P.ACTIONS.SETUP]({ commit, rootState, state }, e) {
    const config = {
      defaultSpinner: true,
    };



    axios.get(rootState.core.apiAddress + '/versandProtokolle/Setup').then(response => {


      commit(V_P.MUTATIONS.SETUP, response.data);


    })
  },
  async [V_P.ACTIONS.SET_BARCODE_PAGES]({ commit, rootState, dispatch, state }, e) {
    const config = {
      defaultSpinner: true,
      disableDefaultLog: true,
      disableDefaultErrorMessage: true,
      cancelableRequest: {
        title: 'Edit Pages'
      }
    };



    await axios.post(rootState.core.apiAddress + '/Barkodes/setBarcodeMultiPages', e, config).then(response => {


      commit(V_P.MUTATIONS.EINGANG, response.data);
      commit(V_P.MUTATIONS.ERROR_EDIT, false);


    }).catch(error => {
     
      dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, 'Prüfen Sie bitte Barkode ');
      commit(V_P.MUTATIONS.ERROR_EDIT, true);

    });
  },
  async [V_P.ACTIONS.SET_BARCODE_PAGES]({ commit, rootState, dispatch, state }, e) {
    const config = {
      defaultSpinner: true,
      disableDefaultLog: true,
      disableDefaultErrorMessage: true,
      cancelableRequest: {
        title: 'Edit Pages'
      }
    };



    await axios.post(rootState.core.apiAddress + '/Barkodes/setBarcodeMultiPages', e, config).then(response => {


      commit(V_P.MUTATIONS.EINGANG, response.data);
      commit(V_P.MUTATIONS.ERROR_EDIT, false);


    }).catch(error => {
     
      dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, 'Prüfen Sie bitte Barkode ');
      commit(V_P.MUTATIONS.ERROR_EDIT, true);

    });
  },

  async [V_P.ACTIONS.SET_KUNDEN_GESEL_PAGES]({ commit, rootState, dispatch, state }, e) {
    const config = {
      defaultSpinner: true,
    
      cancelableRequest: {
        title: 'Edit Pages'
      }
    };



    await axios.post(rootState.core.apiAddress + '/Barkodes/setGesellschaftMultiPages', e, config).then(response => {


      commit(V_P.MUTATIONS.EINGANG, response.data);
      commit(V_P.MUTATIONS.ERROR_EDIT, false);


    }).catch(error => {
      
      commit(V_P.MUTATIONS.ERROR_EDIT, true);

    });
  },

  async [V_P.ACTIONS.SET_KUNDEN_DECKBLATT_PAGES]({ commit, rootState, dispatch, state }, e) {
    const config = {
      defaultSpinner: true,
    
      cancelableRequest: {
        title: 'Edit Pages'
      }
    };



    await axios.post(rootState.core.apiAddress + '/Barkodes/setKundenDeckBlattMultiPages', e, config).then(response => {


      commit(V_P.MUTATIONS.EINGANG, response.data);
      commit(V_P.MUTATIONS.ERROR_EDIT, false);


    }).catch(error => {
      
      commit(V_P.MUTATIONS.ERROR_EDIT, true);

    });
  },

  async [V_P.ACTIONS.DEACT]({ commit, rootState, dispatch, state }, e) {
    const config = {
      defaultSpinner: true,
    
      cancelableRequest: {
        title: 'Edit Pages'
      }
    };

    

    await axios.post(rootState.core.apiAddress + '/Barkodes/page_activate?eingangId='+e.eingangId+'&pageNo='+e.rownum+'&active='+(!e.active), {},config).then(response => {

      commit(V_P.MUTATIONS.EINGANG, null);
      commit(V_P.MUTATIONS.EINGANG, response.data);
      


    })
  },


  async [V_P.ACTIONS.LABEL]({ commit, rootState, state }, e) {
    const config = {
      defaultSpinner: true,
    };



    axios.get(rootState.core.apiAddress + '/versandProtokolle/menuItem').then(response => {


      commit(V_P.MUTATIONS.LABEL, response.data);


    })
  },
  async [V_P.ACTIONS.GET_VP_BEMERKUNG]({ commit, rootState, state }, id) {
    const config = {
      defaultSpinner: true,
    };


    commit(V_P.MUTATIONS.VP_BEMERKUNG, null)
    await axios.get(rootState.core.apiAddress + '/versandProtokolle/getBemerkung/' + id).then(response => {


      commit(V_P.MUTATIONS.VP_BEMERKUNG, response.data);


    })
  },
  async [V_P.ACTIONS.TRACE]({ commit, rootState, state }, id) {
    const config = {
      defaultSpinner: true,
    };


    commit(V_P.MUTATIONS.TRACE, null)
    await axios.get(rootState.core.apiAddress + '/versandProtokolle/getTrace/' + id).then(response => {


      commit(V_P.MUTATIONS.TRACE, response.data);


    })
  },
  async [V_P.ACTIONS.QUELLE]({ commit, rootState, state }, id) {
    const config = {
      defaultSpinner: true,
    };


    commit(V_P.MUTATIONS.QUELLE, null)
    await axios.get(rootState.core.apiAddress + '/versandProtokolle/getQuelle/' + id).then(response => {


      //commit(V_P.MUTATIONS.QUELLE, response.data);
      let idCom = 'l' + id;
      commit(COMMUNICATION_TYPES.MUTATIONS.LOAD_EMAIL, { id: idCom, data: response.data });
      router.push({
        path: `/communication/postfach/emails/email/${idCom}`,

      });




    })
  },

  async [V_P.ACTIONS.SET_VP_BEMERKUNG]({ commit, rootState, state }, e) {
    const config = {
      defaultSpinner: true,
    };


    await axios.post(rootState.core.apiAddress + '/versandProtokolle/setBemerkung/' + e.id, e.bemerkung).then(response => {

      let bb = {}
      bb.subject = response.data.subject;
      bb.text = response.data.text;

      commit(V_P.MUTATIONS.VP_BEMERKUNG, bb);


    })
  },

  async [V_P.ACTIONS.CONFIRM]({ commit, rootState, state }, id) {
    const config = {
      defaultSpinner: true,
    };



    await axios.post(rootState.core.apiAddress + '/versandProtokolle/confirm/' + id, config).then(response => {



      //commit(V_P.MUTATIONS.VP_BEMERKUNG,bb);


    })
  },
  async [V_P.ACTIONS.TOERROR]({ commit, rootState, state }, id) {
    const config = {
      defaultSpinner: true,
    };



    return axios.post(rootState.core.apiAddress + '/versandProtokolle/toerror/' + id, config);
  },


  async [V_P.ACTIONS.FILTER]({ commit, getters, dispatch, state, rootState }, e) {
    const config = {
      defaultSpinner: true,
      cancelableRequest: {
        title: 'Versandprotokolle'
      }
    };

    await axios.post(rootState.core.apiAddress + '/versandProtokolle/Filter', e, config).then(response => {
      commit(V_P.MUTATIONS.FILTER, {...response.data, page: e.page});
      commit(V_P.MUTATIONS.SET_ROW_COUNT, response.data.rowCount);
    })
  },
  [V_P.ACTIONS.FILTER_EXPORT]({ rootState }, params) {
    return axios.post(rootState.core.apiAddress + '/versandProtokolle/exportFilter', params, {defaultSpinner: true})
  },
  async [V_P.ACTIONS.COMMUNICATIONS]({ commit, getters, dispatch, state, rootState }, e) {



    const config = {
      defaultSpinner: true,
      cancelableRequest: {
        title: 'Versandprotokolle'
      }
    };

    await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/versandProtokolle/communications?eingangId=${e}`, config).then(response => {

      state.COMMUNICATIONS = response.data;
      commit(V_P.MUTATIONS.COMMUNICATIONS, response.data);

    })
  },


  [V_P.ACTIONS.LOAD_WEB_SERVICE]({ rootState }, id) {
    doUpload(rootState.core.apiAddress + '/versandProtokolle/webserviceupload?id=' + id);
  },
  [V_P.ACTIONS.LOAD_PDF]({ rootState }, id) {
    doUpload(rootState.core.apiAddress + '/versandProtokolle/dokumentupload?id=' + id);
  },
  [V_P.ACTIONS.LOAD_WEB_SERVICE_REPORT]({ rootState }, id) {
    doUpload(rootState.core.apiAddress + '/versandProtokolle/webserviceReportUpload?id=' + id);
  },
  [V_P.ACTIONS.LOAD_FAX_REPORT]({ rootState }, id) {
    doUpload(rootState.core.apiAddress + '/versandProtokolle/faxReportUpload?id=' + id);
  },
  [V_P.ACTIONS.LOAD_MAIL_REPORT]({ rootState }, id) {
    doUpload(rootState.core.apiAddress + '/versandProtokolle/mailReportUpload?id=' + id);
  },
  [V_P.ACTIONS.LOAD_EINGANG]({ rootState }, id) {
    doUpload(rootState.core.apiAddress + '/versandProtokolle/eingangData?id=' + id);
  },



  async [V_P.ACTIONS.EINGANG]({ commit, getters, dispatch, state, rootState }, e) {



    const config = {
      defaultSpinner: true,
      cancelableRequest: {
        title: 'Versandprotokolle'
      }
    };

    await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/Barkodes/eingang?eingangId=${e}`, config).then(response => {

      state.EINGANG = response.data;
      commit(V_P.MUTATIONS.EINGANG, response.data);

    }).catch(error => {
      
       //router.back();
      router.push('/communication/versandprtokolle?backAction=true')  
  })
  },

  async [V_P.ACTIONS.BARCODES_COMBO]({ commit, getters, dispatch, state, rootState }, e) {



    const config = {
      defaultSpinner: true,
      cancelableRequest: {
        title: 'BarcodesCombo'
      }
    };
    await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/Barkodes/barcodesCombo?eingangId=${e.id}&page=${e.page}`, config).then(response => {

      state.BARCODES_COMBO = response.data;
      commit(V_P.MUTATIONS.BARCODES_COMBO, response.data);

    })
  },

  async [V_P.ACTIONS.GET_PAGE]({ commit, getters, dispatch, state, rootState }, e) {



    const config = {
      defaultSpinner: true,
      cancelableRequest: {
        title: 'getPage'
      }
    };
    commit(V_P.MUTATIONS.EDIT_GESEL_PAGE, null);
    let gesell = null;
    await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/versandProtokolle/getPage?eingangId=${e.id}&rowNum=${e.page}`, config).then(response => {

      gesell = response.data.gesellschaftIn;

      commit(V_P.MUTATIONS.EDIT_GESEL_PAGE, response.data);


    })
    if (gesell) {
      await dispatch(V_P.ACTIONS.GESELL_KOMM_COMBO, gesell);

    }
    if (!getters[V_P.GETTERS.EDIT_GESEL_PAGE_CONFIG]) {
      await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/versandProtokolle/getGesellKundeConfig`, config).then(response => {


        commit(V_P.MUTATIONS.EDIT_GESEL_PAGE_CONFIG, response.data);
      })
    }
    
    if (!getters[V_P.GETTERS.EDIT_KUNDEN_PAGE_CONFIG]) {
      await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/versandProtokolle/getKundeDeckBlattConfig`, config).then(response => {


        commit(V_P.MUTATIONS.EDIT_KUNDEN_PAGE_CONFIG, response.data);
      })
    }
    await dispatch(V_P.ACTIONS.PERSONEN_COMBO);
  },

  async [V_P.ACTIONS.GET_KUNDEN_NAME]({ commit, getters, dispatch, state, rootState }, e) {



    const config = {
      defaultSpinner: true,
      cancelableRequest: {
        title: 'getPage'
      }
    };
    //commit(V_P.MUTATIONS.EDIT_GESEL_PAGE, null);
    let page = getters[V_P.MUTATIONS.EDIT_GESEL_PAGE];
    page.kundenname = null;
    commit(V_P.MUTATIONS.EDIT_GESEL_PAGE, page);
    await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/versandProtokolle/getKunde?kundennr=${e}`, config).then(response => {

      page.kundenname = response.data.name;
      commit(V_P.MUTATIONS.EDIT_GESEL_PAGE, page);

    })
  },

  async [V_P.ACTIONS.GESELL_KOMM_COMBO]({ commit, getters, dispatch, state, rootState }, e) {



    const config = {
      defaultSpinner: true,
      cancelableRequest: {
        title: 'Versandprotokolle'
      }
    };

    await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/versandProtokolle/getGesellCommCombo?gesellschaft=${e}`, config).then(response => {


      commit(V_P.MUTATIONS.GESELL_KOMM_COMBO, response.data.items);

    })
  },
  async [V_P.ACTIONS.GET_GESELL_KOMM]({ commit, getters, dispatch, state, rootState }, e) {



    const config = {
      defaultSpinner: true,
      cancelableRequest: {
        title: 'Versandprotokolle'
      }
    };
    let page = getters[V_P.MUTATIONS.EDIT_GESEL_PAGE];
    await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/versandProtokolle/getGesellschaftKommunikation?id=${e}`, config).then(response => {
      page.subject = response.data.subject;
      page.htmlText = response.data.htmlText;
      page.commAddress = response.data.address;
      page.kommunikationsArt = response.data.art;
      
      commit(V_P.MUTATIONS.EDIT_GESEL_PAGE, page);

    })
  },

  async [V_P.ACTIONS.PERSONEN_COMBO]({ commit, getters, dispatch, state, rootState }) {



    const config = {
      defaultSpinner: true,
      cancelableRequest: {
        title: 'Versandprotokolle'
      }
    };
    commit(V_P.MUTATIONS.PERSONEN_COMBO, null);

    let b = getters[V_P.GETTERS.EDIT_GESEL_PAGE]
   
    if(isLegi(b)){
    await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/versandProtokolle/getPersons?kundennr=${b.kundennrIn}`, config).then(response => {
     commit(V_P.MUTATIONS.PERSONEN_COMBO, response.data);
     if(response.data.length==1) {
         b.legiInhaberPersonId=response.data[0].value;
         commit(V_P.MUTATIONS.EDIT_GESEL_PAGE, b);
     }
    })}
  },
 [V_P.ACTIONS.SEND]({ commit, getters, dispatch, state, rootState },e) {
    const config = {
      defaultSpinner: true,
      cancelableRequest: {
        title: 'versand'
      }
    };
    let s=rootState.core.apiAddress + '/versandProtokolle/versand?id='+e.eingangId;
    
    commit(V_P.MUTATIONS.PAGES_ACTIVE,true);
    return axios.post(s,{}, config).then(response => {


      commit(V_P.MUTATIONS.EINGANG, null)
      commit(V_P.MUTATIONS.EINGANG, response.data);
     
      
      if (e.fromFiltr && response.data.eingangOutput){
        let items = getters[V_P.GETTERS.FILTER]?.[e.page];
        
        var index = items.findIndex(item => item.id === e.eingangId);

        if (index !== -1) {
          items[index] = response.data.eingangOutput;
        }
       
        commit(V_P.MUTATIONS.FILTER, {records: items, page: e.page});  
      }
    
      commit(V_P.MUTATIONS.PAGES_ACTIVE,false);
      if(e.back){
        router.push('/communication/versandprtokolle?backAction=true')  
      }
    }).catch(() => {
      commit(V_P.MUTATIONS.PAGES_ACTIVE,false);
    })
  },

  async [V_P.ACTIONS.MAPPE_COMBO]({ commit, getters, dispatch, state, rootState }, e) {

    commit(V_P.MUTATIONS.MAPPE_COMBO, null);
    commit(V_P.MUTATIONS.MAPPE_TABLE, null);
    const config = {
      defaultSpinner: true,
      cancelableRequest: {
        title: 'Versandprotokolle'
      }
    };

    await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/versandProtokolle/getMappenCombo`,e, config).then(response => {

      commit(V_P.MUTATIONS.MAPPE_COMBO, response.data);

    });
  },
  
  async [V_P.ACTIONS.MAPPE_COMBO]({ commit, getters, dispatch, state, rootState }, e) {
    
    commit(V_P.MUTATIONS.MAPPE_COMBO, null);
    commit(V_P.MUTATIONS.MAPPE_TABLE, null);
    const config = {
      defaultSpinner: true,
      cancelableRequest: {
        title: 'Versandprotokolle'
      }
    };
    let b =null;
    await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/versandProtokolle/getMappenCombo?maps=`+e, config).then(response => {

      commit(V_P.MUTATIONS.MAPPE_COMBO, response.data);
      b=response.data;
      
    });
    if(b && b.length>0){
      await dispatch(V_P.ACTIONS.MAPPE_TABLE, b[0].value);
    }
  },

  async [V_P.ACTIONS.MAPPE_TABLE]({ commit, getters, dispatch, state, rootState }, e) {

    
    commit(V_P.MUTATIONS.MAPPE_TABLE, null);
    const config = {
      defaultSpinner: true,
      cancelableRequest: {
        title: 'Versandprotokolle'
      }
    };

    await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/versandProtokolle/getCheckMappen?map=`+e, config).then(response => {

      commit(V_P.MUTATIONS.MAPPE_TABLE, response.data);
      
    });
  },


}


