<template>
  <div>
    <PageHeaderTitleNavigation 
      title="Depotpositionen bearbeiten"
      :actions="headerActions"
      @action-ADD="toolAction('neu')"
    >
    </PageHeaderTitleNavigation>
    
    <BaseCollapsable  v-if="filter.hinweise" class="box__container">
      <template v-slot:title>
        <span class="box__title">
          {{hinweisTitle()}}
        </span>
      </template>
      <template v-slot:content>
        <div class="d-block">
          <template v-for="(text, idx) in filter.hinweise">
            <div :key="idx"  v-html="sanitize(text)"></div>
          </template>
        </div>
      </template>
    </BaseCollapsable>

    <BaseFilter ref="refFilter"
      v-if="filter.metadata"
      title="Depotpositionenfilter"
      filterId="c97dab9f-d659-4aaf-a6e9-483700b50ebf"
      :metadata="filter.metadata"
      hasSmartSearch
      :immidiateSearch="!isIntern"
      :configFilter="configFilter"
      :defaultOptions="defaultOptions"
      showSaveButton
      @onFilter="handleSearch" 
    />
    <HandleTablePin keyOfPIN="TABELLE_EDIT_DEPOTPOSITIONEN" />

    <div class="box__container">
      <GhostLoading v-if="loading && !scrollLoading" type="table" :title="TABLE_TITLE" />
      <PaginatedTable v-else
        ref="depotliste"
        :title="TABLE_TITLE"
        tableId="d987e6e2-fb13-4d13-ae6e-11da6c918d71"
        :headers="headers"
        :pages="pages"
        :pageCount="pageCount"
        :rowCount="rowsCount"
        :page="pageIndex"
        :pageSize="rowsPerPage"
        :menuRowsPerPage="menuRowsPerPage"
        :exportConfig="exportConfig"
        :mobileConfig="{title: 'wertpapier', headers: ['name', 'vorname', 'wert_euro']}"
        noDataContent="Keine Depotpositionen"
        :sorted="tableSort"
        @sort="onSetTableSort"
        @localSort="tableSort = $event"
        @page="setPageIndex"
        @rowsPerPage="onPageSizeChange"
        @requestPage="loadPage"
        @click-positionsnr="gotoDepotposition"
        @action-delete="doAction('delete', $event)"
        @action-limit="doAction('limit', $event)"
        @action-courtage="doAction('courtage', $event)"
        @action-transakt="doAction('transakt', $event)"
        @action-transaktPdf="doAction('transaktPdf', $event)"
        @action-transTzuT="doAction('transTzuT', $event)"
        @action-info="doAction('info', $event)"
        @action-bemerkung="doAction('bemerkung', $event)"
        @action-edit="doAction('edit', $event)"
        @onScroll="onScroll"
      >
        <template v-slot:infoActions="row">
          <div class="d-flex">
            <div v-for="(line, index) in row.infoActions" :key="index">
              <a class="text-color" @click="openInfo(line, row)" v-if="line.isLink">
                <Pill :label="line.label" :type="line.type" :bold="line.bold"></Pill>
              </a>
              <Pill :label="line.label" :type="line.type" :bold="line.bold" v-else></Pill>
            </div>
          </div>
        </template>
      </PaginatedTable>
    </div>

    <BaseModal
      ref="modalRef"
      :modalTitle="modalArg.title"
      :showConfirmButton="modalArg.showConfirmButton"
      :labelButtonConfirm="modalArg.labelButtonConfirm"
      :labelButtonCancel="modalArg.labelButtonCancel"
      @onConfirmButton="modalArg.onConfirm" > 
        <div v-html="modalArg.body"></div>
    </BaseModal>

    <BaseModal
      ref="editBemerkung"
      modalTitle="Bemerkung bearbeiten"
      labelButtonConfirm="Speichern"
      @onConfirmButton="saveBemerkung()"
    >
      <template v-slot:default>
          <InputTextArea
              label="Freitextfeld zur persönlichen Bemerkung:"
              :value="interneBemerkungRowEdit.bemerkung"
              @input="bemerkungChanged($event)">
        </InputTextArea>
      </template>
  </BaseModal>

  </div>
</template>

<script>
import DEPOTPOSITIONENEDIT_TYPES from "@/store/depotpositionenedit/types";
import BROKERDATA_TYPES from '@/store/brokerData/types';
import { mapGetters } from 'vuex';
import BaseButton from '@/components/core/BaseButton.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn, DateColumn, CurrencyColumn, PercentageColumn, SlotColumn, ActionColumn, NumberColumn, SimpleAction, LinkAction, numberToSortable, MENU_ROWS_PER_PAGE} from "@/components/table2/table_util.js";
import { PhArrowsLeftRight, PhGauge, PhFile, PhFileText, PhTrash, PhInfo, PhNotePencil, PhArrowRight, PhArrowSquareOut } from 'phosphor-vue'
import BaseModal from "@/components/core/BaseModal.vue";
import BaseFilter from '@/components/core/BaseFilter.vue';
import BaseCollapsable from '@/components/core/BaseCollapsable.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import {sanitize} from '@/helpers/string-helper.js';
import { viewDocument } from '@/components/core/download/DownloadLink.vue';
import CORE_TYPES from '@/store/core/types';
import InputTextArea from '@/components/core/forms/InputTextArea.vue'
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import HandleTablePin from '@/components/core/HandleTablePin.vue';
import PaginatedTable from "@/components/table2/PaginatedTable.vue";
import Pill from '@/components/core/Pill.vue'

const TABLE_TITLE = 'Depotpositionen';

export default {
  name: 'DepotpositionenEdit',
  components: {
    BaseCollapsable, 
    BaseButton, 
    BaseModal,
    BaseFilter, 
    Table,
    GhostLoading,
    NoData,
    InputTextArea,
    OptionMenu,
    PageHeaderTitleNavigation,
    HandleTablePin,
    PaginatedTable,
    Pill,
  },
  mixins: [],
  props: {
  },
  data: function() {
    return {
      TABLE_TITLE,
      configFilter: {
        placeholderForDefSearchInput: 'ISIN, WKN **',
        defaultSearchInputKeys: ['inputisin'],
        filterType: 'wertpapierinfo',

        filterZurucksetzen: () => {
          this.$store.commit(DEPOTPOSITIONENEDIT_TYPES.MUTATIONS.LIST, {});
        }
      },
      interneBemerkungRowEdit: null,
      defValue: '',
      modalArg: {
        title: '',
        showConfirmButton: false,
        labelButtonConfirm: '',
        labelButtonCancel: '',
        body: '',
        onConfirm: () => {}
      },
      activeCriteria: {},
      loading: false,
      scrollLoading: false,
      tableSort: null,
      pageSize: 0,
      menuRowsPerPage: MENU_ROWS_PER_PAGE,
    }
  },
  mounted() {
    if ( !this.filter?.metadata ) {
      this.$store.dispatch(DEPOTPOSITIONENEDIT_TYPES.ACTIONS.FILTER);
    }

    this.$updateCurrentBreadcrumb({
      breadcrumb: 'Depotpositionen bearbeiten',
    });
  },
  beforeRouteLeave(to, from, next) {
    if (-1 === to.fullPath.indexOf('backAction=true')) {
      this.$addBreadcrumb({
        label: 'zurück zur Depotpositionen bearbeiten', 
        to,
        from,
      });
    }
    next();
  },
  computed: {
    ...mapGetters({
      filter: DEPOTPOSITIONENEDIT_TYPES.GETTERS.FILTER,
      tableData: DEPOTPOSITIONENEDIT_TYPES.GETTERS.TABLE_DATA,
      pageIndex: DEPOTPOSITIONENEDIT_TYPES.GETTERS.PAGE_INDEX,
      totalRows: DEPOTPOSITIONENEDIT_TYPES.GETTERS.TOTAL_ROWS,
      rowsOnPage: BROKERDATA_TYPES.GETTERS.GET_BROKER_PAGE_TABLE_SIZE,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
      isCustomer: CORE_TYPES.GETTERS.IS_CUSTOMER,
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
    }),
    rowsPerPage() {
      return this.pageSize || this.rowsOnPage || 25;
    },
    headerActions() {
      const { isCustomer, } = this;

      const actions = [];
      if(isCustomer) {
        actions.push(PageHeaderSimpleAction('ADD', 'Neue Depotposition'));
      }
      return actions;
    },
    defaultOptions() {
      return {CHECK_NUR_MIT_ANTEILEN: {value: true}}
    },
    rowsCount() {
      return this.totalRows || 0;
    },
    headers() {
      const headers = {
        lockedLeft: [
          TextColumn("kundennr", "Kundennr").makeAlwaysVisible(),
          TextColumn("positionsnr", "Int. Nr.").makeLink().makeAlwaysVisible().makeSortable(numberToSortable),
        ],
        center: [
          TextColumn("depotnr", "Depotnummer"),
          TextColumn("wertpapier", "Wertpapier"),
          NumberColumn("anteile", "Anteile", 6),
          CurrencyColumn("wert_euro", "Wert (€)").withSumFooter(),
          TextColumn("isin", "ISIN"),
          TextColumn("wkn", "WKN"),
          TextColumn("gesellschaft", "Gesellschaft"),
          TextColumn("bezug", "Bezug"),
          TextColumn("name", "Name"),
          TextColumn("vorname", "Vorname"),
          DateColumn("datum", "Datum"),
          TextColumn("depotname", "Depotname"),
          CurrencyColumn("gesamtwert", "Gesamtwert pro Depotname (€)"),
          PercentageColumn("anteil", "Anteil Depot (%)"),
          TextColumn("depotart", "Depotart"),
          TextColumn("depotZusatz", "Depot Zusatz"),
          TextColumn("bemerkung", "Bemerkung").makeHidden(),
        ],
        lockedRight: [
          ActionColumn("actions"),
        ]
      };
      if (this.pages[this.pageIndex]?.some(row => row.infoActions?.length)) {
        headers.center.splice(2, 0, SlotColumn('infoActions', 'Info'));
      }
      return headers;
    },
    pages() {
      const result = {};
      if ( !this.tableData || !Object.keys(this.tableData).length) {
         return result;
      }
      const entries = Object.entries(this.tableData);
      if (entries?.length) {
          entries.forEach(([key, page]) => {
              result[key] = this.buildPage(page);
          })
      }
      return result;
    },
    pageCount() {
      return Math.ceil(this.rowsCount / this.rowsPerPage);
    },
    exportConfig() {
      return {
        pdf: true, 
        xls: true, 
        name: 'Depotpositionen', 
        title: 'Depotpositionen',
        printMaklerLogo: '1',
        dispatch: this.exportAsFile,
      };
    },
  },
  methods: {
    async onScroll(onScrollComplete) {
        try {
            this.scrollLoading = true
            this.setPageIndex(this.pageIndex + 1);
            await this.loadPage()
        } finally {
            this.scrollLoading = false
            onScrollComplete()
        }
    },
    buildPage(page) {
      if ( !page) {
         return [];
      }
      const icons = {
        edit: PhArrowRight,
        delete: PhTrash,
        limit: PhGauge,
        courtage: PhFileText,
        transakt: PhArrowsLeftRight,
        transaktPdf: PhFile,
        transTzuT: PhArrowSquareOut,
        info: PhInfo,
        bemerkung: PhNotePencil,
      }
      function setAct(actions) {
        return actions.filter(f => f?.key).map(m => m.type ? LinkAction(m.key, icons[m.key], m.label, m.type)
                : SimpleAction(m.key, icons[m.key], m.label))
      }
      return page.map((row, index) => {
        
        const bordered = row.infoActions?.filter(act => act?.label === 'L')?.[0]?.bordered
          //   .map(act => ({ label: act.info, type: act.color || '', isLink: act.isLink})) ||  []
        return {
          ...row, 
          id: row.positionsnr + index,
          // info: row.infoActions?.filter(act => act?.info)
          //   .map(act => ({ label: act.info, type: act.color || '', isLink: act.isLink})) ||  [],
          actions: setAct(row.actions) 
        }
      });
    },
    sanitize(htmlString) {
        return sanitize(htmlString);
    },
    getParams(criteria) {
      const params = {};
      if (Array.isArray(criteria)) {
        criteria.forEach( c => {
          if ( c.value !== null ) {
            params[c.key] = c.filterNot ? false : c.value;
          }
        })
      } else {
        Object.keys(criteria).forEach( key => {
          if ( criteria[key] !== null ) {
            params[key] = criteria[key];
          }
        });
      }
      this.activeCriteria = params;
    },
    handleSearch(criteria) {
      this.setPageIndex(0);
      this.getParams(criteria);
      this.loadPage();
    },
    gotoDepotposition(row) {
      const base = this.$route.path.slice(0, this.$route.path.indexOf('depotpositionenedit'));
      this.navigateTo(`${base}depotpositionenedit/begleitscheinsteps/${row?.positionsnr}/POSITION`, row?.kundennr);
     
    },
    async toolAction(key) {
      switch (key) {
        case 'pdf': {
          let response = await this.$store.dispatch(DEPOTPOSITIONENEDIT_TYPES.ACTIONS.JOB, this.activeCriteria);
          viewDocument({
            data: response.pdf,
            filename: 'Depopositionen.pdf',
            contentType: 'application/pdf',
          }, true);
        }
          break;
        case 'xls':{
          let response = await this.$store.dispatch(DEPOTPOSITIONENEDIT_TYPES.ACTIONS.XLS, this.activeCriteria);
          viewDocument({
            data: response.xls,
            filename: 'Depopositionen.xls',
            contentType: 'application/xls',
            fileExtension: 'xls',
          }, true);
        }
          break;
        case 'neu':{
          this.$store.commit(DEPOTPOSITIONENEDIT_TYPES.MUTATIONS.BGS, {bgsnr: '0'});
          this.$store.dispatch(DEPOTPOSITIONENEDIT_TYPES.ACTIONS.GET_BGS, {bgsnr: '0'});
          const base = this.$route.path.substring(0, this.$route.path.indexOf('depotpositionenedit'));
          this.$router.push({ path: `${base}depotpositionenedit/begleitscheinsteps/0/POSITION` });
        }
          break;
      }
      
    },
    doAction(key, row) {
      switch (key) {
        case 'delete':
          this.$confirmModal({
            title: 'Depotposition löschen',
            message: `Wollen Sie die Depotposition "${row.wertpapier}" wirklich löschen?`,
            labelButtonCancel: 'Abbrechen',
            labelButtonConfirm: 'Löschen',
            showCancelButton: true,
          }).then(() => {
            const params = this.getSuchParams();
            params.delete_bgsnr = row.positionsnr;
            params.canDelete = 'ok';
            this.$store.dispatch(DEPOTPOSITIONENEDIT_TYPES.ACTIONS.DEL_BGS, params);
          });
          break;
        case 'limit':
          this.navigateTo(`/home/vermogensubersicht/wertpapiere/limits/${row.isin}/${row.positionsnr}`, row?.kundennr)
          break;
        case 'courtage':{
            this.$store.dispatch(DEPOTPOSITIONENEDIT_TYPES.ACTIONS.COURTAGE_BGS, {bgsnr: row.positionsnr})
            .then(data => {
              if (data.pdf) {
                viewDocument({
                  data: data.pdf,
                  filename: 'Depotposition.pdf',
                  contentType: 'application/pdf',
                }, false);
              }
            });
          }
          break;
        case 'transakt': {
            const bodyParams = JSON.stringify(JSON.parse(`{"isin": "${row.isin}"}`));
            const params = encodeURIComponent(bodyParams);
            const path = `/home/berichte/transaktionen?params=${params}&title=${encodeURIComponent(row.wertpapier)}&depoid=${row.depotnr}`;
            this.navigateTo(path. row?.kundennr);
          }
          break;
        case 'transTzuT': {
          const path = `/home/depotpositionenedit/transaktionen-uebertragen/${row.positionsnr}`;
          this.navigateTo(path. row?.kundennr);
        }
          break;
        case 'info': {
          this.showInfo(row);
          }
          break;
        case 'bemerkung': {
            this.editBemerkung(row);
          }
          break;
        case 'edit': 
          this.gotoDepotposition(row);
          break;
      }
    },
    saveBemerkung() {
      this.$store.dispatch(DEPOTPOSITIONENEDIT_TYPES.ACTIONS.PATCH_BEMERKUNG, {
          begleitscheinNr: this.interneBemerkungRowEdit.positionsnr,
          bemerkung: this.interneBemerkungRowEdit.bemerkung,
          pageIndex: this.pageIndex,
      });
    },
    bemerkungChanged(event) {
      this.interneBemerkungRowEdit.bemerkung = event;
    },
    editBemerkung(row) {
        this.interneBemerkungRowEdit = row;
        this.$refs.editBemerkung.open();
    },
    navigateTo(path, kundennr) {
      if (this.isCustomer) {
        this.$router.push({ path });
      } else if (kundennr) {
        this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, {customerId: kundennr, insurances: false, path });
      } 
    },
    openModalEditCustomerSearchResultTable() {
      this.$refs.tableResult.openColumnsConfig();
    },
    hinweisTitle() {
      if (this.filter.hinweise?.length === 1) {
        return '1 Hinweis'
      }
      if (this.filter.hinweise?.length > 1) {
        return this.filter.hinweise.length + ' Hinweise'
      }
      return 'Hinweise'
    },
    setPageIndex(index) {
      this.$store.commit(DEPOTPOSITIONENEDIT_TYPES.MUTATIONS.UPDATE_PAGE_INDEX, index);
    },
    getSuchParams() {
        const params = {...this.activeCriteria, pageSize: this.rowsPerPage};
        params.sortCol = !this.tableSort?.key ? '' : this.tableSort.key;
        params.sortOrder = !this.tableSort?.key ? '' : (this.tableSort.sortDirection || 'asc');
        return params;
    },
    async loadPage() {
      try {
        this.loading = true
        const params = this.getSuchParams();
        await this.$store.dispatch(DEPOTPOSITIONENEDIT_TYPES.ACTIONS.TABLE_DATA, params);
      } finally {
        this.loading = false
      }
    },
    onSetTableSort(tableSort) {
      this.tableSort = tableSort
      this.setPageIndex(0) 
      this.loadPage();
    },
    onPageSizeChange(pageSize) { // 
      this.pageSize = pageSize
      this.setPageIndex(0) 
      this.loadPage();
    },
    openInfo(elem, row) {
      if (elem?.label === 'T') {
        this.doAction('transakt', row);
      } else if (elem?.label === 'T>T') {
        this.doAction('transTzuT', row);
      } else if (elem?.label === 'L') {
        this.doAction('limit', row);
      }
    },
    async showInfo(row) {
      
      if (row.infoDepot == '?' || row.infoSparplan == '?') {
        const params = {
          bgsnr: row.positionsnr,
          plan: row.infoSparplan == '?',
          depot: row.infoDepot == '?',
        }
        const result = await this.$store.dispatch(DEPOTPOSITIONENEDIT_TYPES.ACTIONS.GETINFO, params);

        const rows = this.tableData[this.pageIndex];
        const idx = rows.findIndex(r => r.positionsnr == row.positionsnr);
        if (result.data.infoSparplan) {
          row.infoSparplan = result.data.infoSparplan;
          if (idx != -1) {
            rows[idx].infoSparplan = result.data.infoSparplan;
          }
        }
        if (result.data.infoDepot) {
          row.infoDepot = result.data.infoDepot;
          if (idx != -1) {
            rows[idx].infoDepot = result.data.infoDepot;
          }
        }
      }
      if (row.infoDepot || row.infoSparplan) {
        this.modalArg.showConfirmButton =false;
        this.modalArg.labelButtonConfirm = '';
        this.modalArg.labelButtonCancel = 'Schließen';
        this.modalArg.body = `${row.infoDepot || ''}${row.infoSparplan || ''}`;
        this.modalArg.onConfirm = () => {};
        this.$refs.modalRef.open();
      }
    },
    exportAsFile(fileType) {
      const params = this.getSuchParams();
      params.headers = this.$refs?.depotliste?.$refs?.sortableTable?.visibleHeadersFlat.filter(h => h.exportFn).map(h => h.label);
      params.keys = this.$refs?.depotliste?.$refs?.sortableTable?.visibleHeadersFlat.filter(h => h.exportFn).map(h => h.key);
      return this.$store.dispatch(DEPOTPOSITIONENEDIT_TYPES.ACTIONS.CREATE_XLS_OR_PDF, params);
    },
  },
  
}
</script>

<style scoped>
.text-color {
  color: var(--color-text);
}
.action-cell{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
}
.action-texts {
  display: flex;
  flex-direction: column;
  padding: 2px;
  color: var(--color-text);
  justify-content: left;
  font-size: 0.85rem;
}
.action-texts a, .action-cell a {
  color: var(--color-text);
  font-style: normal;
}
.action-texts a:hover {
  color: var(--color-text);
  /* text-decoration: none; */
}
.ampel-rot, .ampel-gelb, .ampel-gruen, .ampel-none{
 height: 16px;
 width: 16px;
 border-radius: 50%;
}
.ampel-rot{
 background-color: var(--color-danger);
}
.ampel-gelb{
 background-color: var(--color-warning);
}
.ampel-gruen{
 background-color: var(--color-success);
}
.ampel-none{
 background-color: transparent;
}
</style>