<template>
  <div class="ticket__container" :class="{ 'ticket--new': !chatId, }">
    <div class="box__container chat-email-header" v-if="emailId && !isSomeActionLoading">
      <div class="box__title">
        <span class="text-right" style="width:5rem">Betreff</span>
        {{ chatInfo ? chatInfo.thema : '' }}
      </div>
      <div v-if="chatInfo">
        <div class="flex gap-2">
          <div class="text-right w-4">Von</div>
          {{ chatInfo.senderName }}
        </div>
        <div v-if="chatInfo.receiverNames.length > 1 && chatInfo.receiverIds" class="flex gap-2">
          <div class="text-right w-4">An</div>
          <div v-for="(receiver, index) in chatInfo.receiverNames">
            <input type="checkbox" v-model="receiverEnabled[chatInfo.receiverIds[index]]" @change="isFormInvalid()">
            {{ receiver }}
          </div>
        </div>
        <div v-else class="flex gap-2">
          <div class="text-right w-4">An</div>
          {{ chatInfo.receiverName }}
        </div>

        <div v-if="(chatInfo.receiverNames && chatInfo.receiverNames.length) > 1 && (!chatInfo.receiverEnabled || chatInfo.receiverEnabled.length == 0)">
          Es muss mindestens ein Empfänger ausgewählt werden. Werden mehrere Empfänger gewählt, wird ein Chat pro Empfänger geöffnet.
        </div>       
      </div>

     </div>

    <div class="ticket__content">
      <TicketBewertung v-if="showBewertung && isIntern" :value="bewertung" centered
        :disabled="bewertungReadonly || isBewertungSaving" @input="setBewertung($event)" />

      <div v-if="isSomeActionLoading">
        <div class="box__container" v-if="!chatId">
          <span class="box__title">{{ title }}</span>
          <div class="mt-1">
            <GhostLoading type="input" />
            <GhostLoading type="input" />
          </div>
        </div>

        <GhostLoading type="custom" useBoxContainer class="ticket__main-content">
          <Block width="100%" height="100%" />
        </GhostLoading>
      </div>
      <DragnDropArea v-else-if="chatInfo" class="ticket__main-content"
        :tid="_generateTidFromString('ticket__main-content')"
        :disabled="chatInfo && chatInfo.readonly || beteiligterId == null || chatId == 0 || !chatInputEnabled"
        hoverText="Datei senden" @files="onUploadChat">
        <div class="ticket--chat-options__container box__container" v-if="!chatId && !embedded"
          :tid="_generateTidFromString('box__container_chat')">
          <span class="box__title">{{ title }}</span>
          <div class="ticket--chat-options">
            <ShortcutInputField
              label="Thema" 
              v-model="themaComputedWithDefault" 
              type="text"
              :maxLength="128"
              />
            <ComboBox 
              v-if="!showSimplifiedChatpartner"
              label="Chatpartner" 
              :value="chatPartner" 
              @change="changeChatPartner($event)" 
              :values="chatPartnerOptions"/>
            <ComboBox 
              :label="showSimplifiedChatpartner ? 'Chatpartner' : 'Bearbeiter'" 
              v-model="bearbeiter" 
              :values="bearbeiterOptions" 
              v-if="bearbeiterOptions && bearbeiterOptions.length && !isChatpartnerReceiverKunde"/>
              <ComboBox 
              label="FMA-Beschwerde" 
              v-model="fmaBeschwerde" 
              :values="fmaBeschwerdeOptions" 
              v-if="isFA && chatPartner && chatPartner.includes('LEVEL+INTERN-10000')"/>
              <InputToggleSwitch 
              v-if="isBypass || isIntern"
            label="Als Wichtig markieren"
            v-model="important"
            :inLineLabel="true"
          />
            </div>
        </div>
        <div v-else class="ticket--chat-messages__container box__container text-selectable">
          <ChatMessages class="ticket--chat-messages" :chatId="chatInfo.chatId" :channelId="chatInfo.channelId"
            :emailId="+emailId" :beteiligterId="beteiligterId" />
        </div>
        <div class="ticket--chat-footer__container box__container m-0">
          <ChatFooter
            ref="chatFooter"
            v-if="!closed && chatInputEnabled"
            class="ticket--chat-footer"
            @changeChannel="changeChannel"
            :chatId="chatInfo.chatId"
            :channelId="chatInfo.channelId"
            :emailId="+emailId"
            :beteiligterId="beteiligterId"
            :disabled="formInvalid"
            :thema="themaComputedWithDefault"
            :bezugId="bezugId"
            :initialText="textInput"
            :richTextInit="richText"
            :embedded="embedded"
            :fmaBeschwerde="fmaBeschwerde"
            :isInternalRemark="isInternalRemark"
            :important="important"
            @typing="calculateChatFooterSize(!$isVirtualKeyboardDetected)"
            @isEditorValid="setEditorValid"
          />
          <div v-if="!chatInputEnabled" class="ticket--chat-footer__container fc-alert fc-alert-danger text-centered text-small m-0">Um zu Chatten, muss Kontakt per App erlaubt werden.</div>
        </div>
      </DragnDropArea>
    </div>

    <BaseModal ref="bearbeiterModal" modalTitle="Bearbeiter anpassen" :showDefaultButtons="false"
      :actions="bearbeiterModalActions" @close="closeBearbeiterMenu" @action-SAVE="saveBearbeiterMenu"
      @action-BECOME_BEARBEITER="becomeBearbeiter">
      <template v-if="bearbeiterModalData">
        <ComboBox v-model="bearbeiterModalData.bearbeiter" :values="bearbeiterChangeOptions"
          @change="loadPhoneOptions($event)" />
        <ComboBox v-model="bearbeiterModalData.phone" :values="bearbeiterPhoneOptions" v-if="bearbeiterPhoneOptions" />
        <InputField v-model="bearbeiterModalData.phoneManual" type="text"
          v-if="bearbeiterPhoneOptions && bearbeiterModalData.phone == PHONE_EMPTY" />
      </template>
    </BaseModal>

    <BaseModal
      ref="changeStatusModal"
      modalTitle="Status anpassen"
      :showDefaultButtons="false"
      :actions="changeStatusModalActions"
      @close="closeChangeStatusModal"
      @action-SAVE="saveChangeStatusModal"
    >
      <template v-if="changeStatusModalData">
        <ComboBox v-model="changeStatusModalData.newTicketStatus" :values="changeStatusOptions" />
      </template>
    </BaseModal>
  </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types';
import COMMUNICATION_TYPES from '@/store/communication/types';
import WEBRTC_TYPES from '@/store/webrtc/types';
import LOG_TYPES from '@/store/log/types';
import { mapGetters } from 'vuex'
import BaseModal from '@/components/core/BaseModal.vue';
import InputField from '@/components/core/forms/InputField.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import ChatMessages from '@/components/communication/ChatMessages';
import ChatFooter from '@/components/communication/ChatFooter';
import DragnDropArea from "@/components/fileUpload/DragnDropArea.vue";
import axios from 'axios';
import qs from 'qs';
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';
import TicketBewertung from '@/views/communication/TicketBewertung.vue';
import { VIEW_ROLES } from '@/router/roles';
import { ROLES } from '@/router/roles';
import { maxLength } from "@/mixins/validator/rules"
import validator from '@/mixins/validator'
import EVENT_BUS, { CHAT_CLOSE, CHAT_EDIT_BEARBEITER, CHAT_CHANGE_STATUS, CHAT_SAVE_BEWERTUNG, } from '@/event-bus';

import { BaseModalSimpleAction } from '@/components/core/base-modal-actions/base-modal-actions-utils';
import ShortcutInputField from '@/components/core/forms/ShortcutInputField.vue';

import { getTicketStatusDescription } from './utils.js';

export default {
  components: {
    BaseModal,
    InputField,
    ComboBox,
    ChatMessages,
    ChatFooter,
    DragnDropArea,
    GhostLoading,
    Block,
    TicketBewertung,
    ShortcutInputField,
    InputToggleSwitch,
  },

  mixins: [InteractiveHelpCommonsMixin, validator],
  props: {
    loading: {
      type: Object,
      default: () => { },
    },
    embedded: {
      type: Boolean,
      default: false,
    },
    richText: {
      type: Boolean,
      default: false,
    },
    textInput: {
      type: String,
      default: '',
    },
    themaProps: {
      type: String,
      default: '',
    },
    thema: {
      type: String,
      default: null
    },
    isInternalRemark: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      apiAddress: process.env.VUE_APP_API,
      bezugId: null,
      fmaBeschwerde: null,
      chatPartner: null,
      bearbeiter: null,
      important: false,
      bearbeiterModalData: null,
      bearbeiterPhoneOptions: null,
      changeStatusModalData: null,
      PHONE_EMPTY: 'MANUELL-',
      // textInput: '',
      // richText: false,
      savingBewertung: false,
      localThema: null,
      receiverEnabled: {},
      editorValid: null,
    }
  },

  validators: {
    thema: maxLength(128, 'Thema ist zu lang.')
  },

  computed: {
    ...mapGetters({
      defaultReceiver: COMMUNICATION_TYPES.GETTERS.DEFAULT_RECEIVER,
      chatPartnerOptions: COMMUNICATION_TYPES.GETTERS.TICKET_CHATPARTNERS,
      bearbeiterOptions: COMMUNICATION_TYPES.GETTERS.TICKET_BEARBEITERS,
      ticketInfo: COMMUNICATION_TYPES.GETTERS.TICKET_INFO,
      bewertungInfo: COMMUNICATION_TYPES.GETTERS.BEWERTUNG_INFO,
      isCustomer: CORE_TYPES.GETTERS.IS_CUSTOMER,
      isBypass: CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
      chatInfos: WEBRTC_TYPES.GETTERS.CHAT_INFO,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      userId: CORE_TYPES.GETTERS.GET_USER_ID,
      isFA: CORE_TYPES.GETTERS.IS_FA,
    }),
    ticket() {
      return this.ticketInfo.ticket
    },
    chatId() {
      return this.ticketInfo.ticket?.chatId || this.$route.params.chatId || 0
    },
    channelId() {
      return this.ticketInfo.ticket?.channelId || this.$route.params.channelId || 0
    },
    emailId() {
      return this.ticketInfo.ticket?.emailId || this.$route.params.emailId || 0
    },
    bearbeiterKey() {
      return this.ticketInfo.bearbeiter
    },
    bearbeiterPhone() {
      return this.ticketInfo.bearbeiterPhone
    },
    bearbeiterChangeOptions() {
      return this.ticketInfo.bearbeiterOptions
    },
    changeStatusOptions() {
      const baseOptions = [
        { value: 'ANFRAGE', label: 'Anfrage' },
        { value: 'IN_BEARBEITUNG', label: 'In Bearbeitung' },
      ];
      const additionalOptions = this.isIntern
      ? [
        { value: 'BITTE_UM_BEARBEITUNG', label: 'Bitte um Bearbeitung' },
        { value: 'BITTE_UM_KENNTNISNAHME', label: 'Bitte um Kenntnisnahme' },
        { value: 'IN_BEARBEITUNG_GESELLSCHAFT', label: 'In Bearbeitung Gesellschaft' },
        { value: 'IN_BEARBEITUNG_IT', label: 'In Bearbeitung IT' },
      ]
      : [];
      const remainingOptions = [
        { value: 'BEARBEITET', label: 'Bearbeitet' },
        { value: 'GESCHLOSSEN', label: 'Geschlossen' },
      ];
      return [...baseOptions, ...additionalOptions, ...remainingOptions];
    },
    fmaBeschwerdeOptions() {
      return [
      {value: 'FREMD_BESCHWERDEN', label: 'Beschwerden, welche seitens externer Kooperationspartner eingegangen sind (z.B. Produktpartner, Depotbanken usw.).'},
      {value: 'BERATER_BESCHWERDEN', label: 'Beschwerden, welche seitens der Berater betreffend Beratung und Vertrieb eingelangt sind.'},
      {value: 'BACK_OFFICE_BESCHWERDEN', label: 'Beschwerden, welche betreffend der Abwicklung insbesondere Bearbeitung von Kundenaufträgen inklusive Weiterleitung und bestmögliche Ausführung eingelangt sind.'},
      {value: 'INFORMATIONEN_FUER_KUNDEN_BERICHTSWESEN', label: 'Beschwerden, bezüglich mangelhafter oder irreführender Informationen für Kunden (z.B. Produktinformation, Kundeneinstufung, Risikoaufklärung, Kosten, usw.) oder das Berichtswesen betreffend (Durchführungsbestätigung, Abrechnung, periodische Berichte, usw.).'},
      {value: 'SONSTIG_BESCHWERDEN', label: 'Beschwerden, welche nicht in die oben angeführten Kategorien zugeordnet werden können.'},
    ]
    },
    showBewertung() {
      return this.bewertungInfo.showBewertung
    },
    bewertungReadonly() {
      return this.bewertungInfo.bewertungReadonly
    },
    bewertung() {
      return this.bewertungInfo.bewertung
    },
    isSomeActionLoading() {
      return Object.values(this.loading).some(valueLoading => valueLoading)
    },
    bearbeiterModalActions() {
      return [
        BaseModalSimpleAction('SAVE', 'Speichern').withVisible(() => !!this.bearbeiterModalData),
        BaseModalSimpleAction('BECOME_BEARBEITER', 'Selbst zum Bearbeiter werden').withVisible(() => !!this.bearbeiterModalData),
      ];
    },
    changeStatusModalActions() {
      return [
        BaseModalSimpleAction('SAVE', 'Speichern'),
      ];
    },
    isIntern() {
      return this.hasRoles([VIEW_ROLES.VIEW_INTERN]);
    },
    chatInputEnabled() {
      // IS_TICKET_ERLAUBT checks data privacy as well as other permissions. Just like javaMSC
      // Brokers and Interns can send tickets even if the app contact is disabled.
      return this.hasRoles(ROLES.IS_TICKET_ERLAUBT) || this.hasRoles(VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS);
    },
    showSimplifiedChatpartner() {
      return this.hasRoles([[VIEW_ROLES.VIEW_CUSTOMER_ONLY]])
    },
    chatInfo() {
      return this.chatInfos.find(chatInfo => chatInfo.chatId == this.chatId && chatInfo.channelId == this.channelId && (!this.emailId || chatInfo.emailId == this.emailId))
    },
    beteiligterId() {
      if (this.chatId && this.chatInfo) {
        return this.chatInfo.beteiligterId || this.chatInfo.receiverId;
      }
      if (!this.bearbeiterOptions)
        return null;
      const chatPartner = this.chatPartnerOptions.find(option => option.value == this.chatPartner);
      return this.bearbeiterOptions.length ? this.bearbeiter : (chatPartner ? chatPartner.beteiligterId : null);
    },
    isChatpartnerReceiverKunde() {
      const chatPartner = this.chatPartnerOptions.find(option => option.value == this.chatPartner);
      return chatPartner?.isReceiverKunde
    },
    title() {
      let result = "Nachricht";
      if (this.chatId)
        result += " #" + this.chatId;
      result += " an ";
      return result;
    },
    customerName() {
      let result = '';
      if (this.chatInfo && this.chatInfo.receiverName)
        result += this.chatInfo.receiverName;
      else if (this.chatId == 0)
        result += this.defaultReceiver;
      return result;
    },
    closed() {
      return this.ticket?.status === 'GESCHLOSSEN';
    },
    isBewertungSaving() {
      this.$emit('savingBewertung', this.savingBewertung)
      return this.savingBewertung
    },
    isConfiguredPartnerAvailable() {
      return this.bearbeiterOptions?.map(option => option.value).includes(this.configuredChatpartner?.beteiligterId)
    },
    isRecieversEmpty() {
      return this.chatInfo && 
            this.chatInfo?.receiverNames && 
            this.chatInfo?.receiverNames.length > 1 && 
            (!this.receiverEnabled || 
            Object.values(this.receiverEnabled).filter((val) => val).length == 0);
    },
    isCommonInvalid() {
      return this.beteiligterId == null || 
            this.themaComputedWithDefault == null || 
            this.themaComputedWithDefault == '' || 
            this.isRecieversEmpty;
    },
    fileUploadInvalid() {
      return this.isCommonInvalid;
    },
    formInvalid() {
      return this.isCommonInvalid || !this.editorValid;
    },
    kundeChatPartnerLabel() {
      const chatPartner = this.chatPartnerOptions.find(option => option.isReceiverKunde);
      return chatPartner?.label
    },
    isViewAsBroker() {
      return this.hasRoles([VIEW_ROLES.VIEW_CUSTOMER_AS_BROKER]);
    },
    notKunde(){
      return !this.isKunde
    },
    themaComputedWithDefault: {
      get() {
        const isBroker = !this.isCustomer && !this.isIntern;
        // if there is no thema, include the customer name/ number in thema, but only if chatpartner is not customer
        // and do not set any initial thema if a broker creates a new ticket (no matter who receiver is MSC-29516)
        if (!isBroker && this.$route.path.includes("new-ticket") && this.thema === null && this.isViewAsBroker && !this.isChatpartnerReceiverKunde) {
          if (this.localThema === null) { // only set to default if nothing was entered so far
            return this.kundeChatPartnerLabel;
          }
        }
        // do not prefill thema for broker even if delivered in chatInfo (MSC-29516)
        if(!isBroker && !this.localThema && this.chatInfo && this.chatInfo.thema) {
          return this.chatInfo.thema
        }
        return this.localThema;
      },
      set(newThema) {
        this.localThema = newThema;
        this.isFormInvalid();
      }
    },
  },
  watch: {
    $route() {
      this.init();
    },
    bearbeiterOptions() {
      this.updateBearbeiter()
    },
    configuredChatpartner() {
      this.updateBearbeiter()
    },
    thema: {
      handler(newValue, oldValue) {
        this.localThema = newValue;
        this.isFormInvalid();
      },
      immediate: true
    },
    chatInfos: {
      handler(newValue) {
        this.receiverEnabled = {}
      },
      deep: true
    },
    receiverEnabled: {
      handler: function (newValue) {
        if(this.chatInfo) this.chatInfo.receiverEnabled = Object.keys(newValue).filter((key) => newValue[key])
      },
      deep: true
    },
    chatPartnerOptions: {
      // if the list of available chatPartners changes, select the first one, to make sure the combobox is not empty;
      // in case of brokers, the first option in the list is FK 
      handler(newValue) {
        if (this.chatPartner  == null && newValue) {
          this.chatPartner = newValue[0].value;
        }
      },
      deep: true
    }
  },
  /**
   * doc: https://vuejs.org/api/options-lifecycle.html#updated
   * WARNING: Do not mutate component state in the updated hook - this will likely lead to an infinite update loop!
   */
  updated() {
    this.$nextTick(this.calculateSizes);
  },
  mounted() {
    this.registerEventBus();
    this.init();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  destroyed() {
    this.unregisterEventBus();
  },
  methods: {
    setEditorValid(event) {
      this.editorValid = event;
      this.isFormInvalid()
      this.calculateChatFooterSize()
    },
    registerEventBus() {
      // Send message
      EVENT_BUS.$on(CHAT_CLOSE, () => {
        this.closeTicket()
      });

      // Upload Document
      EVENT_BUS.$on(CHAT_EDIT_BEARBEITER, () => {
        this.openBearbeiterMenu()
      });

      EVENT_BUS.$on(CHAT_CHANGE_STATUS, () => {
        this.openChangeStatusModal()
      });

      // Save bewertung
      EVENT_BUS.$on(CHAT_SAVE_BEWERTUNG, (bewertung) => {
        this.setBewertung(bewertung)
      });
    },
    unregisterEventBus() {
      EVENT_BUS.$off(CHAT_CLOSE);
      EVENT_BUS.$off(CHAT_EDIT_BEARBEITER);
      EVENT_BUS.$off(CHAT_CHANGE_STATUS);
      EVENT_BUS.$off(CHAT_SAVE_BEWERTUNG);
    },
    init() {
      if (window.visualViewport) {

        window.visualViewport.addEventListener('resize', this.onResize);
      } else {
        window.addEventListener('resize', this.onResize);
      }
      this.onResize()
      this.localThema = this.thema;
      this.validation.setTouched('themaComputedWithDefault')
    },
    isFormInvalid() {
      this.$emit('isFormInvalid', this.formInvalid);
      this.$emit('isUploadDisabled', this.fileUploadInvalid);

      return this.formInvalid;
    },
    updateBearbeiter() {
      if (this.hasRoles([[VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS]]) && !this.chatPartner) {
        const chatPartnerKunde = this.chatPartnerOptions.find(option => option.isReceiverKunde);

        if (chatPartnerKunde) {
          this.changeChatPartner(chatPartnerKunde.value)
        }
      }
      if (this.isConfiguredPartnerAvailable) {
        this.bearbeiter = this.configuredChatpartner.beteiligterId
      } else {
        const found = this.bearbeiterOptions?.find(option => option.isLevel)
        this.bearbeiter = found?.value || null
      }
    },
    changeChatPartner(chatPartner) {
      this.chatPartner = chatPartner;
      this.$store.dispatch(COMMUNICATION_TYPES.ACTIONS.LOAD_TICKET_COMBOBOXES, this.chatPartner);
    },
    calculateMainContentSize() {
      requestAnimationFrame(() => {
        const rootEl = this.$el;
        if (!rootEl) return;

        const ticketMainContentEl = rootEl.querySelector('.ticket__main-content');
        if (!ticketMainContentEl) return;

        const { offsetTop } = ticketMainContentEl
        const windowHeight = window.innerHeight;

        const ticketMainContentHeight = windowHeight - offsetTop
        rootEl.style.setProperty('--ticket--main-content--height', `${ticketMainContentHeight}px`);
      });
    },
    calculateChatFooterSize(scrollToBottom = false) {
      requestAnimationFrame(() => {
        const rootEl = this.$el;
        if (!rootEl) return;

        const ticketChatFooterContainerEl = rootEl.querySelector('.ticket--chat-footer__container');
        if (!ticketChatFooterContainerEl) return;
        rootEl.style.setProperty('--ticket--chat-footer--height', `${ticketChatFooterContainerEl?.clientHeight}px`);
        scrollToBottom && window.scrollTo(0, document.body.scrollHeight);
      });
    },
    calculateSizes() {
      this.calculateMainContentSize();
      this.calculateChatFooterSize();
    },
    onResize() {
      this.$nextTick(() => this.calculateSizes());
    },
    changeChannel({ chatId, channelId }) {
      this.$emit('changeChannel', { chatId, channelId })
    },
    setBewertung(bewertung) {
      this.savingBewertung = true;
      axios.post(this.apiAddress + '/ticketsnachrichten/setBewertung', qs.stringify({ chatId: this.chatId, note: bewertung }), { defaultSpinner: true })
        .then(() => {
          this.$store.commit(COMMUNICATION_TYPES.MUTATIONS.LOAD_BEWERTUNG_INFO_SUCCESS, {
            ...this.bewertungInfo,
            bewertung,
          })
        }).catch(error => {
          this.$store.dispatch(LOG_TYPES.ACTIONS.ERROR, { message: 'error in Ticket.setBewertung', error });
        })
        .finally(() => this.savingBewertung = false);
    },
    onUploadChat(files) {
      // may happen due to drag&drop
      if (files && files.length) {
        const file = files[0];
        this.$store.dispatch(WEBRTC_TYPES.ACTIONS.UPLOAD_CHAT, { chatId: this.chatId, channelId: this.channelId, file })
      }
    },
    openBearbeiterMenu() {
      this.bearbeiterModalData = {
        bearbeiter: this.bearbeiterKey,
        phone: this.bearbeiterPhone,
        phoneManual: '',
      };
      this.loadPhoneOptions(this.bearbeiterKey);
      this.$refs.bearbeiterModal.open();
    },
    openChangeStatusModal() {
      // broker/intern can not see the hiddenStatuses, they are all "IN_BEARBEITUNG"
      let ticketStatus;
      const hiddenStatuses = ["BITTE_UM_BEARBEITUNG", "BITTE_UM_KENNTNISNAHME", "IN_BEARBEITUNG_GESELLSCHAFT", "IN_BEARBEITUNG_IT"];
      if (hiddenStatuses.includes(this.ticket?.status)) {
        ticketStatus = "IN_BEARBEITUNG";
      } else {
        ticketStatus = this.ticket?.status;
      }
      this.changeStatusModalData = {
        newTicketStatus: ticketStatus,
      };
      this.$refs.changeStatusModal.open();
    },
    loadPhoneOptions(bearbeiterId) {
      axios.get(this.apiAddress + '/ticketsnachrichten/getPhoneNumbers', { params: { bearbeiterId }, defaultSpinner: true })
        .then(response => {
          this.bearbeiterPhoneOptions = response.data;
          if (this.bearbeiterModalData && !this.bearbeiterPhoneOptions.includes(this.bearbeiterModalData.phone)) {
            // currently selected phone number isn't among options
            this.bearbeiterModalData.phone = this.bearbeiterPhoneOptions[0].value;
          }
        });
    },
    becomeBearbeiter() {
      this.bearbeiterModalData = {};
      this.saveBearbeiterMenu();
    },
    saveBearbeiterMenu() {
      const params = {
        channelId: this.channelId,
        bearbeiterId: this.bearbeiterModalData.bearbeiter,
        telephoneId: this.bearbeiterModalData.phone,
        telephoneManual: this.bearbeiterModalData.phoneManual,
      }
      axios.post(this.apiAddress + '/ticketsnachrichten/setTicketBearbeiter', params, { defaultSpinner: true })
        .then(() => {
          this.$emit('loadTicketData')
        });
      this.closeBearbeiterMenu();
    },
    closeBearbeiterMenu() {
      this.$refs.bearbeiterModal.close();
      this.bearbeiterModalData = null;
    },
    saveChangeStatusModal() {
      const params = {
        channelId: this.channelId,
        newTicketStatus: this.changeStatusModalData.newTicketStatus,  
      }
      //console.log("saveChangeStatusModal:", params);
      axios.post(this.apiAddress + '/ticketsnachrichten/setTicketStatus', params, {defaultSpinner: true})
      .then(() => {
        this.$emit('loadTicketData')
      });
      this.closeChangeStatusModal();
    },
    closeChangeStatusModal() {
      this.$refs.changeStatusModal.close();
      this.changeStatusModalData = null;
    },
    async closeTicket() {
      try {
        await axios.post(this.apiAddress + '/ticketsnachrichten/closeTicket', qs.stringify({ chatId: this.chatId }), { defaultSpinner: true });
        this.$router.push({ name: 'chats' })
      } catch (error) {
        this.$store.dispatch(LOG_TYPES.ACTIONS.ERROR, { message: 'error in Ticket.closeTicket', error });
      }
    },
  },
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.ticket__main-content {
  margin-bottom: 16px;
  height: calc(var(--ticket--main-content--height) - 16px);
  min-height: 442px;

  .ticket--chat-messages__container {
    margin-bottom: 1rem;
    height: calc(100% - var(--ticket--chat-footer--height) - 1rem);
    min-height: 120px;
  }
}

.ticket__embedded .ticket__main-content {
  margin-bottom: 0px;
  height: calc(var(--ticket--main-content--height) - 24px);
}

.ticket--new {
  .ticket__main-content {
    margin-bottom: 1rem;
    height: auto;
    min-height: initial;
  }
}

.flex {
  display: flex;
}

.gap-2 {
  gap: 2rem;
}

@media (max-width: 767px) {
  .ticket__main-content {
    margin-bottom: 0;
    position: relative;
    height: calc(var(--ticket--main-content--height) - 16px); // minus base-modal padding
    min-height: 300px;

    .ticket--chat-messages__container {
      margin-bottom: 0;
      padding-bottom: var(--ticket--chat-footer--height);
      height: 100%;
    }

    .ticket--chat-footer__container {
      border-radius: 0 0 8px 8px;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
    }
  }

  .ticket--new {
    .ticket__main-content {
      height: auto;
      min-height: initial;

      .ticket--chat-footer__container {
        border-radius: 8px;
        position: initial;
      }
    }
  }
}
</style>

<!-- GLOBAL STYLE -->
<style>
.ticket__main-content.ghost-loading__container>.ghost-loading {
  height: 100%;
}

.ticket--new .ticket__main-content.ghost-loading__container>.ghost-loading {
  height: 180px;
}

.ticket__container .ProseMirror p {
  padding: 0;
  margin: 0;
}

.ticket__container .ProseMirror.html-editor__text,
.ticket__container .html-editor__preview {
  height: auto;
  max-height: initial;
  min-height: initial;
}

.ticket__container .html-editor__preview {
  overflow: auto;
}

.subj {
  width: 5rem;
}

.chat-email-header {
  overflow-y: auto;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
}

.ticket__embedded .html-editor__content {
  margin-top: 0 !important;
}

.ticket__embedded .ticket__container .html-editor__menu {
  display: none;
}

.w-4 {
  width: 1rem;
}

@media (max-width: 767px),
(max-height: 512px) {
  .ticket__container .html-editor__menu {
    display: none;
  }

  .ticket__container .html-editor__content {
    margin: 0 !important;
  }

  .ticket__container .ProseMirror.html-editor__text,
  .ticket__container .html-editor__preview {
    max-height: 100px
  }
}
</style>
