<template>
  <div class="calendar__container">
    <div class="calendar__wrap">
      <CalendarNavigation v-if="isSmallScreen" :key="isSmallScreen" hideMenu :type="type" :title="title" :titleShortening="titleShortening" :disabled="!compgetWeekCalendarDataReady" 
        @current="handleCurrent" @prev="handleLeft" @next="handleRight" @addTask="newTask" @addAppointment="newAppointment" />
      <CalendarNavigation v-else :key="isSmallScreen" :type="type" :title="title" :titleShortening="titleShortening" :disabled="!compgetWeekCalendarDataReady" 
        @current="handleCurrent" @prev="handleLeft" @next="handleRight" @addTask="newTask" @addAppointment="newAppointment">
        <template #calendarDatePicker>
          <CalendarDatePicker 
            v-model="selectedDate" 
            :asCalendar="false"
            :disabled="!compgetWeekCalendarDataReady" 
            :monthPicker="isActive('month')"
            :highlightWeek="isActive('arbeitswoche') ? 'work-week' : isActive('week')"
            @change="openDate" 
          />
        </template>
      </CalendarNavigation>

      <div ref="calendarMainEl" class="calendar__main">
        <div class="calendar__sidebar">
          <InputField 
              label="Suche" 
              v-model="searchTerm" 
              type="text"
              @searchAction="loadBySearch()" 
              />
              <hr>
          <div class="box__container calendar__sidebar--date-picker">
            <CalendarDatePicker 
              v-model="selectedDate" 
              :disabled="!compgetWeekCalendarDataReady" 
              :monthPicker="isActive('month')"
              :highlightWeek="isActive('arbeitswoche') ? 'work-week' : isActive('week')"
              @change="openDate" 
            />
          </div>
        </div>
        <div class="calendar__content">
          <div class="box__container m-0">
            <template v-if="isActive('day') || isSmallScreen">
              <Day :searchTerm="searchTerm" :next="privateNext" :prev="privatePrev" :setNextPrev="setNextPrev()" :doRenew="privateDoRenew" :current="privateCurrent" 
                @title="setTitle" @titleShortening="setTitleShortening" @editEvent="editEvent" @addNew="addNew" @dateChanged="setDate($event)" />
            </template>
            <template v-else-if="isActive('week')">
              <WeekCalendar :searchTerm="searchTerm" :date="selectedDate" :next="privateNext" :prev="privatePrev" :setNextPrev="setNextPrev()" :doRenew="privateDoRenew" :current="privateCurrent" 
                @title="setTitle" @titleShortening="setTitleShortening" @editEvent="editEvent" @addNew="addNew" @dateChanged="setDate($event); makeDayElements($event);" />
            </template>
            <template v-else-if="isActive('arbeitswoche')">
              <WeekCalendar :searchTerm="searchTerm" :date="selectedDate" :next="privateNext" :prev="privatePrev" :setNextPrev="setNextPrev()" :doRenew="privateDoRenew" :current="privateCurrent" 
              @title="setTitle" @titleShortening="setTitleShortening" @editEvent="editEvent" @addNew="addNew" @dateChanged="setDate($event); makeDayElements($event);" />
            </template>
            <template v-else-if="isActive('month')">
              <MonthCalendar :searchTerm="searchTerm" :date="selectedDate" :next="privateNext" :prev="privatePrev" :setNextPrev="setNextPrev()" :doRenew="privateDoRenew" :current="privateCurrent" 
                @title="setTitle" @titleShortening="setTitleShortening" @editEvent="editEvent" @addNew="addNew" @dateChanged="setDate($event); makeDayElements($event);" />
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Day from '@/components/calendar/Day.vue';
import MonthCalendar from '@/components/calendar/MonthCalendar.vue';
import WeekCalendar from '@/components/calendar/WeekCalendar.vue';
import CalendarMixins from "@/components/calendar/CalendarMixins";
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import CalendarNavigation from '@/components/calendar/CalendarNavigation.vue';
import CalendarDatePicker from '@/components/calendar/CalendarDatePicker.vue';
import { mapGetters } from "vuex";
import CALENDAR_TYPES from "@/store/calendar/types";
import dayjs from "dayjs";
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import screenSizeMixin from '@/mixins/screen-size/index.js';
import InputField from '@/components/core/forms/InputField.vue';

export default {
  mixins: [CalendarMixins, InteractiveHelpCommonsMixin, screenSizeMixin],
  data() {
    return {
      privateNext: false,
      privatePrev: false,
      isActivity: false,
      privateDoRenew: false,
      privateCurrent: false,
      title: 'Kalenderwoche',
      titleShortening: 'KW',
      selectedDate: new Date(),
      searchTerm: '',
      currentMode: '',
      windowSize: null,
      firstDay: null,
      lastDay: null,
      lastEvent: null,
    }
  },
  mounted() {
    this.$store.dispatch(CALENDAR_TYPES.ACTIONS.GET_APPOINTMENT_SETUP);

    this.$nextTick(() => {
      this.screenSizeData.throttleLimit = 200;
      this.handleResize(this.screenSizeData.initialSize);
      this.$addResizeListener((event, currentSize) => this.handleResize(currentSize));
    });
  },
  computed: {
    ...mapGetters({
        getSelectedEvent: CALENDAR_TYPES.GETTERS.GET_SELECTED_APPOINTMENT_DATA,
        getDayElements: CALENDAR_TYPES.GETTERS.GET_DAY_ELEMENTS,
        compgetWeekCalendarDataReady: CALENDAR_TYPES.GETTERS.GET_CALENDAR_DATA_READY,
    }),
    isSmallScreen() {
      return this.windowSize === 'sm' || this.windowSize === 'xs';
    },
    type() {
      return this.$route?.meta?.type;
    },
    next() {
      return this.privateNext;
    },
    prev() {
      return this.privatePrev;
    },
    current() {
      return this.privateCurrent;
    },
    doRenew() {
      return this.privateDoRenew;
    },
    getSelectedDate() {
      return this.isActive('day') ? this.getDayElements && this.getDayElements.day && this.getDayElements?.day?.day : null;
    },
  },
  methods: {
    handleResize(size) {
      if(size){
        this.windowSize = size;
      }
    },
    getCurrentMode(bereich) {
      if (!bereich){
        bereich = this.getSelectedEvent?.appointment?.bereich;
      }
      this.currentMode = '';
      if (bereich) {
        this.currentMode = bereich === 'VersVertrag' && 'Aufgabe' || (bereich === 'Termin' || bereich === 'Aufgabe' ? bereich : 'Aufgabe')
      }
    },
    editEvent(event) {
      this.editAppointment(event);
    },
    addNew(id) {
      this.newAppointment();
    },
    setTitle(value) {
      this.title = value;
    },
    setTitleShortening(value) {
      this.titleShortening = value;
    },
    renewView() {
      this.privateDoRenew = true;
      this.privateDoRenew = true;
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.RENEW_CALENDAR_DATA);
    },
    newTask() {
      this.isActivity = false;
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_NEW_AUFGABE);
      this.getCurrentMode('Aufgabe');
      this.openAppointment();
    },
    newAppointment() {
      this.isActivity = false;
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_NEW_TERMIN);
      this.getCurrentMode('Termin');
      this.openAppointment();
    },
    editAppointment(event) {
      this.isActivity = false;
      this.getCurrentMode(event?.bereich);
      this.openAppointment();
    },
    openAppointment() {
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_CONFIG, {
        title: `${this.currentMode} bearbeiten`,
        mode: this.currentMode,
        selectedDate: this.getSelectedDate,

        back: this.$router.currentRoute.fullPath,
      })
      this.$router.push({ path: `/communication/appointment` });
    },
    handleLeft() {
      this.privatePrev = true;
    },
    handleRight() {
      this.privateNext = true;
    },
    handleCurrent() {
      this.privateCurrent = true;
    },
    isActive(type) {
      return this.type === type;
    },
    setNextPrev(e) {
      this.$nextTick( () => {
        this.privateNext = false;
        this.privatePrev = false;
        this.privateDoRenew = false;
        this.privateCurrent = false;
      });
    },
    makeDayElements(thisday) {
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_DAY_ELEMENTS, {
        day: {
          day: dayjs(thisday),
          isSelected: true,
          inMonth: dayjs(thisday).month() === dayjs().month(),
          isToday: dayjs(thisday).date() === dayjs().date(),
          label: dayjs(thisday).format("D.MM")
        },
        dayIndex: dayjs(thisday).weekday()
      });
    },
    openDate(event) {
      var firstdayFromEvent = dayjs(this.selectedDate).weekday(0).get('date');
      var lastdayFromEvent = dayjs(this.selectedDate).weekday(6).get('date');

      if (this.type == 'day' && firstdayFromEvent != this.firstDay) {
        this.firstDay = firstdayFromEvent;
        this.lastDay = lastdayFromEvent;
        this.loadDay(this.selectedDate);
      }
      
      this.makeDayElements(this.selectedDate)
    },
    loadBySearch() {
      this.$router.push({path: '/communication/postfach/termine', query: {q: this.searchTerm}})
    },
    loadDay(event) {
      const thisday = dayjs(event);

      this.getWeekCalendarData(thisday, this.searchTerm);
    },    
    setDate(date) {
      this.selectedDate = date;
    },
  },
  beforeRouteLeave (to, from, next) {
    this.$addBreadcrumb({
      label: `zurück zur Terminkalender`, 
      to,
      from,
    });
    next()
  },
  components:{
    MonthCalendar,
    WeekCalendar,
    Day,
    OptionMenu,
    CalendarNavigation,
    CalendarDatePicker,
    InputField,
  }
}
</script>

<style lang="scss" scope>

.calendar__main {
  display: flex;
  flex-flow: row;

  .calendar__sidebar {
    flex: 0 0 272px;
    margin: 0 16px 0 0;
  }

  .calendar__content {
    flex: 1 1 auto;
    max-width: calc(100% - 288px); // 100 + sidebar width (basis + margin-right)
  }
}

@media only screen and (max-width: 1152px) {
  .calendar__main {

    .calendar__sidebar {
      display: none;
    }

    .calendar__content {
      max-width: 100%;
    }

  }
}

</style>
