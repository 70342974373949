var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box__container-wrap" }, [
    _c(
      "div",
      { staticClass: "box__container-rows" },
      [
        _vm.isDisabled
          ? _c(
              "div",
              { staticClass: "postfach-widget-chats__disabled" },
              [_c("UndrawChatting")],
              1
            )
          : _vm.rows.length
          ? [
              !_vm.$isSmallScreen
                ? _c("OverviewTable", {
                    attrs: { records: _vm.rows },
                    on: {
                      title: _vm.openCustomerNewTab,
                      subject: _vm.clickBetreff
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function(row) {
                            return [
                              _c(
                                "div",
                                { staticClass: "ellipsis" },
                                [
                                  row.hasAttachment
                                    ? _c("ph-paperclip", {
                                        staticClass: "mr-1",
                                        staticStyle: {
                                          "vertical-align": "bottom"
                                        },
                                        attrs: { size: 16 }
                                      })
                                    : _vm._e(),
                                  _c("span", [_vm._v(_vm._s(row.title))]),
                                  row.important
                                    ? [
                                        _c("span", { staticClass: "mx-2" }, [
                                          _vm._v(" | ")
                                        ]),
                                        _c("ph-warning-circle", {
                                          staticClass: "color-warning",
                                          attrs: { size: 16 }
                                        })
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2408926672
                    )
                  })
                : _c("Table", {
                    attrs: {
                      headers: _vm.headers,
                      rows: _vm.rows,
                      rowId: "channelId",
                      mobileConfig: {
                        title: "title",
                        headers: ["subject", "date"]
                      }
                    },
                    on: { clickRow: _vm.clickBetreff },
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function(row) {
                          return [
                            _c(
                              "div",
                              { staticClass: "ellipsis" },
                              [
                                row.hasAttachment
                                  ? _c("ph-paperclip", {
                                      staticClass: "mr-1",
                                      staticStyle: {
                                        "vertical-align": "bottom"
                                      },
                                      attrs: { size: 16 }
                                    })
                                  : _vm._e(),
                                _c("span", [_vm._v(_vm._s(row.title))]),
                                row.important
                                  ? [
                                      _c("span", { staticClass: "mx-2" }, [
                                        _vm._v(" | ")
                                      ]),
                                      _c("ph-warning-circle", {
                                        staticClass: "color-warning",
                                        attrs: { size: 16 }
                                      })
                                    ]
                                  : _vm._e()
                              ],
                              2
                            )
                          ]
                        }
                      }
                    ])
                  })
            ]
          : _vm.loading
          ? _c("GhostLoading", {
              attrs: { type: "table", config: { table: { rows: 5 } } }
            })
          : _c("NoData", { attrs: { noIcon: _vm.$isSmallScreen } })
      ],
      2
    ),
    !_vm.loading
      ? _c(
          "div",
          { staticClass: "bottom-row text-right mt-3" },
          [
            _c(
              "router-link",
              { attrs: { to: "/communication/postfach/" + _vm.routeStep } },
              [
                _vm.isDisabled
                  ? [_vm._v("zu den Nachrichten...")]
                  : [_vm._v("weitere...")]
              ],
              2
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }