<template>
  <div>
    <div class="box__container">
      <ComboBox
        label="Zugehörige Sparte (kann nachträglich nicht mehr geändert werden!)"
        :isComponentHalfSize="true"
        v-model="form.sparteId"
        :values="sparte"
        :validateUntouched="isNewSchaden"
        v-show="isNewSchaden"
        @change="dataChanged()"
      />
      <InputField
        label="Schadensnummer"
        :isComponentHalfSize="true"
        v-model="form.schadennr"
        @input="dataChanged()"
      />

      <InputField
        label="Sachbearbeiter Versicherungsgesellschaft sofern vorhanden"
        :isComponentHalfSize="true"
        v-model="form.sachbearbvu"
        @change="dataChanged()"
      />

      <InputField
        label="Sachbearbeiter E-Mail sofern vorhanden"
        :isComponentHalfSize="true"
        v-model="form.sachbearbeiterEmail"
        @change="dataChanged()"
      />

    <InputField
      label="Sachbearbeiter Telefonnumer"
      :isComponentHalfSize="true"
      v-model="form.sachbearbeiterTelefonnummer"
      @change="dataChanged()"
    />    

    <DatePickerField
      label="Wann wurde der Schaden verursacht?"
      :isComponentHalfSize="true"
      v-model="form.ereignisdatum"
      @change="dataChanged()"
    />

      <TimePicker
        label="Um wieviel Uhr?"
        :isComponentHalfSize="true"
        v-model="form.ereignisUhrzeit"
        @change="dataChanged()"
      />

      <InputField
        label="Ursache (Höchstwert 100 Zeichen)"
        :isComponentHalfSize="true"
        v-model="form.schadensursacheText"
        @change="dataChanged()"
      />

      <ComboBox
        label="Verschulden"
        :isComponentHalfSize="true"
        v-model="form.verschulden"
        :values="verschulden"
        :firstEmpty="true"
        @change="dataChanged()"
      />

      <InputTextArea label="Schadensablauf" :isComponentHalfSize="true" v-model="form.schadenKommentar" @change="dataChanged()" ></InputTextArea>
    </div>
    <div class="box__container">
      <InputToggleSwitch
        label="Schadenort"
        inLineLabel
        v-model="form.displaySchadenort"
        @change="dataChanged()"
      />
      <div v-if="form.displaySchadenort">
        <FormLabel>Schadensort</FormLabel>

        <InputField label="Straße, Hausnummer" :isComponentHalfSize="true" v-model="form.adresseStrasse" @change="dataChanged()" />
        
        <InputZIP
          label="PLZ"
          :isComponentHalfSize="true"
          v-model="form.adressePlz"
          @change="dataChanged()"
          @city="($event) => {
            form.adresseOrt = $event;
            dataChanged();
          }"/>

        <InputField label="Ort" :isComponentHalfSize="true" v-model="form.adresseOrt" @change="dataChanged()" />

        <ComboBox
          label="Land"
          :isComponentHalfSize="true"
          v-model="form.adresseLand" 
          :values="configData.lands"
          :firstEmpty="true"
          @change="dataChanged()"
        />

      </div>

    </div>
    <div class="box__container">
      <DatePickerField
        label="Meldedatum"
        :isComponentHalfSize="true"
        v-model="form.meldedatum"
        @change="dataChanged()"
      />
      
      <DatePickerField
        label="Datum erledigt"
        :isComponentHalfSize="true"
        v-model="form.erledigtDatum"
        :validateUntouched="true"
        @change="dataChanged()"
      />

      <ComboBox
        label="Bearbeitungsstand"
        :isComponentHalfSize="true"
        v-model="form.bearbstand"
        :values="bearbeitungsStandValues"
        @change="dataChanged()"
      />

      <InputField
        label="Schadenshöhe (ca.)"
        type="number"
        :isComponentHalfSize="true"
        v-model="form.schadenshoehe"
        @change="dataChanged()"
      />

      <InputTextArea label="Schadensreserve (Gesellschaft)" :isComponentHalfSize="true" v-model="form.schadensReserve" @change="dataChanged()" ></InputTextArea>

      <ComboBox
        label="Währung"
        :isComponentHalfSize="true"
        v-model="form.waehrung"
        :values="configData.wahrungen"
        :firstEmpty="true"
        @change="dataChanged()"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SCHADENSMELDUNG_TYPES from "@/store/schadensmeldung/types";
import VERSICHERUNG_TYPES from "@/store/versicherungen/types";

import validator from "@/mixins/validator";
import { required, email, maxLength, } from "@/mixins/validator/rules";

import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';
import TimePicker from '@/components/core/forms/TimePicker.vue';
import InputField from '@/components/core/forms/InputField.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import FormLabel from '@/components/core/forms/FormLabel.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import InputZIP from '@/components/core/forms/InputZIP.vue'
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";

import mainFormMixin from '../main-form-mixin';
import CORE_TYPES from "@/store/core/types";
import dayjs from 'dayjs';

const WAEHRUNG_DEFAULT = "EUR";


export default {
  mixins: [mainFormMixin, validator],
  validators: {},
  props: {
    vertragId: {
      type: [Number, String],
    },
    schadenId: {
      type: [Number, String],
    },
     schadenInfos: {
      type: Array,
      default: () => [],
    },
    ortDatum:{
      type:String,
    },
    ansprechpartner:{
      type:String,
    }
   
  },
  data() {
    return {
      form: {
        sparte: '',
        sparteId: null,
        schadennr: "",
        ereignisdatum: "",
        ereignisUhrzeit: "",
        meldedatum: "",
        schadensartId: null,
        erledigtDatum: "",
        bearbstand: "",
        schadensursacheText:"",
        verschulden:"",
        schadenshoehe: null,
        schadensReserve: null,
        waehrung: WAEHRUNG_DEFAULT,
        sachbearbvu: "",
        sachbearbeiterEmail: "",
        adresseStrasse: "",
        adressePlz: "",
        adresseOrt: "",
        adresseLand: "",
        schadenKommentar:"",
        displaySchadenort: this.schadenortHasData,
      },
    };
  },
  computed: {
    ...mapGetters({
      versicherungenDetails: VERSICHERUNG_TYPES.GETTERS.INSURANCE_DETAILS,  
      schadensmeldung: SCHADENSMELDUNG_TYPES.GETTERS.SCHADENSMELDUNG,
      configData: SCHADENSMELDUNG_TYPES.GETTERS.CONFIG_DATA,
      isFA: CORE_TYPES.GETTERS.IS_FA,
      isFK: CORE_TYPES.GETTERS.IS_FK,
      schadenBearbstandValues: SCHADENSMELDUNG_TYPES.GETTERS.SCHADEN_BEARBSTAND_VALUES,  
    }),
     detailsData() {
      return this.versicherungenDetails || null;
    },
     sparte() {
      return this.configData?.sparten || [];
    },
     verschulden() {
      return this.configData?.wessenVershulden || [];
    },
    isNewSchaden() {
      return this.$route.params?.schadenId === "neuesSchaden";
    },
    schadenortHasData() {
      return !!(this.form.adresseStrasse || this.form.adressePlz || this.form.adresseOrt || this.form.adresseLand || this.form.schadenKommentar);
    },
    bearbeitungsStandValues() {
      let values = this.schadenBearbstandValues;

      // some enum values were disabled for new schaden
      let filteredValues = values?.filter(v => !['04', '05'].some(x => x ===v.value));

      // but older records might have the old values
      let formWasPersistedWithDisabledValues = ['04', '05'].some(x => x === this.form?.bearbstand);

      
      return this.isNewSchaden ? filteredValues : formWasPersistedWithDisabledValues ? values : filteredValues;
    }   
    
  },
  watch: {
    schadensmeldung: {
      handler(newValue) {
        this.setForm(newValue);
      },
      immediate: true,
    },
    configData: {
      handler() {
        if(this.configData?.lands){
          this.configureLandsCombobox();
        }
        if(!this.form.sparteId && this.configData?.sparten?.length === 1) {
          this.form.sparteId = this.configData?.sparten[0]?.value;
        }
      },
      immediate: true
    },
    sparte: {
      handler(newValue) {
        this.form.sparteId = newValue?.length == 1 ? newValue[0]?.value : null
      },
      immediate: true,      
     
    }
  },
  methods: {
    setForm(schadensmeldung) {
      
      if(!schadensmeldung || !Object.keys(schadensmeldung).length) {
        return ;
      }

      Object.keys(schadensmeldung).forEach(key => this.$set(this.form, key, schadensmeldung[key]));

      this.$set(this.form, 'ereignisdatum', this.parseDate(schadensmeldung.ereignisdatum));
      this.$set(this.form, 'meldedatum', this.parseDate(schadensmeldung.meldedatum));
      this.$set(this.form, 'erledigtDatum', this.parseDate(schadensmeldung.erledigtDatum));
      this.$set(this.form, 'waehrung', schadensmeldung?.waehrung || WAEHRUNG_DEFAULT);
      this.$set(this.form, 'displaySchadenort', this.schadenortHasData);
    },
    sendInfo(){
      this.$emit('sendInfo', this.ortDatum);
    },
    configureLandsCombobox() {
      let land = "";
      if(this.isNewSchaden){
        if(this.isFA){
          land = 'Österreich'
        } else if(this.isFK){
          land = 'Deutschland'
        }
        this.form.adresseLand = land;
      }
      return land;
    },
    toggleSchadenort() {
      this.form.displaySchadenort = !this.form.displaySchadenort;
    },
    async retrieveSchadensBearbStandValues() {
        await this.$store.dispatch(SCHADENSMELDUNG_TYPES.ACTIONS.RETRIEVE_SCHADEN_BEARBSTAND_VALUES, {schadenId: this.schadenId === 'neuesSchaden' ? null : this.schadenId});
      },
  },
  async mounted() {
    if(Object.keys(this.schadensmeldung)?.length) {
      this.setForm(this.schadensmeldung);
    } else {
      this.setForm({});
    }
    this.form.sparte = '';
    this.form.sparteId = this.sparte?.length === 1 ? this.sparte[0]?.value : null;
    if(!this.form.meldedatum && this.isNewSchaden) {
      this.form.meldedatum = dayjs().format('DD.MM.YYYY');
    }
    const validator = {
      form: {
        sachbearbeiterEmail: [email(null,{emptyAllowed: true})],
      }
    }
    if(this.isNewSchaden) {
      validator.form['sparteId'] = [required('Zugehörige Sparte ist erforderlich!')];
      this.dataChanged();
    }
    validator.form['schadensursacheText'] = [maxLength(100, "Der Maximalwert beträgt 100.", { forceTouch: true })];
    validator.form['schadensReserve'] = [maxLength(4000, "Der Maximalwert beträgt 4000.", { forceTouch: true })];
    
    this.$configureValidators(validator);

    await this.retrieveSchadensBearbStandValues()
  },
 
  components: {
    DatePickerField,
    TimePicker,
    InputField,
    ComboBox,
    InputTextArea,
    FormLabel,
    InputZIP,
    InputToggleSwitch,
   
  },
}
</script>
