<template>
  <div class="box__container-wrap">
    <span v-if="showTitle" class="box__title">Nachrichten</span>

    <div class="box__container-rows">
      <div v-if="isDisabled" class="tickets-nachrichten__disabled">
        <UndrawChatting />
      </div>
      <Table v-else-if="!loadingTicketsNachrichten && !isDisabled && rows.length > 0"
          tableId="cf1a65f8-3933-4d68-b299-a23edeb56b2a"
          :headers="headers"
          :rows="rows"
          :rowsPerPage="5"
          hidePagination
          :mobileConfig="mobileConfig"
          @click-thema="clickThema"
          @click-nameBeteiligter="openCustomerNewTab"
      >
        <template v-slot:status="props">
          <span class="black"><Pill :label="props.status.label" :type="props.status.type"></Pill></span>
        </template>
      </Table>
      <GhostLoading v-else-if="loadingTicketsNachrichten" type="table" :config="{ table: { rows: 5, }, }" />
      <NoData v-else-if="rows.length == 0" noIcon />
    </div>

    <div v-if="!loadingTicketsNachrichten && !$isSmallScreen" class="bottom-row text-right mt-3">
      <router-link to="/communication/postfach/chats">
        <template v-if="!isDisabled">{{rows.length > 0 ? "weitere..." : "Übersicht anzeigen"}}</template>
        <template v-else>zu den Nachrichten...</template>
      </router-link>
    </div>
  </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types';
import COMMUNICATION_TYPES from '@/store/communication/types';
import { mapGetters } from 'vuex'
import Table from "@/components/table2/Table.vue";
import {TextColumn, DateColumn, DateTimeColumn, IconColumn, PillColumn, Icon} from "@/components/table2/table_util.js";
import { DatePickerUtils } from '@/components/core/forms/DatePicker/date-picker-utils';
import {PhWarning, PhEnvelopeSimpleOpen, PhEnvelopeSimple, } from 'phosphor-vue'
import { BROKER_LEVEL_ROLES, EMPLOYEE_ROLES } from '@/router/roles';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Pill from '@/components/core/Pill.vue';
import NoData from '@/components/core/NoData.vue';
import UndrawChatting from '@/components/icons/undraw/UndrawChatting.vue';

export default {
  components: {
    Table,
    GhostLoading,
    Pill,
    NoData,
    UndrawChatting,
  },
  props: {
    showTitle: {
      type: Boolean,
      default: true,
    },
  },
  
  data: function () {
    return {
      loadingTicketsNachrichten: false,
    }
  },

  computed: {
        ...mapGetters({
            tickets: COMMUNICATION_TYPES.GETTERS.TICKETS_NACHRICHTEN_LAZY,
            hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
            userId: CORE_TYPES.GETTERS.GET_USER_ID,
            isBroker: CORE_TYPES.GETTERS.IS_BROKER,
        }),
        headers() {
            return {
                lockedLeft: [
                  IconColumn("gelesen", "gelesen").makeAlwaysVisible(),
                  DateTimeColumn("geaendert", "geändert"),
                ],
                center: [
                  DateColumn("erstellt", "erstellt"),
                  TextColumn("nameBearbeiter", "Bearbeiter"),
                ],
                lockedRight: [
                  TextColumn("nameBeteiligter", "Beteiligter").makeConditionalLink("canOpenCustomerNewTab"),
                  IconColumn("important", "Wichtig"),
                  TextColumn("thema", "Thema", undefined, 2).makeAlwaysVisible().makeLink(),
                  PillColumn("status", "Status", 80).makeSortable(pill => pill ? pill.label : ""),
                ],
            };
        },
        rows() {
            let tickets = this.tickets || [];
            if(this.isDisabled){
              tickets = []
            }
            return tickets.filter(t => t.status != "GESCHLOSSEN")
            .sort((a, b) => b.dateChangedChat - a.dateChangedChat)
            .map(ticket => {
                const status = ticket.status == "BEARBEITET" ? "bearbeitet" : (ticket.status == "GESCHLOSSEN" ? "geschlossen" : "offen");
                return {
                    ...ticket,
                    gelesen: [ticket.ungelesen
                        ? Icon(PhEnvelopeSimple, "Nein", 20, 'fill')
                        : Icon(PhEnvelopeSimpleOpen, "Ja", 20)
                    ],
                    erstellt: DatePickerUtils.toDateStr(new Date(ticket.dateCreated)),
                    geaendert: ticket.dateChangedChat,
                    id: ticket.chatId + " " + ticket.channelId,
                    status: {
                        label: status,
                        type: 'black',
                    },
                    important: ticket.important ? [Icon(PhWarning , "Wichtig", 20, null ,'color-warning')]: null,
                    canOpenCustomerNewTab: this.canOpenCustomerNewTab(ticket),
                    customRowStyle: {...(ticket.ungelesen ? { fontWeight: 'bold' } : {}), },
                };
            })
        },
        isDisabled(){
          return this.tickets?.['DISABLED'] === '1'
        },
        mobileConfig() {
          return {
              title: '',
              headers: ['geaendert', 'erstellt', 'nameBearbeiter', ],
          }
        }
    },
	mounted: function () {
        this.loadTicketsNachrichten();
    },

  methods: {
    clickThema(row) {
        this.$router.push({name: 'ticket', params: {chatId: row.chatId, channelId: row.channelId}});
    },
    async loadTicketsNachrichten() {
      try {
        this.loadingTicketsNachrichten = true;

        const params = {
          ignoreResponsibility: true,
          isOverview: true,
          limit: 5,
        };
        await this.$store.dispatch(COMMUNICATION_TYPES.ACTIONS.LOAD_TICKETS_NACHRICHTEN_LAZY, params);
      } finally {
        this.loadingTicketsNachrichten = false;
      }
    },
    canOpenCustomerNewTab(ticketNachrichten) {
      const isTicketSameMakler = ticketNachrichten?.desktopMaklerVonKundeId === this.userId
      return ticketNachrichten?.kundennr && this.hasRoles([[EMPLOYEE_ROLES.ZUGRIFF_KUNDEN,  BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN]]) && isTicketSameMakler
    },
    openCustomerNewTab(ticketNachrichten) {
      if (ticketNachrichten?.kundennr) {
        this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, { customerId: ticketNachrichten.kundennr });
      }
    },
  },

}
</script>

<style scoped>
.tickets-nachrichten__disabled {
  margin: 0 auto;
  max-width: 250px;
}
</style>
