<template>
<div>
    <template v-if="showOptionen">
        <PageHeaderTitleNavigation title="2FA Intervall ändern" />
    </template>

    <div class="box__container">
        <div class="box__title">Intervall für Zwei-Faktor-Authentifizierung festlegen</div>
        <p>
            <GhostLoading v-if="loading" type="custom">
                <Block />
            </GhostLoading>
            <template v-else>
                Momentan können Sie sich nach einem Login mit Zwei-Faktor-Authentifizierung {{currentInterval}} {{ currentInterval <= 1 ? 'Tag' : 'Tage' }} anmelden, bis Sie wieder Ihren zweiten Faktor verwenden müssen.
            </template>
        </p>
        <p v-if="error" class="color-danger">{{error}}</p>
        <ComboBox
            isComponentHalfSize
            v-model="currentInterval"
            :values="options"
            :sortComboboxValues="false"
            :label="currentIntervalLabel"
            @change="changeIntervals"
            :disabled="loading"/>

        <hr v-if="isEmployeeLimitConfigVisible || isCustomerLimitConfigVisible" />

        <ComboBox
            isComponentHalfSize
            v-if="isEmployeeLimitConfigVisible"
            v-model="brokerEmployeeLimit"
            :values="brokerEmployeeLimitOptions"
            :sortComboboxValues="false"
            label="Obergrenze für Mitarbeiter <strong>(in Tagen)</strong>"
            @change="changeIntervals"
            :disabled="loading"/>
        <ComboBox
            isComponentHalfSize
            v-if="isCustomerLimitConfigVisible"
            v-model="customerLimit"
            :values="customerLimitOptions"
            :sortComboboxValues="false"
            label="Obergrenze für Kunden <strong>(in Tagen)</strong>"
            @change="changeIntervals"
            :disabled="loading"/>
    </div>
</div>
</template>

<script>
import LOG_TYPES from '@/store/log/types';

import axios from 'axios';
import { buildMessage } from '@/helpers/log-message-helper';

import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import { ROLES, VIEW_ROLES } from '@/router/roles';

const config = {
    defaultSpinner: true,
};

const ALWAYS_TEXT = 'Immer';
const toOptions = (max, standard = -1) => {
    const options = [];
    for (let i = 0; i <= max; i++) {
        const label = i === 0 ? ALWAYS_TEXT : i;
        options.push({
            label: `${label}${i === standard ? ' (Standard)' : ''}`, 
            value: i, 
        });
    }
    return options;
};

export default {
    components: {
        OptionMenu,
        PageHeaderTitleNavigation,
        GhostLoading,
        Block,
        ComboBox,
    },
    props: {
        showOptionen: {
            type: Boolean,
            default: true,
        }
    },
    data() {
        return {
            currentInterval: -1,
            customerLimit: -1,
            brokerEmployeeLimit: -1,
            maxInterval: 90,
            maxCustomerLimit: -1,
            maxBrokerEmployeeLimit: -1,
            brokerEmployeeLimitStandard: -1,
            customerLimitStandard: -1,
            loading: false,
            error: null,
        }
    },
    computed: {
        currentIntervalLabel() {
            const label = 'Tage zwischen Zwei-Faktor-Authentifizierung';

            if (this.$hasRoles([ROLES.IS_USER_UNTERNR])) {
                return `${label} <strong>eigene Einstellung (Mitarbeiter)</strong>`;
            } else if (this.$hasRoles([VIEW_ROLES.VIEW_BROKER])) {
                return `${label} <strong>eigene Einstellung (Vermittler)</strong>`;
            }

            return label;
        },
        isEmployeeLimitConfigVisible() {
            return this.maxBrokerEmployeeLimit >= 0;
        },
        isCustomerLimitConfigVisible() {
            return this.maxCustomerLimit >= 0;
        },
        options() {
            return toOptions(this.maxInterval);
        },
        customerLimitOptions() {
            return toOptions(this.maxCustomerLimit, this.customerLimitStandard);
        },
        brokerEmployeeLimitOptions() {
            return toOptions(this.maxBrokerEmployeeLimit, this.brokerEmployeeLimitStandard);
        },
    },
    async mounted() {
        await this.loadInterval();
    },
    methods: {
        async loadInterval() {
            this.loading = true;
            let response = await axios.get(`${process.env.VUE_APP_API}/login/interval_2fa`, config);
            this.loading = false;
            if (response.status == 200) {
                this.currentInterval = response.data.interval;
                this.maxInterval = response.data.maxInterval;
                this.customerLimit = response.data.customerLimit;
                this.maxCustomerLimit = response.data.maxCustomerLimit;
                this.customerLimitStandard = response.data.customerLimitStandard;
                this.brokerEmployeeLimit = response.data.brokerEmployeeLimit;
                this.maxBrokerEmployeeLimit = response.data.maxBrokerEmployeeLimit;
                this.brokerEmployeeLimitStandard = response.data.brokerEmployeeLimitStandard;
                this.error = response.data.error;
            }
        },
        async changeIntervals() {
            this.$nextTick(() => {
                const data = {
                    newValue: this.currentInterval,
                    customerLimit: this.customerLimit,
                    brokerEmployeeLimit: this.brokerEmployeeLimit,
                };
                this.loading = true;
                axios.post(`${process.env.VUE_APP_API}/login/interval_2fa`, data, config)
                .then(response => {
                    this.loading = false;
                    if (response.status != 200) {
                        this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage("Der Intervall konnte nicht geändert werden.", 'danger'));
                        this.loadInterval();
                    }
                })
            });
        },
    },
}
</script>

<style scoped>

</style>
