<template>
    <div v-if="vertragsdatenFieldsDefinition">

        <Table v-if="rows.length"
            ref="table"
            :tableId="tableId"
            cardView
            :headers="headers"
            :rows="rows"
            rowId="id"
            hidePagination
        >


        </Table>

    </div>
</template>


<script>
import BoxContainer from "@/components/core/BoxContainer.vue";
import { PhPencilLine, PhBookOpen } from 'phosphor-vue';
import {TextColumn, CurrencyColumn} from "@/components/table2/table_util.js";
import Table from "@/components/table2/Table.vue";
import { mapGetters } from 'vuex'
import VERSICHERUNG_TYPES from "@/store/versicherungen/types";
import CORE_TYPES from '@/store/core/types';


export default {
    components: {
        BoxContainer,
        PhPencilLine,
        PhBookOpen,
        Table,
    },
    data: function () {
            return {
                title: "Basisdaten"
            }
    },
    computed: {
        ...mapGetters({
        versicherungenDetails: VERSICHERUNG_TYPES.GETTERS.INSURANCE_DETAILS,
        isFA: CORE_TYPES.GETTERS.IS_FA,
        isFK: CORE_TYPES.GETTERS.IS_FK,
        hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
        vertragsdatenFieldsDefinition: VERSICHERUNG_TYPES.GETTERS.INSURANCE_VERTRAGSDATEN_FIELDS_DEFINITION,
        gesellschaftList: VERSICHERUNG_TYPES.GETTERS.GESELLSCHAFT_LIST,
        }),
        headers() {
            const result = {
            center: [],
            };

            if (this.vertragsdatenFieldsDefinition?.gesellschaft?.visible) {
                result.center.push(TextColumn("gesellschaft", "Gesellschaft"));
            }
            if (this.vertragsdatenFieldsDefinition?.displayFremdges?.visible) {
                result.center.push(TextColumn("displayFremdges", "Zeige Fremdgesellschaften"));
            }
            result.center.push(TextColumn("vertragssparte", "Vertragssparte"));
            if (this.vertragsdatenFieldsDefinition?.aktuellerStatus?.visible) {
                result.center.push(TextColumn("aktuellerStatus", "Aktueller Status").setColorFn((row) => row?.itemValue == 'aktiv' ? 'color-success' : 'color-text' ));
            }
            if (this.vertragsdatenFieldsDefinition?.polNr?.visible) {
                result.center.push(TextColumn("nummer", this.vertragsdatenFieldsDefinition?.polNr?.value));
            }
            if (this.vertragsdatenFieldsDefinition?.produktbezeichnung?.visible) {
                result.center.push(TextColumn("produktbezeichnung", "Produktbezeichnung"));
            }
            if (this.vertragsdatenFieldsDefinition?.vertragsbeginn?.visible) {
                result.center.push(TextColumn("vertragsbeginn", "Vertragsbeginn"));
            }
            if (this.vertragsdatenFieldsDefinition?.vertragsende?.visible) {
                result.center.push(TextColumn("vertragsende", "Vertragsende"));
            }
            if (this.vertragsdatenFieldsDefinition?.laufzeit?.visible) {
                result.center.push(TextColumn("laufzeit", "Laufzeit (Jahre)"));
            }
            if (this.vertragsdatenFieldsDefinition?.hauptfaelligkeit?.visible) {
                result.center.push(TextColumn("hauptfaelligkeit", this.vertragsdatenFieldsDefinition?.hauptfaelligkeit?.value));
            }            
            if (this.vertragsdatenFieldsDefinition?.pramieBrutto?.visible) {
                result.center.push(CurrencyColumn("pramieBrutto", "Bruttoprämie gem. Zahlweise"));
            }
            if (this.vertragsdatenFieldsDefinition?.pramieNetto?.visible) {
                result.center.push(CurrencyColumn("pramieNetto", "Nettoprämie gem. Zahlweise"));
            }
            if (this.vertragsdatenFieldsDefinition?.zahlrhythmus?.visible) {
                result.center.push(TextColumn("zahlrhythmus", "Zahlweise"));
            }
            if (this.vertragsdatenFieldsDefinition?.zahlungsart?.visible) {
                result.center.push(TextColumn("zahlungsart", "Zahlungsart"));
            }
            if (this.vertragsdatenFieldsDefinition?.bankverbindung?.visible) {
                result.center.push(TextColumn("bankverbindung", "Bankverbindung"));
            }
            if (this.vertragsdatenFieldsDefinition?.externeKennung?.visible) {
                result.center.push(TextColumn("externeKennung", "Ext. Kennung"));
            }
            if (this.vertragsdatenFieldsDefinition?.eigenbestand?.visible) {
                result.center.push(TextColumn("eigenbestand", "Eigenbestand"));
            }
            if (this.vertragsdatenFieldsDefinition?.fremdanlage?.visible) {
                result.center.push(TextColumn("fremdanlage", "Fremdanlage"));
            }
            if (this.vertragsdatenFieldsDefinition?.kundeEingelesen?.visible) {
                result.center.push(TextColumn("kundeEingelesen", "Durch Kunde angelegt"));
            }            


            // no default order asked for the next columns (See ticket MSC-27944)
            result.center.push(TextColumn("id", "Id").makeHidden());
            if (this.vertragsdatenFieldsDefinition?.provisionsfrei?.visible) {
                result.center.push(TextColumn("provisionsfrei", "Provisionsfrei"));
            }
            if (this.vertragsdatenFieldsDefinition?.teilung?.visible) {
                result.center.push(TextColumn("teilung", "Teilung geprüft"));
            }
            if (this.vertragsdatenFieldsDefinition?.anbindung?.visible) {
                result.center.push(TextColumn("anbindung", "Anbindung"));
            }
            if (this.vertragsdatenFieldsDefinition?.betreuungsstufe?.visible) {
                result.center.push(TextColumn("betreuungsstufe", "Betreuungsstufe"));
            }
            if (this.vertragsdatenFieldsDefinition?.konvertierung?.visible) {
                result.center.push(TextColumn("konvertierung", "Konvertierung"));
            }
            if (this.vertragsdatenFieldsDefinition?.letzte_aenderung_gueltig_ab?.visible) {
                result.center.push(TextColumn("letzte_aenderung_gueltig_ab", "letzte Vertragsänderung gültig ab"));
            }
            if (this.vertragsdatenFieldsDefinition?.aenderungs_grund?.visible) {
                result.center.push(TextColumn("aenderungs_grund", "Änderungsgrund"));
            }

        return result;
        },
        rows() {
            const rows = [];

            const row = {};

            row.id = this.versicherungenDetails?.insurance?.id;



            const vertragsparte = this.vertragsdatenFieldsDefinition?.vertragssparte?.value?.find(vd => vd.value == this.versicherungenDetails?.insurance?.description?.id)
            
            row.vertragssparte = vertragsparte?.label;

            if (this.vertragsdatenFieldsDefinition?.gesellschaft?.visible) {
                const gesellschaft = this.gesellschaftList?.find(vd => vd.value == this.versicherungenDetails?.insurance?.gesellschaft.id)
                row.gesellschaft = gesellschaft?.label;
            }
            if (this.vertragsdatenFieldsDefinition?.displayFremdges?.visible) {
                row.displayFremdges = this.versicherungenDetails?.insurance?.displayFremdges? 'Ja' : 'Nein';
            }
            if (this.vertragsdatenFieldsDefinition?.fremdanlage?.visible) {
                row.fremdanlage = this.versicherungenDetails?.insurance?.fremdanlage? 'Ja' : 'Nein'
            }
            if (this.vertragsdatenFieldsDefinition?.kundeEingelesen?.visible) {
                row.kundeEingelesen = this.versicherungenDetails?.insurance?.kundeEingelesen? 'Ja' : 'Nein'
            }
            if (this.vertragsdatenFieldsDefinition?.eigenbestand?.visible) {
                row.eigenbestand = this.versicherungenDetails?.insurance?.isEigenerVertrag? 'Ja' : 'Nein';
            }        
            if (this.vertragsdatenFieldsDefinition?.provisionsfrei?.visible) {
                row.provisionsfrei = this.versicherungenDetails?.insurance?.provisionsfrei? 'Ja' : 'Nein';
            } 
            if (this.vertragsdatenFieldsDefinition?.teilung?.visible) {
                row.teilung = this.versicherungenDetails?.insurance?.teilung? 'Ja' : 'Nein';
            }      
            if (this.vertragsdatenFieldsDefinition?.produktbezeichnung?.visible) {
                row.produktbezeichnung = this.versicherungenDetails?.insurance?.produktbez || '-';
            }      
            if (this.vertragsdatenFieldsDefinition?.aktuellerStatus?.visible) {
                row.aktuellerStatus = this.versicherungenDetails?.insurance?.status || '-';
            }      
            if (this.vertragsdatenFieldsDefinition?.anbindung?.visible) {
                const anbindung = this.vertragsdatenFieldsDefinition?.anbindung?.value?.find(vd => vd.value == this.versicherungenDetails?.insurance?.anbindung);
                row.anbindung = anbindung?.label || '-';
            }
            if (this.vertragsdatenFieldsDefinition?.betreuungsstufe?.visible) {
                const betreuungsstufe = this.betreungsstufeComputedValues?.find(vd => vd.value == this.versicherungenDetails?.insurance?.betreuungsstufe);
                row.betreuungsstufe = betreuungsstufe?.label || betreuungsstufe || '-';
            }      
            if (this.vertragsdatenFieldsDefinition?.konvertierung?.visible) {
                row.konvertierung = this.versicherungenDetails?.insurance?.konvertierung? 'Ja' : 'Nein';
            }  
            if (this.vertragsdatenFieldsDefinition?.polNr?.visible) {
                row.nummer = this.versicherungenDetails?.insurance?.nummer || '-';
            }      
            if (this.vertragsdatenFieldsDefinition?.vertragsbeginn?.visible) {
                row.vertragsbeginn = this.versicherungenDetails?.insurance?.beginn || './.';
            }   
            if (this.vertragsdatenFieldsDefinition?.vertragsende?.visible) {
                row.vertragsende = this.versicherungenDetails?.insurance?.ende || './.';
            }  
            if (this.vertragsdatenFieldsDefinition?.laufzeit?.visible) {
                row.laufzeit = this.versicherungenDetails?.insurance?.laufzeit || '-';
            }    
            if (this.vertragsdatenFieldsDefinition?.hauptfaelligkeit?.visible) {
                row.hauptfaelligkeit = this.versicherungenDetails?.insurance?.hauptfaelligkeit || './.';
            }       
            if (this.vertragsdatenFieldsDefinition?.zahlrhythmus?.visible) {
                const zahlrhythmus = this.vertragsdatenFieldsDefinition?.zahlrhythmus?.value?.find(vd => vd.value == this.versicherungenDetails?.insurance?.zahlrhythm);
                row.zahlrhythmus = zahlrhythmus?.label || '-';
            } 
            if (this.vertragsdatenFieldsDefinition?.zahlungsart?.visible) {
                row.zahlungsart = this.versicherungenDetails?.insurance?.zahlungsart || '-';
            }
            if (this.vertragsdatenFieldsDefinition?.bankverbindung?.visible) {
                const bankverbindung = this.vertragsdatenFieldsDefinition?.bankverbindung?.value?.find(vd => vd.value == this.versicherungenDetails?.insurance?.bankverbindung);
                row.bankverbindung = bankverbindung?.label || '-';
            }
            if (this.vertragsdatenFieldsDefinition?.externeKennung?.visible) {
                row.externeKennung = this.versicherungenDetails?.insurance?.externeKennung || '-';
            }
            if (this.vertragsdatenFieldsDefinition?.pramieBrutto?.visible) {
                row.pramieBrutto = this.versicherungenDetails?.insurance?.praemiebto_zahlweise || '-';
            }
            if (this.vertragsdatenFieldsDefinition?.pramieNetto?.visible) {
                row.pramieNetto = this.versicherungenDetails?.insurance?.praemiento_zahlweise || '-';
            }
            if (this.vertragsdatenFieldsDefinition?.letzte_aenderung_gueltig_ab?.visible) {
                row.letzte_aenderung_gueltig_ab = this.versicherungenDetails?.insurance?.letzteAenderungGueltigAb || '-';
            }     
            if (this.vertragsdatenFieldsDefinition?.aenderungs_grund?.visible) {
                row.aenderungs_grund = this.versicherungenDetails?.insurance?.aenderungsGrund || '-';
            }       
                 
            
            rows.push(row);
            return rows;
        },
        betreungsstufeComputedValues() {
            let betreungsstufeValues = this.vertragsdatenFieldsDefinition.betreuungsstufe.value;
            if('FPPLUS_GEWERBE' === this.vertragsdatenFieldsDefinition.anbindung.value[0].value || 'FPPLUS_GEWERBE' === this.versicherungenDetails?.insurance?.anbindung ) {
                //removing 'Ohne' value for FP+ Gewerbe GmbH
                betreungsstufeValues = this.vertragsdatenFieldsDefinition.betreuungsstufe.value.filter(item => item.value !== 'Ohne');
                return betreungsstufeValues;
            }
            return betreungsstufeValues;
        },
        tableId() {
            const uniqueUUID = 'f3cfb91f-3016-4a8f-bef4-93c9e7c9a3b8';
            return uniqueUUID;
        },

    },  
    methods: {
        currency(value) {
            if (isNaN(value)) {
                return value;
            }
            let val = (value / 1).toFixed(2).replace('.', ',');
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' €';
        },
    },
    mounted() {

    },
    validators: {},
};
</script>

<style scoped>
.align-end {
  flex: 1 1 auto;
  align-self: flex-end;
  padding: 0px 0px 16px 5px;
  color: var(--color-text);
}
.form-control {
  display: flex;
  align-content: stretch;
  align-items: center;
}
</style>