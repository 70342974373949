<template>
  <div class="event-body">
    <template v-if="isLoadingAppoiment">
      <GhostLoading useBoxContainer>
        <Block type="title" />
        <Block height="250" />
      </GhostLoading>
    </template>
    <template v-else-if="modus === 'Empfänger'">
      <Table v-if="rowsEmpf.length" v-model="selectedEmpf" rowId="label" :headers="empfHeaders" :rows="rowsEmpf"
        hidePagination>
        <template #rolle="empf">
          <div style="min-width: 9em;">
            <ComboBox label="" :value="empf.rolle" :values="rolleOptions" @input="(rolle) => {
              empf.rolle = rolle;
              onChangeEmpf(empf.index, 'rolle', rolle);
            }
              ">
            </ComboBox>
          </div>
        </template>
      </Table>
      <!-- <BaseButton class="m-3" isSecondary @click="setEmpfaenger">Markierten Empfänger hinzufügen</BaseButton> -->
      <NoData v-else noIcon content="Keine Empfänger vorhanden" />
      <BaseButton isSecondary label="Zurück" @click="showEmpfaenger = false" />
      <BaseButton isSecondary label="Markierte Empfänger hinzufügen" v-if="rowsEmpf.length"
        :disabled="!selectedEmpf.length" @click="setEmpfaenger" />
      <BaseButton isSecondary label="Empfängersuche" @click="handleAddUsers" />
    </template>
    <template v-else>
      <div class="d-flex">
        <span>Titel</span>
        <span v-if="versId" class="pl-2">
          | Interne Nummer: <a @click="openInsurance">{{ versId }}</a></span>
        <span v-if="versId && schadenId" class="pl-2">
          | Schaden-Nr. <a @click="openSchaden">{{ schadenId }}</a></span>
      </div>
      <ShortcutInputField label="" v-model="label" validateUntouched  id="title" :disabled="!canEdit || loading" v-if="!preview" />
      <ShortcutInputField label="Titel" disabled v-model="previewSubject" id="preview_title" validateUntouched :disabled="!canEdit || loading" v-if="preview" />
      <div v-if="modus === 'Aktivität' || modus === 'Aufgabe'">
        <InputField label="Bereich" :value="additionalParamsBereich" :disabled="true" />
      </div>
      <hr />
      <div class="d-flex">
        <InputToggleSwitch v-if="modus === 'Termin'" label="Ganztägig" v-model="fullDay" class="pr-3" :disabled="!canEdit || loading" />
        <InputColorPicker v-if="modus !== 'Aktivität'" label="Farbe" v-model="color" :disabled="!canEdit || loading" />
        <!-- <InputToggleSwitch  v-if="modus === 'Aktivität'"
          label="wurde bereits bearbeitet"
          v-model="additionalParamsbestaetigt"
          class="pl-3"
        /> -->
      </div>
      <div class="data-triple" v-if="modus === 'Aufgabe'">
        <div class="padding-top">
          <label class="gridlabel">Von</label>
          <div>
            <DatePickerField dateFormat="DD.MM.YYYY" v-model="beginDate" :disabled="!canEdit || loading"></DatePickerField>
          </div>
        </div>
        <div class="padding-top">
          <label class="gridlabel">Fälligkeit</label>
          <div>
            <DatePickerField dateFormat="DD.MM.YYYY" v-model="endDate" :disabled="!canEdit || loading"></DatePickerField>
          </div>
        </div>
        <ComboBox class="padding-top" label="Status" v-model="additionalParamsstatus" :values="statusOptions" :disabled="!canEdit || loading">
        </ComboBox>
      </div>
      <ComboBox v-if="modus === 'Aktivität' || modus === 'Aufgabe'" class="padding-top" label="Verkaufsphase" v-model="additionalParamssellingPhase"
        :values="sellingOptions" :disabled="!canEdit || loading">
      </ComboBox>
      <div class="data-double" v-if="modus === 'Termin'">
        <div class="padding-top">
          <label class="gridlabel">Von</label>
          <div class="data-double">
            <DatePickerField dateFormat="DD.MM.YYYY" v-model="beginDate"></DatePickerField>
            <TimePicker v-if="beginDate" label="" :disabled="fullDay || !canEdit || loading" v-model="beginTime"></TimePicker>
          </div>
        </div>
        <div class="padding-top">
          <label class="gridlabel">Bis</label>
          <div class="data-double">
            <DatePickerField dateFormat="DD.MM.YYYY" v-model="endDate"></DatePickerField>
            <TimePicker v-if="endDate" label="" :disabled="fullDay || !canEdit || loading" v-model="endTime"></TimePicker>
          </div>
        </div>
      </div>

      <div class="data-double" v-if="modus === 'Aktivität' || modus === 'Aufgabe'">
        <ComboBox label="Wiederholung" v-model="retry" :values="retryCombo" :disabled="!canEdit || loading">
        </ComboBox>
        <ComboBox class="padding-top" label="Art" v-model="additionalParamsart" :values="artOptions" :disabled="!canEdit || loading">
        </ComboBox>
      </div>
      <div class="data-triple" v-if="modus === 'Termin'">
        <ComboBox class="padding-top" label="Wiederholung" v-model="retry" :values="retryCombo" :disabled="!canEdit || loading">
        </ComboBox>
        <InputField class="padding-top" label="Anzahl Termine" v-model="retryCount" :disabled="retry === 'Keine' || !canEdit || loading" />
        <DatePickerField label="Höchstens bis zum" dateFormat="DD.MM.YYYY" :disabled="retry === 'Keine' || !canEdit || loading"
          v-model="retryMaxDate"></DatePickerField>
      </div>
      <template v-if="modus === 'Aktivität' || modus === 'Aufgabe'">
        <!--<div class="data-double">
          <div class="padding-top">
            <label class="gridlabel">Fälligkeit</label>
            <div>
              <DatePickerField dateFormat="DD.MM.YYYY" v-model="beginDate" :disabled="!canEdit || loading"></DatePickerField>
            </div>
          </div>
          <ComboBox class="padding-top" label="Status" v-model="additionalParamsstatus" :values="statusOptions" :disabled="!canEdit || loading">
          </ComboBox>
        </div>-->
        <div class="data-double padding-top">
          <InputField label="Produkt" v-model="additionalParamsprodukt"  :disabled="!canEdit || loading"/>
          <InputField label="Geschätzter Geschäftswert" type="number" v-model="additionalParamsgeschaeftwert" :disabled="!canEdit || loading" />
        </div>
      </template>

      <ParticipantInput class="mb-1 padding-top" label="Teilnehmer" :combinedDisplay="true" :hidePagination="true"
        :maxItemsVisible="maxItemsVisible" :value="beteiligteValue" :availableUsers="availableUsers"
        :isUserSelect="false" :isRole="true" :isReceiveUserClickable="isReceiveUserClickable"
        :showMailAndAddressActions="modus === 'Termin'" :bereich="bereich" @searchReceiverUsers="searchUsers($event)"
        @deleteReceiveUsers="deleteUsers($event)" @onChange="onChange" @input="onAddParticipant"
        @clickReceiveUsers="openReceiveUser($event)" @setBesitzer="setBesitzer" @copyAddress="copyAddress"
        @sendEmail="mailSend()" @sendEmailSingle="mailSend" @setAddress="setAddress" :loading="searchReceiverUsersLoading" :disabled="!canEdit || loading"  />
      <div class="d-flex input-forms__container" v-if="modus === 'Aktivität' || modus === 'Aufgabe'">
        <BaseButton class="mr-16" isSecondary @click="getEmpfaenger" :disabled="!canEdit || loading">Empfänger</BaseButton>
      </div>
      <div class="d-flex input-forms__container justify-between overflow-x-scroll" v-else>
        <BaseButton class="mr-16" isSecondary @click="addUserVermittler" :disabled="!canEdit || loading">Vermittler</BaseButton>
        <BaseButton class="mr-16" isSecondary @click="addUserKunde" :disabled="!canEdit || loading">Kunde</BaseButton>
        <BaseButton class="mr-16" isSecondary @click="addUserGesellschaft" :disabled="!canEdit || loading">Gesellschaft</BaseButton>
        <BaseButton class="mr-16" isSecondary @click="addUserKontakt" :disabled="!canEdit || loading">Kontakt</BaseButton>
      </div>

      <div class="d-flex items-center " v-if="modus !== 'Aktivität'">
        <InputField label="Ort" v-model="place" class="flex_grow_1" :disabled="!canEdit || loading" />
        <InputToggleSwitch label="Privattermin" v-model="additionalParamsprivate" class="pl-3"  :disabled="!canEdit || loading"/>
      </div>
      <InputTextArea label="Beschreibung" v-model="text"  :disabled="!canEdit || loading"/>
      <hr />
      <div class="data-double">
        <ComboBox label="Benachrichtigung per Popup" v-model="popup" :values="durationOptions"
          :sortComboboxValues="false" :disabled="!canEdit || loading">
        </ComboBox>
        <ComboBox label="Benachrichtigung per E-Mail" v-model="email" :values="durationOptions"
          :sortComboboxValues="false" :disabled="!canEdit || loading">
        </ComboBox>
      </div>
      <div class="mb-3">
        <DragnDropArea hoverText="Datei hier ablegen" @files="addDocument($event)">
          <div class="d-block justify-content-between">
            <Table v-if="allAttachments.length" :headers="headers" :rows="rows" hidePagination
              @action-DELETE="actionDelete"  :disabled="!canEdit || loading">
              <template #dateiname="row">
                <DownloadLink v-if="appointmentSid" :title="row.dateiname" downloadServicePath="/termin_attachment"
                  :filename="row.dateiname" :queryParams="{
                    sid: appointmentSid,
                    fileId: row.id,
                  }" />
                <span v-else>{{ row.dateiname }}</span>
              </template>
            </Table>
            <div
              class="d-flex d-md-block mt-1 layout__negative-margin--8 antrag-action-button__container justify-content-center">
              <BaseButton isSecondary class="upload-btn" :disabled="!canEdit || loading">
                <slot>Datei hochladen</slot>
                <input type="file" :accept="acceptFileTypes" @change="onAddFiles" multiple />
              </BaseButton>

              <BaseButton isPrimary @click="handleAddDocuments" :disabled="!canEdit || loading">
                Dokumentenarchiv
              </BaseButton>
            </div>
          </div>
        </DragnDropArea>
      </div>
      <div class="action-buttons mb-3">
        <BaseButton class="mr-16" isSecondary @click="deleteAppointment" v-if="
          getSelectedEvent.appointment && getSelectedEvent.appointment.sid
        " :disabled="!canEdit || loading">Löschen</BaseButton>
      </div>
    </template>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import dayjs from "dayjs";
import CALENDAR_TYPES from "@/store/calendar/types";
import CUSTOMER_FILTERS_TYPES from "@/store/customerFilters/types";
import validator from "@/mixins/validator";
import InputField from "@/components/core/forms/InputField.vue";
import DatePickerField from "@/components/core/forms/date-picker2/DatePickerField.vue";
import { required } from "@/mixins/validator/rules";
import BaseButton from "@/components/core/BaseButton.vue";
import TimePicker from "@/components/core/forms/TimePicker.vue";
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";
import InputTextArea from "@/components/core/forms/InputTextArea.vue";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import InputColorPicker from "@/components/core/forms/InputColorPicker.vue";
import axios from "axios";
import CORE_TYPES from "@/store/core/types";
import LOG_TYPES from "@/store/log/types";
import CUSTOMER_SEARCH_TYPES from "@/store/customerSearch/types";
import ParticipantInput from "@/components/mailcomposer/ParticipantInput.vue";
import DragnDropArea from "@/components/fileUpload/DragnDropArea.vue";
import { uploadFileChunked } from "@/helpers/upload-helper";
import { PhTrash } from "phosphor-vue";
import Table from "@/components/table2/Table.vue";
import {
  TextColumn,
  SlotColumn,
  ActionColumn,
  SimpleAction,
} from "@/components/table2/table_util.js";
import MAILCOMPOSER_TYPES from "@/store/mailcomposer/types";
import { buildMessage } from "@/helpers/log-message-helper";
import GhostLoading from "@/components/core/loading/GhostLoading.vue";
import Block from "@/components/core/loading/ghost-loading/Block.vue";
import DownloadLink from "@/components/core/download/DownloadLink.vue";
import NoData from "@/components/core/NoData.vue";
import { rgbToHex } from "@/helpers/colors-helper";
import ShortcutInputField from '@/components/core/forms/ShortcutInputField.vue';

const MAX_ITEMS_VISIBLE = 15;

export default {
  name: "AppointmentEditCreate",
  components: {
    InputField,
    DatePickerField,
    BaseButton,
    TimePicker,
    GhostLoading,
    InputToggleSwitch,
    InputTextArea,
    ComboBox,
    InputColorPicker,
    ParticipantInput,
    DragnDropArea,
    Table,
    Block,
    DownloadLink,
    NoData,
    ShortcutInputField,
  },
  props: {
    isActivity: {
      type: Boolean,
      default: true,
    },
    discardChanges: {},
    isEmbeded: {
      type: Boolean,
      default: false,
    },
    user: {},
    subject: {},
    previewSubject: {},
    preview: {
      type: Boolean,
      default: false,
    },
    selectedDate: {},
    bereich: {
      type: String,
      default: null,
    },
    bereichId: {
      type: [String, Number],
      default: null,
    },
    bereichText: {
      type: String,
      default: null,
    },
    status: {
      type: String,
      default: "",
    },
    mode: {
      type: String,
      default: "",
    },
    attachCustomer: {
      type: Boolean,
      default: true,
    },
    noInitialise: {
      type: Boolean,
      default: false,
    },
    additionalBeteiligte: {
      type: Array,
    },
    vsnr: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      durationOptions: [
        {
          label: "keine Benachrichtigung",
          value: "",
        },
        {
          label: "0 Min.",
          value: "0",
        },
        {
          label: "15 Min.",
          value: "15",
        },
        {
          label: "30 Min",
          value: "30",
        },
        {
          label: "1 Stunde",
          value: "60",
        },
        {
          label: "1.5 Stunden",
          value: "90",
        },
        {
          label: "3 Stunden",
          value: "180",
        },
        {
          label: "4 Stunden",
          value: "240",
        },
        {
          label: "5 Stunden",
          value: "300",
        },
        {
          label: "6 Stunden",
          value: "360",
        },
        {
          label: "1 Tag",
          value: "1440",
        },
        {
          label: "2 Tage",
          value: "2880",
        },
        {
          label: "3 Tage",
          value: "4320",
        },
        {
          label: "4 Tage",
          value: "5760",
        },
        {
          label: "5 Tage",
          value: "7200",
        },
        {
          label: "6 Tage",
          value: "8640",
        },
        {
          label: "1 Woche",
          value: "10080",
        },
      ],
      kunden: "",
      emailCount: "",
      popupCount: "",
      timeoutRef: null,
      availableUsers: [],

      allAttachments: [],
      acceptFileTypes: "*/*",

      loading: false,
      showEmpfaenger: false,
      empfaenger: [],
      rowsEmpf: [],
      selectedEmpf: [],
      empfHeaders: {
        lockedLeft: [
          TextColumn("label", "Empfänger", 100).makeAlwaysVisible(),
          TextColumn("name", "Name", 150),
        ],
        center: [
          SlotColumn("rolle", "Rolle", 150),
          TextColumn("email", "E-Mail", 150),
        ],
        lockedRight: [],
      },
      isMultipleBesitzerRolles: false,
      customerNr: "",
      maxItemsVisible: MAX_ITEMS_VISIBLE,
      canEdit: true,
      searchReceiverUsersLoading: false,
    };
  },

  mixins: [validator],
  validators: {
    label: [required()],
  },

  computed: {
    ...mapGetters({
      getSelectedEvent: CALENDAR_TYPES.GETTERS.GET_SELECTED_APPOINTMENT_DATA,
      getAppointmentCombos: CALENDAR_TYPES.GETTERS.GET_APPOINTMENT_COMBOS,
      getAppointmentBeginDate:
        CALENDAR_TYPES.GETTERS.GET_APPOINTMENT_BEGIN_DATE,
      getAppointmentBeginTime:
        CALENDAR_TYPES.GETTERS.GET_APPOINTMENT_BEGIN_TIME,
      getAppointmentEndDate: CALENDAR_TYPES.GETTERS.GET_APPOINTMENT_END_DATE,
      getAppointmentEndTime: CALENDAR_TYPES.GETTERS.GET_APPOINTMENT_END_TIME,
      getEmptyAppointment: CALENDAR_TYPES.GETTERS.GET_EMPTY_APPOINTMENT,
      getAppointment: CALENDAR_TYPES.GETTERS.GET_APPOINTMENT,
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
      isCustomer: CORE_TYPES.GETTERS.IS_CUSTOMER,
      loginData: CORE_TYPES.GETTERS.GET_LOGIN_DATA,
      selectedCustomersExternal:
        CUSTOMER_SEARCH_TYPES.GETTERS.SET_SELECTED_CUSTOMERS,
      presavedAppointment: CALENDAR_TYPES.GETTERS.PRESAVE_APPOINTMET,
      beteiligte: CALENDAR_TYPES.GETTERS.GET_BETEILIGTE_FOR_UPDATE,
      attachments: MAILCOMPOSER_TYPES.GETTERS.SET_ATTACHMENTS,
      isLoadingAppoiment: CALENDAR_TYPES.GETTERS.LOAD_APPOINTMENT,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
    }),

    isDisabled() {
      return (
        !this.label ||
        !this.beginDate ||
        this.loading ||
        this.isMultipleBesitzerRolles ||
        !this.canEdit
      );
    },

    alarmConfig() {
      return this.getAppointmentCombos?.alarmConfig || {};
    },

    modus() {
      if (this.showEmpfaenger) {
        return "Empfänger";
      }
      const bereich = this.mode || this.getSelectedEvent?.appointment?.bereich;
      if (!bereich) {
        return "";
      }
      switch (bereich) {
        case "Termin":
          return "Termin";
        default:
          return "Aufgabe";
      }
    },
    // empfValues() {
    //   if (this.empfaenger?.length) {
    //     let exclude = ','
    //     this.beteiligteValue.forEach( bet => {
    //       exclude += (bet.personId || -1) + ',';
    //     });
    //     const result = [];
    //     this.empfaenger.forEach( empf => {
    //       if ( -1 === exclude.indexOf( ',' + (empf.personId) + ',')) {
    //         result.push(empf);
    //       }
    //     });
    //     return result;
    //   }
    //   return [];
    // },
    getShowModal() {
      return this.showModal;
    },
    duration: {
      get() {
        let begin_date;
        let end_date;
        if (dayjs(this.beginDate).isValid())
          begin_date = dayjs(this.beginDate).format("DD.MM.YYYY");
        else begin_date = this.beginDate;
        if (dayjs(this.endDate).isValid())
          end_date = dayjs(this.endDate).format("DD.MM.YYYY");
        else end_date = this.endDate;
        let startDayjs = dayjs(
          begin_date + " " + this.beginTime,
          "DD.MM.YYYY HH:mm"
        );
        let endDayjs = dayjs(end_date + " " + this.endTime, "DD.MM.YYYY HH:mm");
        let timeDifference = endDayjs.diff(startDayjs, "m");
        if (timeDifference < 0) {
          alert(
            "Endezeit kann nicht vor der Beginzeit sein! Wir kehren die Zeiten um."
          );
          this.$store.commit(
            CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_BEGIN_DATE,
            dayjs(endDayjs).format("DD.MM.YYYY")
          );

          this.$store.commit(
            CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_BEGIN_TIME,
            dayjs(endDayjs).format("HH:mm")
          );
        }

        return timeDifference;
      },
      set(value) {
        let begin_date;
        if (dayjs(this.beginDate).isValid())
          begin_date = dayjs(this.beginDate).format("DD.MM.YYYY");
        else begin_date = this.beginDate;
        let begin_time = this.beginTime !== "" ? this.beginTime : "00:00";
        let startDayjs = dayjs(
          begin_date + " " + this.beginTime,
          "DD.MM.YYYY HH:mm"
        );
        let endDayjs = dayjs(startDayjs).add(value, "m");
        this.$store.commit(
          CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_END_DATE,
          dayjs(endDayjs).format("DD.MM.YYYY")
        );

        this.$store.commit(
          CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_END_TIME,
          dayjs(endDayjs).format("HH:mm")
        );
      },
    },

    beginDate: {
      get() {
        return this.getAppointmentBeginDate;
      },
      set(value) {
        const date =
          value instanceof Date ? dayjs(value).format("DD.MM.YYYY") : value;
        this.$store.commit(
          CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_BEGIN_DATE,
          date
        );
        if (this.modus !== "Termin") {
          this.$store.commit(
            CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_BEGIN_TIME,
            "23:59"
          );
        }
        this.$store.commit(
          CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_END_DATE,
          value
        );
      },
    },
    beginTime: {
      get() {
        if (this.fullDay) {
          return "0:00";
        }
        return this.getAppointmentBeginTime;
      },
      set(value) {
        this.$store.commit(
          CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_BEGIN_TIME,
          value
        );
        this.$store.commit(
          CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_END_TIME,
          ""
        );
        const nextHour = dayjs(value, "HH:mm").add(1, "hour");
        this.$store.commit(
          CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_END_TIME,
          nextHour.format("HH:mm")
        );
      },
    },
    endDate: {
      get() {
        return this.getAppointmentEndDate;
      },
      set(value) {
        const date =
          value instanceof Date ? dayjs(value).format("DD.MM.YYYY") : value;
        this.$store.commit(
          CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_END_DATE,
          date
        );
        if (this.modus !== "Termin") {
          this.$store.commit(
            CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_END_TIME,
            "23:59"
          );
        }
        this.$store.commit(
          CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_DURATION,
          0
        );
      },
    },
    endTime: {
      get() {
        if (this.fullDay) {
          return "23:59";
        }
        return this.getAppointmentEndTime;
      },
      set(value) {
        this.$store.commit(
          CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_END_TIME,
          value
        );
        this.$store.commit(
          CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_DURATION,
          0
        );
      },
    },
    email: {
      get() {
        let modus = 1;
        switch (this.getSelectedEvent?.appointment?.alarmModuEmail) {
          case "TAG":
            modus = 1440;
            break;
          case "MINUTEN":
            modus = 1;
            break;
          case "STUNDEN":
            modus = 60;
            break;
          case "WOCHE":
            modus = 10080;
            break;
          case "MONAT":
            modus = 30 * 1440;
            break;
          case "JAHR":
            modus = 365 * 1440;
            break;
        }
        return `${this.getSelectedEvent?.appointment?.alarmEmail * modus}`;
      },
      set(value) {
        this.$store.commit(
          CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_EMAIL,
          value
        );
      },
    },
    popup: {
      get() {
        let modus = 1;
        switch (this.getSelectedEvent?.appointment?.alarmModusMSC) {
          case "TAG":
            modus = 1440;
            break;
          case "MINUTEN":
            modus = 1;
            break;
          case "STUNDEN":
            modus = 60;
            break;
          case "WOCHE":
            modus = 10080;
            break;
          case "MONAT":
            modus = 30 * 1440;
            break;
          case "JAHR":
            modus = 365 * 1440;
            break;
        }
        return `${this.getSelectedEvent?.appointment?.alarmMsc * modus}`;
      },
      set(value) {
        this.$store.commit(
          CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_POPUP,
          value
        );
      },
    },
    color: {
      get() {
        let color = this.getSelectedEvent?.appointment?.color;
        if (!color) {
          color = document.documentElement.style.getPropertyValue(
            "--color-primary"
          );
        }
        return rgbToHex(color);
      },
      set(hex) {
        if (hex) {
          this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT, {
            color: hex,
          });
        }
      },
    },
    additionalParamsstatus: {
      get() {
        return this.getSelectedEvent?.appointment?.status || "";
      },
      set(value) {
        if (value) {
          this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT, {
            status: value,
          });
        }
      },
    },
    additionalParamssellingPhase: {
      get() {
        return (
          this.getSelectedEvent?.appointment?.sellingPhase || "KEINE_ANGABEN"
        );
      },
      set(value) {
        if (value) {
          this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT, {
            sellingPhase: value,
          });
        }
      },
    },
    additionalParamsgeschaeftwert: {
      get() {
        return this.getSelectedEvent?.appointment?.geschaeftwert;
      },
      set(value) {
        if (value) {
          this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT, {
            geschaeftwert: `${value}`,
          });
        }
      },
    },
    additionalParamsprodukt: {
      get() {
        return this.getSelectedEvent?.appointment?.produkt;
      },
      set(value) {
        if (value) {
          this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT, {
            produkt: value,
          });
        }
      },
    },
    additionalParamsbestaetigt: {
      get() {
        return this.getSelectedEvent?.appointment?.bestaetigt;
      },
      set(value) {
        this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT, {
          bestaetigt: value,
        });
      },
    },
    additionalParamsprivate: {
      get() {
        return this.getSelectedEvent?.appointment?.private;
      },
      set(value) {
        if (value) {
          this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT, {
            private: value,
          });
        }
      },
    },
    additionalParamsart: {
      get() {
        return this.getSelectedEvent?.appointment?.art;
      },
      set(value) {
        if (value) {
          this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT, {
            art: value,
          });
        }
      },
    },
    additionalParamsBereich() {
      return (
        this.getSelectedEvent?.appointment?.bereichDisplay ||
        this.getSelectedEvent?.appointment?.bereich ||
        ""
      );
    },
    isBereichId() {
      return (
        !this.getSelectedEvent?.appointment?.sid ||
        this.getSelectedEvent?.appointment?.bereichId
      );
    },
    appointmentSid() {
      return this.getSelectedEvent?.appointment?.sid;
    },
    versId() {
      return (
        this.getSelectedEvent?.appointment?.bereich === "VersVertrag" &&
        this.getSelectedEvent?.appointment?.bereichId
      );
    },
    schadenId() {
      if (this.label && this.label.includes(" Schaden-Nr.")) {
        const ids = this.label
          .split(" Schaden-Nr.")
          .map((v) => v.replaceAll(" ", "").replaceAll(",", ""));
        return ids[ids.length - 1];
      }
      return false;
    },
    beteiligteValue() {
      return this.beteiligte.map((b) => {
        const beteiligter = {
          ...(b.beteiligte ? b.beteiligte : b),
          beteiligte: b.beteiligte,
          nummerText: b.userId || b.beteiligte?.userId,
          value:
            b.id ||
            b?.customerId ||
            b?.brokerId ||
            b?.personId ||
            b.beteiligte?.id ||
            b.beteiligte?.customerId ||
            b.beteiligte?.brokerId ||
            b.beteiligte?.personId,
          label: b.name,
        };
        Object.keys(beteiligter).forEach((key) => {
          if (beteiligter[key] === null) {
            beteiligter[key] = b[key];
          }
        });
        return beteiligter;
      });
    },
    label: {
      get() {
        return (
          this.getSelectedEvent?.appointment?.label ||
          this.subject ||
          this.insuranceNumber
        );
      },
      set(value) {
        this.$store.commit(
          CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_LABEL,
          value
        );
      },
    },
    text: {
      get() {
        return this.getSelectedEvent?.appointment?.text;
      },
      set(value) {
        this.$store.commit(
          CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_TEXT,
          value
        );
      },
    },
    place: {
      get() {
        return this.getSelectedEvent?.appointment?.place;
      },
      set(value) {
        this.$store.commit(
          CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_PLACE,
          value
        );
      },
    },
    fullDay: {
      get() {
        return this.getSelectedEvent?.appointment?.fullDay;
      },
      set() {
        this.$store.commit(
          CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_FULL_DAY,
          !this.getSelectedEvent?.appointment?.fullDay
        );
      },
    },
    retry: {
      get() {
        return this.getSelectedEvent?.appointment?.retry || "Keine";
      },
      set(value) {
        this.$store.commit(
          CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_RETRY,
          value
        );
      },
    },
    retryCount: {
      get() {
        return this.getSelectedEvent?.appointment?.retryCount;
      },
      set(value) {
        this.$store.commit(
          CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_RETRY_COUNT,
          value
        );
      },
    },
    retryMaxDate: {
      get() {
        if (this.getSelectedEvent?.appointment?.retryMaxDate === "Invalid Date")
          return null;
        else return this.getSelectedEvent?.appointment?.retryMaxDate;
      },
      set(value) {
        this.$store.commit(
          CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_RETRY_MAX_DATE,
          dayjs(value).format("DD.MM.YYYY")
        );
      },
    },
    insuranceNumber() {
      if (this.vsnr) {
        return `VSNR ${this.vsnr}`;
      }
      return null;
    },
    existsEvent() {
      return this.getSelectedEvent?.appointment?.sid != null;
    },

    getTitle() {
      if (!this.existsEvent) return "Termin erstellen";
      else return "Termin verarbeiten";
    },
    retryCombo() {
      if (
        !this.getAppointmentCombos ||
        (this.getAppointmentCombos && !this.getAppointmentCombos.retryCombo)
      ) {
        return [];
      }
      const keys = Object.keys(this.getAppointmentCombos.retryCombo);
      return keys.map((k) => ({
        label: this.getAppointmentCombos.retryCombo[k],
        value: k,
      }));
    },
    statusOptions() {
      if (
        !this.getAppointmentCombos ||
        (this.getAppointmentCombos &&
          !this.getAppointmentCombos.statusComboAufgabe)
      ) {
        return [];
      }
      const statusCombo =
        this.modus === "Aufgabe"
          ? this.getAppointmentCombos.statusComboAufgabe
          : this.getAppointmentCombos.statusComboWV;
      const keys = Object.keys(statusCombo);
      const result = [].concat(
        keys.map((k) => ({
          label: statusCombo[k],
          value: k,
        }))
      );
      if (!result.filter((r) => r.label === "Keine Angaben").length) {
        result.unshift({
          label: "Keine Angaben",
          value: "",
        });
      }
      return result;
    },
    sellingOptions() {
      if (
        !this.getAppointmentCombos ||
        (this.getAppointmentCombos &&
          !this.getAppointmentCombos.verkaufsphaseWVCombo)
      ) {
        return [];
      }
      const keys = Object.keys(this.getAppointmentCombos.verkaufsphaseWVCombo);
      return keys.map((k) => ({
        label: this.getAppointmentCombos.verkaufsphaseWVCombo[k],
        value: k,
      }));
    },
    artOptions() {
      if (
        !this.getAppointmentCombos ||
        (this.getAppointmentCombos && !this.getAppointmentCombos.artComboWV)
      ) {
        return [];
      }
      const keys = Object.keys(this.getAppointmentCombos.artComboWV);
      return keys.map((k) => ({
        label: this.getAppointmentCombos.artComboWV[k],
        value: k,
      }));
    },
    headers() {
      return {
        lockedLeft: [SlotColumn("dateiname", "Dateiname").makeAlwaysVisible()],
        lockedRight: [ActionColumn("actions", "")],
      };
    },
    rows() {
      if (!this.allAttachments) return [];
      const actions = [SimpleAction("DELETE", PhTrash, "Löschen")];
      return this.allAttachments.map((v) => ({
        ...v,
        dateiname: v.fileName || v.label,
        grosse: "",
        previewLink: v.id !== true,
        actions,
      }));
    },
    rolleOptions() {
      if (
        !this.getAppointmentCombos ||
        (this.getAppointmentCombos && !this.getAppointmentCombos.artComboWV)
      ) {
        return [];
      }
      const keys = Object.keys(this.getAppointmentCombos.rolleCombo);
      return keys.map((k) => ({
        label: this.getAppointmentCombos.rolleCombo[k],
        value: k,
      }));
    },
  },
  watch: {
    getAppointment(val) {
      if (val.files && val.files.length) {
        val.files.map((file) => {
          this.allAttachments.push({
            id: file.id,
            label: file.fileName,
            fileName: file.fileName,
            uploaded: true,
          });
        });
      }
      if (this.getSelectedEvent?.appointment) {
        if (!this.getSelectedEvent?.appointment?.sid) {
          this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT, {
            bereich: this.isBroker
              ? this.getSelectedEvent?.appointment?.bereich == "Termin" ||
                this.getSelectedEvent?.appointment?.bereich === "Aufgabe"
                ? this.getSelectedEvent?.appointment?.bereich
                : "Makler"
              : this.bereich ||
              this.getSelectedEvent?.appointment?.bereich ||
              "Kunde",
            bereichId:
              this.bereichId ||
              this.getSelectedEvent?.appointment?.bereichId ||
              this.loginData.userid,
          });
        } else {
          this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT, {
            bereich: this.getSelectedEvent?.appointment?.bereich || "",
            bereichId: this.getSelectedEvent?.appointment?.bereichId || "",
          });
        }
      }
      this.customerNr = val.customerNr;
      if (!val.beteiligteOutput) {
        return;
      }
      this.$store.commit(
        CALENDAR_TYPES.MUTATIONS.SET_BETEILIGTE_FOR_UPDATE,
        []
      );
      val.beteiligteOutput
        .filter((u) => u.beteiligte?.id)
        .map((v) => {
          this.$store.commit(
            CALENDAR_TYPES.MUTATIONS.ADD_BETEILIGTE_FOR_UPDATE,
            {
              ...v,
            }
          );
        });

      this.canEdit = val.updateable

      this.getModalTitle();
    },
    getAppointmentCombos(values) {
      if (!this.getSelectedEvent?.appointment?.sid) {
        if (!this.beteiligte.length && values?.initBeteiligte) {
          let initBeteiligte = values.initBeteiligte || [];
          if (this.isBereichId && this.isCustomer && !this.attachCustomer) {
            initBeteiligte = initBeteiligte.filter(
              (b) => b.customerId !== this.loginData.userid
            );
          }
          initBeteiligte = initBeteiligte.map((c) => ({
            ...c,
            canChange: c.rolle === "BESITZER" || c.rolle === "BEARBEITER" || c.canChange,
            alarmMail: (c.rolle === "BESITZER" || c.rolle === "BEARBEITER" || c.alarmMail) && !c.customerID,
            alarmMsc: (c.rolle === "BESITZER" || c.rolle === "BEARBEITER" || c.alarmMsc) && !c.customerID,
            alarmDailyMail: (c.rolle === "BESITZER" || c.rolle === "BEARBEITER" || c.alarmDailyMail) && !c.customerID,
          }));
          this.$store.commit(
            CALENDAR_TYPES.MUTATIONS.SET_BETEILIGTE_FOR_UPDATE,
            [...initBeteiligte, ...(this.additionalBeteiligte || [])]
          );
        }
      }
    },
    modus: "getModalTitle",
    subject(value) {
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_LABEL, value);
    },
  },
  methods: {
    handleAddUsers() {
      this.presaveAppointmentData();
      this.navigateTo(
        "/communication/calendar-customer-search/?back=" +
        this.getCurrentRoute()
      );
    },
    searchUsers(value) {
      this.timeoutRef && clearTimeout(this.timeoutRef);
      this.timeoutRef = setTimeout(() => {
        if (value && value.length > 2) {
          this.searchReceiverUsersLoading = true

          axios
            .get(
              `${process.env.VUE_APP_API}/QuickSearch/getQuickSearch?searchParam=${value}`
            )
            .then((response) => {
              this.availableUsers = response.data.cusomers.map((v) => ({
                value: v.customerID,
                label: `${v.firstName ? v.firstName + " " : ""}${v.lastName}`,
                email: v.email,
                data: v,
              }));

              const maklernr =
                (this.isBroker && this.loginData.userid) ||
                (this.isCustomer && this.loginData.maklernr);
              axios
                .get(
                  `${process.env.VUE_APP_API}/calendarService/getEmpfaenger?maklernr=${maklernr}`
                )
                .then((response) => {
                  this.availableUsers = this.availableUsers
                    .concat(
                      response.data.filter(
                        (v) =>
                          v.personId &&
                          v.name.toLowerCase().includes(value.toLowerCase())
                      )
                    )
                    .map((v) =>
                      v.value
                        ? v
                        : {
                          value: v.personId,
                          label: `${v.name}`,
                          data: v,
                          email: v.email,
                        }
                    );
                }).finally(() => {
                  this.searchReceiverUsersLoading = false
                })
            });
        }
      }, 300);
    },
    deleteUsers(value) {
      if (!value.id || value.id == null) {
        this.$store.commit(
          CALENDAR_TYPES.MUTATIONS.DELETE_BETEILIGTE_BY_VALUE,
          value.name
        );
      } else {
        let v = {};
        v.beteiligte = {};
        v.beteiligte.id = value.id;
        this.$store.dispatch(CALENDAR_TYPES.ACTIONS.DELETE_BETEILIGTE, v);
      }
    },
    getModalTitle() {
      if (this.$parent.$data?.titleSetFromChild !== undefined) {
        let title = this.modus + " ";
        if (this.modus === "Empfänger") {
          title += "auswählen";
        } else if (!this.modus) {
          title = " ";
        } else if (
          this.getAppointment?.appointment?.sid ||
          this.isLoadingAppoiment
        ) {
          title += "bearbeiten";
        } else {
          title += "erstellen";
        }
        this.$parent.$data.titleSetFromChild = title;
      }
    },
    getEmpfaenger() {
      const maklernr =
        (this.isBroker && this.loginData.userid) ||
        (this.isCustomer && this.loginData.maklernr);
      if (maklernr) {
        if (
          this.empfaenger?.length &&
          this.empfaenger[0].maklernr === maklernr
        ) {
          this.empfValues();
          this.showEmpfaenger = true;
        } else {
          this.$store
            .dispatch(CALENDAR_TYPES.ACTIONS.GET_EMPFAENGER, maklernr)
            .then((result) => {
              if (result.data?.length) {
                this.empfaenger = result.data.map((e) => {
                  e.maklernr = maklernr;
                  e.personId = e.personId || -1;
                  e.rolle = "TEILNEHMER";
                  e.label =
                    maklernr + (e.unternr !== -1 ? "-" + e.unternr : "");
                  return e;
                });
                this.empfValues();
                this.showEmpfaenger = true;
              }
            });
        }
      }
    },
    empfValues() {
      if (this.empfaenger?.length) {
        let exclude = ",";
        this.beteiligteValue.forEach((bet) => {
          exclude += (bet.personId || -1) + ",";
        });
        const result = [];
        let idx = 0;
        this.empfaenger.forEach((empf) => {
          if (-1 === exclude.indexOf("," + empf.personId + ",")) {
            result.push({ ...empf, index: idx++ });
          }
        });
        this.rowsEmpf = result;
      } else {
        this.rowsEmpf = [];
      }
    },
    onChangeEmpf(index, key, value) {
      this.rowsEmpf[index][key] = value;
    },
    setEmpfaenger() {
      this.showEmpfaenger = false;

      const { alarmMail, alarmMSC, alarmTagesmail } = this.alarmConfig;

      this.selectedEmpf.forEach((e) => {
        const beteiligte = {
          id: null,
          name: e.name,
          email: e.email,
          personId: e.personId === -1 ? null : e.personId,
          brokerId: e.personId === -1 ? e.maklernr : null,
          canChange:
            e.canChange !== undefined
              ? e.canChange
              : e.rolle === "BESITZER" || e.rolle === "BEARBEITER",
          alarmMail:
            e.alarmMail !== undefined
              ? e.alarmMail
              : (e.rolle === "BESITZER" || e.rolle === "BEARBEITER" ||alarmMail) && !e.customerID,
          alarmMsc:
            e.alarmMsc !== undefined
              ? e.alarmMsc
              : (e.rolle === "BESITZER" || e.rolle === "BEARBEITER" || alarmMSC) && !e.customerID,
          alarmDailyMail:
            e.alarmDailyMail !== undefined
              ? e.alarmDailyMail
              : (e.rolle === "BESITZER" || e.rolle === "BEARBEITER" || alarmTagesmail) && !e.customerID,
          rolle: e.rolle || "TEILNEHMER",
        };
        this.$store.commit(
          CALENDAR_TYPES.MUTATIONS.ADD_BETEILIGTE_FOR_UPDATE,
          beteiligte
        );
      });

      this.selectedEmpf = [];
      // this.rowsEmpf = [];
    },
    shallowCopy(src) {
      return Object.assign({}, src);
    },
    createAppointment() {
      if (!this.getSelectedEvent?.appointment?.sid) {
        if (this.getAppointmentCombos?.initBeteiligte) {
          let initBeteiligte = this.getAppointmentCombos.initBeteiligte || [];
          if (this.isBereichId && this.isCustomer && !this.attachCustomer) {
            initBeteiligte = initBeteiligte.filter(
              (b) => b.customerId !== this.loginData.userid
            );
          }
          initBeteiligte = initBeteiligte.map((c) => ({
            ...c,
            canChange: c.rolle === "BESITZER" || c.rolle === "BEARBEITER" || c.canChange,
            alarmMail: (c.rolle === "BESITZER" || c.rolle === "BEARBEITER" || c.alarmMail) && !c.customerID,
            alarmMsc: (c.rolle === "BESITZER" || c.rolle === "BEARBEITER" || c.alarmMsc) && !c.customerID,
            alarmDailyMail: (c.rolle === "BESITZER" || c.rolle === "BEARBEITER" || c.alarmDailyMail) && !c.customerID,
          }));
          this.$store.commit(
            CALENDAR_TYPES.MUTATIONS.SET_BETEILIGTE_FOR_UPDATE,
            [...initBeteiligte, ...(this.additionalBeteiligte || [])]
          );
        } else {
          this.$store.commit(
            CALENDAR_TYPES.MUTATIONS.SET_BETEILIGTE_FOR_UPDATE,
            []
          );
        }
        if (!this.getSelectedEvent?.appointment?.begin) {
          this.$store.commit(
            CALENDAR_TYPES.MUTATIONS.SET_SELECTED_APPOINTMENT_DATA,
            {
              appointment: {
                appointment: {
                  ...this.getEmptyAppointment,
                  bereich: this.getSelectedEvent?.appointment?.bereich,
                },
              },
              day: this.thisday,
            }
          );
        }
      }
    },
    async saveAppointment() {
      this.loading = true;
      this.$store.commit(
        CALENDAR_TYPES.MUTATIONS.SET_ATTACHMENT,
        this.allAttachments
      );
      let sid = null;
      await this.$store
        .dispatch(CALENDAR_TYPES.ACTIONS.SAVE_APPOINTMENT)
        .then((isSaved) => {
          this.$emit("renew-view", true);
          this.$store.commit(
            CALENDAR_TYPES.MUTATIONS.SET_SELECTED_APPOINTMENT_DATA,
            {
              appointment: {
                appointment: { ...this.getEmptyAppointment },
              },
              day: this.thisday,
            }
          );
          sid = isSaved?.data?.appointment?.sid;
          this.discardChanges();
        })
        .finally(() => (this.loading = false));
      return sid;
    },
    navigateTo(event) {
      this.$router.push({ path: `${event}` });
    },
    getCurrentRoute() {
      return this.$router.currentRoute.path;
    },
    presaveAppointmentData() {
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.PRESAVE_APPOINTMET, {
        ...this.getSelectedEvent,
        appointment: {
          activity: this.isActivity,
          ...this.getSelectedEvent.appointment,
          begin: `${typeof this.beginDate === "string"
            ? this.beginDate
            : dayjs(this.beginDate).format("DD.MM.YYYY")
            } ${this.beginTime}`,
          end: `${typeof this.endDate === "string"
            ? this.endDate
            : dayjs(this.endDate).format("DD.MM.YYYY")
            } ${this.endTime}`,
        },
        allAttachments: this.allAttachments,
        beteiligte: this.beteiligteValue,
      });
    },
    addUserVermittler() {
      this.presaveAppointmentData();
      this.navigateTo(
        "/communication/calendar-customer-search/broker?back=" + this.getCurrentRoute()
      );
    },
    addUserKunde() {
      this.presaveAppointmentData();
      this.navigateTo(
        "/communication/calendar-customer-search/?back=" +
        this.getCurrentRoute()
      );
    },
    addUserGesellschaft() {
      this.presaveAppointmentData();
      this.$addBreadcrumb({
        label: `zurück zu ${this.$route.meta?.breadcrumb?.label}`,
        fullPath: this.$route.fullPath,
        breadcrumb: "Ansprechpartner hinzufügen",
      });
      this.navigateTo(
        "/communication/calendar-customer-search/company?back=" + this.getCurrentRoute()
      );
    },
    addUserKontakt() {
      this.presaveAppointmentData();
      this.$addBreadcrumb({
        label: `zurück zu ${this.$route.meta?.breadcrumb?.label}`,
        fullPath: this.$route.fullPath,
        breadcrumb: "Kontakte hinzufügen",
      });
      this.navigateTo(
        "/communication/calendar-customer-search/contact?back=" + this.getCurrentRoute()
      );
    },

    onChange(prop) {
      if (prop.data) {
        this.$store.commit(
          CALENDAR_TYPES.MUTATIONS.UPDATE_BETEILIGTE,
          prop.data
        );
      }
    },

    setBesitzer(value) { },
    copyAddress(value) {
      if (!value?.address) {
        this.$store.dispatch(
          LOG_TYPES.ACTIONS.ADD_MESSAGE,
          buildMessage(`${value.name} hat keine Adresse hinterlegt`, "warning")
        );
        return;
      }
      this.place = value.address;
    },
    onAddParticipant(prop) {
      const el = prop.findLast((v) => !v.id);
      if (el) {
        const user = this.availableUsers.find(
          (v) => v.value === el.value || (!el.value && v.email === el.email)
        );
        if (user) {
          const extractAddress = (obj) => {
            if (obj.street || obj.zip || obj.city) {
              return `${obj.street}, ${obj.zip} ${obj.city}`;
            }
            return null;
          };

          const { alarmMail, alarmMSC, alarmTagesmail } = this.alarmConfig;

          if(user.data) {
            const c = user.data;

            if(!c.personId && !c.customerID && !c.userId) return;

            user.data = {
              //...data,
              id: c.id,
              name: user.label,
              email: user.email,
              personId: c.personId,
              customerId: c.customerID,
              brokerId: c.userId,
              canChange:
                c.canChange !== undefined
                  ? c.canChange
                  : c.rolle === "BESITZER" || c.rolle === "BEARBEITER",
              alarmMail:
                c.alarmMail !== undefined
                  ? c.alarmMail
                  : (c.rolle === "BESITZER" || c.rolle === "BEARBEITER" || alarmMail) && !c.customerID,
              alarmMsc:
                c.alarmMsc !== undefined
                  ? c.alarmMsc
                  : (c.rolle === "BESITZER" || c.rolle === "BEARBEITER" || alarmMSC) && !c.customerID,
              alarmDailyMail:
                c.alarmDailyMail !== undefined
                  ? c.alarmDailyMail
                  : (c.rolle === "BESITZER" || c.rolle === "BEARBEITER" || alarmTagesmail) && !c.customerID,
              rolle: c.rolle || "TEILNEHMER",
              address: extractAddress(c),
            };
            this.$store.commit(
              CALENDAR_TYPES.MUTATIONS.ADD_BETEILIGTE_FOR_UPDATE,
              user.data
            );
          }
        }
      }
    },
    addDocument(files) {
      files &&
        files.map((file) => {
          uploadFileChunked(file).then((id) => {
            this.allAttachments.push({
              id: id,
              label: file.name,
              fileName: file.name,
              uploaded: true,
            });
          });
        });
    },
    handleAddDocuments() {
      this.presaveAppointmentData();
      this.navigateTo(
        "/communication/mailcomposer-dokumentenarchiv/calendar?back=" +
        this.getCurrentRoute()
      );
    },
    onAddFiles(event) {
      this.allAttachments = this.allAttachments || [];
      const files = Object.values(event.target.files);
      files.map((file) => {
        uploadFileChunked(file).then((id) => {
          this.allAttachments.push({
            id: id,
            label: file.name,
            fileName: file.name,
            uploaded: true,
          });
          this.allAttachments = [...this.allAttachments];
        });
      });
      event.target.value = "";
    },
    actionDelete(row) {
      const index = this.allAttachments.findIndex(
        (a) => a.id === row.id && a.copyFileId === row.copyFileId
      );
      this.$store.dispatch(CALENDAR_TYPES.ACTIONS.DELETE_ATTACHMENT, row.id);
      if (index > -1) {
        this.allAttachments.splice(index, 1);
      }
    },
    async deleteAppointment() {
      this.loading = true;
      await this.$store.dispatch(CALENDAR_TYPES.ACTIONS.DELETE_APPOINTMENT);
      this.loading = false;
      this.discardChanges();
      this.$emit("renew-view", true);
    },
    async mailSend(selectedParticipants = []) {
      const selectedBeteiligtes =
        selectedParticipants && selectedParticipants.length
          ? selectedParticipants
          : this.beteiligteValue;
      const sid = await this.saveAppointment();
      const beteiligtes =
        selectedBeteiligtes.map((value) => value.id || value.value) ||
        this.getSelectedEvent.appointment.beteiligtes.map(
          (value) => value.id || value.value
        );
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAILCOMPOSER_CONFIG, {
        type: "mailcomposer-appointment",
        action: "clear",
        receiver: [],
      });
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, {
        type: "mailcomposer-appointment",
        action: "clear-cache",
      });
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAIL_LINKED_OBJECT, {
        data: beteiligtes,
        toCompany: false,
        participants:
          selectedParticipants && selectedParticipants.length
            ? selectedParticipants
            : undefined,
        place: this.place,
      });
      this.$router.push(
        `/communication/mailcomposer-appointment/${this.getSelectedEvent
          ?.appointment?.sid || sid}`
      );
    },
    setAddress(address) {
      this.$store.commit(
        CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_PLACE,
        address
      );
    },
    isReceiveUserClickable(receiveUser) {
      return (
        receiveUser.value === "..." ||
        (!!receiveUser?.customerId &&
          receiveUser.customerId !== this.loginData.userid)
      );
    },
    openReceiveUser(receiveUser) {
      this.openCustomer(receiveUser);
    },
    openCustomer(receiveUser) {
      if (!receiveUser?.customerId) return;
      this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, {
        customerId: receiveUser?.customerId,
      });
    },
    openInsurance() {
      if (this.isBroker || this.isIntern) {
        this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, {
          customerId: this.customerNr,
          insurances: true,
          path: `/home/versicherungen/insurance-group/overview/${this.versId}`,
        });
      } else {
        this.$router.push(
          `/home/versicherungen/insurance-group/overview/${this.versId}`
        );
      }
    },
    openSchaden() {
      if (this.isBroker || this.isIntern) {
        this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, {
          customerId: this.customerNr,
          insurances: true,
          path: `/home/versicherungen/insurance-group/schadensmeldung/${this.versId}/schadennr/${this.schadenId}`,
        });
      } else {
        this.$router.push(
          `/home/versicherungen/insurance-group/schadensmeldung/${this.versId}/schadennr/${this.schadenId}`
        );
      }
    },
    getDefaultPermission(rolle) {
      if (this.bereich === "Termin" && rolle === "TEILNEHMER") {
        return true;
      }
      return rolle === "BEARBEITER" || rolle === "BESITZER";
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.$store.dispatch(
        CALENDAR_TYPES.ACTIONS.RETRIEVE_SELECTED_APPOINTMENT,
        this.$route.params.id
      );
    }
    if (this.noInitialise) {
      return;
    }
    if (this.presavedAppointment) {
      this.$store.commit(
        CALENDAR_TYPES.MUTATIONS.SET_SELECTED_APPOINTMENT_DATA,
        {
          appointment: {
            appointment: this.presavedAppointment?.appointment,
          },
          day: this.presavedAppointment.day || this.thisday,
        }
      );
      this.allAttachments = this.presavedAppointment.allAttachments;
      this.$store.commit(
        CALENDAR_TYPES.MUTATIONS.SET_BETEILIGTE_FOR_UPDATE,
        this.presavedAppointment.beteiligte
      );
      if (
        this.selectedCustomersExternal &&
        Array.isArray(this.selectedCustomersExternal) &&
        this.selectedCustomersExternal.length
      ) {
        const { alarmMail, alarmMSC, alarmTagesmail } = this.alarmConfig;

        //Broker have userId , Kontakte and Ansprechpartner have personId, Customer has customerID
        this.selectedCustomersExternal.forEach((c) => {
          const beteiligte = {
            id: null,
            name:
              (c.email && c.name && `${c.name}`) ||
              (c.fullName &&
                `${c.fullName}${c.email ? " <" + c.email + ">" : ""}`) ||
              (c.data && c.data?.fullName) ||
              c.name,
            email: c.email,
            personId: c.personId,
            customerId: c.customerID,
            brokerId: c.userId,
            canChange:
              c.canChange !== undefined
                ? c.canChange
                : this.getDefaultPermission(c.rolle || "TEILNEHMER"),
            alarmMail:
              c.alarmMail !== undefined
                ? c.alarmMail
                : (this.getDefaultPermission(c.rolle || "TEILNEHMER") || alarmMail) && !c.customerID,
            alarmMsc:
              c.alarmMsc !== undefined
                ? c.alarmMsc
                : (this.getDefaultPermission(c.rolle || "TEILNEHMER") || alarmMSC) && !c.customerID,
            alarmDailyMail:
              c.alarmDailyMail !== undefined
                ? c.alarmDailyMail
                : (this.getDefaultPermission(c.rolle || "TEILNEHMER") || alarmTagesmail) && !c.customerID,
            rolle: c.rolle || "TEILNEHMER",
            address: c.adresse || `${c.street}, ${c.plz} ${c.location}`,
          };
          this.$store.commit(
            CALENDAR_TYPES.MUTATIONS.ADD_BETEILIGTE_FOR_UPDATE,
            beteiligte
          );
        });

        this.$store.commit(
          CUSTOMER_SEARCH_TYPES.MUTATIONS.SET_SELECTED_CUSTOMERS,
          []
        );
      }
    } else {
      this.createAppointment();
    }

    if (this.subject) {
      this.$store.commit(
        CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_LABEL,
        this.subject
      );
    }

    if (this.attachments && this.attachments && this.attachments.length) {
      const params = this.attachments
        .filter((v) => v?.node && !v.uploaded)
        .map((v) => ({ nodeId: v?.node, fileId: v?.id }));
      if (params.length) {
        axios
          .post(`${process.env.VUE_APP_API}/documents/getTempFiles`, params)
          .then((response) => {
            response?.data?.map((f) => {
              this.allAttachments.push({
                id: f.id,
                label: f.name,
                fileName: f.name,
              });
            });
          })
          .catch((error) => {
            this.$store.dispatch(
              LOG_TYPES.ACTIONS.ADD_MESSAGE,
              buildMessage(
                "Beim importieren des Filters ist ein Fehler aufgetreten.",
                "danger"
              )
            );
          });
      } else {
        this.attachments
          .filter((v) => v?.preloaded)
          .map((f) => {
            this.allAttachments.push({
              id: f.id,
              label: f.name,
              fileName: f.name,
            });
          });
      }
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, null);
    }
    if (this.allAttachments && this.allAttachments.length) {
      this.allAttachments = [
        ...new Set(this.allAttachments.map((obj) => obj.id || 0)),
      ].map((value) => {
        return this.allAttachments.find((obj) => obj.id === value);
      });
    }
    this.$store.commit(
      CUSTOMER_FILTERS_TYPES.MUTATIONS.RENEW_SELECTED_CUSTOMERS
    );

    if (!this.presavedAppointment) {
      this.createAppointment();
    } else {
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.PRESAVE_APPOINTMET, null);
    }
    if (this.selectedDate) {
      this.$store.commit(
        CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_BEGIN_DATE,
        dayjs(this.selectedDate).format("DD.MM.YYYY")
      );
      this.$store.commit(
        CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_END_DATE,
        dayjs(this.selectedDate).format("DD.MM.YYYY")
      );
    }
    if (this.subject) {
      this.$store.commit(
        CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_LABEL,
        this.subject
      );
    }
    if (this.status) {
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT, {
        status: this.status,
      });
    }
    this.getModalTitle();
  },
  beforeDestroy() {
    this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_SELECTED_APPOINTMENT_DATA, {
      appointment: {
        appointment: { ...this.getEmptyAppointment },
      },
      day: this.thisday,
    });
    this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_BETEILIGTE_FOR_UPDATE, []);
  },
};
</script>
<style scoped>
.mr-16 {
  margin-right: 16px;
}

.event-body {}

.fieldset_class {
  /* padding: 1em;
  margin: 1em; */
  max-width: 100%;
  min-width: 10%;
}

.event-header {
  font-size: large;
  font-weight: bold;
}

.limited_inputfield {
  max-width: 100%;
  min-width: 10%;
}

.inputtext__input {
  /* border: 1px solid rgba(0, 0, 0, 0.15); */
  border-radius: 2px;
  padding: 0.5em;
  width: 95%;
}

.large_inputfield {
  width: 100%;
}

.gridcheck {
  justify-self: center;
  align-self: center;
}

.gridlabel {
  justify-self: end;
  align-self: center;
  text-align: end;
  padding-right: 0.5em;
}

.data-row {
  padding-top: 1em;
  display: grid;
  grid-template-columns: 1fr 4fr;
  justify-self: end;
}

.data-double {
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-width: 10%;
  justify-self: stretch;
  column-gap: 0.5em;
  grid-gap: 1rem;
}

.flex_grow_1 {
  flex-grow: 1;
}

.items-center {
  align-items: center;
}

.data-triple {
  padding-top: 1em;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  min-width: 10%;
  column-gap: 0.5em;
  justify-self: stretch;
}

.pl-all>div:first-child {
  padding-right: 0.5rem;
}

.pl-3 {
  padding-left: 3rem;
}

.pr-3 {
  padding-right: 3rem;
}

.padding-top {
  padding-top: 0rem;
}

.upload-btn {
  position: relative;
}

.upload-btn input {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

@media only screen and (max-width: 1024px) {
  .data-double {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    justify-self: center;
    grid-gap: 0;
    widows: 100%;
  }

  .action-buttons>button {
    margin-top: 1rem;
    width: 100%;
  }

  .gridlabel {
    align-self: start;
    justify-self: center;
  }

  .padding-top {
    padding-top: 1rem;
  }

  .mr-16 {
    margin-right: 0px;
  }

  .justify-between {
    justify-content: space-between;
  }
}

@media only screen and (max-width: 1200px) {
  .event-body {
    margin-left: 0em;
  }

  .large_inputfield {
    max-width: 70%;
  }

  .fieldset_class {
    padding: 0.3em;
    margin: 0.3em;
  }

  .data-double {
    justify-self: center;
    width: 100%;
  }

  .data-triple {
    padding-top: 0.3em;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }

  .data-row {
    padding-top: 0.3em;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    justify-content: center;
  }

  .gridlabel {
    align-self: start;
    justify-self: center;
  }

  .gridcheck {
    padding-top: 0.3em;
  }
}
</style>
