<template>
    <div class="cards__container">
        <div v-if="positionsSorted && positionsSorted.length" class="cards__items">
            <div class="box__container cards__item" v-for="(pos, index) in positionsSorted" :key="pos.isin + index" 
                :class="getPositionClasses(pos)" ref="cards"
            >
                <PageHeaderTitleNavigation 
                    :id="pos.isin + index" 
                    :actions="headerActions(pos)" 
                    :noPrimaryAction="true"
                    :showBackButton="false"
                    v-on="$listeners"
                    @action-REMOVE="openModalDeleteFond(pos)"
                    @action-AA="setAAForAll(pos.inputAA)"
                >
                    
                    <template #title><slot name="title">
                        <div class="cards__item--header ">
                            <a @click="gotoFondsinfo(pos)">{{ getWertpapiernameStr(pos, false) }}</a>
                            <span class="text-divider">|</span>
                            <span :class="getColorClass(pos)"> {{getOrderTyp(pos)}} </span>
                        </div>
                    </slot></template>
                    <template #subtitle><slot name="subtitle">
                        <div class="font-bold">
                            <span>ISIN: {{pos.isin}}</span>
                            <span class="text-divider">|</span>
                            <span>WKN: {{pos.wkn}}</span>
                            <span class="text-divider">|</span>
                            <span>SRI {{pos.sri}}</span>
                            <span v-if="isNurAnteile(pos)"> | {{ pos.produktArt }}</span>
                        </div>
                    </slot></template>
                </PageHeaderTitleNavigation>      


                <div class="cards__item--inputs">                    
                    <InputToggleSwitch v-if="pos.lagerstelle === 'CAPITALBANK' && (pos.positionTyp == 'ALT_KAUF' || pos.positionTyp == 'NEU_KAUF')"
                        isEmbedded   
                        label="Ca. Auftrag"
                        :value="pos.isCaAuftrag" 
                        :id="'isCaAuftrag' + pos.index"
                        :disabled="readOnly || pos.hellobankOrderart == 'Limit' || pos.hellobankOrderart == 'Stop' "
                        @input="assureOnlyOneCheckboxIsChecked($event, pos, 'isCaAuftrag')"
                              
                    />
                    <InputField 
                        isEmbedded
                        type="number" 
                        label="Auftragshöhe"
                        :value="pos.betrag === 0 ? '' : pos.betrag" 
                        :precision="getPrecision(pos)"
                        :id="'betrag' + pos.index" 
                        :disabled="readOnly || pos.betragModus === 'VALUE_BETRAG_MODUS_ALLE_ANTEILE' || pos.auftragArt === 'LOESCHEN' || !!pos.comdirectBoOrderId"
                        @inputRawValue="onChangeBetrag($event, pos, 'betrag')"
                        @change="onChangeBetrag($event, pos, 'betrag')"  />
                    <ComboBox
                        class="cards__item--input-no-label"
                        isEmbedded
                        :value="pos.betragModus"
                        :id="'betragModus' + pos.index"
                        :disabled="readOnly || pos.istEbaseTauschRegelmaessig == 'true' || pos.istEbaseTauschRegelmaessig == true || pos.auftragArt === 'LOESCHEN' || !!pos.comdirectBoOrderId"
                        :values="betragModusValues(pos)"
                        @change="modusChanged(pos, $event)">
                    </ComboBox>

                    <InputField v-if="hasInputAA(pos) && (inputAAType(pos) === 'INPUT_AA' || inputAAType(pos) === 'INPUT_BONUS')"
                        isEmbedded
                        type="percent" 
                        :precision="inputAAType(pos) === 'INPUT_AA' ? 5 : 2"
                        :label="pos.inputAAComponents.label"
                        :value="pos.inputAA" 
                        :id="'inputAA' + pos.index"
                        :disabled="readOnly || !!pos.comdirectBoOrderId || pos.comdirectBoModus == 'K'"
                        @inputRawValue="onChangeInputField(!$event ? 0 : $event, pos, 'inputAA')"
                        @change="onChangeInputField(!$event ? 0 : $event, pos, 'inputAA')" 
                    />
                    <div class="cards__item--input-240" v-else-if="hasInputAA(pos) && (inputAAType(pos) === 'COMBO_BONUS' || inputAAType(pos) === 'COMBO_RABATT')">
                        <ComboBox
                            :label="pos.inputAAComponents.label"
                            :disabled="readOnly"
                            :value="pos.inputAA" 
                            :id="'inputAA' + pos.index"
                            :values="pos.inputAAComboboxValues || []"
                            :sortComboboxValues="false"
                            @change="onChangeInputField($event, pos, 'inputAA')"
                        />
                    </div>

                    <div v-if="getAuftragArtValues(pos)" :class="{'cards__item--input-240': pos.lagerstelle === 'FODB'}">
                        <ComboBox 
                            label="Verwendung"
                            :disabled="readOnly"
                            :value="pos.auftragArt" 
                            :id="'auftragArt' + pos.index"
                            :values="getAuftragArtValues(pos) || []"
                            :sortComboboxValues="false"
                            @change="onChangeAuftragArt($event, pos)" 
                        />
                    </div>
                    <div :class="{'cards__item--input-295': pos.lagerstelle === 'FODB'}" v-if="pos && pos.hasPlannummer">
                        <ComboBox 
                            isEmbedded
                            :label="pos.lagerstelle === 'FODB' ? 'Bestehender Plan' : 'Plannummer'"
                            :disabled="readOnly || pos.auftragArt === 'NEU'"
                            :value="pos.plannummer" 
                            :id="'plannummer' + pos.index"
                            :values="pos.plannummerComboboxValues || []"
                            @change="onChangeInputField($event, pos, 'plannummer')" 
                        />
                        <div class="plannummer-info">{{' nur für Änderung und Löschung relevant'}}</div>
                    </div>

                    <ComboBox v-if="hasBoerseData(pos) && (pos.lagerstelle === 'HELLOBANK' || pos.lagerstelle === 'CAPITALBANK')"
                        isEmbedded
                        label="Orderart"
                        :value="pos.hellobankOrderart"
                        :id="'hellobankOrderart' + pos.index"
                        :disabled="readOnly"
                        :values="orderartValues(pos)"
                        @change="updateBoersenOrder($event, pos, 'hellobankOrderart')">
                    </ComboBox>
                   
                    <ComboBox v-if="hasBoerseData(pos) && pos.lagerstelle === 'COMDIRECT'"
                        isEmbedded
                        label="Orderart"
                        :value="pos.comdirectBoModus"
                        :id="'comdirectBoModus' + pos.index"
                        :disabled="readOnly"
                        :values="orderartValuesComdirect(pos)"
                        @change="updateBoersenOrder($event, pos, 'comdirectBoModus')">
                    </ComboBox>
                      <ComboBox v-if="pos.positionTyp == 'ALT_TAUSCH' && (pos.lagerstelle === 'EBASE' || pos.lagerstelle == 'EBASE_AAB')"
                        isEmbedded
                        label="Art"
                        :value="'false'"
                        :id="'istEbaseTauschRegelmaessig' + pos.index"                    
                        :values="[{label:'einmalig', value:'false'}]"
                        :disabled="readOnly"
                        @change="updateEbaseUmschichtungArt($event, pos, 'istEbaseTauschRegelmaessig')">
                    </ComboBox>
                     <div class="cards__item--input-295" v-if="pos.positionTyp == 'ALT_TAUSCH' && pos.lagerstelle === 'FODB'"> 
                        <ComboBox 
                            isEmbedded
                            label="Zieldepot"
                            :value="pos.tauschInDepot"
                            :id="'tauschInDepot' + pos.index" 
                            :disabled="readOnly"
                            :values="tauschInDepotValues"
                            @change="onChangeInputField($event, pos, 'tauschInDepot')"
                        />
                    </div>
                    <InputField v-if="pos.tauschInDepot === 'freie Eingabe:' && pos.positionTyp == 'ALT_TAUSCH' && pos.lagerstelle === 'FODB'"
                        isEmbedded
                        class="comboalign"
                        :id="'tauschInDepotText' + pos.index" 
                        :value="pos.tauschInDepotText || ''"
                        :disabled="readOnly"
                        @change="onChangeInputField($event || '', pos, 'tauschInDepotText')"
                    />
                </div>

                <div>
                    <template v-if="hasInputAA(pos)">
                        <div class="font-bold" v-if="inputAAType(pos) === 'INPUT_AA'">
                            Ausgabeaufschlag: normal {{displayAANormal(pos)}}%
                            <span v-if="displayAANormal(pos) > 0">, entspricht {{displayAARabatt(pos)}}% Rabatt</span>
                        </div>
                        <div class="font-bold" v-else>
                            {{ pos.inputAAComponents.comment }}
                        </div>
                    </template>
                    
                    <template>
                        <WertpapierorderAnteileInfo :position="pos" />
                    </template>

                    <div class="color-danger" v-if="getWarningText(pos)">{{ getWarningText(pos) }}</div>
                </div>

                <template v-if="pos.hellobankOrderart && (pos.lagerstelle === 'HELLOBANK' || pos.lagerstelle === 'CAPITALBANK')">
                    <div>
                        <div class="font-bold mt-4">
                            Achtung: die folgenden Angaben werden nur berücksichtigt, wenn das Wertpapier über eine Börse gehandelt wird.
                        </div>

                        <div class="cards__item--inputs">
                            <InputField 
                                label="Börse"
                                :id="'hellobankBoerse' + pos.index"
                                :value="pos.hellobankBoerse"
                                :disabled="readOnly"
                                @change="onChangeInputField($event, pos, 'hellobankBoerse')"
                            />
                            <InputField 
                                :class="{
                                    'cards__item--input-240': pos.lagerstelle === 'HELLOBANK',
                                }"
                                type="currency"
                                :id="'hellobankKurs' + pos.index"
                                :label="pos.lagerstelle === 'HELLOBANK' ? 'Kurs, falls Limit- oder Stoporder' : 'Kurs'"
                                :value="pos.hellobankKurs"
                                :validateUntouched="true"
                                :disabled="readOnly || pos.hellobankOrderart === 'Bestens'"
                                @change="onChangeInputField($event, pos, 'hellobankKurs')"
                            />
                             <ComboBox v-if="pos.lagerstelle === 'CAPITALBANK'" 
                                label="Währung"
                                :id="'boerseWaehrung' + pos.index"
                                :value="pos.boerseWaehrung"
                                :values="waehrungen"
                                :disabled="readOnly"
                                @change="onChangeInputField($event, pos, 'boerseWaehrung')"
                            />
                            <DatePickerField 
                                label="Gültigkeit bis"
                                :id="'hellobankGueltigkeit' + pos.index"
                                :value="pos.hellobankGueltigkeit"
                                isValueAsString
                                :validateUntouched="true"
                                :showPreset="false"
                                :disabled="readOnly"
                                @change="onChangeInputField($event, pos, 'hellobankGueltigkeit')"
                            />
                            <ComboBox v-if="pos.lagerstelle === 'HELLOBANK' && pos.positionTyp !== 'ALT_VERKAUF'" 
                                label="Art des Kaufs"
                                :id="'hellobankIstZeichnung' + pos.index"
                                :value="pos.hellobankIstZeichnung"
                                :values="[{ label: 'Kauf', value: 'KF'}, { label: 'Zeichnung', value: 'ZE'}]"
                                :disabled="readOnly"
                                @change="onChangeInputField($event, pos, 'hellobankIstZeichnung')"
                            />
                        </div>
                    </div>
                </template>

                <template v-if="pos.comdirectBoModus && pos.comdirectBoModus !== 'NICHT' && pos.comdirectBoModus !== 'LAST'">
                    <div class="cards__item--inputs">
                        <DatePickerField
                            label="Gültigkeit bis"
                            :id="'comdirectBoGueltigkeit' + pos.index"
                            :value="pos.comdirectBoGueltigkeit"
                            isValueAsString
                            :validateUntouched="true"
                            :showPreset="false"
                            :disabled="readOnly || (!!pos.comdirectBoOrderId && pos.comdirectBoModus == 'ST') || !supportsComdirectGueltigkeit(pos)"
                            @change="onChangeInputField($event, pos, 'comdirectBoGueltigkeit')"
                        />
                        <InputField
                            label="Limit"
                            :id="'comdirectBoLimit' + pos.index"
                            :value="pos.comdirectBoLimit"
                            :validateUntouched="true"
                            :disabled="readOnly || (!!pos.comdirectBoOrderId && pos.comdirectBoModus == 'ST') || !supportsComdirectLimitOrder(pos)"
                            @change="onChangeInputField($event, pos, 'comdirectBoLimit')"
                        />
                        <ComboBox
                            label="Orderzusatz"
                            :id="'comdirectBoOrderzusatz' + pos.index"
                            :value="pos.comdirectBoOrderzusatz"
                            :values="getOrderzusatzValues(pos)"
                            :validateUntouched="true"
                            :disabled="readOnly || !!pos.comdirectBoOrderId || getOrderzusatzValues(pos).length == 0"
                            @change="onChangeInputField($event, pos, 'comdirectBoOrderzusatz')"
                        />
                        <InputField v-if="!venuesAvailable(pos)"
                            label="Ausführungsplatz"
                            :id="'comdirectBoAusfuehrungsplatz' + pos.index"
                            :value="pos.comdirectBoAusfuehrungsplatz"
                            :validateUntouched="true"
                            :disabled="readOnly || !!pos.comdirectBoOrderId"
                            @change="onChangeComdirectAusfuehrungsplatz($event, pos, 'comdirectBoAusfuehrungsplatz')"
                        />
                        <ComboBox v-if="venuesAvailable(pos)"
                            label="Ausführungsplatz"
                            :id="'comdirectBoAusfuehrungsplatz' + pos.index"
                            :value="pos.comdirectBoAusfuehrungsplatz"
                            :values="getVenues(pos)"
                            :validateUntouched="true"
                            :disabled="readOnly || !!pos.comdirectBoOrderId"
                            @change="onChangeComdirectAusfuehrungsplatz($event, pos, 'comdirectBoAusfuehrungsplatz')"
                        />
                        <InputField v-if="!pos.comdirectWebserviceActive"
                            label="Hinweise"
                            :id="'comdirectBoHinweise' + pos.index"
                            :value="pos.comdirectBoHinweise"
                            :validateUntouched="true"
                            :disabled="readOnly || !!pos.comdirectBoOrderId"
                            @change="onChangeInputField($event, pos, 'comdirectBoHinweise')"
                        />
                    </div>
                </template>
                <div v-if="getTauschStr(pos)">
                    {{getTauschStr(pos)}}
                    <!--
                    <div v-if="(pos.lagerstelle == 'EBASE' || pos.lagerstelle == 'EBASE_AAB') && pos.positionTyp == 'ALT_TAUSCH'">
                         <div>
                            
                               <InputToggleSwitch
                               isEmbedded   
                                label="oder in eine neue Depotposition (zur Bestandstrennung wg. Altbestand)"
                                :value="pos.istNeuPos" 
                                :id="'istNeuPos' + pos.index"
                                :disabled="readOnly"
                                @input="onChangeInputField($event, pos, 'istNeuPos')"
                                />
                         </div>
                    </div> -->
                </div>

                <div v-if="isNurAnteile(pos)">
                    Im Regelfall nur in ganzen Anteilen möglich.
                </div>
            </div>
        </div>
        <GhostLoading v-else-if="isWertpapierorderLoading" useBoxContainer class="mb-4">
            <Block type="title" class="mb-4" />
            <Block height="120px" />
        </GhostLoading>
        <div v-else class="box__container"><NoData noIcon content="Keine Positionen" /></div>

        <div class="row my-0">
            <div class="col col-12 col-sm-6 mb-4 mb-sm-0">
                <div class="box__container m-0">
                    <div>
                        <CurrencyLabel label="Einmalanlagen:" :value="einmalSum"  showInlineLabel/>
                        <CurrencyLabel label="Verkäufe:" :value="verkaufSum"  showInlineLabel/>
                        <hr>
                        <CurrencyLabel label="Gesamt:" :value="einmalGesamtSum"  showInlineLabel/>
                    </div>
                </div>
            </div>
            <div class="col col-12 col-sm-6">
                <div class="box__container m-0">
                    <div>
                        <CurrencyLabel label="monatliche Anlage:" :value="sparplanSum"  showInlineLabel/>
                        <CurrencyLabel label="monatliche Entnahme:" :value="entnahmeSum"  showInlineLabel/>
                        <hr>
                        <CurrencyLabel label="Gesamt:" :value="monatGesamtSum"  showInlineLabel/>
                    </div>
                </div>
            </div>
        </div>

        <DeletePositionModal ref="deleteModal" :position="positionToDelete" :positionLabel="getOrderTyp(positionToDelete)"
            @delete="removePosition()">
        </DeletePositionModal>
    </div>
</template>

<script>
import mixin from '@/mixins/wertpapierorder/mixin.js';
import { mapGetters } from 'vuex';
import WERTPAPIERORDER_TYPES from '@/store/wertpapierorder/types';
import { formatNumber, parse, isNumber } from '@/helpers/number-formatter.js';
import InputField from '@/components/core/forms/InputField.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';
import CurrencyLabel from '@/components/core/CurrencyLabel.vue';
import { minValue, numbers, required } from "@/mixins/validator/rules";
import DeletePositionModal from '@/components/core/forms/antraege/wertpapierAuswahl/DeletePositionModal.vue';
import { PhTrash, PhCurrencyEur } from 'phosphor-vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';
import NoData from '@/components/core/NoData.vue';
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";
import CORE_TYPES from '../../store/core/types';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import WertpapierorderAnteileInfo from '@/components/wertpapierorder/WertpapierorderAnteileInfo.vue';

export default {
    mixins: [mixin],
    props: {
        focusOnPosition: {
        type: String,
        default: '',
        }
    },
    components: {
        InputField,
        ComboBox,
        DatePickerField,
        CurrencyLabel,
        DeletePositionModal,
        PhTrash,
        PhCurrencyEur,
        GhostLoading,
        Block,
        NoData,
        InputToggleSwitch,
        PageHeaderTitleNavigation,
        WertpapierorderAnteileInfo,
    },
    data() {
        return {
            // isInit: true,
            positionToDelete: null,
            trackAddedWarningsByPath: {},
        }
    },
    mounted() {
        if (this.focusOnPosition) {
            this.setFocus(this.focusOnPosition);
        }
    },
    computed: {
        ...mapGetters({
            comboboxValuesStore: WERTPAPIERORDER_TYPES.GETTERS.COMBOBOX_VALUES || {},
            isWertpapierorderLoading: WERTPAPIERORDER_TYPES.GETTERS.IS_WERTPAPIERORDER_LOADING,
            isFA: CORE_TYPES.GETTERS.IS_FA,
            isTestCustomer: CORE_TYPES.GETTERS.IS_TEST_CUSTOMER,
        }),
        comboboxValues() {
            return this.comboboxValuesStore || {};
        },
        tauschInDepotValues() {
            return this.comboboxValues.tauschInDepotValues || []
        },
        // sort for creation order, reversed: first created position has a smaller posGrpId and is placed in the bottom; the last created position is placed on the top
        positionsSorted() {
            return this.positions
                .sort((a,b) => !a.posGrpId ||!b.posGrpId ? -1 : ((a?.posGrpId || '').localeCompare(b?.posGrpId) * -1))
                // sort aphabetically for wertpapiername and for category
                //.sort((a,b) => (this.getOrderTyp(a) || '').localeCompare(this.getOrderTyp(b)) || (a?.wertpapiername || '').localeCompare(b?.wertpapiername || ''))
                .map((pos, index) => Object.assign(pos, { 
                    fondsname: this.getWertpapiernameStr(pos, false),
                    displayAARabatt: this.displayAARabatt(pos),
                    hellobankOrderart: pos.hellobankOrderart || '',
                    waehrung: pos.waehrung || 'EUR',
                    index: index,
                    actions: this.readOnly ? null: this.getActions(pos)
                }))
        },
        warnings() {
            return this.getWarnings('main') || {};
        },
        getWarningsProPosition() {
            return (pos) => {
                return this.warnings?.length && (pos?.posGrpId || pos.depotNr) && this.warnings.filter(warn => pos.posGrpId === warn.posGrpId || pos.depotNr === warn.depotnr) || [];
            }
        },
        getWarningsProPositionNotChanged() {
            return (pos) => {
                return this.getWarningsProPosition(pos)
                    ?.filter(warn => {
                        const path = this.positionValidationPath(pos, warn);
                        return this.validation?.hasErrors(path);
                    });
            }
        },
        istTestMaklerFA(){
            return this.isTestCustomer && this.isFA;
        },
        waehrungen(){
            return [{label:'EUR', value:'EUR'}, {label:'USD', value:'USD'},{label:'GBP', value:'GBP'}, 
            {label:'JPY', value:'JPY'},{label:'NOK', value:'NOK'},{label:'AUD', value:'AUD'}, 
            {label:'CHF', value:'CHF'},{label:'SEK', value:'SEK'}, {label:'CAD', value:'CAD'},{label:'DKK', value:'DKK'},
            {label:'HKD', value:'HKD'},{label:'TRY', value:'TRY'},]
        },
        einmalSum() {
            return this.positionsSorted?.filter(pos => pos.positionTyp === 'NEU_KAUF' || pos.positionTyp === 'ALT_KAUF' 
                || (pos.positionTyp === 'ALT_TAUSCH' && !this.isEbaseUmschichtung(pos)))
                .map(pos => this.getBetragInEUR(pos))
                .reduce((acc, curr) => acc += curr, 0) || 0;
        },
        verkaufSum() {
            return this.positionsSorted?.filter(pos => pos.positionTyp === 'ALT_VERKAUF' 
                || (pos.positionTyp === 'ALT_TAUSCH' && !this.isEbaseUmschichtung(pos)))
                .map(pos => this.getBetragInEUR(pos))
                .reduce((acc, curr) => acc += curr, 0) || 0;
        },
        sparplanSum() {
            return this.positionsSorted?.filter(pos => pos.positionTyp === 'NEU_SPARPLAN' || pos.positionTyp === 'ALT_SPARPLAN'
                || (pos.positionTyp === 'ALT_TAUSCH' && this.isEbaseUmschichtung(pos)))
                .map(pos => this.getBetragInEUR(pos))
                .reduce((acc, curr) => acc += curr, 0) || 0;
        },
        entnahmeSum() {
            return this.positionsSorted?.filter(pos => pos.positionTyp === 'ALT_ENTNAHMEPLAN' || (pos.positionTyp === 'ALT_TAUSCH' && this.isEbaseUmschichtung(pos)))
                .map(pos => this.getBetragInEUR(pos))
                .reduce((acc, curr) => acc += curr, 0) || 0;
        },
        einmalGesamtSum()  {
            return this.einmalSum - this.verkaufSum;
        },
        monatGesamtSum() {
            return this.sparplanSum - this.entnahmeSum;
        }
    },
    watch: {
        positionsSorted: {
            handler() {
                // if (this.isInit && this.positionsSorted?.length > 0) {
                    // this.configureValidators();
                    // this.isInit = false;
                // }
                this.updateWarnings();
                this.checkForEbaseEdelmetal();
                this.checkForEbaseUmschichtung();  
                this.positionsSorted.forEach(p => {
                    if(!this.venues[p.isin] && p.comdirectWebserviceActive){
                        this.$store.dispatch(WERTPAPIERORDER_TYPES.ACTIONS.GET_VENUES,
                                        { lagerstelle: this.lagerstelle, isin: p.isin })
                    }
                });
            },
            immediate: true
        },
        warnings: {
            handler() {
                this.updateWarnings();
            },
            immediate: true
        },
        focusOnPosition(posId) {
            this.setFocus(posId);
        }
    },
    methods: {
        headerActions(pos) {
            const actions = [];
            if (this.hasAAForAll(pos)) {
                actions.push(PageHeaderSimpleAction('AA', `${pos.inputAAComponents?.label || 'Ausgabeaufschlag'} für alle übernehmen`))
            }
            actions.push(PageHeaderSimpleAction('REMOVE', 'Position löschen'));
            return actions;
        },
        setFocus(posId) {
            if (posId) {
                const found = this.positionsSorted?.find(pos => pos.posGrpId === posId);
                if (found) {
                    const card = this.$refs.cards[found.index]?.children[1];
                    card?.querySelector('input')?.focus();
                }
            }
        },
        getWarningText(pos) {
            return this.getWarningsProPositionNotChanged(pos)?.map(warn => warn.message).join(' ');
        },
        hasWarnings(pos) {
            return this.getWarningsProPositionNotChanged(pos)?.length > 0;
        },
        getBoersenWarning(pos) {
            return this.getWarningsProPosition(pos)?.find(warn => warn?.config?.titleExtra === 'Börsendaten')?.message;
        },
        setAAForAll(aa) {
            if (!this.readOnly) {
                (this.positionsSorted || []).forEach(pos => pos.inputAA !== aa && this.onChangeInputField(aa, pos, 'inputAA'));
            }
        },
        openModalDeleteFond(fond) {
            if (!this.readOnly) {
                this.positionToDelete = fond;
                this.$refs.deleteModal.open()
            }
        },
        removePosition() {
            if (!this.readOnly) {
                this.$store.dispatch(WERTPAPIERORDER_TYPES.ACTIONS.REMOVE_POSITION, this.positionToDelete);
            }
        },
        navigateToFondsInfo(fond) {
            if (this.readOnly || !fond?.isin || !fond?.depotNr) {
                return
            }
            const path = `/shared/vermogensubersicht/fondsinfo/${fond.isin}/${fond.depotNr}`;
            this.$router.push({ path })
        },
        modusChanged(position, modusValue) {
            if (position) {
                const changedPosition = Object.assign(position, { betragModus: modusValue });
                if (modusValue === 'VALUE_BETRAG_MODUS_ALLE_ANTEILE') {
                    changedPosition.betrag = 0;
                }
                this.$store.commit(WERTPAPIERORDER_TYPES.MUTATIONS.UPDATE_POSITION, changedPosition);
            }
        },
        configureValidators() {
            if (this.positionsSorted && this.positionsSorted.length) {
                const valConfig = {};
                this.positionsSorted.forEach((pos, index) => {
                    valConfig['inputAA' + pos.index] = [minValue(0, 'Ausgabeaufschlag ist ungültig.')];
                })
                this.$configureValidators(valConfig, {resetPreviousValidators: true});
            }
        },
        configureValidatorOrderart(pos = {}) {
            if (pos?.index >= 0) {
                const valConfig = {};
                valConfig['betrag' + pos.index] = pos.hellobankOrderart ? [numbers('Bei der Börsenorder können nur ganze Anteile gehandelt werden.')] : [];
                this.$configureValidators(valConfig, {resetPreviousValidators: true});                
            }
        },
        checkForEbaseEdelmetal(){
            const pos = this.positionsSorted.find(pos => pos.extra == 'EBASE_EDELMETALL');
            if(pos){
                this.$emit('ebaseEdelmetalFound')
            }
        },
        checkForEbaseUmschichtung(){
            const pos = this.positionsSorted.filter(pos => pos.lagerstelle == 'EBASE' && pos.positionTyp == 'ALT_TAUSCH');
            if(pos.length>0 ){
                for(let p of pos){
                    if((p.istEbaseTauschRegelmaessig == true  || p.istEbaseTauschRegelmaessig == 'true')){
                         p.istEbaseTauschRegelmaessig = 'true'
                    }else{
                        p.istEbaseTauschRegelmaessig = 'false'
                    }
                }
            }
        },
       assureOnlyOneCheckboxIsChecked(value, position,field){
            if(this.positionsSorted.length >1 && value == true){
                this.positionsSorted.filter(p=> p!= position).map(p=>{p.isCaAuftrag = false});
            }
            this.onChangeInputField(value, position, field)
       },
       onChangeBetrag(value, position, field) { 
            if (value?.startsWith('-')) {
                value = parse(value.substring(1));
            }
            this.onChangeInputField(value, position, field);
        },
        onChangeComdirectAusfuehrungsplatz(value, position, field){
            this.onChangeInputField(value, position, field);
            if (!!value){
                if (!this.getOrderzusatzValues(position).map(v => v?.value).includes(position?.comdirectBoOrderzusatz)){
                    this.onChangeInputField('', position, 'comdirectBoOrderzusatz')
                }
                if (!this.supportsComdirectGueltigkeit(position)){
                    this.onChangeInputField('', position, 'comdirectBoGueltigkeit')
                }
                if (!this.supportsComdirectLimitOrder(position)){
                    this.onChangeInputField('', position, 'comdirectBoLimit')
                }
            }
        },
        onChangeInputField(value, position, field) { 
            if (this.readOnly || (position && field && position[field] === (value ? value + '' : ''))) {
                return;
            }
            const changedPosition = Object.assign({}, position, { [field]: value});
            this.$store.commit(WERTPAPIERORDER_TYPES.MUTATIONS.UPDATE_POSITION, changedPosition);
        },
        orderartValues(pos) {
            if (pos?.lagerstelle === 'HELLOBANK') {
                return [{ label: 'Keine', value: '' }, {label: 'Bestens', value: 'Bestens'}, { label: 'Limit', value: 'Limit' }, { label: 'Stop', value: 'Stop'} ];
            } else {
                return [{ label: 'Keine', value: '' }, 
                { label: 'Limit Order', value: 'Limit' }, { label: 'Stop Market', value: 'Stop'} ];
            }
        },
        orderartValuesComdirect(pos){
            if(pos?.lagerstelle == 'COMDIRECT'){
                if (pos?.comdirectBoOrderId){
                    if (pos?.isDeletable && pos?.isEditable){
                        return [{label:'Limitänderung',value:'LÄ'},{label:'Streichung',value:'ST'}]
                    } else if (pos?.isDeletable  || pos?.comdirectBoModus == 'ST'){
                        return [{label:'Streichung',value:'ST'}]
                    } else if (pos?.isEditable || pos?.comdirectBoModus == 'LÄ'){
                        return [{label:'Limitänderung',value:'LÄ'}]
                    } else {
                        return []
                    }
                } else if( pos?.positionTyp == 'ALT_VERKAUF'){
                     return [{label: 'Verkauf (Festpreisgeschäft)', value: 'NICHT'},{label:'Verkauf (Börsenorder)',value:'V'}]
                } else if(pos?.positionTyp == 'ALT_KAUF' || pos?.positionTyp== 'NEU_KAUF'){
                    if (pos?.comdirectWebserviceActive){
                        return [{label: 'Kauf (Festpreisgeschäft)', value: 'NICHT'},{label:'Kauf (Börsenorder)',value:'K'}]
                    } else {
                        return [{label: 'Kauf (Festpreisgeschäft)', value: 'NICHT'},{label:'Lastschrift (Festpreisgeschäft)', value:'LAST'},{label:'Kauf (Börsenorder)',value:'K'},{label:'Zeichnung',value:'Z'}]
                    }
                }
            }
        },
    
        getActions(pos) {
            const actions = [{
                legend: {
                icon: 'PhTrash',
                index: 1,
                key: 'DELETE',
                label: 'Löschen',
                }
            }];
            if (this.hasInputAA(pos)) {
                actions.unshift({
                legend: {
                    icon: 'PhTrash',
                    index: 1,
                    key: 'AA',
                    label: 'Ausgabeaufschlag für alle Positionen übernehmen',
                }
                });
            }
            return actions;
        },
        hasInputAA(pos) {
            return !this.isCustomerLogin && pos?.inputAAComponents && Object.keys(pos.inputAAComponents).length;
        },
        inputAAType(pos) {
            return pos?.inputAAComponents?.type;
        },
        inputAACombo(pos) {
            return this.comboboxValues?.inputAA || [];
        },
        hasAAForAll(pos) {
            if (this.hasInputAA(pos)) {
                return this.positions?.filter(pos => pos.positionTyp !== 'ALT_VERKAUF' || pos.positionTyp !== 'ALT_ENTNAHMEPLAN')?.length > 1;
            }
            return false;
        },
        getOrderTyp(pos) {
            const retVal = this.categories[pos?.positionTyp]?.label;
            if (pos?.comdirectBoOrderId){
                if (pos?.comdirectBoModus === 'LÄ'){
                    return retVal + ' - Limitänderung';
                } else if (pos?.comdirectBoModus === 'ST'){
                    return retVal + ' - Streichung';
                }
            }
            return retVal;
        },
        getPrecision(pos) {
            const betragModus = pos?.betragModus || '';
            switch (betragModus) {
                case 'VALUE_BETRAG_MODUS_ALLE_ANTEILE':
                    return 0;
                case 'VALUE_BETRAG_MODUS_ANTEILE':
                    return this.isNurAnteile(pos) ? 0 : 6;
                default: 
                    return 2;
            }
        },
        isNurAnteile(pos) {
            return this.isFA && ('Anleihen' === pos?.produktArt || 'Zertifikat' === pos?.produktArt || 'Aktien' === pos?.produktArt);
        },
        betragModusValues(pos) {
            if(this.hasBoerseDataSelected(pos) && pos?.lagerstelle !== 'COMDIRECT'){
                return [{label: 'Anteile', value: 'VALUE_BETRAG_MODUS_ANTEILE'}];
            }else if(pos.isCaAuftrag){
                 return [{label: 'EUR', value: 'VALUE_BETRAG_MODUS_EURO'}];
            }else {
                const produktArt = pos.produktArt ? `_${pos.produktArt}` : '';
                return pos?.lagerstelle && this.comboboxValues?.betragModus && this.comboboxValues.betragModus[(pos.positionTyp || '') + produktArt];
            }
        },
        updateBetragModusAnteile(pos) {
            if (this.hasBoerseDataSelected(pos)) {
                this.onChangeInputField('VALUE_BETRAG_MODUS_ANTEILE', pos, 'betragModus');
            }
        },
        hasBoerseData(pos) {
            return (this.isFA || pos?.lagerstelle === 'COMDIRECT')
                && (pos?.positionTyp === 'NEU_KAUF' || pos?.positionTyp === 'ALT_KAUF' || pos?.positionTyp === 'ALT_VERKAUF');
        },
        hasBoerseDataSelected(pos) {
            if (this.hasBoerseData(pos)) {
                return this.isFA && pos.hellobankOrderart || pos.comdirectBoModus && pos.comdirectBoModus !== 'NICHT';
            }
        },
        getBoerseStr(pos) {
            return this.hasBoerseDataSelected(pos) ? 'Börsendaten bearbeiten' : 'Börsendaten hinzufügen';
        },
        displayAANormal(pos) {
          return pos.displayAANormal || '0';
        },

        displayAARabatt(pos) {
            if (pos) {
                const aa = pos.inputAA && isNumber(pos.inputAA) && parse(pos.inputAA) || 0;
                const rabatt = isNumber(aa) && isNumber(pos.displayAANormal) && (aa <= pos.displayAANormal) ? Math.ceil(100 - (aa / pos.displayAANormal * 100)) : 0
                return (aa >= 0) ? (rabatt == 0 ? 0 : (rabatt || '')) : '';
            }
        },
        
        getAuftragArtValues(position) {
            return position?.isin && this.comboboxValues?.auftragArt && this.comboboxValues.auftragArt[`${position.isin}${position.positionTyp}`];
        },
        auftragArtChanged(position, newValue) {
            if (position) {
                const changedPosition = Object.assign(position, { auftragArt: newValue });
                this.$store.commit(WERTPAPIERORDER_TYPES.MUTATIONS.UPDATE_POSITION, changedPosition);
            }
        },
        getTauschStr(pos) {
            if (pos?.positionTyp === 'ALT_TAUSCH') {
                return 'Tausch in: ' + pos.tauschInWertpapiername;
            }
        },
        getBetragInEUR(pos) {
            if (pos?.positionTyp) {
                let betrag = pos.betrag && isNumber(pos.betrag) ? pos.betrag : 0;
                if (typeof(betrag) === 'string') {
                    betrag = +betrag;
                }
                switch (pos.betragModus) {
                    case 'VALUE_BETRAG_MODUS_ALLE_ANTEILE':
                    return pos.gesamterWert || 0;
                    case 'VALUE_BETRAG_MODUS_EURO':
                    return betrag
                    case 'VALUE_BETRAG_MODUS_ANTEILE':
                    return pos.anteilspreisEuro && (betrag * pos.anteilspreisEuro) || 0;
                    case 'VALUE_BETRAG_MODUS_CHF':
                    return betrag;
                    case 'VALUE_BETRAG_MODUS_PROZENT':
                    return betrag / 100 * pos.gesamterWert || 0;
                    default:
                    return betrag
                }
            }
            return 0;
        },
        getPositionClasses(pos) {
            return {
                'has-warnings': this.hasWarnings(pos),
            };
        },
        positionValidationPath(position, warn) {
            // MSC-24067 Hinweise nicht als Fehler markieren
            if (warn?.status !== 'HINWEIS') {
                if (position && (!warn?.id || warn?.id?.includes('betrag') || warn?.id === position.posGrpId)) {
                    return 'betrag' + position.index;
                } else if (position) {
                    return warn.id + position.index;
                }
            }
        },
        updateWarnings() {
            if (this.warnings?.length) {
                this.warnings.forEach(warn =>  {
                    const position = this.positionsSorted.find(pos => warn.posGrpId === pos.posGrpId ||  warn.id === pos.posGrpId ||  warn.depotNr === pos.depotNr);                   
                         const path = this.positionValidationPath(position, warn);
                         if(path && !this.trackAddedWarningsByPath[path]) {
                        this.$pushErrors(path, ['']);
                        this.trackAddedWarningsByPath[path] = true;
                        }
                });
            }
        },
        updateBoersenOrder(value, pos = {}, compId) {
            if(pos && pos.isCaAuftrag == true){
                pos.isCaAuftrag = false;
            }
            this.onChangeInputField(value, pos, compId);
            if (compId) {
                pos[compId] = value;
            }
            if (pos && compId == 'comdirectBoModus'){
                if (value == 'NICHT'){
                    this.onChangeInputField('', pos, 'comdirectBoGueltigkeit')
                    this.onChangeInputField('', pos, 'comdirectBoLimit')
                } else {
                    pos.inputAA = this.displayAANormal(pos)
                    this.onChangeInputField(this.displayAANormal(pos), pos, 'inputAA')
                }
            }
            this.updateBetragModusAnteile(pos);
        },
        updateEbaseUmschichtungArt(value, pos, compId){
         this.onChangeInputField(value, pos, compId);
            if (compId) {
                pos[compId] = value;
            }
            if(value == 'true'){
                const betragModus = 'VALUE_BETRAG_MODUS_EURO';
                this.onChangeInputField(betragModus, pos, 'betragModus');
            }else{
                 const betragModus = 'VALUE_BETRAG_MODUS_ALLE_ANTEILE';
                this.onChangeInputField(betragModus, pos, 'betragModus');
            }
        },
        isEbaseUmschichtung(pos){
          return  pos.istEbaseTauschRegelmaessig == 'true' || pos.istEbaseTauschRegelmaessig == true
        },
        onChangeAuftragArt(value, pos) {
            const data = Object.assign({}, pos, { auftragArt: value});
            if (value === 'LOESCHEN') {
                data.betrag = 0;
            }
            this.$store.commit(WERTPAPIERORDER_TYPES.MUTATIONS.UPDATE_POSITION, data);
        },
        formatNumber(value, precision) {
            return formatNumber(value, precision);
        },
        getVenues(pos) {
           // console.log('getVenues for: ', pos?.isin);
            //this.$store.dispatch(WERTPAPIERORDER_TYPES.ACTIONS.GET_VENUES, { lagerstelle: this.lagerstelle, isin: isin });
            //const res = Array.of({label: 'Best Execution', value : ''})
            const res = this.venues[pos?.isin]?.map(v => v.name)
            const retVal = Array.of({label: 'Best Execution', value : ''})
            res.forEach(v => retVal.push({label : v, value : v}));
            if(pos && pos.comdirectBoAusfuehrungsplatz == null && res.includes('Festpreisgeschäft')){
                //pos.comdirectBoAusfuehrungsplatz = 'Festpreisgeschäft';
                this.$set(pos, 'comdirectBoAusfuehrungsplatz', 'Festpreisgeschäft' ); //computed will be forced
            }
            return retVal;
        },
        venuesAvailable(pos) {
            return this.venues[pos?.isin]?.length > 0;
        },
        getOrderzusatzValues(pos){
            const mapping = {};
            mapping[''] = {label: 'Kein', value : ''}
            mapping['FOK']  = {label: 'Fill-Or-Kill', value : 'FOK'}
            mapping['IOC']  = {label: 'Immediate-or-Cancel', value : 'IOC'}
            mapping['AON']  = {label: 'All-or-None', value : 'AON'}
            let  retVal = []
            if (!this.isComdirectAusfuehrungsplatzSet(pos)){
                retVal = ['FOK', 'IOC', 'AON']
            } else {
                const venue = this.venues[pos?.isin]?.find(v => v.name == pos?.comdirectBoAusfuehrungsplatz)
                if (!venue){
                    retVal = ['FOK', 'IOC', 'AON']
                } else if (venue?.orderTypes){
                    if (pos.comdirectBoLimit){
                        retVal = retVal.concat(venue.orderTypes?.LIMIT?.limitExtensions)
                    } else {
                        retVal = retVal.concat(venue.orderTypes?.MARKET?.limitExtensions)
                    }
                }
            }

            if (retVal.length > 0){
                retVal.push('')
            }
            return retVal.map(v => mapping[v])
        },
        supportsComdirectLimitOrder(pos){
            if (!this.isComdirectAusfuehrungsplatzSet(pos)){
                return true
            }
            const venue = this.venues[pos?.isin]?.find(v => v.name == pos?.comdirectBoAusfuehrungsplatz)
            const result = !venue || venue?.orderTypes?.LIMIT
            if (this.venues[pos?.isin] && pos && !result){
                pos.comdirectBoLimit = ''
            }
            return result
        },
        supportsComdirectGueltigkeit(pos){
            if (!this.isComdirectAusfuehrungsplatzSet(pos)){
                return true
            }
            const venue = this.venues[pos?.isin]?.find(v => v.name == pos?.comdirectBoAusfuehrungsplatz)
            const result = !venue || venue?.validityTypes?.includes('GTD')
            if (this.venues[pos?.isin] && !result && pos){
                pos.comdirectBoGueltigkeit = ''
            }
            return result
        },
        isComdirectAusfuehrungsplatzSet(pos){
            return pos?.comdirectBoAusfuehrungsplatz != ''
        },
        gotoFondsinfo(row) {
            this.$addBreadcrumb({
                fullPath: this.$route.fullPath,
            })

            // Beteiligungen do not have isin property, instead it's in _fondsData
            const isin = row.isin || row._fondsData?.isin
            const p = this.type === 'B' ? 'beteiligungen' : 'vermogensubersicht' ;
            this.$router.push(`/shared/${p}/fondsinfo/${isin}`);
        },
    }
}
</script>

<style lang="scss" scoped>
.text-divider {
    font-weight: normal;
    margin: 0 0.25rem;
}
.comboalign {
    align-self: flex-end
}
.plannummer-info {
  font-size: small;
  font-style: italic;
}
.cards__container {
    .cards__items {
        .cards__item {
            &.has-warnings {
                border: 1px solid var(--color-danger);
            }

            &.box__container > * {
                margin-bottom: 0.25rem;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .cards__item--header {
            display: flex;
            justify-content: space-between;

            &.cards__item--header-wrap {
                flex-wrap: wrap;
            }
        }

        .cards__item--header-title {
            display: flex;
            flex-wrap: wrap;
            margin: 0 0.75rem 0 0;
        }

        .cards__item--header-actions {
            display: flex;
            justify-content: flex-end;
            flex: 1 1 auto;
            text-align: right;

            .cards__item--header-action--item-text {
                display: none;
            }
        }

        .cards__item--inputs {
            display: flex;
            margin-left: -0.5rem;
            margin-right: -0.5rem;
            flex-wrap: wrap;

            > * {
                flex: 0 0 140px;
                margin: 0 0.5rem 0.25rem;
                padding: 0;
                width: auto;

                &.cards__item--input-240 {
                    flex-basis: 240px;
                }
         
                &.cards__item--input-295 {
                    flex-basis: 295px;
                    position: relative;
                }
            }

            .cards__item--input-no-label {
                margin-top: 1.5rem;
            }
        }
    }
}

@media (max-width: 767px) {
    .cards__container {
        .cards__items {
            .cards__item--inputs {
                .cards__item--input-no-label {
                    margin-top: 1.2rem;
                }
            }
        }
    }
}

@media (max-width: 520px) {
    .cards__container {
        .cards__items {
            .cards__item--inputs {
                &.cards__item--input-295 {
                    flex-basis: calc(100% - 1rem);
                }
            }
        }
    }
}

@media (max-width: 375px) {
    .cards__container {
        .cards__items {
            .cards__item--inputs {
                > * {
                    width: calc(50% - 1rem);
                    flex-basis: auto;
                }
            }

            .cards__item--header-actions {
                flex-flow: column;

                .text-divider {
                    display: none;
                }

                .cards__item--header-action--item-icon {
                    display: none;
                }

                .cards__item--header-action--item-text {
                    display: initial;
                }

            }
        }
    }
}

@media (max-width: 335px) {
    .cards__container {
        .cards__items {
            .cards__item--inputs {
                > * {
                    width: calc(100% - 1rem);
                    flex-basis: auto;
                }

                .cards__item--input-no-label {
                    margin-top: 0;
                }
            }
        }
    }
}
</style>
