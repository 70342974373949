<template>
<div>
	<OptionMenu :id="$appNavigation.parentOptionMenuId" :defaultMenu="$appNavigation.parentOptionMenu" />

	<PageHeaderTitleNavigation 
		:id="$appNavigation.currentOptionMenuId" 
		:defaultMenu="$appNavigation.currentOptionMenu" 
		:title="formatName + ' Datenimport'"
		subtitle="Daten einlesen" 
		:actions="headerActions" 
		@action-IMPORT-FILE="$refs.importFileModal.open()"
		@action-STATUS-WERTE="$refs.statusWerteModal.open()"
	/>

	<DragnDropArea hoverText="Datei hier ablegen" @files="openImportFileModal($event)">

		<div v-if="!showFullFilter" class="box__container">
			<span class="box__title">
				Anleitung
			</span>
			<br>
			<div>
				Mit der Funktion Datenimport haben Sie die Möglichkeit, <b>Bestandsdaten</b> im	<b>{{formatName}}-Format</b> einzulesen und zu aktualisieren. 
				Als Formate können {{formatName}}, aber auch z.B. Textdateien verwendet werden. 
				Kundendaten werden automatisch angelegt. 
				Somit halten Sie Ihre Versicherungsdaten auf dem aktuellen Stand.
				<b>Bitte beachten Sie unbedingt die Anleitung und lesen Sie diese sorgfältig durch.</b>
				<br><br>
				Folgendes bitten wir Sie zu beachten:
				<br><br>
				Die Nutzung dieser Funktion ist kostenpflichtig und mit der Nutzung erkennen Sie dies an. 
				Zurzeit liegen die Gebühren bei 0,10€ pro angelegtem Vertrag/ Monat zzgl. der gesetzlichen MwSt. 
				Die ersten drei Monate können Sie das Tool kostenfrei nutzen, um sich über die Funktionalitäten einen Überblick zu schaffen. 
				Wir übernehmen keinerlei Haftung für die Qualität der Daten. 
				Falls Sie Fehler entdecken sollten, bitten wir Sie uns eine Nachricht einzustellen, damit wir uns so schnell wie Möglich darum kümmern können.
				<br><br>
				<DownloadLink title="Anleitung" :href="anleitungLink" />
			</div>
		</div>

		<BaseFilter
			ref="gdvFileImportViewFilter"
			title="Daten anzeigen"
			filterId="Daten anzeigen"
			:configFilter="filterConfig"
			:metadata="metadata"
			:defaultOptions="defaultOptions"
			:immidiateSearch="!!$route.query.filename"
			showSaveButton
			@onFilter="onFilter"
			@onFilterZurucksetzen="onResetFilter"
		>
			<span v-if="filterErrorMessage" class="color-danger">
				{{filterErrorMessage}}
			</span>
		</BaseFilter>

		<div class="gdv-file-import-view__content" :class="{ 'is-details-panel-visible': isDetailsPanelVisible, }">
			<div class="gdv-file-import-view__table box__container">
				<div v-if="loading || !rows.length">
					<span class="box__title">
						Daten
					</span>
					<br>
				</div>
				<GhostLoading v-if="loading" type="table"/>
				<BigTable 
					style="clear: both;"
					selectable
					ref="bigTable"
					v-else-if="rows.length"
					:headers="headers" 
					:headerActions="tableHeaderActions"
					:title="'Daten Einlesen - Anzahl der Datensätze ' + rows.length"
					:tableId="tableId"
					:rows="rows" 
					:initialPageSize="minNumRows"
					:page="page"
					:sortingState="sorting"
					@onClickHeader="onClickHeader"
					@onRowClick="showLineDetails"
					@action-EDIT="lineEdit=$event"
					@click-icon-details-DATENSATZ_ZUORDNEN="actionDatensatzZuordnen"
					@click-icon-DATENSATZ_BEARBEITEN="lineEdit=$event"
					@click-icon-DATENSATZ_LOESCHEN="confirmRowDeletion"
					@action-details-DATENSATZ_ZUORDNEN="actionDatensatzZuordnen"
					@action-TARIF="actionTarif"
					@action-KUNDENDATEN_ZUWEISEN="actionKundendatenZuweisen"
					@action-DELETE="deleteRow"
					@headerAction-AUSWERTEN="actionAuswertung(firstRowObject)"
					@headerAction-MARKIERTE_LOESCHEN="deleteSelected"
					@headerAction-EXPORT-FILE="exportFile(firstRowObject)"
					@loadMoreRows="onLoadMoreRows"
					@selectedRow="onRowSelection"
					@selectedRows="onAllRowsSelection"					
					>
			
				</BigTable>			
				<NoData v-else/>
			</div>

			<div class="details__container" v-if="isDetailsPanelVisible">
				<div class="column box__container">
					<GhostLoading v-if="readDetails" type="table"/>	
					<Table
						v-else-if="!readDetails && journalRows" style="clear: both;"
						:headers="journalHeaders"
						:rows="journalRows"
						rowId="index"
						hidePagination
					/>		
					<NoData v-else/>
					
				</div>
				<div class="details__column box__container">
					<GhostLoading v-if="readDetails" type="table"/>
					<Table
						v-else-if="!readDetails && lineDetails.values" style="clear: both;"
						:headers="valuesHeaders"
						:rows="lineDetails.values"
						rowId="label"
						hidePagination
					/>
					<NoData v-else/>
					
				</div>
			</div>
		</div>

	</DragnDropArea>

	<ImportFileModal ref="importFileModal"/>
	<FileImportLineEdit v-if="lineEdit" :line="lineEdit" @close="lineEdit = null"/>
	<GdvImportAuswertungModal v-if="auswertungId" :id="auswertungId" @close="closeAuswertung"/>
	
	<BaseModal ref="infoModal"
	  :showCancelButton="false" 
	  labelButtonConfirm="Schließen"
	>
	  <template v-slot:modalTitle>Daten Einlesen</template>
	  <template #default>
      <div v-html="sanitize(message)"></div>
	  </template>
	</BaseModal>

	<BaseModal ref="statusWerteModal"
	  :showCancelButton="false" 
	  labelButtonConfirm="Schließen"
	>
	  <template v-slot:modalTitle>Status Werte</template>
	  <template #default>
		<Table
			style="clear: both;"
			:headers="statusWerteHeaders"
			:rows="statusWerteRows"
			rowId="wert"
			hidePagination
				/>		
	  </template>
	</BaseModal>

	<BaseModal ref="exporFileModal" :showDefaultButtons="false"
	>
	  <template v-slot:modalTitle>Export von Daten mit Fehler -4</template>
	  <template #default>

		<ComboBox
            label="Anzahl der Tage"
            v-model="anzahlDerTage"
            :values="anzahlDerTageComboboxValues"
            :firstEmpty="true"
            :validateUntouched="true"
          />

	  </template>
      <template v-slot:footer>
        <BaseButton @click="closeExporFileModal()" isSecondary>
          Abbrechen
        </BaseButton>
		<DownloadLink target="_blank" rel="noopener noreferer" asButton
                    title="Export"
                    filename="Fehlerliste"
                    downloadServicePath="/fetchErrorsAsExcel"
                    :queryParams="{
                      numberOfDays: anzahlDerTage, 
                      gesellschaft: firstRowObject.gesellschaft
                    }"
                />
      </template>

	</BaseModal>

</div>
</template>

<script>
import CORE_TYPES from '@/store/core/types'
import VERSICHERUNGSTARIFE_TYPES from "@/store/versicherungstarife/types";

import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue'
import BaseFilter from "@/components/core/BaseFilter.vue";
import Table from "@/components/table2/Table.vue";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import FileImportLineEdit from '@/components/bipro/FileImportLineEdit.vue'
import ImportFileModal from '@/components/bipro/ImportFileModal.vue'
import GdvImportAuswertungModal from '@/components/bipro/GdvImportAuswertungModal.vue'
import DownloadLink from '@/components/core/download/DownloadLink.vue';
import BaseModal from "@/components/core/BaseModal.vue";
import {sanitize} from '@/helpers/string-helper.js';

import {TextColumn, DateTimeColumn, SimpleAction, ConfirmedAction, LoadingAction, DownloadLinkAction} from "@/components/table2/table_util.js";
import axios from 'axios';
import { mapGetters } from 'vuex';
import { DatePickerUtils } from '@/components/core/forms/DatePicker/date-picker-utils';
import {PhArrowSquareOut, PhFloppyDiskBack, PhPencilLine, PhArrowFatRight, PhTrash} from 'phosphor-vue';
import {BROKER_LEVEL_ROLES, ROLES} from '@/router/roles';
import { PageHeaderSimpleAction, PageHeaderConfirmedAction} from '@/components/core/header-title-navigation/page-header-utils';
import { PhArrowBendUpRight} from "phosphor-vue";
import GDV_TYPES from '@/store/gdv/types'
import BigTable from '@/components/bigTable/BigTable.vue';
import {STRING_COLUMN, DATE_COLUMN, ACTION_COLUMN, SINGLE_SELECTION, FIXED_LEFT, Icon, ICON_COLUMN, NUMBER_COLUMN} from '@/components/bigTable/BigTable.vue';
import DragnDropArea from '@/components/fileUpload/DragnDropArea.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue'
import BaseButton from "@/components/core/BaseButton.vue";

const config = {
	defaultSpinner: true,
};

const LINE_DETAILS_TABLE_CELL_PROPS = {
	lineClamp: 999,
  maxWidth: '100%',
};

export default {
	mixins: [],
	components: {
		PageHeaderTitleNavigation,
		OptionMenu,
		BaseFilter,
		Table,
		GhostLoading,
		NoData,
		FileImportLineEdit,
		ImportFileModal,
		GdvImportAuswertungModal,
		DownloadLink,
		BaseModal,
		PhArrowBendUpRight,
		BigTable,
		DragnDropArea,
		ComboBox,
		BaseButton,
	},
	data() {
		return {
			SINGLE_SELECTION,
			loading: false,
			readDetails: false,
			filterConfig: {
				placeholderForDefSearchInput: 'Dateiname',
				checkDefaultSearchInput: false,
				cbDefSearchInput: null,
				hideFirstColumn: true,
				filterZurucksetzen: null,
				noResetOnDefaultSearchInputExit: true,
			},
			filterParams: {},
			filterErrorMessage: null,
			lines: null,
			lineDetails: null,
			lineEdit: null,
			rowToDelete: null,
			auswertungId: null,
			message: '',
			currentRow: null,
			shouldUseFilter: false,
			shouldUseSavedSorting: false,
			//Big table properties BEGIN
			page: 0,
			rows: [], 
			minNumRows: 100,
			sortingState: { key: "customerName", direction: "desc" },
			tableId: '62bf8bbf-c72c-4a56-afb3-43c1d940dd36',
            headers: [
                { label: "Datensatz zuordnen", key: "DATENSATZ_ZUORDNEN", width: 50, type: ICON_COLUMN, position: FIXED_LEFT, hideLabel: true },
				{ label: "Bearbeiten", key: "DATENSATZ_BEARBEITEN", width: 50, type: ICON_COLUMN, position: FIXED_LEFT, hideLabel: true },
				{ label: "Löschen", key: "DATENSATZ_LOESCHEN", width: 50, type: ICON_COLUMN, position: FIXED_LEFT, hideLabel: true },
                { label: "lfd.Nr.", key: "index", width: 80, type: NUMBER_COLUMN, visible: true },
				{ label: "Zeilennr", key: "id", width: 150, type: STRING_COLUMN, visible: true },
				{ label: "Dateiname", key: "fileName", width: 471, type: STRING_COLUMN, visible: true },
				{ label: "Interfacename", key: "interfaceName", width: 471, type: STRING_COLUMN, visible: true },
				{ label: "Status", key: "status", width: 80, type: NUMBER_COLUMN, visible: true },
				{ label: "Datum", key: "date", width: 150, type: DATE_COLUMN, visible: true },
                { label: "Aktionen", key: "actions", width: 120, type: ACTION_COLUMN, visible: true },
            ],		   	
			//Big table properties END
			selected: [],
			anzahlDerTage: 7,
		};
	},
	created() {
		if (this.showFullFilter) {
			this.headers.splice(3, 0, {
				label: "Gesellschaft", 
				key: "gesellschaft", 
				width: 222, 
				type: STRING_COLUMN, 
				visible: true
			});
		}
	},	
	computed: {
		...mapGetters({
			isFK: CORE_TYPES.GETTERS.IS_FK,
			isFPPlus: CORE_TYPES.GETTERS.IS_FPP_PLUS,
			isIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
			hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
			savedFilters: GDV_TYPES.GETTERS.SAVED_FILTERS,
			savedSorting: GDV_TYPES.GETTERS.SAVED_SORTING,
			cachedRows: GDV_TYPES.GETTERS.CACHED_ROWS,
			lastRow: GDV_TYPES.GETTERS.LAST_ROW,
			currentPageSize: GDV_TYPES.GETTERS.CURRENT_PAGE_SIZE,
		}),
		anzahlDerTageComboboxValues() {
			return Array.from({ length: 31 }, (v, i) => (
				{
					label: String(i + 1),
					value: i + 1
				}
			));
		},		
		numberSelected() {
            return this.selectedRows.length;
        },
        tableActions() {
            return [
			PageHeaderConfirmedAction('MARKIERTE_LOESCHEN', 'Markierte löschen', 'Wollen Sie die ausgewählten Datensätze wirklich löschen?', 'Markierte löschen', 'Löschen')
                .withDisabled(() => this.numberSelected <=0),
            ];
        },		   
		statusWerteHeaders() {
			const headers = { 
				lockedLeft: [
					TextColumn("wert", "Wert"),
					TextColumn("beschreibung", "Beschreibung"),
				],
			};
			return headers;
		},			
		statusWerteRows() {
			const rows = [
				{ wert: '-1', beschreibung: 'Wert fehlt/falsch bei Stammdaten' },
				{ wert: '-2', beschreibung: 'Wert fehlt/falsch Folgefehler' },
				{ wert: '-3', beschreibung: 'Fehler in Datenbank z.B. Wert mehrfach vorhanden' },
				{ wert: '-4', beschreibung: 'Daten in Datei oder Datenbank falsch zugeordnet. Zum Beispiel Maklernummer.' },
				{ wert: '-5', beschreibung: 'Erforderliche Zusatzangabe fehlt/falsch' },
				{ wert: '-6', beschreibung: 'Angabe zu Sparte bzw. Portfolio fehlt/falsch' },
				{ wert: '-7', beschreibung: 'Formatfehler z.B. bei Policenummer, Objektnummer oder ISIN' },
				{ wert: '-8', beschreibung: 'Daten in Datei oder Datenbank falsch zugeordnet. Zum Beispiel Vertragsnummer.' },
				{ wert: '-9', beschreibung: 'Allgemeiner Fehler. ERROR-MESSAGE aus IF wird angezeigt.' },
				{ wert: '-10', beschreibung: 'Fremdbezeichnung nicht in Datenbank' },
				{ wert: '-13', beschreibung: 'Vertrag nicht in Datenbank' },
				{ wert: '-14', beschreibung: 'Allgemeiner Fehler. Personendaten können nicht gespeichert werden.' },
				{ wert: '-15', beschreibung: 'Allgemeiner Fehler. Kunden/Kontodaten können nicht gespeichert werden.' },
				{ wert: '-16', beschreibung: 'Zu viele Personen mit gleichem Namen, Gebdatum, PLZ gefunden.' },
				{ wert: '-19', beschreibung: 'Fremdwiederanlage Antragsnr_2 fehlt in Datei.' },
				{ wert: '-20', beschreibung: 'Allgemeiner Fehler. Fremdwiederanlage.' },
				{ wert: '-21', beschreibung: 'Auskommentiert' },
				{ wert: '-22', beschreibung: 'CAPITALBANK Fehler: Pondon_Storno finden ohne BGS Fehler.' },
				{ wert: '1/0', beschreibung: 'Abrechnungen Ok.' },
				{ wert: '2/0', beschreibung: 'Versicherungen Ok.' }
			];


			return rows;
		},
		isDetailsPanelVisible() {
			return !this.loading && (this.readDetails || this.journalRows?.length);
		},
        journalRows() {
            return (this.lineDetails?.journal || []).map((row, index) => ({
                index,
                ...row,
            }));
        },	
		journalHeaders() {
			const headers = { 
				lockedLeft: [
					DateTimeColumn("label", "Zeit"),
					TextColumn("value", "Journal-Text").addCellProps(LINE_DETAILS_TABLE_CELL_PROPS),
				],
			};
			return headers;
		},	
		valuesHeaders() {
			const headers = { 
                lockedLeft: [
                    TextColumn("label", "Beschreibung"),
                    TextColumn("value", "Inhalt").addCellProps(LINE_DETAILS_TABLE_CELL_PROPS),
                ],
			};
			return headers;
		},		
		headerActions() {
			return [
				PageHeaderSimpleAction('IMPORT-FILE', 'Datei einlesen'),
				PageHeaderSimpleAction('STATUS-WERTE', 'Status Werte'),
			];
		},
		tableHeaderActions() {
			return [
				PageHeaderSimpleAction('AUSWERTEN', 'Auswertung').withAsPrimary(() => true).withDisabled(() => !this.rows.length),
				PageHeaderSimpleAction('EXPORT-FILE', 'Export von Daten mit Fehler -4').withDisabled(() => !this.rows.length),
				PageHeaderConfirmedAction('MARKIERTE_LOESCHEN', 'Markierte löschen', 'Wollen Sie die ausgewählten Datensätze wirklich löschen?', 'Markierte löschen', 'Löschen')
                .withDisabled(() => !this.rows.some(item => item.selected)),

				
			];
		},		
		anleitungLink() {
			return `${process.env.VUE_APP_API}/download_service/bipro_gdv_anleitung`;
		},
		showFullFilter() { // == bFilterVisible in MSC
			return (this.isIntern || this.hasRoles(BROKER_LEVEL_ROLES.RIGHT_DATENIMPORT));
		},
		showingErrorFile() { 
			return this.$route?.query?.filename && this.$route?.query?.showFile;
		},		
		formatName() {
			return this.isFK ? "GDV" : "OMDS";
		},
		metadata() {
			const result = [
				{
					type: 'group',
					key: 'allgemein',
					label: 'Allgemein',
					menuItems: [
						{
							type: 'text',
							label: 'Dateiname *',
							key: 'filename',
							emptyDenied: true,
						},
					],
				},
			];
			if (this.showFullFilter) {
				result[0].menuItems.push(
					{
						type: 'numberRange',
						label: 'Zeilennummer',
						key: 'lineRange',
						emptyDenied: true,
					},
					{
						type: 'dateRange',
						label: 'Zeitraum',
						key: 'zeitraum',
						emptyDenied: true,
					},
					{
						type: 'text',
						label: 'Gesellschaft *',
						key: 'gesellschaft',
						emptyDenied: true,
					},
					{
						type: 'text',
						label: 'Interface *',
						key: 'interfacename',
						emptyDenied: true,
					},
					{
						type: 'text',
						label: 'Subinterface *',
						key: 'subinterfacename',
						emptyDenied: true,
					},
					{
						type: 'combobox',
						label: 'Auswahl',
						key: 'use4Booking',
						comboOptions: [
							{label: "aktive Sätze", value: 1},
							{label: "zurückgestellte Sätze", value: 0},
							{label: "nicht berücksicht", value: -1},
						],
					},
					{
						type: 'combobox',
						label: 'ab Status',
						key: 'statusFrom',
						comboOptions: [
							{label: "Fehler (<0)", value: -1000},
							{label: "gelesen (0)", value: 0},
							{label: "ausgewertet (1)", value: 1},
							{label: "übertragen (2)", value: 2},
							{label: "abgeschlossen (3)", value: 3},
						],
					},
					{
						type: 'combobox',
						label: 'bis Status',
						key: 'statusTo',
						comboOptions: [
							{label: "Fehler (<0)", value: -1},
							{label: "gelesen (0)", value: 0},
							{label: "ausgewertet (1)", value: 1},
							{label: "übertragen (2)", value: 2},
							{label: "abgeschlossen (3)", value: 3},
							{label: "alle (>=3)", value: 500},
						],
					},
					{
						type: 'text',
						label: 'Journal-Text',
						key: 'journal',
						emptyDenied: true,
					},
				);
			} else {
				result[0].menuItems.push(
					{
						type: 'default',
						label: 'nur fehlerhafte Datensätze anzeigen',
						key: 'onlyErrors',
						emptyDenied: true,
					},
				);
			}
			return result;
		},
		filters() {
			return this.shouldUseFilter ? this.savedFilters || {} : {};
        },
		sorting() {
			return this.shouldUseSavedSorting ? this.savedSorting || this.sortingState : this.sortingState;
		},
		defaultOptions() {
			if (this.showingErrorFile) {
				return {
					filename: {value: this.$route.query.filename || ""},
					onlyErrors: {value: false},
					use4Booking: {value: 1,},					
				};
			}

			if (!this.showFullFilter) {
				return {
					filename: {value: this.$route.query.filename || ""},
					onlyErrors: {value: true},
				};
			}
			const startDate = new Date();
			startDate.setMonth(startDate.getMonth() - 1);
			const result = {
				use4Booking: {
					value: 1,
				},
				statusFrom: {
					value: -1000,
				},
				statusTo: {
					value: 1,
				},
			};
			if (this.$route.query.filename)
				result.filename = {value: this.$route.query.filename};
			return result;
		},
		firstRowObject() {
			return this.rows.length > 0 ? this.rows[0] : null;
		},
	},
	methods: {
		onRowSelection(payload) {
			if (payload?.action === "added") {
				// Add a shallow copy to avoid referencing the original row in "rows"
				if (!this.selected.some(item => item === payload.row)) {
					this.selected.push({ ...payload.row });
				}
			} else if (payload?.action === "removed") {
				const index = this.selected.findIndex(item => item === payload.row);
				if (index !== -1) {
					this.selected.splice(index, 1);
				}
			}
		},
		onAllRowsSelection(payload) {
			// Map each item in payload to a shallow copy, avoiding references to the original rows
			this.selected = payload.map(row => ({ ...row }));
		},
		openImportFileModal(file) {
			this.$refs.importFileModal.open(file);
		},
        onClickHeader(headerMetadata) {
			const newSortingState = {key: headerMetadata?.header?.key, direction: headerMetadata?.sortingState?.direction };
            this.sortingState = newSortingState;
			this.saveSorting(newSortingState);
            // this.$store.commit(BIPRO_TYPES.MUTATIONS.SAVE_SORTING, this.sortingState);
        },		
        onResetFilter() {
            this.$store.commit(GDV_TYPES.MUTATIONS.SAVE_FILTERS, this.metadata);
        },		
		sanitize(htmlString) {
			return sanitize(htmlString);
		},
        saveFilters(filterArr = []) {
            const filters = {};
            filterArr.forEach(filter => filters[filter.key] = filter.value);
            this.$store.commit(GDV_TYPES.MUTATIONS.SAVE_FILTERS, { filters: filters });
        },
        saveSorting(sortingState) {
            this.$store.commit(GDV_TYPES.MUTATIONS.SAVE_SORTING, sortingState);
        },
        cacheLastRowDetails(whatDetails) {
            this.$store.commit(GDV_TYPES.MUTATIONS.SAVE_LAST_ROW, whatDetails);
        },
        saveCurrentPageSize(whatSize) {
            this.$store.commit(GDV_TYPES.MUTATIONS.SAVE_CURRENT_PAGE_SIZE, whatSize);
        },
		onLoadMoreRows(whatPageSize) {
			this.saveCurrentPageSize(whatPageSize)
		},						 
        cacheRows(whatRows) {
            this.$store.commit(GDV_TYPES.MUTATIONS.CACHE_ROWS, whatRows);
        }, 					 		
		async onFilter(filterConfig) {
			this.lineDetails = null;
			this.filterParams = {};

			filterConfig.forEach(fc => {
				let value = fc.value;
				if (fc.key == 'showDeleted' || fc.key == 'showFilesOfStructure')
					value = true; // the filters with above keys have no input field and should only be considered as boolean (true if added to the filter)
				if (fc.key == "onlyErrors") {
					this.filterParams.statusFrom = -1000;
					this.filterParams.statusTo = -1;
				} else if (fc.key == "zeitraum") {
					this.filterParams.fromDate = DatePickerUtils.toDateStr(value.find(v => v.key == 'min').value);
					this.filterParams.toDate = DatePickerUtils.toDateStr(value.find(v => v.key == 'max').value);
				} else if (fc.key == "lineRange") {
					this.filterParams.lineFrom = value.find(v => v.key == 'min').value;
					this.filterParams.lineTo = value.find(v => v.key == 'max').value;
				} else if (!fc.key.startsWith("lineRange")) {
					this.filterParams[fc.key] = value;
				}
			});
			this.filterParams.modus = 1;
			this.loadRows();
		},
		buildFilterErrorMessage() {
			this.filterErrorMessage = null;
			if (this.hasRoles(ROLES.IS_EXTERNAL_BROKER)) {
				if (!this.filterParams.filename)
					this.filterErrorMessage =  "Sie können nur suchen, wenn Sie zuvor eine Datei eingelesen haben.";
			} else {
				if (!this.showFullFilter && !this.filterParams.filename ||
					!this.filterParams.filename && !this.filterParams.lineFrom && !this.filterParams.lineTo && !this.filterParams.fromDate && !this.filterParams.toDate && !this.filterParams.gesellschaft && !this.filterParams.interface && !this.filterParams.subinterface)
					this.filterErrorMessage =  "Bitte befüllen Sie ein Suchfeld.";
			}
		},
		async loadRows() {
			this.buildFilterErrorMessage();
			if (this.filterErrorMessage || this.loading)
				return;

			// collapse base filter
			this.$refs.gdvFileImportViewFilter?.setCollapsed?.(false);

			this.loading = true;
			
			const config = { defaultSpinner: true, cancelableRequest: {title: 'Daten einlesen'} };
			const response = await axios.post(`${process.env.VUE_APP_API}/bipro_import/filter`, this.filterParams, config);
			if (response.data?.rows) {
				this.lines = response.data.rows;
				if (this.lines?.length) {
					this.showLineDetails(this.lines[0]);
				}
			} else {
				this.lines = null;
			}

		this.rows = (this.lines || []).map((row, index) => {
				const actions = [
					SimpleAction("EDIT", PhPencilLine, "Datensatz bearbeiten"),
				];
				if (row.dateiAbfrage) {
					if (this.showFullFilter)
						actions.push(DownloadLinkAction("SAVE", PhFloppyDiskBack, "Fehlerhafte Daten speichern", row.fileName, "/bipro_gdv_line_errors", {id: row.fileId}));
				}
				if (row.actionDatensatz)
					actions.push(SimpleAction("DATENSATZ_ZUORDNEN", PhArrowFatRight, "Datensatz zuordnen"));
				if (row.actionTarif) {
					if (row.actionTarif.disabled)
						actions.push(LoadingAction("", PhArrowFatRight, "Für den Spartentyp '" + row.actionTarif.versSpartentyp + "' kann kein Tarif angelegt werden", _ => true));
					else
						actions.push(SimpleAction("TARIF", PhArrowFatRight, "Für den Spartentyp '" + row.actionTarif.versSpartentyp + "' Tarif angelegen"));
				}
				if (row.actionKundendaten)
					actions.push(SimpleAction("KUNDENDATEN_ZUWEISEN", PhArrowFatRight, "Kundendaten zuweisen"));
				if (row.status <= 1)
					actions.push(ConfirmedAction("DELETE", PhTrash, "Löschen", "Wollen Sie den ausgewählten Datensatz wirklich löschen?"));
				return {
					index: index + 1,
					...row,
					DATENSATZ_ZUORDNEN: row.actionDatensatz ? Icon("PhArrowBendUpRight", "Datensatz zuordnen") : null, 
					DATENSATZ_BEARBEITEN: Icon("PhPencilLine", "Datensatz zuordnen"),
					DATENSATZ_LOESCHEN: row.status <= 1 ? Icon("PhTrash", "Datensatz zuordnen") : null,
					actions,
				};
			});

			this.cacheRows(this.rows);

			if (response.data?.mess) {
				this.message = response.data.mess;
				this.$refs.infoModal.open();
			}
			this.loading = false;
		},
		async showLineDetails(row) {
			if (!row.selected) {
				this.lineDetails = null;
				return;
			}

			if (this.readDetails) {
				return;
			}

			this.readDetails = true;
			const config = { defaultSpinner: true, cancelableRequest: {title: 'Details einlesen'} };
			const response = await axios.get(`${process.env.VUE_APP_API}/bipro_import/line_details?id=${row.id}`, config)
			this.lineDetails = {
				title: row.fileName + ", Zeile " + row.id,
				...response.data
			};
			this.readDetails = false;
			this.currentRow = row;

		},
		actionAuswertung(row) {
			this.auswertungId = row.fileId;
		},
		exportFile(row) {
			this.$refs.exporFileModal.open();
		},
		closeExporFileModal() {
			this.$refs.exporFileModal.close();
		},
		closeAuswertung(success) {
			this.auswertungId = null;
			if (success)
				this.loadRows();
		},
		actionDatensatzZuordnen(rowDetails) {
			this.cacheLastRowDetails(rowDetails);
			this.$router.push({path: "/intern/gdv/import/zuordnen", query: {id: rowDetails?.row?.id}});
		},
		async actionTarif(row) {
			let response = await axios.get(`${process.env.VUE_APP_API}/bipro_import/gesellschaften`, config);
			this.$store.commit(VERSICHERUNGSTARIFE_TYPES.MUTATIONS.VERSICHERUNGSTARIFE_GESELLSCHAFTEN, response.data);
			response = await this.$store.dispatch(VERSICHERUNGSTARIFE_TYPES.ACTIONS.RETRIEVE_SPARTE, {gesellschaftId: row.actionTarif.gesellschaftId});
			
			this.$store.commit(VERSICHERUNGSTARIFE_TYPES.MUTATIONS.RESET_TARIFE_DOKUMENTE);
			this.$store.commit(VERSICHERUNGSTARIFE_TYPES.MUTATIONS.SELECTED_VERSICHERUNGSTARIF, {
				gesellschaft: {
					id: row.actionTarif.gesellschaftId,
					disabled: true,
				},
				sparte: {
					id: row.actionTarif.versSpartentyp,
					valueList: response.values,
				},
			});
			this.$router.push({path: "/beratung/versicherungstarife/tarifBearbeiten"});
		},
		actionKundendatenZuweisen(row) {
			this.$router.push({path: "/intern/gdv/import/zuordnen", query: {id: row.id}});
		},
		confirmRowDeletion(whatRow) {
			this.$confirmModal({
				title: "Löschen",
				message: "Wollen Sie den ausgewählten Datensatz wirklich löschen?",
				labelButtonConfirm: "Löschen",
				showCloseButton: false,
			}).then(() => {
				this.deleteRow(whatRow);
			});



		},
		deleteRow(row) {
			axios.delete(`${process.env.VUE_APP_API}/bipro_import/line?id=${row.id}`, config)
			.then(_ => {
				this.loadRows();
			})
		},
		deleteSelected() {
			const params = new URLSearchParams();
			
			// Directly map and append the ids from selectedRows
			this.selected.forEach(sr => params.append('id', sr.id));

			axios.delete(`${process.env.VUE_APP_API}/bipro_import/lines?${params.toString()}`, config)
			.then(_ => {
				this.loadRows(); // Reload rows after successful deletion
				this.selected = []; // Clear selected rows after deletion
			})
			.catch(error => {
				console.error('Error deleting rows:', error);
			});
		},
		scrollLineDetailsIntoView() {

			if (this.lastRow) {
				const rowClass = `table__row__${this.lastRow?.rowIndex}`;

				if (this.$refs?.bigTable) {
					this.$refs.bigTable.scrollToRowWithClass(rowClass);
				}

				this.showLineDetails(this.lastRow?.row);
			}
		},		
	},
	mounted() {
		this.$nextTick(() => {
			this.minNumRows = this.currentPageSize;

			setTimeout(() => {
				// console.log(this.currentPageSize, "mounted called");
				this.scrollLineDetailsIntoView();
			}, 2500); 

			// this.scrollLineDetailsIntoView();
		});
	},
	beforeRouteLeave(to, from, next) {
		this.$addBreadcrumb({ to, from, });
		next();
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			const isComingFromZuordnenComponent = from?.path?.includes('/intern/gdv/import/zuordnen');
			vm.shouldUseFilter = isComingFromZuordnenComponent;
			vm.shouldUseSavedSorting = isComingFromZuordnenComponent;

			if (vm.cachedRows && isComingFromZuordnenComponent) {
				vm.rows = vm.cachedRows;
			}
		});
	}	
}
</script>

<style scoped>


.box__container {
  margin: 0 auto 8px auto;
}


.gdv-file-import-view__content {
	display: flex;
	flex-flow: column;
}

.gdv-file-import-view__table {
	flex: 0 0 auto;
	width: 100%;
}


.details__container {
  display: flex;
  width: 100%; 
  column-gap: 8px;

}

.details_column {
  width: calc(50% - 4px);
  min-width: calc(50% - 4px);
}


@media screen and (max-width: 1290px) {
	.details__container {
		flex-direction: column;
	}

	.details_column {
		width: 100%;
	}

}
</style>
