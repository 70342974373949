import ANLEGERPROFIL_TYPES from './types';
import axios from 'axios';

const config = {
  defaultSpinner: true
};

export default {
  [ANLEGERPROFIL_TYPES.ACTIONS.GET_ANLEGERPROFIL]({ commit, rootState }, payload) {

    const param = Object.entries(payload).map(([key, val]) => `${key}=${val}`).join('&');
    const path = '/../mrsinvestmentprofile' + (param ? `?${param}` : '');
    axios.get(`${rootState.core.apiAddress}${path}`, config).then(response => {
      if (response.status === 200 && response.data) {
        commit(ANLEGERPROFIL_TYPES.MUTATIONS.GET_ANLEGERPROFIL_SUCCESS, response.data)
      }
    });
  },

  [ANLEGERPROFIL_TYPES.ACTIONS.SAVE_ANLEGERPROFIL]({ state, commit, rootState}, payload) {
    return new Promise((resolve, reject) => {
      if (!payload?.category) {
        reject();
      }

      if (!state.data?.extraData?.isDisabled && (state.data?.dataHasChanged 
        || (state.data?.extraData?.schiene === 'WEALTH_KONZEPT' && payload.category === 'riskRange') // MSC-30348 always save WealthKonzept Risikobereitschaft 
        || (payload.category === 'startStep' && state.data?.extraData?.isNew))) {
        commit(ANLEGERPROFIL_TYPES.MUTATIONS.START_SAVE_ANLEGERPROFIL_STATE)
        const categoryData = state.data && state.data[payload.category];
        const categoryName = payload.categoryRequest || payload.category;
        if (categoryData) {
          const data = {
            beratungsMappeId: state.data.extraData?.beratungsMappeId,
            schiene: state.data.extraData?.schiene,
            step: payload.step,
            personType: payload.personType,
            [categoryName]: categoryData
          }
        
          axios.post(`${rootState.core.apiAddress}/../mrsinvestmentprofile`, data, config).then(response => {
            if (response.status === 200 && response.data) {
              commit(ANLEGERPROFIL_TYPES.MUTATIONS.SAVE_ANLEGERPROFIL_SUCCESS, response.data);
            }
            commit(ANLEGERPROFIL_TYPES.MUTATIONS.STOP_SAVE_ANLEGERPROFIL_STATE)
            resolve();
          })
          .catch (error => {
            commit(ANLEGERPROFIL_TYPES.MUTATIONS.STOP_SAVE_ANLEGERPROFIL_STATE)
            reject();
          });
        }
      } else {
        resolve();
      }
    });
  },
 
  async [ANLEGERPROFIL_TYPES.ACTIONS.SAVE_ANLAGEZIELE]({ state, commit, rootState}, payload) {
    if (!payload) {
      return
    }

    try {
      commit(ANLEGERPROFIL_TYPES.MUTATIONS.START_SAVE_ANLEGERPROFIL_STATE)
      const data = {
        beratungsMappeId: state.data.extraData?.beratungsMappeId,
        schiene: state.data.extraData?.schiene,
        anlageziele: Object.assign(payload || {}, { isAnlageziele: true })
      }
    
      const response = await axios.post(`${rootState.core.apiAddress}/../mrsinvestmentprofile`, data, config);
      if (response.status === 200 && response.data) {
        commit(ANLEGERPROFIL_TYPES.MUTATIONS.SAVE_ANLEGERPROFIL_SUCCESS, response.data);
      }
      commit(ANLEGERPROFIL_TYPES.MUTATIONS.STOP_SAVE_ANLEGERPROFIL_STATE)
    } catch (error) {
        commit(ANLEGERPROFIL_TYPES.MUTATIONS.STOP_SAVE_ANLEGERPROFIL_STATE)
    }
  },

  async [ANLEGERPROFIL_TYPES.ACTIONS.REFUSE_CATEGORY]({ commit, state, rootState }, payload) {
    if (!payload?.category) {
      return
    }

    try {
        commit(ANLEGERPROFIL_TYPES.MUTATIONS.START_SAVE_ANLEGERPROFIL_STATE)
        const data = {
          beratungsMappeId: state.data.extraData?.beratungsMappeId,
          schiene: state.data.extraData?.schiene,
          refusedCategory: payload
        }
        
        const response = await axios.post(`${rootState.core.apiAddress}/../mrsinvestmentprofile`, data, config);
        if (response.status === 200 && response.data) {
          commit(ANLEGERPROFIL_TYPES.MUTATIONS.SAVE_ANLEGERPROFIL_SUCCESS, response.data);
        }
        commit(ANLEGERPROFIL_TYPES.MUTATIONS.STOP_SAVE_ANLEGERPROFIL_STATE)
    } catch (error) {
      // empty block
    }
  },

  async [ANLEGERPROFIL_TYPES.ACTIONS.COPY_FINANCE_FROM_CUSTOMER_DATA]({ state, dispatch, commit, rootState }) {
    // no Anlegerprofil available
    if (state.data?.extraData?.isNew) {
      state.data.dataHasChanged = true;
      state.data.finance = {};
      const payload = {
        category: 'finance',
        personType: 'person1',
        step: 'step3-1',
      }
      await dispatch(ANLEGERPROFIL_TYPES.ACTIONS.SAVE_ANLEGERPROFIL, payload);
    }
    return new Promise((resolve, reject) => {
      const depotInhaber2 = state.data?.depotOwner2?.person?.value;
      axios.put(`${rootState.core.apiAddress}/../mrsinvestmentprofile`, { depotInhaber2: depotInhaber2, schiene: state.data.extraData?.schiene }, config).then(response => {
        if (response.status === 200 && response.data) {
          commit(ANLEGERPROFIL_TYPES.MUTATIONS.COPY_FINANCE_SUCCESS, response.data);
          resolve();
        }
      })
      .catch(() => {
        reject()
      })
    });
  },

  async [ANLEGERPROFIL_TYPES.ACTIONS.DOWNLOAD_PDF]({ rootState }, payload) {
    const response = await axios.post(`${rootState.core.apiAddress}/fileServicesActions/generatePDF`, payload, config);
    if (response.status === 200 && response.data) {
      return response.data;
    }
  },
  
  async [ANLEGERPROFIL_TYPES.ACTIONS.SIGN_PDF]({ rootState }, payload) {
    const response = await axios.post(`${rootState.core.apiAddress}/fileServicesActions/signPDF`, payload, config);
    if (response.status === 200 && response.data) {
      return response.data;
    }
  },
  
  async [ANLEGERPROFIL_TYPES.ACTIONS.SEND_TO_ELECTRONIC_SIGNATURE]({ state, rootState, dispatch, commit }) {
    // Anlegerprofile is probably null
    if (!state.data.infoRefuse) {
      await dispatch(ANLEGERPROFIL_TYPES.ACTIONS.SAVE_ANLEGERPROFIL);
    }
    const payload = {};
    payload.beratungsMappeId = state.data.extraData?.beratungsMappeId;
    payload.schiene = state.data.extraData?.schiene;
    axios.post(`${rootState.core.apiAddress}/anlegerprofil/sendToElectronicSignature`, payload, config).then(response => {
      commit(ANLEGERPROFIL_TYPES.MUTATIONS.UPDATE_IS_VORGELEGT, response?.data?.IS_VORGELEGT || false)
    })
  },

}