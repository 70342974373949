<template>
  <div class="box__container-wrap">
    <div class="box__container-rows">
      <div v-if="isDisabled" class="postfach-widget-chats__disabled">
        <UndrawChatting />
      </div>
      <template v-else-if="rows.length">
        <OverviewTable
          v-if="!$isSmallScreen"
          :records="rows"
          @title="openCustomerNewTab"
          @subject="clickBetreff"
        >
        <template #title="row"> 
            <div class="ellipsis">
              <ph-paperclip v-if="row.hasAttachment" class="mr-1" style="vertical-align: bottom" :size="16"/>
              <span>{{ row.title }}</span>
              <template v-if="row.important">
                <span class="mx-2"> | </span>
                <ph-warning-circle class="color-warning" :size="16" />
              </template>
            </div>
          </template>
      </OverviewTable>

        <Table 
          v-else
          :headers="headers"
          :rows="rows"
          rowId="channelId"
          :mobileConfig="{title: 'title', headers: ['subject', 'date']}"
          @clickRow="clickBetreff"
        >
          <template #title="row"> 
            <div class="ellipsis">
              <ph-paperclip v-if="row.hasAttachment" class="mr-1" style="vertical-align: bottom" :size="16"/>
              <span>{{ row.title }}</span>
              <template v-if="row.important">
                <span class="mx-2"> | </span>
                <ph-warning-circle class="color-warning" :size="16" />
              </template>
            </div>
          </template>
        </Table>
      </template>
      <GhostLoading v-else-if="loading" type="table" :config="{ table: { rows: 5 } }"/>
      <NoData :noIcon="$isSmallScreen" v-else />
    </div>
    
    <div v-if="!loading" class="bottom-row text-right mt-3">
      <router-link :to="`/communication/postfach/${routeStep}`">
        <template v-if="isDisabled">zu den Nachrichten...</template>
        <template v-else>weitere...</template>
      </router-link>
    </div>
  </div>
</template>

<script>
import postfachMixin from '@/views/communication/postfach/widgets/postfach-mixin.js';
import UndrawChatting from '@/components/icons/undraw/UndrawChatting.vue';
import Table from "@/components/table2/Table.vue";
import { SlotColumn, TextColumn, DateColumn } from "@/components/table2/table_util.js";
import { PhWarningCircle, PhPaperclip } from 'phosphor-vue'

export default {
  mixins: [postfachMixin],
  components: {
    UndrawChatting,
    Table,
    PhPaperclip,
    PhWarningCircle
  },
  data() {
    return {
      routeStep: 'chats',
    }
  },
  computed: {
    isDisabled(){
      return this.records?.['DISABLED'] === '1';
    },
    headers() {            
      return {
        lockedLeft: [
          SlotColumn("title", "Name"),
          DateColumn("date", "Datum"),
          TextColumn("subject", "Betreff").addCellProps({lineClamp: 4}),
        ],
        center: [],
        lockedRight: []
      }
    },
    rows() {
      return this.records?.map?.(record => ({
        ...record,
        title: record.name || "[Unbekannt]",
        subject: record.subject || "[kein Betreff]",
        isTitleLink: this.canOpenCustomer(record),
        isSubjectLink: !!record.chatId,
      })) || [];
    },
  },
  methods: {
    clickBetreff(row) {
      if (row.chatId) {
        this.$router.push({name: 'ticket', params: {chatId: parseInt(row.chatId), channelId: parseInt(row.channelId)}});
      }
    },
  },
}
</script>

<style scoped>
.postfach-widget-chats__disabled {
  margin: 0 auto;
  max-width: 250px;
}
</style>
